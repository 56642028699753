import * as React from 'react';
import {History, Location} from 'history';
import styles from './ModuleMenu.module.css';
import {Module} from '../../../shared/models/Module';
import {Page} from '../../../shared/models/Page';
import {filter} from 'lodash-es';
import ReactResizeDetector from 'react-resize-detector';
import {ThemeContext, ThemeContextProps} from '../../../app/context/ThemeContext';
import {isEmptyOrWhiteSpace} from '../../../shared/helpers/StateHelpers';
import {useContext, useRef} from 'react';
import {Navigator} from '../../context/Navigator';
import RenderHelpers from '../../../shared-ui/helpers/RenderHelpers';

interface ModuleMenuProps {
  className?: string;
  modules: Module[];
  history: History;
  location: Location;
  onResize: (hasSufficientSpace: boolean) => void;
}

export const ModuleMenu = (props: ModuleMenuProps) => {
  const {navigateTo} = useContext(Navigator);
  const currentWidthRef = useRef<number>(undefined);

  const onResize = (width: number) => {
    // Only call onResize() when the width actually changes, to prevent recursive update loops
    if (currentWidthRef.current !== width) {
      currentWidthRef.current = width;
      let requiredWidth = props.modules.length * 96;
      let hasSufficientSpace = width + 12 >= requiredWidth;
      props.onResize(hasSufficientSpace);
    }
  };

  const renderMenuItem = (module: Module,
                          index: number,
                          themeContext: ThemeContextProps) => {
    const url = '/' + module.urlSlug;
    const words = module.name.split(' ', 2);
    const firstWord = words[0];
    const secondWord = words[1];

    let activePages = filter<Page<any>>(module.pages, (page: Page<any>) => page.isVisible());

    let willShowPageMenu = (!activePages || activePages.length === 0 ||
      (activePages.length === 1 && activePages[0].isDefault));

    let isActive = props.history.location.pathname.split('/')[1] === url.split('/')[1];

    const moduleExternalLinkUrl = findModuleExternalLinkUrl(module);

    const tabContents = [
      <span
        key={0}
        style={{color: isActive ? themeContext.theme.mainMenu.primaryTextColor : ''}}
        className={styles.firstWord}
      >
          {(firstWord || '').toLowerCase()}
      </span>,
      <br key={1}/>,
      <span
        key={2}
        style={{color: isActive ? themeContext.theme.mainMenu.secondaryTextColor : ''}}
        className={styles.secondWord}
      >
      {(secondWord || '').toUpperCase()}
      </span>,
      <div
        key={3}
        className={willShowPageMenu ? styles.activeCaretWithPageMenu : styles.activeCaretWithoutPageMenu}
        style={{borderBottomColor: themeContext.theme.subMenu.primaryBackgroundColor}}
      />
    ];

    if (isEmptyOrWhiteSpace(moduleExternalLinkUrl)) {
      return (
        <div
          key={index}
          className={
            RenderHelpers.classes(
              'moduleMenuItem',
              isActive ? 'active' : undefined
            )
          }
          onClick={() => {
            navigateTo(url);
          }}
        >
          {tabContents}
        </div>
      );
    } else {
      return (<div
        key={index}
        onClick={() => openExternalLink(moduleExternalLinkUrl)}
        className={['moduleMenuItem', isActive ? 'active' : ''].join(' ')}
      >
        {tabContents}
      </div>);
    }
  };

  const openExternalLink = (url: string) => {
    window.open(url, '_blank');
  };

  const findModuleExternalLinkUrl = (module: Module): string | undefined => {
    if (module.pages.length === 1) {
      const onlyPage = module.pages[0];
      if (onlyPage.isDefault && !isEmptyOrWhiteSpace(onlyPage.externalLinkUrl)) {
        return onlyPage.externalLinkUrl;
      }
    }

    return undefined;
  };

  return (
    <ThemeContext.Consumer>
      {themeContext => {
        return (
          <div className={[styles.menu, props.className].join(' ')}>
            <ReactResizeDetector
              handleWidth={true}
              onResize={onResize}
              refreshMode="debounce"
              refreshRate={500}
            />
            {
              props.modules.map((module: Module, index: number) =>
                renderMenuItem(module, index, themeContext)
              )
            }
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
};
