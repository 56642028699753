import * as React from 'react';
import {History} from 'history';
import styles from './SideModuleMenu.module.css';
import {Module} from '../../../shared/models/Module';
import {NavLink} from 'react-router-dom';
import {Page} from '../../../shared/models/Page';
import Collapse from 'antd/es/collapse/Collapse';
import ConfigService from '../../../shared/services/ConfigService';
import {filter} from 'lodash-es';
import {findPageSlug, stripRouteParameters} from '../../../shared/helpers/RouteHelpers';
import {classes} from '../../../shared/helpers/RenderHelpers';
import ReactResizeDetector from 'react-resize-detector';
import MobileMenuService from '../../../shared/services/MobileMenuService';

const Panel = Collapse.Panel;

interface ModuleMenuProps {
  modules: Module[];
  history: History;
  location: Location;
  onNavigate?: (opening: boolean) => void;
}

export default class SideModuleMenu extends React.Component<ModuleMenuProps, {}> {
  private currentWidth: number | undefined;
  private onlyMobilePages: boolean = false;

  render() {

    let modules;
    let Active = this.props.history.location.pathname.split('/')[1];
    if (this.hideNoneMobilePages()) {
      modules = filter(this.props.modules, x => x.showOnMobile !== false)
        .map((module, index) => this.renderMenuItem(module, index));
    } else {
      modules = this.props.modules
        .map((module, index) => this.renderMenuItem(module, index));
    }

    return (
      <div>
        <ReactResizeDetector
          handleWidth={true}
          onResize={width => this.resizeEvent(width)}
        />
        <div className={styles.menu}>
          <Collapse defaultActiveKey={[Active]} accordion={true}>
            {modules}
          </Collapse>
        </div>
      </div>
    );
  }

  resizeEvent = (width: number) => {

    if (this.currentWidth !== width) {
      this.currentWidth = width;
      if (width < 650 && this.onlyMobilePages === false) {
        this.onlyMobilePages = true;
        this.forceUpdate();
        MobileMenuService.showAllPages = false;
        return;
      }

      if (width > 650) {
        this.onlyMobilePages = false;
        MobileMenuService.showAllPages = true;
        this.forceUpdate();
        return;
      }
    }
  };

  hideNoneMobilePages = (): boolean => {
    if (this.onlyMobilePages) {
      return true;
    } else {
      return false;
    }
  };

  private renderMenuItem = (module: Module, index: number) => {

    const url = '/' + stripRouteParameters(module.urlSlug);
    const words = module.name.split(' ', 2);
    const firstWord = words[0];
    const secondWord = words[1];
    let activePages;

    if (this.hideNoneMobilePages()) {
      activePages = filter<Page<any>>(
        module.pages,
        (page: Page<any>) =>
          page.isVisible()
          && (page.showInMenu !== false)
          && (page.showOnMobile !== false)
      );
    } else {
      activePages = filter<Page<any>>(
        module.pages,
        (page: Page<any>) => page.isVisible() && (page.showInMenu !== false));
    }

    let isDefault = (activePages.length === 1 && activePages[0].isDefault && !activePages[0].pages);
    let isActive =
      this.props.history.location.pathname.split('/')[1] === url.split('/')[1];

    return (
      <Panel
        className={[styles.panelGrey, isDefault ? styles.noDropDown : '', isActive ? styles.active : ''].join(' ')}
        header={
          isDefault ?
            <NavLink
              key={index + 'Panel'}
              exact={true}
              to={url}
              onClick={() => this.navigate(url)}
              className={[styles.sideModuleMenuItem].join(' ')}
            >
              <span className="firstWord" style={{marginRight: 5}}>{(firstWord || '').toLowerCase()}</span>
              <span>{(secondWord || '').toUpperCase()}</span>
            </NavLink>
            :
            <div className={styles.sideModuleMenuItem} key={'divSub' + index}>
              <span className="firstWord" style={{marginRight: 5}}>{(firstWord || '').toLowerCase()}</span>
              <span>{(secondWord || '').toUpperCase()}</span>
            </div>
        }

        key={stripRouteParameters(module.urlSlug)}
      >
        {
          activePages.length > 1 ?
            activePages.map((entries, subIndex) => this.renderSubMenuItem(entries, subIndex, url))
            :
            activePages.length === 1 && activePages[0].pages ?
              this.renderOnePageWithSideMenu(activePages[0], url)
              :
              isDefault ? '' : activePages.map((entries, subIndex) => this.renderSubMenuItem(entries, subIndex, url))
        }
      </Panel>
    );
  };

  private renderSubMenuItem = (module: Page<any>, index: number, url: string) => {

    const subUrl = '/' + stripRouteParameters(module.urlSlug);

    let isActive = findPageSlug(this.props.history.location.pathname) === subUrl;

    if (module.pages !== undefined && module.pages.length > 1) {
      return (
        <Collapse accordion={true} key={'sub' + module.name}>
          <Panel
            className={[styles.subPanelGrey, styles.panelGrey, isActive ? styles.active : ' '].join(' ')}
            header={
              <div
                key={index + 'collapseIndex'}
                className={styles.subNavLinks}
              >
                {module.name}
              </div>
            }
            key={'SubPanel' + (index + 1)}
          >
            {
              module.pages.map((entries, subIndex) => {
                  let isNavActive =
                    this.props.history.location.pathname.split('/')[3] === entries.urlSlug;
                  return (
                    <div
                      key={'modules' + index}
                      className={
                        classes(
                          styles.subNavLinksContainer,
                          styles.subPanelLink,
                          isNavActive ? styles.active : null
                        )
                      }
                    >
                      <NavLink
                        className={styles.subNavLinks + ' ' + styles.subPanelNavLink}
                        to={url + '/' + stripRouteParameters(module.urlSlug) + '/' + entries.urlSlug}
                        onClick={this.generalNavigate}
                      >
                        {entries.name}
                      </NavLink>
                    </div>
                  );
                }
              )
            }
          </Panel>
        </Collapse>
      );

    } else {
      let currentPath = this.props.history.location.pathname.split('/')[2];
      let isNavActive =
        currentPath === module.urlSlug || module.urlSlug.startsWith(currentPath);

      return (
        <div
          className={[styles.subNavLinksContainer, isNavActive ? styles.active : ''].join(' ')}
          key={url + '/' + stripRouteParameters(module.urlSlug)}
        >
          <NavLink
            className={styles.subNavLinks}
            key={index + 'NavLink'}
            to={url + '/' + stripRouteParameters(module.urlSlug)}
            onClick={this.generalNavigate}
          >
            {module.name}
          </NavLink>
        </div>
      );
    }
  };

  private renderOnePageWithSideMenu = (module: Page<any>, url: string) => {
    let currentLocation = window.location.pathname;
    let downloadBaseUrl = ConfigService.config.backendUrl;
    let routeURL = currentLocation.replace(downloadBaseUrl + '/', '');

    let activePages;

    if (this.hideNoneMobilePages()) {
      activePages = filter<Page<any>>(
        module.pages,
        (page: Page<any>) =>
          page.isVisible()
          && (page.showInMenu !== false)
          && (page.showOnMobile !== false)
      );
    } else {
      activePages = filter<Page<any>>(
        module.pages,
        (page: Page<any>) => page.isVisible() && (page.showInMenu !== false));
    }

    return (
      <div key={routeURL}>
        {
          activePages !== undefined && activePages.length >= 1 ?
            activePages.map((entries, index) => {
              let newURl = url + '/' + stripRouteParameters(module.urlSlug) + '/' + entries.urlSlug;
              return (
                <div
                  className={
                    classes(
                      routeURL === newURl ? styles.activeSubNavLinksContainer : null,
                      styles.subNavLinksContainer
                    )
                  }
                  key={'onse' + index}
                >
                  {entries.showOnMobile
                    ? <NavLink className={styles.subNavLinks} to={newURl} onClick={() => this.navigate(newURl)}>
                      {entries.name}
                    </NavLink>
                    : <div className={classes(styles.subNavLinks, styles.unselected)}>
                      {entries.name}
                    </div>
                  }

                </div>
              );
            })
            :
            ''
        }
      </div>);
  };

  private navigate = (url: string) => {
    let params = url.indexOf(':');
    if (params >= 0) {
      url = url.substr(0, params - 1);
    }

    this.props.history.push(url);

    this.generalNavigate();
  };

  private generalNavigate = () => {
    // Navigating Using Nav Links
    this.forceUpdate();
    if (this.props.onNavigate) {
      this.props.onNavigate(true);
    }
  };
}
