import * as React from 'react';
import {Report} from '../types/Report';

export interface ReportCacheProps {
  getPublishedReport: (reportId: string) => Report | undefined;
  clearReportCache: (reportId?: string) => void;
}

export const ReportCache = React.createContext<ReportCacheProps>({
  getPublishedReport: () => {
    throw new Error('Not implemented');
  },
  clearReportCache: () => {
    throw new Error('Not implemented');
  }
});
