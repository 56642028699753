import {isNil} from 'lodash-es';

export interface ShowWizardInterface {
  hidePermanently: boolean;
}

export default class SurveyWizardService {
  private static readonly  SHOW_SURVEY_WIZARD_STORAGE_KEY = 'SSWSK';
  private static showSurveyWizard: boolean = true;
  private static skipNextDisplay: boolean = false;

  public static setShowSurveyWizard(currentState: boolean): void  {
    let currentStorage = localStorage.getItem(this.SHOW_SURVEY_WIZARD_STORAGE_KEY);
    if (isNil(currentStorage)) {
      let token: ShowWizardInterface = {
        hidePermanently: false
      };
      localStorage.setItem(this.SHOW_SURVEY_WIZARD_STORAGE_KEY, JSON.stringify(token));
      SurveyWizardService.showSurveyWizard = currentState;
    } else {
      let w: ShowWizardInterface = JSON.parse(currentStorage);
      if (w.hidePermanently === true) {
        SurveyWizardService.showSurveyWizard = false;
      } else {
        SurveyWizardService.showSurveyWizard = currentState;
      }
    }
  }

  public static skipDisplay(): void  {
    this.skipNextDisplay = this.skipNextDisplay === false ? true : false;
  }

  public static permanentlyDisable(): void  {
    let token: ShowWizardInterface = {
      hidePermanently: true
    };
    localStorage.setItem(this.SHOW_SURVEY_WIZARD_STORAGE_KEY, JSON.stringify(token));
    SurveyWizardService.showSurveyWizard = false;
  }

  public static permanentlyEnable(): void  {
    let token: ShowWizardInterface = {
      hidePermanently: false
    };
    localStorage.setItem(this.SHOW_SURVEY_WIZARD_STORAGE_KEY, JSON.stringify(token));
    SurveyWizardService.showSurveyWizard = true;
  }

  public static isPermanentlyDisable(): boolean  {
    let currentStorage = localStorage.getItem(this.SHOW_SURVEY_WIZARD_STORAGE_KEY);
    if (isNil(currentStorage)) {
      return false;
    } else {
      let w: ShowWizardInterface = JSON.parse(currentStorage);
      if (w.hidePermanently === true) {
        return true;
      } else {
        return false;
      }
    }
  }

  public static displaySurveyWizard(): boolean  {
    let currentStorage = localStorage.getItem(this.SHOW_SURVEY_WIZARD_STORAGE_KEY);
    if (isNil(currentStorage)) {
      if (this.skipNextDisplay === true ) {
        this.skipDisplay();
        return false;
      }
      return SurveyWizardService.showSurveyWizard;
    } else {
      let w: ShowWizardInterface = JSON.parse(currentStorage);
      if (w.hidePermanently === true) {
        return false;
      } else {
        if (this.skipNextDisplay === true ) {
          this.skipDisplay();
          return false;
        }
        return SurveyWizardService.showSurveyWizard;
      }
    }
  }

}