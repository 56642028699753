import {isNil} from 'lodash-es';

export default class StringHelpers {

  public static padStart(text: string, paddedLength: number, character: string) {
    return character.repeat(paddedLength - text.length) + text;
  }

  public static safeTrim(str: string | undefined | null) {
    if (!str) {
      return '';
    }

    return str.trim();
  }

  public static replaceAll(inString: string, match: string, replaceWith: string, ignoreCase: boolean = false) {
    return inString.replace(
      new RegExp(
        match.replace(/([\/,!\\^${}\[\]().*+?|<>\-&])/g, '\\$&'),
        (ignoreCase ? 'gi' : 'g')
      ),
      replaceWith.replace(/\$/g, '$$$$'));
  }

  public static equalsIgnoringCase<T extends string>(a: T | undefined | null, b: T | undefined | null): boolean {
    if (!isNil(a) && !isNil(b)) {
      return StringHelpers.safeTrim(a).toLowerCase() === StringHelpers.safeTrim(b).toLowerCase();
    }

    return a === b;
  }

  // tslint:disable:no-bitwise
  /// Hash a string using cyrb53
  public static hash(str: string, seed: number = 0) {
    let h1 = 0xdeadbeef ^ seed;
    let h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  }
  // tslint:enable:no-bitwise
}
