import Helpers from '../helpers/Helpers';
import {isNil} from 'lodash-es';

export type Message = { messageType: string } | string;
export type MessageFilter = string | ((...args: any[]) => boolean);
export type SubscriptionCallback = (...args: any[]) => Promise<unknown>;

interface EventSubscription {
  filter: MessageFilter;
  callback: SubscriptionCallback;
}

export class MessageBus {

  private static _subscribers: EventSubscription[] = [];

  public static subscribe = (filter: MessageFilter, callback: SubscriptionCallback): void => {
    if (isNil(filter) || isNil(callback)) {
      return;
    }

    MessageBus._subscribers = Helpers.addOrReplaceInImmutableWhere(
      MessageBus._subscribers,
      {
        filter: filter,
        callback: callback
      },
      item => item.callback === callback
    );
  };

  public static unsubscribe = (callback: SubscriptionCallback) => {
    if (isNil(callback)) {
      return;
    }

    MessageBus._subscribers = Helpers.removeFromImmutableWhere(
      MessageBus._subscribers,
      item => item.callback === callback
    );
  };

  public static publish = async <T>(message: Message & any): Promise<T | undefined> => {
    const messageType = typeof message === 'string' ? message : message.messageType;

    const args = [];
    if (typeof message === 'string') {
      args.push({messageType: messageType});
    } else {
      args.push(message);
    }

    for (let subscriber of MessageBus._subscribers) {
      if (typeof subscriber.filter === 'string' && subscriber.filter !== messageType) {
        continue;
      }
      if (typeof subscriber.filter === 'function' && !subscriber.filter(...args)) {
        continue;
      }

      return await subscriber.callback(...args) as T;
    }

    return undefined;
  };
}
