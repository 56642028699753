import * as React from 'react';
import styles from './LegacyAppModal.module.css';
import CircleIcon from '../../../shared/components/CircleIcon/CircleIcon';
import Icon from 'antd/es/icon';
import {ReactComponent as EnterpriseLogo} from '../../../app/images/svg/Engage Logo EX.svg';
import {ReactElement, ReactNode} from 'react';
import StackPanel from '../StackPanel/StackPanel';
import {isNil} from 'lodash-es';
import {deprecated} from '../../../shared-refactoring/decorators/deprecated';
import If from '../../../shared-ui/components/If/If';
import RenderHelpers from '../../../shared-ui/helpers/RenderHelpers';
import {ServiceTerms} from '../../../shared-ui/components/ServiceTerms/ServiceTerms';

interface LegacyAppModalProps {
  icon: string;
  title: string;
  className?: string;
  childrenClassName?: string;
  showDisclaimer?: boolean;
  hideBranding?: boolean;
  hidePanelOnMobile?: boolean;
  secondPanelContent?: ReactElement[] | ReactNode | undefined;
}

@deprecated('ShellModal', 'LoginDisclaimer')
export default class LegacyAppModal extends React.Component<LegacyAppModalProps, {}> {
  render() {
    return (
      <StackPanel
        orientation={'vertical'}
        verticalAlignment={'center'}
        className={
          RenderHelpers.classes(
            styles.component,
            isNil(this.props.secondPanelContent) ? styles.container : styles.bigContainer
          )
        }
      >
        <div className={styles.headingContainer}>
          <CircleIcon> <Icon type={this.props.icon} className={styles.headingIcon}/></CircleIcon>
          <span className={styles.headingText}>{this.props.title}</span>
        </div>
        <StackPanel orientation={'horizontal'} className={styles.contentContainer} width={'fill'}>
          <StackPanel
            orientation={'vertical'}
            className={
              RenderHelpers.classes(
                this.hasSidePanelContent() ? styles.halfWidth : styles.mainContentContainer
              )
            }
          >
            {this.renderLogo()}
            {this.props.children}
            <If condition={this.props.showDisclaimer}>
              <ServiceTerms className={styles.disclaimer}/>
            </If>
          </StackPanel>
          {this.hasSidePanelContent() ? <div className={styles.verticalDivider}>&nbsp;</div> : ''}
          {
            this.hasSidePanelContent() ?
              <StackPanel
                orientation={'vertical'}
                className={RenderHelpers.classes(styles.sidePanel, styles.halfWidth)}
              >
                {this.props.secondPanelContent}
              </StackPanel> : ''
          }
        </StackPanel>
      </StackPanel>
    );
  }

  hasSidePanelContent = () => {
    return !isNil(this.props.secondPanelContent);
  };

  renderLogo = () => {
    return (
      <div>
        <div className={styles.enterpriseLogo}>
          <EnterpriseLogo className={styles.enterpriseLogoSvg}/>
        </div>
        <span className={styles.enterpriseLogoSubHeading}>understand • enable • engage</span>
      </div>
    );
  };
}
