import * as React from 'react';
import {PropsWithChildren, useState} from 'react';
import {useCache} from '../../shared-common/hooks/useCache';
import {Person} from '../../shared-common/types/Person';
import {CohortCache} from '../../shared-common/context/CohortCache';
import DataService from '../../shared/services/DataService';
import {PersonFilter} from '../../shared-common/types/PersonFilter';

export const CohortStore = (props: PropsWithChildren<{}>) => {
  const [isProcessing, setIsProcessing] = useState<boolean>();

  const {clearCache, getValue} = useCache<PersonFilter, Person[]>(
    async (key: PersonFilter) => {
      setIsProcessing(true);
      try {
        return await DataService.tryLoad<Person[]>(
          '/master-data/get-cohort', {
            clientId: key.clientId,
            virtualStructureId: key.virtualStructureId,
            useLatestStructure: key.useLatestStructure,
            unitIds: key.unitIds,
            datasetIds: key.datasetIds,
            onlyWithResponses: key.onlyWithResponses
          });
      } finally {
        setIsProcessing(false);
      }
    }
  );

  const getCohort = (filter: PersonFilter) => {
    return getValue(filter);
  };

  const clearCohortCache = () => {
    clearCache();
  };

  return (
    <CohortCache.Provider
      value={{
        getCohort: getCohort,
        clearCohortCache: clearCohortCache,
        isCohortCacheProcessing: isProcessing
      }}
    >
      {props.children}
    </CohortCache.Provider>
  );
};
