import {DataResponse} from '../../shared/models/DataResponse';
import DataService, {FetchOperation} from '../../shared/services/DataService';
import {handleError} from '../../shared/helpers/ErrorHandlerHelpers';
import {showErrorMessage} from '../../shared/helpers/PopupMessageHelpers';
import ChartDataPoint from '../models/ChartDataPoint';
import {isNil, some} from 'lodash-es';
import {PerQuestionResults} from '../models/PerQuestionResults';
import {PerDemographicAttributeResults} from '../models/PerDemographicAttributeResults';
import {DataPointStatus} from '../models/DataPointStatus';
import {DataPoint} from '../models/DataPoint';
import {MercuryResult} from '../../mercury/types/MercuryResult';

export async function loadDataOrThrow<T>(url: string, requestBody?: any, abortSignal?: AbortSignal): Promise<T> {
  let response: DataResponse<T>;
  try {
    response = await DataService.fetch<T>(url, requestBody, FetchOperation.Post, undefined, abortSignal);
  } catch (err) {
    if (!isNil(abortSignal) && abortSignal.aborted) {
      return undefined;
    }
    handleError(err);
    throw err;
  }

  if (response.statusCode === 200) {
    return response.data;
  } else if (response.hasNetworkFailure) {
    showErrorMessage(true);
  }

  const err = new Error(response.message);
  handleError(err);
  throw err;
}

export function createChartDataPoint<TData>(entry: MercuryResult,
                                            accessor: (data: TData) => number | undefined,
                                            label?: string,
                                            colour?: string): ChartDataPoint {

  let status: DataPointStatus;
  let value: number | undefined;

  switch (entry.availability) {
    case 'pending':
    case 'loading':
      status = DataPointStatus.Loading;
      value = 0;
      break;
    case 'failed':
      status = DataPointStatus.LoadingError;
      value = 0;
      break;
    default:
      status = DataPointStatus.Available;
      if (isNil(entry.value)) {
        status = DataPointStatus.InsufficientResponses;
        value = 0;
      } else {
        value = accessor(entry.value as TData);
        if (isNil(value)) {
          status = DataPointStatus.InsufficientResponses;
          value = 0;
        }
      }
      break;
  }

  return {
    status: status,
    value: value,
    label: label,
    colour: colour
  };
}

export function createDataPoint<TQueryData, TDataPointValue>(
  entry: MercuryResult,
  defaultValue: TDataPointValue,
  accessor: (data: TQueryData) => TDataPointValue)
  : DataPoint<TDataPointValue> {

  let status: DataPointStatus;
  let value: TDataPointValue;

  switch (entry.availability) {
    case 'pending':
    case 'loading':
      status = DataPointStatus.Loading;
      value = undefined;
      break;
    case 'failed':
      status = DataPointStatus.LoadingError;
      value = undefined;
      break;
    default:
      status = DataPointStatus.Available;
      if (isNil(entry.value)) {
        status = DataPointStatus.InsufficientResponses;
        value = undefined;
      } else {
        value = accessor(entry.value as TQueryData);
        if (isNil(value)) {
          status = DataPointStatus.InsufficientResponses;
          value = undefined;
        }
      }
      break;
  }

  return {
    status: status,
    value: value
  };
}

export function getAverageForQuestions<T>(results: PerQuestionResults<T>,
                                          questionCodes: string[],
                                          accessor: (a: T) => number): number | undefined {

  if (!some(questionCodes)) {
    return undefined;
  }

  let total: number = 0;
  let includedCount: number = 0;
  for (let code of questionCodes) {
    const result = results[code];
    if (result) {
      total += accessor(result);
      includedCount++;
    }
  }

  if (includedCount === 0) {
    return undefined;
  }

  return total / includedCount;
}

export function getTotalForQuestions<T>(results: PerQuestionResults<T>,
                                        questionCodes: string[],
                                        accessor: (a: T) => number): number | undefined {

  if (questionCodes.length <= 0) {
    return undefined;
  }

  let total: number = 0;
  for (let code of questionCodes) {
    const result = results[code];
    if (result) {
      total += accessor(result);
    }
  }

  return total;
}

export function getMaxForQuestions<T>(results: PerQuestionResults<T>,
                                      questionCodes: string[],
                                      accessor: (a: T) => number): number | undefined {

  if (questionCodes.length <= 0) {
    return undefined;
  }

  let max: number = 0;
  for (let code of questionCodes) {
    const result = results[code];
    if (result) {
      max = Math.max(max, accessor(result));
    }
  }

  return max;
}

export function getMaxForQuestionChoices<T>(results: PerQuestionResults<T>,
                                            questionChoiceCodes: string[],
                                            accessor: (a: T) => number): number | undefined {

  if (questionChoiceCodes.length <= 0) {
    return undefined;
  }

  let max: number = 0;
  for (let code of questionChoiceCodes) {
    const result = results[code];
    if (result) {
      max = Math.max(max, accessor(result));
    }
  }

  return max;
}

export function getTotalForDemographicAttributes<T>(results: PerDemographicAttributeResults<T>,
                                                    attributeCodes: string[],
                                                    accessor: (a: T) => number | undefined): number | undefined {

  if (attributeCodes.length <= 0) {
    return undefined;
  }

  let total: number = 0;
  for (let code of attributeCodes) {
    const result = results[code];
    if (result) {
      const accessedResult = accessor(result);
      // Skip cases where there are insufficient responses
      if (!isNil(accessedResult)) {
        total += accessedResult;
      }
    }
  }

  return total;
}

export function getAverageForDemographicAttributes<T>(results: PerDemographicAttributeResults<T>,
                                                      attributeCodes: string[],
                                                      accessor: (a: T) => number): number | undefined {

  if (!some(attributeCodes)) {
    return undefined;
  }

  let total: number = 0;
  let includedCount: number = 0;
  for (let code of attributeCodes) {
    const result = results[code];

    if (result) {
      const accessedValue = accessor(result);
      if (!isNil(accessedValue)) {
        total += accessor(result);
        includedCount++;
      }
    }
  }

  if (includedCount === 0) {
    return undefined;
  }

  return total / includedCount;
}
