import * as React from 'react';
import styles from './License.module.css';
import {ReactComponent as IconExpire} from './Images/License-Expire.svg';
import {ReactComponent as IconTrail} from './Images/License-Trial.svg';
import {ReactComponent as IconValid} from './Images/License-Valid.svg';
import {ReactComponent as Icon30days} from './Images/License-30Days.svg';
import {default as PalimsPublicService} from '../../../partner-management/PalimsPublicService';
import UserService from '../../services/UserService';
import {ClientLicenseState} from '../../../partner-management/PalimsShared';
import PalimsProtectedService, {LicenceDetails} from '../../../partner-management/PalimsProtectedService';

export enum subscription_statuses {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  PROVISIONALLY_ACTIVE = 'PROVISIONALLY_ACTIVE'
}

interface LicenseState {
  licenceState: ClientLicenseState;
  partnerName: string;
}

export default class License extends React.Component<{}, LicenseState> {
  private client: string | undefined;

  constructor(props: {}) {
    super(props);
    this.state = {
      partnerName: '',
      licenceState: ClientLicenseState.NotSet
    };

  }

  async componentDidMount() {
    if (UserService.instance.currentClient) {
      this.client = UserService.instance.currentClient.id;
      this.calculateLicenceIcon();
    } else {
      this.client = undefined;
    }
  }

  async componentWillReceiveProps(props: {}) {

    if (UserService.instance.currentClient) {
      if (this.client !== UserService.instance.currentClient.id
        || this.state.licenceState.valueOf() === ClientLicenseState.NotSet.valueOf() || this.client === undefined ) {
        this.client = UserService.instance.currentClient.id;
        this.calculateLicenceIcon();
      }
    }
  }

  calculateLicenceIcon = async () =>  {
    let result =  await PalimsProtectedService.GetLicenceDetails();
    if (result) {
      let licences: LicenceDetails[] = result.licences;
      let licenseState: ClientLicenseState = ClientLicenseState.ValidLicense;
      for (var i = 0 ; i < result.licences.length; i++) {
        licenseState = PalimsPublicService.calculateLicence(licences[i]);
        if (licenseState !== ClientLicenseState.ValidLicense) {
          break;
        }
      }
      this.setState({licenceState: licenseState});
    }
  }

  render() {
    return <div className={styles.container}>
      {this.state.licenceState.valueOf()  === ClientLicenseState.ExpiredLicense.valueOf()         ?  <IconExpire/> : ''}
      {this.state.licenceState.valueOf()  === ClientLicenseState.Expires30daysLicense.valueOf()   ?  <Icon30days/> : ''}
      {this.state.licenceState.valueOf()  === ClientLicenseState.TrialLicense.valueOf()           ?  <IconTrail/>  : ''}
      {this.state.licenceState.valueOf()  === ClientLicenseState.ValidLicense.valueOf()           ?  <IconValid/>  : ''}
    </div>;
  }
}
