import * as React from 'react';
import { Input, Button} from 'antd';
import UserService from '../../../shared/services/UserService';
import RouteInformation from '../../../shared/models/RouteInformation';
import styles from './ShellChangePassword.module.css';
import {
  bindToInput, createTwoWayBinding, getBindingErrorMessage, validate,
  ValidateOn
} from '../../../shared/helpers/BindingHelpers';
import FormSection from '../../../shared/components/FormSection/FormSection';
import {TwoWayBinding} from '../../../shared/models/TwoWayBinding';
import {isEmptyOrWhiteSpace} from '../../../shared/helpers/StateHelpers';
import FormItem from '../../../shared/components/FormItem/FormItem';
import {showErrorMessage} from '../../../shared/helpers/PopupMessageHelpers';
import LegacyAppModal from '../../../shared/components/LegacyAppModal/LegacyAppModal';
import {SecurityHelpers} from '../../../shared-security/helpers/SecurityHelpers';

interface ResetState {
  oldPassword: string;
  newPassword: string;
  passwordConfirm: string;
  isLoading: boolean;
}

export default class ShellChangePassword extends React.Component<RouteInformation<{}>, ResetState> {
  private _oldPassword: TwoWayBinding;
  private _passwordConfirm: TwoWayBinding;
  private _password: TwoWayBinding;

  constructor(props: RouteInformation<{}>) {
    super(props);
    this.state = {
      isLoading: false,
      newPassword: '',
      passwordConfirm: '',
      oldPassword: '',
    };

    if (!UserService.instance.userEmail) {
      props.history.push('/');
      return;
    }

    this._oldPassword = createTwoWayBinding(this, 'oldPassword')
      .withValidation(this.validateOldPassword);

    this._password = createTwoWayBinding(this, 'newPassword')
      .withValidation(this.validatePassword);

    this._passwordConfirm = createTwoWayBinding(this, 'passwordConfirm')
      .withValidation(this.validatePasswordConfirm);
  }

  private validateOldPassword = async () => {
    if (isEmptyOrWhiteSpace(this.state.oldPassword)) {
      return 'Please enter old password...';
    }

    return '';
  };

  private validatePassword = async () => {

    if (isEmptyOrWhiteSpace(this.state.newPassword)) {
      return 'Please enter new password...';
    }
    return '';
  };

  private validatePasswordConfirm = async () => {
    if (isEmptyOrWhiteSpace(this.state.passwordConfirm)) {
      return 'Please retype new password...';
    }

    const complexityCheck = SecurityHelpers.validatePasswordComplexity(this.state.newPassword);
    if (complexityCheck !== true) {
      return complexityCheck;
    }

    if (this.state.passwordConfirm !== this.state.newPassword) {
      return 'Passwords do not match...';
    }

    try {
      let result = await UserService.instance.changePassword(this.state.oldPassword, this.state.newPassword);
      if (result.statusCode === 200) {
        this.props.history.push('/');
      } else {
        return 'Your old password did not match, please try again...';
      }
    } catch (ex) {
      if (ex.statusCode >= 500) {
        showErrorMessage();
      }
    }
    return '';
  };

  handleSubmit = async () => {
    this.setState({isLoading: true});
    await validate(this._oldPassword, this._password, this._passwordConfirm);
    this.setState({isLoading: false});
  };

  render() {
    return (
      <LegacyAppModal icon={'idcard'} title={'Change Password'} showDisclaimer={false} hideBranding={true}>
        <FormSection
          formWidth={332}
          controlWidth={215}
          className={styles.formSection}
          errorMessage={getBindingErrorMessage(this._oldPassword, this._password, this._passwordConfirm)}
        >
          <FormItem label="Old password" hideLoadingAnimations={true}>
            <Input
              autoFocus={true}
              {...bindToInput(this._oldPassword, ValidateOn.Change)}
              type="password"
            />
          </FormItem>
          <FormItem label="New password" hideLoadingAnimations={true}>
            <Input
              {...bindToInput(this._password, ValidateOn.Change)}
              type="password"
            />
          </FormItem>
          <FormItem label="Retype password" hideLoadingAnimations={true}>
            <Input
              {...bindToInput(this._passwordConfirm, ValidateOn.Change)}
              type="password"

            />
          </FormItem>
        </FormSection>
          <Button
            loading={this.state.isLoading}
            type="primary"
            htmlType="submit"
            className={styles.submit}
            onClick={this.handleSubmit}
            style={{marginTop: 5, marginBottom: 20}}
          >
            Change password
          </Button>
      </LegacyAppModal>
    );
  }
}
