import * as React from 'react';

export interface LanguageRoot {
  code: string;
  name: string;
  isCommon: boolean;
}

export interface Territory {
  code: string;
  name: string;
}

export interface Dialect {
  languageRootCode: string;
  territoryCode: string;
  isCommon: boolean;
}

export interface LanguageProps {
  languageRoots: LanguageRoot[];
  territories: Territory[];
  dialects: Dialect[];
  getNameForLanguageCode: (languageCode: string) => string | undefined;
  splitLanguageCode: (languageCode: string | undefined) =>
    { rootCode: string | undefined, territoryCode: string | undefined };
}

export const Languages = React.createContext<LanguageProps>({
  languageRoots: [],
  territories: [],
  dialects: [],
  getNameForLanguageCode: () => { throw 'Not implemented'; },
  splitLanguageCode: () => { throw 'Not implemented'; }
});
