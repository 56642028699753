import * as React from 'react';
import {Icon, Tooltip} from 'antd';
import UserService from '../../../shared/services/UserService';
import StackPanel from '../../../shared/components/StackPanel/StackPanel';
import {ReactComponent as InclusionIcon} from '../LoginPage/images/menu-inclusion.svg';
import {ReactComponent as FlowIcon} from '../LoginPage/images/menu-flow-at-work.svg';
import styles from './DemoLinks.module.css';
import RouteInformation from '../../../shared/models/RouteInformation';

export default class DemoLinks
  extends React.Component<RouteInformation<{}>, {}> {

  render() {
    return (
      <StackPanel
        orientation={'vertical'}
      >
        <StackPanel
          orientation={'vertical'}
          className={styles.demoLinksContainer}
          horizontalAlignment={'center'}
          width={'fill'}
        >
        <span className={styles.dashboardHeadings}>Analytics Dashboard Demos</span>
        <span className={styles.dashboardContent}>Click on the links below to access demonstration versions of
            the analytics dashboard
          </span>
        </StackPanel>
        <StackPanel
          orientation={'vertical'}
          className={styles.demoLinksContainer}
          horizontalAlignment={'center'}
          width={'fill'}
        >
          <StackPanel orientation={'horizontal'} className={styles.demoLinksItems} >
            <Tooltip
              title={'View our Incusion@Way-of-Work Diversity dashboard'}
              openClassName={styles.tooltip}
              overlayClassName={styles.tooltip}
            >
              <span className={styles.demoIcon} onClick={() => this.openInclusionDemo()}>
                <InclusionIcon/>
              </span>
              <span className={styles.demoText} onClick={() => this.openInclusionDemo()}>
                Incusion@Way-of-Work Diversity & Inclusion (desktop only)
              </span>
              <span
                className={styles.demoTriangle}
                onClick={() => this.openInclusionDemo()}
              >
                <Icon type="caret-right" className={styles.carrot}/>
              </span>
            </Tooltip>
          </StackPanel>
          <StackPanel orientation={'horizontal'} className={styles.demoLinksItems}>
            <Tooltip
              title={'View our Flow@Work Employee Engagement dashboard'}
              openClassName={styles.tooltip}
              overlayClassName={styles.tooltip}
            >
            <span className={styles.demoIcon} onClick={() => this.openAnalyticsDemo()}>
              <FlowIcon/>
            </span>
            <span className={styles.demoText} onClick={() => this.openAnalyticsDemo()}>
              Flow@Work Employee<br/>Engagement (desktop only)</span>
            <span
              className={styles.demoTriangle}
              onClick={() => this.openAnalyticsDemo()}
            >
              <Icon type="caret-right" className={styles.carrot}/>
            </span>
            </Tooltip>
          </StackPanel>
        </StackPanel>
      </StackPanel>
    );
  }

  private openInclusionDemo = async () => {
    this.setState({isNavigatingToDemo: true});
    UserService.instance.signOut(false);
    let results = await UserService.instance.DemoSignIn('inclusion', 'FLOW@WORK');
    if (results.statusCode === 200) {
      this.props.history.push('/engage-analytics/summary');
    } else {
      this.setState({isNavigatingToDemo: false});
    }
  };

  private openAnalyticsDemo = async () => {
    this.setState({isNavigatingToDemo: true});
    UserService.instance.signOut(false);
    await UserService.instance.DemoSignIn('', 'FLOW@WORK');
    this.setState({isNavigatingToDemo: false});
  };
}
