import * as React from 'react';
import {ErrorInfo} from 'react';
import {handleError} from '../../helpers/ErrorHandlerHelpers';
import {showErrorMessage} from '../../helpers/PopupMessageHelpers';

interface ErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({hasError: true});
    console.error({
      caught: {
        error: error,
        errorInfo: errorInfo
      }
    });
    handleError(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      showErrorMessage();
      return null;
    }
    return this.props.children;
  }
}