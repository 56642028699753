import * as React from 'react';
import styles from './SetupError.module.css';
import {ReactComponent as NoRules} from './images/Lady.svg';
import RenderHelpers from '../../../shared-ui/helpers/RenderHelpers';
import {ReactComponentElement} from 'react';
import {isNil} from 'lodash-es';

interface SetupErrorProps {
  text?: string;
  className?: string;
  mode?: 'auto' | 'vertical';
  html?: ReactComponentElement<any>;
}

export default class SetupError extends React.Component<SetupErrorProps, {}> {

  render() {

    const mode = this.props.mode || 'auto';

    return (
      <div
        className={RenderHelpers.classes(
          styles.container,
          this.props.className,
          mode === 'vertical' ? styles.vertical : null
        )}
      >
        <div className={styles.box}>
          <NoRules className={styles.icon}/>
          {!isNil(this.props.html)
            ? this.props.html
            : !isNil(this.props.text)
              ? <p className={styles.text}>
                  {this.props.text}
                </p>
              : null}
        </div>
      </div>
    );
  }
}