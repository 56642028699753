import {useCache} from './useCache';
import {isNil} from 'lodash-es';
import {DependencyList, useEffect} from 'react';
import {DatasetData} from '../types/DatasetData';
import {ModelDataset} from '../types/ModelDataset';

export function useDatasetStore(loadDatasetCallback: (surveyModelId: string) => Promise<DatasetData>,
                                deps: DependencyList = []) {
  // Cache the data for all accessible survey models, indexed by language code
  const {
    getValue: getDatasetData,
    clearCache
  } =
    useCache<string, DatasetData>(loadDatasetCallback);

  // Reload the cache if any dependencies have changed
  useEffect(
    () => {
      clearCache();
    },
    deps
  );

  const getDatasets = (surveyModelId: string): ModelDataset[] => {
    const data = getDatasetData(surveyModelId);
    return isNil(data) ? [] : data.datasets;
  };

  const isLoaded = (surveyModelId: string) => {
    const data = getDatasets(surveyModelId);
    return !isNil(data);
  };

  return {
    getDatasets,
    clearCache,
    isLoaded
  };
}
