import {MercuryQuery} from '../types/MercuryQuery';
import stringify from 'json-stable-stringify';
import StringHelpers from '../../shared-common/helpers/StringHelpers';

export class MercuryQueryHelpers {
  public static hash(query: MercuryQuery, seed: number = 0): number {
    const stableJson = stringify(query);
    return StringHelpers.hash(stableJson, seed);
  }
}
