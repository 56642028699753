import uuidv4 from 'uuid/v4';
import {isEmptyOrWhiteSpace} from './StateHelpers';
import {includes} from 'lodash-es';

export function createUniqueId(): string {
  return uuidv4();
}

export function createUniqueCode(): string {
  let bytes = uuidv4(null, new Buffer(16));
  let base64 = bytes.toString('base64');
  return base64
    .replace(/\+/g, '-')  // Replace + with - (see RFC 4648, sec. 5)
    .replace(/\//g, '_')  // Replace / with _ (see RFC 4648, sec. 5)
    .substring(0, 22);    // Drop '==' padding
}

export function createRandomName(length: number = 8): string {
  let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  let str = '';
  for (let i = 0; i < length; i++) {
    str += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return str;
}

export function createNewUniqueName(desiredName: string, usedNames: string[], numberSeparator: string = ' #'): string {
  if (!includes(usedNames, desiredName)) {
    return desiredName;
  }

  let numberSeparatorRegex = '';

  for (let i = 0; i < numberSeparator.length; i++) {
    numberSeparatorRegex = numberSeparatorRegex.concat('[', numberSeparator[i], ']');
  }

  const regex = new RegExp('^(.+)(' + numberSeparatorRegex + ')(\\d+)$');

  let matches = regex.exec(desiredName);

  let nameWithoutNumber = matches ? matches[1] : desiredName.trim();
  let largestNumber: number = parseInt(matches ? matches[3] : '0', 10);

  for (let usedName of usedNames) {
    let match = regex.exec(usedName);

    if (!match) {
      continue;
    }

    if (match[1] === nameWithoutNumber) {
      largestNumber = Math.max(largestNumber, parseInt(match[3] || '0', 10));
    }
  }

  return `${nameWithoutNumber}${numberSeparator}${largestNumber + 1}`;
}

export function validateCode(code: string | undefined): string {
  if (isEmptyOrWhiteSpace(code)) {
    return 'A code is required';
  }

  if (!/^[a-zA-Z0-9_-]{22}$/.test(code || '')) {
    return 'Enter a valid code (22 alphanumeric characters, underscores or hyphens)';
  }

  return '';
}
