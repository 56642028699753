import {isNil, transform} from 'lodash-es';
import * as React from 'react';
import {ReactNode} from 'react';
import {isMoment, Moment} from 'moment';
import {asMoment, formatMoment} from '../helpers/MomentHelpers';
import {createUniqueCode} from '../helpers/IdentifierHelpers';

export default class TranslationService {
  private static _language: string | undefined = undefined;
  private static _messages: Map<string, any> = new Map<string, any>();

  private static FALLBACK_LANGUAGE: string = 'en';

  private static TOKENIZER_REGEX = /({\d+(?::[^}]+)?}|\n)/gi;
  private static ARG_REGEX = /({(\d+)(?::(.+))?})/gi;

  public static set language(value: string) {
    TranslationService._language = value;
  }

  public static get language(): string {
    if (!TranslationService._language) {
      TranslationService._language = navigator.language;
    }

    return TranslationService._language;
  }

  public static loadMessages = async (language: string = TranslationService.language) => {
    await TranslationService.findMostPreciseMessagesForLanguage(language);
  };

  public static decodeAsString = async (code: string, module?: string | undefined, ...args: (string | number)[])
    : Promise<string> => {
    if (module) {
      return await TranslationService.translateAsString(`${module}::${code}`, ...args);
    } else {
      return await TranslationService.translateAsString(code, ...args);
    }
  };

  public static decode = async (code: string, module?: string | undefined, ...args: any[]): Promise<ReactNode> => {
    if (module) {
      return await TranslationService.translate(`${module}::${code}`, ...args);
    } else {
      return await TranslationService.translate(code, ...args);
    }
  };

  public static translateAsString = async (text: string, ...args: (string | number)[]): Promise<string> => {
    return TranslationService.formatString(await TranslationService.findMessageTranslation(text), ...args);
  };

  public static translate = async (text: string, ...args: any[]): Promise<ReactNode> => {
    return TranslationService.formatHtml(await TranslationService.findMessageTranslation(text), ...args);
  };

  private static findMessageTranslation = async (text: string) => {
    const searchText = text.toLowerCase();
    const messages = await TranslationService.findMostPreciseMessagesForLanguage(TranslationService.language);

    if (!messages) {
      return text;
    }

    if (messages.hasOwnProperty(`${searchText}`)) {
      return messages[`${searchText}`];
    } else {
      return text; // As a fallback, return the untranslated text
    }
  };

  private static formatHtml = (text: string, ...args: any[]): React.ReactNode => {
    if (!args) {
      return text;
    }

    const splitText = text.split(TranslationService.TOKENIZER_REGEX);
    return React.createElement(
      'span',
      null,
      splitText.map(sentence => {
        if (sentence === '\n') {
          return React.createElement('br');
        }

        return TranslationService.formatToken(sentence, args);
      }));
  };

  private static formatString = (text: string, ...args: (string | number | Moment)[]): string => {
    if (!args) {
      return text;
    }

    const splitText = text.split(TranslationService.TOKENIZER_REGEX);
    return splitText.map(sentence => {
      return TranslationService.formatToken(sentence, args).toString();
    }).join('');
  };

  private static formatToken = (token: string, args: any[]): any => {
    let parseResult = TranslationService.ARG_REGEX.exec(token);

    if (!parseResult || parseResult.length !== 4) {
      return token;
    }

    let argIndex = parseInt(parseResult[2], 10);
    let formatString = parseResult[3];

    const arg = args[argIndex];

    if (isNil(arg)) {
      return token;
    }

    if (isMoment(arg)) {
      return formatMoment(asMoment(arg), formatString || 'YYYY-MM-DD');
    } else {
      return arg;
    }
  };

  private static findMostPreciseMessagesForLanguage = async (language: string): Promise<any | undefined> => {
    const messages = await TranslationService.loadMessagesForLanguage(language);
    if (messages) {
      return messages;
    } else {
      const splitIndex = language.lastIndexOf('-');

      if (splitIndex === -1) {
        return await TranslationService.loadMessagesForLanguage(TranslationService.FALLBACK_LANGUAGE);
      }

      const moreGeneralLanguage = language.slice(0, splitIndex);
      return await TranslationService.findMostPreciseMessagesForLanguage(moreGeneralLanguage);
    }
  };

  private static loadMessagesForLanguage = async (language: string): Promise<any | undefined> => {
    if (TranslationService._messages.has(language)) {
      return TranslationService._messages.get(language);
    }

    try {
      const response = await fetch(
        `/i18n/${language}.json?bust=${createUniqueCode()}`,
        {
          method: 'GET'
        });
      let messages = await response.json();
      messages = transform(messages, (result: any, val: any, key: string) => {
        result[key.toLowerCase()] = val;
      });
      TranslationService._messages.set(language, messages);
      return messages;
    } catch (e) {
      TranslationService._messages.set(language, undefined);
      return undefined;
    }
  };
}
