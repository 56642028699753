import {Component} from 'react';
import {isNil, remove} from 'lodash-es';

interface RegisteredDialog {
  groupCode: string;
  dialog?: Component;
  closeDialog?: () => void;
}

export default class SingletonDialogService {
  private static _registeredDialogs: RegisteredDialog[] = [];

  public static registerAnonymousDialog = (groupCode: string, closeDialogCallback: () => void) => {
    const otherClosableDialogsInGroup = remove(
      SingletonDialogService._registeredDialogs,
      d => d.groupCode === groupCode
    );

    for (let closableDialogs of otherClosableDialogsInGroup) {
      closableDialogs.closeDialog();
    }

    SingletonDialogService._registeredDialogs.push({
      groupCode: groupCode,
      closeDialog: closeDialogCallback
    });
  };

  public static registerDialog = (dialog: Component, groupCode: string, closeDialogCallback: () => void) => {
    const otherClosableDialogsInGroup = remove(
      SingletonDialogService._registeredDialogs,
      d => d.dialog !== dialog
        && d.groupCode === groupCode
    );

    for (let closableDialogs of otherClosableDialogsInGroup) {
      closableDialogs.closeDialog();
    }

    SingletonDialogService._registeredDialogs.push({
      dialog: dialog,
      groupCode: groupCode,
      closeDialog: closeDialogCallback
    });
  };

  public static deregisterAnonymousDialogs = (groupCode: string) => {
    remove(SingletonDialogService._registeredDialogs, d => isNil(d.dialog) && d.groupCode === groupCode);
  };

  public static deregisterDialog = (dialog: Component) => {
    remove(SingletonDialogService._registeredDialogs, d => d.dialog === dialog);
  };
}