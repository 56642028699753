import {isNil, sortBy} from 'lodash-es';

export function sortAscending<T>(arr: T[], by: (item: T) => any = (i) => i): T[] {
  if (isNil(arr)) {
    return [];
  }

  return sortBy(arr, [by]);
}

export function sortDescending<T>(arr: T[], by: (item: T) => any = (i) => i): T[] {
  if (isNil(arr)) {
    return [];
  }

  return sortBy(arr, [by]).reverse();
}