import {Module} from '../../shared/models/Module';

export function isModuleAccessAllowed(module: Module): Module | undefined {
  let filteredModule: Module | undefined = {...module};
  filteredModule.pages = [];
  let oneValidSubModule = false;

  for (let page of module.pages) {
    if (page.isVisible()) {
      oneValidSubModule = true;
      filteredModule.pages.push(page);
    }
  }

  if (oneValidSubModule === true) {
    return filteredModule;
  }

  return undefined;
}
