import DataService from '../shared/services/DataService';
import {DataResponse, StandardResponseData} from '../shared/models/DataResponse';
import UserService from '../shared/services/UserService';
import {
  Currencies, InvoiceDetails, LicenceTypes, ProductsPaymentData, QuoteDetails, QuoteRequestType, QuoteSource,
  QuoteStatus
} from './PalimsShared';
import {ProductsPaymentDetails} from './PalimsPublicService';
import {subscription_statuses} from '../shared/components/License/License';

import {NamedEntity} from '../shared/models/NamedEntity';
import {Moment} from 'moment';

export interface LicenceDetails {
  id: string;
  activation_date: Moment;
  expiry_date: Moment;
  licence_code: LicenceTypes;
  subscription_status_code: subscription_statuses;
  access_months: number;
  maximum_headcount: number;
  maximum_survey_count: number;
  comments: string;
  assessments_count: number;
  bundle_size: number;
  three_sixty_count: number;
  three_sixty_bundle_size: number;
  add_new_bundle: number;
  add_three_sixty_bundle: number;
}

interface LicenceRequest {
  licences: LicenceDetails[];
  partnerName: string;
}

interface PartnerCommissionDetails {
  name: string;
  id: string;
  commission_percentage: number;
}

export default class PalimsProtectedService {

  public static async CalculatePrice(headcount: number, currency: Currencies, partnerId: string, clientId: string,
                                     discountPercentage: number)
    : Promise<DataResponse<ProductsPaymentData>> {
    return await DataService.fetch<ProductsPaymentData>('/palims-protected/calculate-price', {
      partner_id: partnerId,
      client_id: clientId,
      headcount: headcount,
      currency: currency,
      discount_percentage: discountPercentage
    });
  }

  public static async ActivateLicence(invoiceId: string)
    : Promise<DataResponse<StandardResponseData>> {
    return await DataService.fetch<StandardResponseData>('/palims-protected/activate-invoice-licence', {
      invoice_id: invoiceId,
    });
  }

  public static async GetPartnerDetails(clientId: string)
    : Promise<PartnerCommissionDetails | undefined> {
    return await DataService.tryLoad<PartnerCommissionDetails>('/palims-protected/get-partner-commission', {
      client_id: clientId,
    });
  }

  public static async GetAllClients()
    : Promise<NamedEntity[] | undefined> {
    let currentId = undefined;
    if (UserService.instance.currentClientOrPartner) {
      currentId = UserService.instance.currentClientOrPartner.id;
    } else {
      UserService.instance.signOut();
    }

    return await DataService.tryLoad<NamedEntity[]>('/palims-protected/get-all-client', {
      client_id: currentId,
    });
  }

  public static async GetAllQuoteRequests(source: QuoteSource, status: QuoteStatus, partnerId: string)
    : Promise<QuoteDetails[] | undefined> {
    return await DataService.tryLoad<QuoteDetails[]>('/palims-protected/get-all-quote-requests', {
      quote_source: source,
      quote_status: status,
      partnerId: partnerId
    });
  }

  public static async GetLicenceDetails(): Promise<LicenceRequest | undefined> {
    let currentId = undefined;
    if (UserService.instance.currentClientOrPartner) {
      currentId = UserService.instance.currentClientOrPartner.id;
    } else {
      UserService.instance.signOut();
    }

    return await DataService.tryLoad<LicenceRequest>(
      '/palims-protected/get-client-licence',
      {
        client_id: currentId
      },
      true
    );
  }

  public static async GetInvoiceInformation(id: string): Promise<InvoiceDetails | undefined> {
    return await DataService.tryLoad<InvoiceDetails>
    ('/palims-protected/get-invoice-details', {
      invoice_id: id
    });
  }

  public static async GetAllQuoteInformation(id: string): Promise<QuoteDetails | undefined> {
    return await DataService.tryLoad<QuoteDetails>
    ('/palims-protected/get-all-quote-information', {
      quote_id: id
    });
  }

  public static async GetAllInvoiceRequests(): Promise<InvoiceDetails[] | undefined> {
    return await DataService.tryLoad<InvoiceDetails[]>
    ('/palims-protected/get-invoices', {
      partnerId: UserService.instance.currentClientOrPartnerId
    });
  }

  public static async CreateNewQuoteRequest(
    paymentDetails: ProductsPaymentDetails, newClientName: string, requestType: QuoteRequestType)
    : Promise<DataResponse | undefined> {
    let clientpartnerID = undefined;
    if (UserService.instance.currentClientOrPartner) {
      clientpartnerID = UserService.instance.currentClientOrPartnerId;
    } else {
      UserService.instance.signOut();
    }

    return await DataService.tryLoad<DataResponse>('/palims-protected/registred-user-quote-request', {
      partnerOrClientId: clientpartnerID,
      newClientName: newClientName,
      quoteDetails: paymentDetails,
      requestType: requestType
    });
  }

  public static async CreateNewQuote(quote: QuoteDetails): Promise<DataResponse<StandardResponseData>> {
    quote.partner_id = UserService.instance.currentClientOrPartnerId;
    return await DataService.fetch<StandardResponseData>
    ('/palims-protected/save-new-quote', {
      quote: quote,
    });
  }

  public static async CreateInvoice(quoteId: string): Promise<DataResponse<StandardResponseData>> {
    return await DataService.fetch<StandardResponseData>
    ('/palims-protected/create-payaple-invoice', {
      quote_id: quoteId,
    });
  }

  public static async UpdateQuoteStatus(quoteId: string, status: QuoteStatus): Promise<DataResponse | undefined> {
    return await DataService.tryLoad<DataResponse>
    ('/palims-protected/update-quote-status', {
      quote_id: quoteId,
      status: status
    });
  }

}
