import * as React from 'react';
import {Person} from '../types/Person';
import CallbackHelpers from '../helpers/CallbackHelpers';
import {Func} from '../types/Func';
import {PersonFilter} from '../types/PersonFilter';

export interface CohortCacheProps {
  getCohort: (filter: PersonFilter) => Person[];
  clearCohortCache: Func;
  isCohortCacheProcessing: boolean;
}

export const CohortCache = React.createContext<CohortCacheProps>({
  getCohort: CallbackHelpers.NotImplemented,
  clearCohortCache: CallbackHelpers.NoOp,
  isCohortCacheProcessing: false
});
