import * as React from 'react';
import {SurveyModel} from '../types/SurveyModel';
import {Construct} from '../types/Construct';
import {Question} from '../types/Question';
import {QuestionChoice} from '../types/QuestionChoice';
import CallbackHelpers from '../helpers/CallbackHelpers';
import {EmployeeSelectOptions} from '../types/EmployeeSelectOptions';

export interface SurveyModelCacheProps {
  getSurveyModels: (languageCode: string) => SurveyModel[];
  getConstructs: (surveyModelId: string, languageCode: string) => Construct[];
  getConstructsAsync: (surveyModelId: string, languageCode: string) => Promise<Construct[]>;
  findConstructTranslationByCodeAsync: (surveyModelId: string,
                                        constructCode: string,
                                        languageCode: string) => Promise<Construct>;
  getConstructQuestions: (surveyModelId: string, constructCode: string, languageCode: string) => Question[];
  getAllQuestions: (surveyModelId: string, languageCode: string) => Question[];
  getAllChoices: (surveyModelId: string, languageCode: string) => QuestionChoice[];
  findConstructByCode: (surveyModelId: string,
                        constructCode: string,
                        languageCode: string) => Construct | undefined;
  findQuestionByCode: (surveyModelId: string,
                       questionCode: string,
                       languageCode: string) => Question | undefined;

  findQuestionByCodeAsync: (surveyModelId: string,
                            questionCode: string,
                            languageCode: string) => Promise<Question>;
  getQuestionChoices: (surveyModelId: string,
                       questionCode: string,
                       languageCode: string) => QuestionChoice[];
  getEmployeeSelectOptions: (surveyModelId: string,
                             questionCode: string,
                             languageCode: string) => EmployeeSelectOptions;
  clearCache: () => void;
  isLoaded: (languageCode: string) => boolean;
  isSurveyModelCacheProcessing: boolean;
}

export const SurveyModelCache = React.createContext<SurveyModelCacheProps>({
  getSurveyModels: CallbackHelpers.NotImplemented,
  getConstructs: CallbackHelpers.NotImplemented,
  getConstructsAsync: CallbackHelpers.NotImplemented,
  findConstructTranslationByCodeAsync: CallbackHelpers.NotImplemented,
  getConstructQuestions: CallbackHelpers.NotImplemented,
  getAllQuestions: CallbackHelpers.NotImplemented,
  getAllChoices: CallbackHelpers.NotImplemented,
  findConstructByCode: CallbackHelpers.NotImplemented,
  findQuestionByCode: CallbackHelpers.NotImplemented,
  findQuestionByCodeAsync: CallbackHelpers.NotImplemented,
  getQuestionChoices: CallbackHelpers.NotImplemented,
  getEmployeeSelectOptions: CallbackHelpers.NotImplemented,
  clearCache: CallbackHelpers.NotImplemented,
  isLoaded: CallbackHelpers.NotImplemented,
  isSurveyModelCacheProcessing: false
});
