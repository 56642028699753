import DataService from '../shared/services/DataService';
import {DataResponse} from '../shared/models/DataResponse';
import moment from 'moment';

import {
  ClientLicenseState, Currencies, LicenceTypes, PaymentMethod, ProductsPaymentData, QuoteDetails, QuoteLicenceTypes,
  QuoteSource,
  UserPaymentTypes
} from './PalimsShared';
import {LicenceDetails} from './PalimsProtectedService';

export interface InvoiceResponse {
  invoice_token: string;
  total_amount_due: number;
  licence_code: LicenceTypes;
  invoice_number: number;
  error_code: string;
}

export interface QuoteInformationRequest {
  quote: QuoteDetails;
  error_code: string;
}

export interface ProductsPaymentDetails {
  licence: QuoteLicenceTypes;
  headcount: number;
  amount_due: number;
  credit_amount: number;
  product_price: number;
  currency: Currencies;
  discount_amount: number;
  partner_commission_amount: number;
}

export interface QuickProductsPaymentDetails {
  licence: LicenceTypes;
  headcount: number;
  amount_due: number;
  product_price: number;
}

interface EmailConfirmResponse {
  email: string;
  error_code: string;
}

export default class PalimsPublicService {

  public static async CalculatePrice(headcount: number, currency: Currencies)
  : Promise<DataResponse<ProductsPaymentData>> {
    return await DataService.fetch<ProductsPaymentData>('/palims/calculate-price', {
      headcount: headcount,
      currency: currency
    });
  }

  public static async QuickRegisterCreateRequestAndQuote(
    product: QuickProductsPaymentDetails,
    email: string,
    quoteType: QuoteSource): Promise<DataResponse | undefined> {
    return await DataService.tryLoad<DataResponse>('/palims/new-client-request-and-quote', {
      email: email,
      product: product,
      source: quoteType
    });
  }

  public static async GetQuoteInformation(userType: UserPaymentTypes,
                                          emailOrCode: string): Promise<DataResponse<QuoteInformationRequest>> {
    return await DataService.fetch<QuoteInformationRequest>('/palims/get-quote-information', {
      emailOrCode: emailOrCode,
      userType: userType
    });
  }

  public static async QuickRegisterConfirmEmail(token: string): Promise<DataResponse<EmailConfirmResponse>> {
    return await DataService.fetch<EmailConfirmResponse>('/palims/confirm-email', {
      token: token
    });
  }

  public static async GeneratePaymentInvoice(email: string,
                                             _paymentMethod: PaymentMethod,
                                             user: UserPaymentTypes): Promise<DataResponse<InvoiceResponse>> {
    return await DataService.fetch<InvoiceResponse>('/palims/generate-payment-invoice', {
      email: email,
      paymentType: _paymentMethod,
      userType: user
    });
  }

  public static calculateLicence(licence: LicenceDetails): ClientLicenseState {
    let licenseState: ClientLicenseState = ClientLicenseState.ValidLicense;
    let oneMonthFromNow = moment().add(1, 'months');
    let today = moment();

    if (licence.licence_code === LicenceTypes.FREE) {
      return ClientLicenseState.TrialLicense;
    }

    if (oneMonthFromNow.isAfter(licence.expiry_date)
      && today.isBefore(licence.expiry_date)) {
      licenseState = ClientLicenseState.Expires30daysLicense;

    }
    if (today.isAfter(licence.expiry_date)) {
      licenseState = ClientLicenseState.ExpiredLicense;

    }
    return licenseState;
  }

  public static convertCurrencyToSymbol(value: Currencies): string {
    switch (value) {
      case Currencies.USD:
        return '$';
      case Currencies.ZAR:
        return 'R';
      default:
        return 'error';
    }
  }
}
