import * as React from 'react';
import {Input, Button} from 'antd';
import styles from './LoginPage.module.css';
import UserService from '../../../shared/services/UserService';
import {Link} from 'react-router-dom';
import RouteInformation from '../../../shared/models/RouteInformation';
import DataService from '../../../shared/services/DataService';
import {DataResponse} from '../../../shared/models/DataResponse';
import {
  bindToInput, createTwoWayBinding,
  getBindingErrorMessage, validate, ValidateOn
} from '../../../shared/helpers/BindingHelpers';
import {TwoWayBinding} from '../../../shared/models/TwoWayBinding';
import {isEmptyOrWhiteSpace} from '../../../shared/helpers/StateHelpers';
import FormSection from '../../../shared/components/FormSection/FormSection';
import FormItem from '../../../shared/components/FormItem/FormItem';
import {showCustomErrorMessage, showErrorMessage} from '../../../shared/helpers/PopupMessageHelpers';
import TranslationService from '../../../shared/services/TranslationService';
import AppDialogModel from '../../../shared/components/LegacyAppModal/LegacyAppModal';
import LoadingAnimation from '../../../shared/components/LoadingAnimation/LoadingAnimation';
import DemoLinks from '../DemoLinks/DemoLinks';
import {useQueryString} from '../../../shared-navigation/hooks/useQueryString';
import {isNil} from 'lodash-es';

interface LoginPageState {
  checkLogin: boolean;
  canLogin: boolean;
  email: string;
  password: string;
  errorPassword: string;
  isLoading: boolean;
  isNavigatingToDemo: boolean;
}

export default class LoginPage extends React.Component<RouteInformation<{}>, LoginPageState> {
  private readonly _email: TwoWayBinding;
  private readonly _password: TwoWayBinding;
  private readonly _onEnter: (event: KeyboardEvent) => void;
  private _myPasswordInput: Input | null = null;

  constructor(props: RouteInformation<{}>) {
    super(props);

    const userEmail = useQueryString<{email: string}>();
    this.state = {
      checkLogin: false,
      canLogin: false,
      errorPassword: '',
      email:  isNil(userEmail) ? '' : userEmail.email,
      password: '',
      isLoading: false,
      isNavigatingToDemo: false
    };

    this._email = createTwoWayBinding(this, 'email')
      .withValidation(this.validateEmail);

    this._password = createTwoWayBinding(this, 'password')
      .withValidation(this.validatePassword);

    this._onEnter = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        this.attemptSubmit();
      }
    };
  }

  attemptSubmit = () => {
    if (!this.state.isLoading) {
      this.handleSubmit();
    }
  };

  componentWillMount() {
    window.addEventListener('keyup', this._onEnter);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this._onEnter);
  }

  private validateEmail = async () => {
    if (isEmptyOrWhiteSpace(this.state.email)) {
      return await TranslationService.decodeAsString('login_blank_email', 'APP');
    }

    return '';
  };

  validatePassword = async () => {
    if (isEmptyOrWhiteSpace(this.state.password)) {
      return await TranslationService.decodeAsString('login_blank_password', 'APP');
    }

    return '';
  };

  checkLogin = async () => {
    try {
      if (!this.state.checkLogin) {
        let canLoginResult: DataResponse = await UserService.instance.loginVerification(this.state.email, false);
        switch (canLoginResult.statusCode) {
          case 200:
            if (this._myPasswordInput) {
              this._myPasswordInput.focus();
            }
            this.setState({checkLogin: true, canLogin: true, isLoading: false});
            break;
          case 400:
            this.setState({checkLogin: true});
            this.props.history.push(
              '/reset-unregistered-user/' + this.state.email,
              {token: this.state.email}
            );
            break;
          default:
            showErrorMessage();
            break;
        }
      }
    } catch (ex) {
      if (ex.statusCode >= 500) {
        showErrorMessage();
      }
    }
    return '';
  };

  attemptToSignIn = async () => {

    if (this.state.checkLogin) {
      try {
        let result = await UserService.instance.signIn(
          (this.state.email || '').trim(),
          this.state.password
        );
        if (result.statusCode !== 200) {
          this.setState({
            errorPassword: await TranslationService.decodeAsString('invalid_login_request', 'APP')
          });
          return false;
        }
        if (!DataService.isLoggedIn) {
          await showCustomErrorMessage(
            'Cookies disabled',
            'Please enable storing of cookies to login...');
        }
        // if (!DataService.accessToken) {
        //   await showCustomErrorMessage(
        //     'Local storage disabled',
        //     'Please enable storing of website data to login...');
        // }

        return true;
        // this.forceUpdate();
      } catch (ex) {
        if (ex.statusCode >= 500) {
          showErrorMessage();
        }
      }
    }

    return false;
  };

  handleSubmit = async () => {
    this.setState({isLoading: true, errorPassword: ''});
    if (this.state.checkLogin === false) {
      let validateEmail = await validate(this._email);
      if (!validateEmail) {
        this.setState({isLoading: false});
        return;
      }
      await this.checkLogin();
      this.setState({isLoading: false});
    } else {
      let validateEmailPassword = await validate(this._email, this._password);
      if (!validateEmailPassword) {
        this.setState({isLoading: false});
        return;
      }
      let attempt = await this.attemptToSignIn();
      if (!attempt) {
        this.setState({isLoading: false});
      }
    }
  };

  openHelp = () => {
    let left = (window.screen.width / 2) - (880 / 2);
    let top = (window.screen.height / 2) - (700 / 2);

    window.open(
      'https://www.mindsetmanage.com/analytics/help',
      '_blank',
      'fullscreen=yes, width=880, height=700,  top=' + top + ', left=' + left
    );

    return false;
  };

  render() {
    return (
      <AppDialogModel
        icon={'idcard'}
        title={'Login'}
        className={styles.loginForm}
        secondPanelContent={<DemoLinks {...this.props} />}
        showDisclaimer={true}
      >
        <FormSection
          className={styles.formSection}
          formWidth={285}
          controlWidth={215}
          style={{margin: '0 auto'}}
          errorMessage={getBindingErrorMessage(this._email, this._password) + this.state.errorPassword}
        >
          <FormItem label="Email" hideLoadingAnimations={true}>
            <Input
              autoFocus={true}
              spellCheck={false}
              type={'email'}
              {...bindToInput(this._email, ValidateOn.Change)}
              onPressEnter={this.attemptSubmit}
            />
          </FormItem>
          <FormItem
            hideLoadingAnimations={true}
            label="Password"
            className={this.state.canLogin ? styles.hidden + ' ' + styles.open : styles.hidden}
          >
            <Input
              ref={(ref) => this._myPasswordInput = ref}
              type={'password'}
              {...bindToInput(this._password, ValidateOn.Change)}
              onPressEnter={this.attemptSubmit}
            />
          </FormItem>
        </FormSection>

        <Button
          onClick={this.handleSubmit}
          type="primary"
          htmlType="submit"
          disabled={this.state.isLoading}
          style={{marginTop: 6, marginBottom: 6}}
        >
          {
            this.state.canLogin ? 'Log in' : 'Next'
          }
        </Button>
        <Link
          className={styles.loginLink}
          style={{marginTop: 10, marginBottom: 4}}
          to="/reset-password"
        >
          Reset/forgot password?
        </Link>
        {
          this.state.isNavigatingToDemo ? <LoadingAnimation /> : ''
        }
      </AppDialogModel>
    );
  }
}
