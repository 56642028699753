export interface StandardResponseData {
  code: string;
}

export class DataResponse<TData = StandardResponseData> {
  public message: string = '';
  public statusCode: number = -1;
  public hasNetworkFailure: boolean = false;
  public data: TData;

  constructor(data: TData) {
    this.data = data;
  }
}