import * as React from 'react';
import {ReactComponent as MindsetLogo} from '../../images/svg/Mindset Logo Washed.svg';
import styles from './PartnerLogo.module.css';
import UserService from '../../../shared/services/UserService';
import {classes} from '../../../shared/helpers/RenderHelpers';
import {Tooltip} from 'antd';
import {navigateToExternalLink} from '../../../shared/helpers/NavigationHelpers';
import {isEmptyOrWhiteSpace} from '../../../shared/helpers/StateHelpers';

export interface PartnerLogoProps {
  isMobile: boolean;
}

class PartnerLogo extends React.Component<PartnerLogoProps> {
  render() {
    let logoUrl = '';
    if (UserService.instance.currentClientOrPartner) {
      if (!UserService.instance.currentClientOrPartner.isPartner) {
        if (UserService.instance.currentClient) {
          logoUrl = UserService.instance.currentClient.partnerLogo;
        }
      } else {
        if (UserService.instance.currentClientOrPartner.isPartner) {
          logoUrl = UserService.instance.currentClientOrPartner.logoUrl;
        }
      }
    }

    const hasLogo = !isEmptyOrWhiteSpace(logoUrl);
    const partnerWebsiteUrl = UserService.instance.currentClientOrPartner.isPartner
      ? UserService.instance.currentClientOrPartner.websiteUrl
      : UserService.instance.currentClient.partnerWebsiteUrl;

    return (
      <div
        className={
          classes(
            styles.component,
            this.props.isMobile ? styles.mobile : undefined
          )
        }
        onClick={
          !isEmptyOrWhiteSpace(partnerWebsiteUrl)
            ? () => navigateToExternalLink(partnerWebsiteUrl)
            : undefined
        }
      >
        <Tooltip
          title={partnerWebsiteUrl}
          placement="bottom"
        >
          {
            hasLogo ?
              <img className={styles.image} src={logoUrl} alt={'logo'}/>
              : <MindsetLogo className={styles.mindsetLogo}/>
          }
        </Tooltip>
      </div>
    );
  }
}

export default PartnerLogo;
