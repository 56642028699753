import {useEffect, useRef, useLayoutEffect} from 'react';
import {filter, find, isEmpty, isEqual, isNil, some} from 'lodash-es';
import {useForceUpdate} from './useForceUpdate';

interface CacheEntry<TKey, TValue> {
  key: TKey;
  value: TValue;
}

type LoadValueCallback<TKey, TValue> = (key: TKey) => Promise<TValue>;

export function useCache<TKey, TValue>(loadValueCallback: LoadValueCallback<TKey, TValue>): {
  getValue: (key: TKey) => TValue | undefined,
  setLocalValue: (key: TKey, value: TValue | undefined) => void,
  waitForValue: (key: TKey) => Promise<TValue>,
  clearCache: (key?: TKey) => void
} {
  const loadValue = useRef<LoadValueCallback<TKey, TValue>>(loadValueCallback);
  const entries = useRef<CacheEntry<TKey, TValue>[]>([]);
  const missingKeys = useRef<TKey[]>([]);
  const isLoading = useRef<boolean>(false);
  const forceUpdate = useForceUpdate();

  useLayoutEffect(
    () => {
      loadValue.current = loadValueCallback;
    },
    [loadValueCallback]
  );

  useEffect(
    () => {
      if (isEmpty(missingKeys.current)) {
        return;
      }

      if (isLoading.current) {
        return;
      }

      isLoading.current = true;

      const loadPromises = missingKeys.current.map(
        key => new Promise<{ key: TKey, value: TValue }>(
          (resolve, reject) => {
            loadValue.current(key)
              .then((value) => {
                entries.current = [...entries.current, {key: key, value: value}];
                resolve({
                  key: key,
                  value: value
                });
              })
              .catch(reject);
          }
        )
      );

      Promise.allSettled(loadPromises).then(() => {
        isLoading.current = false;
        forceUpdate();
      });
    },
    [missingKeys.current]
  );

  const getValue = (key: TKey): TValue | undefined => {
    if (isNil(key)) {
      return undefined;
    }

    const isKeyAlreadyMarkedAsMissed = some(missingKeys.current, m => isEqual(m, key));
    const data = find(entries.current, e => isEqual(e.key, key));
    if (isNil(data)) {
      if (!isKeyAlreadyMarkedAsMissed) {
        missingKeys.current = [...missingKeys.current, key];
        forceUpdate();
      }
      return undefined;
    } else {
      if (isKeyAlreadyMarkedAsMissed) {
        missingKeys.current = filter(missingKeys.current, k => !isEqual(k, key));
        forceUpdate();
      }
      return data.value;
    }
  };

  const setLocalValue = (key: TKey, value: TValue | undefined) => {
    if (isNil(key)) {
      return;
    }

    if (isNil(value)) {
      entries.current = filter(entries.current, e => !isEqual(e.key, key));
    }

    const data = find(entries.current, e => isEqual(e.key, key));
    data.value = value;
  };

  const waitForValue = async (key: TKey): Promise<TValue> => {
    // Nil keys are not supported
    if (isNil(key)) {
      return new Promise<TValue>(resolve => resolve());
    }

    // If we have already cached the value for this key, return it as a promise that resolves immediately...
    const entry = find(entries.current, e => isEqual(e.key, key));
    if (!isNil(entry)) {
      return new Promise<TValue>(resolve => resolve(entry.value));
    }

    // ...otherwise, await the loading of the value
    const loadedValue = await loadValue.current(key);

    // Remove the key from the missing keys list if necessary, since it has been loaded
    const isKeyAlreadyMarkedAsMissed = some(missingKeys.current, m => isEqual(m, key));
    if (isKeyAlreadyMarkedAsMissed) {
      missingKeys.current = filter(missingKeys.current, k => !isEqual(k, key));
      forceUpdate();
    }

    // Cache the loaded value
    entries.current = [...entries.current, {key: key, value: loadedValue}];

    // Queue a rerender of components that depend on this cache
    forceUpdate();

    // Return the loaded value to the caller.
    return loadedValue;
  };

  const clearCache = (key?: TKey): void => {
    if (isNil(key)) {
      entries.current = [];
    } else {
      entries.current = filter(entries.current, e => !isEqual(e.key, key));
    }
    forceUpdate();
  };

  return {
    getValue: getValue,
    setLocalValue: setLocalValue,
    waitForValue: waitForValue,
    clearCache: clearCache
  };
}