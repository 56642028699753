import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './app/App';
import './ant.css';
import './ant-overrides.css';
import ConfigService from './shared/services/ConfigService';

function isBrowserSupported(): boolean {
  // Blacklist IE 10, 9 and 8 based on user agent
  const IE_USER_AGENT_REGEX = /MSIE\s?(\d+)(?:\.(\d+))?/i;

  const matches = navigator.userAgent.match(IE_USER_AGENT_REGEX);
  if (matches != null) {
    if (matches[1] === '10' || matches[1] === '9' || matches[1] === '8') {
      return false;
    }
  }

  return true;
}

if (isBrowserSupported()) {
  ConfigService.loadConfig().then(() => {
    ReactDOM.render(
      <App/>,
      document.getElementById('root') as HTMLElement
    );
  });
} else {
  (document.getElementById('root') as HTMLElement).innerText = 'Sorry, your browser is not supported';
}
