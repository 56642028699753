import * as React from 'react';
import {SurveyModelString} from '../types/SurveyModelString';

export interface SurveyModelStringsCacheProps {

  getSurveyModelStringByCode: (SurveyModelId: string,
                               languageCode: string,
                               code: string) => SurveyModelString;

  getSurveyModelStringByCodeAsync: (SurveyModelId: string,
                                    languageCode: string,
                                    code: string) => Promise<SurveyModelString>;

  getTranslatedStringByCode: (SurveyModelId: string,
                              languageCode: string,
                              code: string) => SurveyModelString;

  getTranslatedStringByCodeAsync: (SurveyModelId: string,
                                   languageCode: string,
                                   code: string) => Promise<SurveyModelString>;

  hasTranslation: (SurveyModelId: string,
                   languageCode: string,
                   code: string) => boolean;

  hasTranslationAsync: (SurveyModelId: string,
                        languageCode: string,
                        code: string) => Promise<boolean>;

  setLocalSurveyModelString: (surveyModelId: string,
                              languageCode: string,
                              code: string,
                              localString: string) => void;
}

export const SurveyModelStringCache = React.createContext<SurveyModelStringsCacheProps>({
  getSurveyModelStringByCode: () => {
    throw 'Not implemented';
  },
  getSurveyModelStringByCodeAsync: () => {
    throw 'Not implemented';
  },
  getTranslatedStringByCode: () => {
    throw 'Not implemented';
  },
  hasTranslation: () => {
    throw 'Not implemented';
  },
  hasTranslationAsync: () => {
    throw 'Not implemented';
  },
  getTranslatedStringByCodeAsync: () => {
    throw 'Not implemented';
  },
  setLocalSurveyModelString: () => {
    throw 'Not implemented';
  }
});
