import React from 'react';
import {AnalyticsFilter} from '../types/AnalyticsFilter';
import CallbackHelpers from '../../shared-common/helpers/CallbackHelpers';
import {AnalyticsFilterType} from '../types/AnalyticsFilterType';

export type ReportFiltersContextProps = {
  isLoadingFilterData: boolean;
  organisationCacheDependencyTag: string;
  getFilter: (filterType: AnalyticsFilterType) => AnalyticsFilter | undefined;
  reloadFilterData: () => void
};

export const ReportFilters = React.createContext<ReportFiltersContextProps>({
  isLoadingFilterData: true,
  organisationCacheDependencyTag: undefined,
  getFilter: CallbackHelpers.Nil,
  reloadFilterData: CallbackHelpers.Nil
});
