import * as React from 'react';
import LoadingAnimation from '../../../shared/components/LoadingAnimation/LoadingAnimation';
import RouteInformation from '../../../shared/models/RouteInformation';
import UserService from '../../../shared/services/UserService';
import SetupError from '../../../shared/components/SetupError/SetupError';
import styles from './Demo.module.css';

interface DemoLogin {
  attempted: boolean;
}

interface DemoParams {
  locationISO: string;
}
export default class Demo extends React.Component<RouteInformation<DemoParams>, DemoLogin> {
  constructor(props: RouteInformation<DemoParams>) {
    super(props);
    this.state = {
      attempted: true
    };
  }

  async componentDidMount() {
    UserService.instance.signOut(false);
    let results = await UserService.instance.DemoSignIn(this.props.match.params.locationISO || '', 'FLOW@WORK');
    if (results.statusCode === 200) {
      this.props.history.push('/');
    } else {
      this.setState({attempted: false});
    }
  }

  render() {
    return this.state.attempted ? <LoadingAnimation/> :
      <SetupError text={'Could not load demo site, please try again...'} className={styles.error}/>;
    }
}
