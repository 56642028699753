import * as React from 'react';
import styles from './ServiceTerms.module.css';
import RenderHelpers from '../../helpers/RenderHelpers';
import {CSSProperties} from 'react';

interface ServiceTermsProps {
  className?: string;
  style?: CSSProperties;
}

export const ServiceTerms = (props: ServiceTermsProps) => {
  const openPdf = () => {
    let left = (window.screen.width / 2) - (880 / 2);
    let top = (window.screen.height / 2) - (700 / 2);

    window.open(
      'https://www.mindsetmanage.com/images/Uploads/Service-Terms-Conditions-Privacy-Policy.pdf',
      '_blank',
      'fullscreen=yes , width=880, height=700, top=' + top + ', left=' + left
    );
    return false;
  };

  return (
    <div
      className={
        RenderHelpers.classes(
          styles.component,
          props.className
        )}
      style={props.style}
    >
      <span>
        By logging in and/or using our <b>Engage EX</b> products and services, you acknowledge that you have
        read, understand and agree to be bound by our&nbsp;
        <b>Service Terms</b>, <b>Conditions</b> and <b>Privacy Policy</b> which can be viewed&nbsp;
        <span
          className={styles.link}
          onClick={openPdf}
        >
          {'here'}
        </span>
      </span>
    </div>
  );
};
