import * as React from 'react';
import {DemographicAttribute} from '../types/DemographicAttribute';
import {Demographic} from '../types/Demograhic';
import {DemographicCategory} from '../types/DemograhicCategory';
import CallbackHelpers from '../helpers/CallbackHelpers';

export interface DemographicsCacheProps {
  getDemographics: (languageCode: string) => Demographic[];

  getDemographicCategories: (languageCode: string) => DemographicCategory[];

  getDemographicAttributes: (languageCode: string) => DemographicAttribute[];

  findDemographicByCode: (categoryCode: string,
                          languageCode: string) => Demographic;

  findCategoryByCode: (categoryCode: string,
                       languageCode: string) => DemographicCategory;

  findAttributeByCode: (attributeCode: string,
                        languageCode: string) => DemographicAttribute;

  findAttributesCategoryId: (categoryId: string,
                             languageCode: string) => DemographicAttribute[];

  clearCache: () => void;
}

export const DemographicsCache = React.createContext<DemographicsCacheProps>({
  getDemographics: () => {
    throw 'Not implemented';
  },
  getDemographicCategories: () => {
    throw 'Not implemented';
  },
  getDemographicAttributes: () => {
    throw 'Not implemented';
  },
  findDemographicByCode: () => {
    throw 'Not implemented';
  },
  findCategoryByCode: () => {
    throw 'Not implemented';
  },
  findAttributeByCode: () => {
    throw 'Not implemented';
  },
  findAttributesCategoryId: () => {
    throw 'Not implemented';
  },
  clearCache: CallbackHelpers.NotImplemented,
});
