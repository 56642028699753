import * as React from 'react';
import UserService from '../../../shared/services/UserService';
import {showCustomErrorMessage} from '../../../shared/helpers/PopupMessageHelpers';
import SetupError from '../../../shared/components/SetupError/SetupError';
import If from '../../../shared-ui/components/If/If';
import styles from './NoPermissions.module.css';

interface NoPermissionsState {
  noPermission: boolean;
  renderSetupError: boolean;
}

export default class NoPermissions extends React.Component<{}, NoPermissionsState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      noPermission: false,
      renderSetupError: false
    };

    UserService.instance.userExists().then(async result => {
      if (result.statusCode === 200) {
        this.setState({renderSetupError: true});
      } else {
        await showCustomErrorMessage(
          'Access Revoked',
          <div>
            <p> Your account has been deactivated</p>
            <p> Please ask your system admin/HR to the correct permission to view products</p>
          </div>);
        this.onOkay();
      }
    }).catch(async result => {
      await showCustomErrorMessage(
        'Access Revoked',
        <div>
          <p> Your account has been deactivated</p>
          <p> Please ask your system admin/HR to the correct permission to view products</p>
        </div>);
      this.onOkay();
    });
  }

  onOkay = () => {
    UserService.instance.signOut();
  };

  render() {
    return (
      <div>
        <If condition={this.state.renderSetupError}>
          <SetupError
            html={
            <>
              <div className={styles.header}>
                <b>There are no personal reports to be viewed</b>
              </div>
              <p className={styles.text}>
                You either do not have a valid profile, or the report(s) you want to view are associated to another
                client account/license. Please select a different client account/license from the drop-down
                field at the top of the side panel, or ask your System Administrator to update your profile.
              </p>
            </>}
            className={styles.container}
            mode="vertical"
          />
        </If>
      </div>
    );
  }
}
