export function findPageSlug(url: string): string {
  let lastPartStartIndex = url.lastIndexOf('/');
  if (lastPartStartIndex === -1) {
    return url;
  }
  return url.slice(lastPartStartIndex + 1);
}

export function findBaseUrl(url: string): string {
  let lastPartStartIndex = url.lastIndexOf('/');
  if (lastPartStartIndex === -1) {
    return '';
  }
  return url.slice(0, lastPartStartIndex);
}

export function stripRouteParameters(url: string): string {

  let hasLeadingSlash = url.startsWith('/');

  let urlParts = url.split('/');

  let baseUrlParts: string[] = [];

  for (let urlPart of urlParts) {
    if (urlPart && !urlPart.includes('?')) {
      baseUrlParts.push(urlPart);
    }
  }

  if (hasLeadingSlash) {
    return '/' + baseUrlParts.join('/');
  } else {
    return baseUrlParts.join('/');
  }
}