import * as React from 'react';
import LoadingAnimation from '../components/LoadingAnimation/LoadingAnimation';
interface AsyncComponentState {
  Component: any;
}

export default function loadAsyncComponent(getComponent: any): any {
  class AsyncComponent extends React.Component<{}, AsyncComponentState> {
    _isUnmounted: boolean;

    constructor(props: any) {
      super(props);

      this.state = {
        Component: null
      };
    }

    componentWillMount() {
      this._isUnmounted = true;
    }

    componentDidMount() {
      this._isUnmounted = false;
      getComponent().then((loaded: any) => {
        if (this._isUnmounted) {
          return;
        }

        const {default: Component} = loaded;
        this.setState({
          Component: Component
        });
      });
    }

    render() {
      const C = this.state.Component;
      return C ? <C {...this.props}/> : <LoadingAnimation/>;
    }

    componentWillUnmount() {
      this._isUnmounted = true;
    }
  }

  return AsyncComponent;
}
