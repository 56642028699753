import {useCallback, useState} from 'react';

export function useForceUpdate(): () => void {
  const [, forceUpdate] = useState<{}>();

  return useCallback(
    () => {
      forceUpdate({});
    },
    []
  );
}
