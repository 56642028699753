import BusinessUnit from '../models/BusinessUnit';
import UserService from '../services/UserService';
import {includes, find, union, cloneDeep} from 'lodash-es';

interface AllowedBusinessUnits {
  allowAll: boolean;
  unitIds: string[];
}

export function filterVirtualStructureBusinessUnitsByPermissions(businessUnits: BusinessUnit[],
                                                                 viewUnitPermissions?: string[][],
                                                                 viewAllPermissions?: string[]): BusinessUnit[] {

  let allowedBusinessUnits: AllowedBusinessUnits = {
    allowAll: false,
    unitIds: []
  };

  if (viewUnitPermissions) {
    for (let i = 0; i < viewUnitPermissions.length; i++) {
      let permittedUnits = getAllowedVirtualBusinessUnitIds(viewAllPermissions, viewUnitPermissions[i]);
      allowedBusinessUnits.allowAll = allowedBusinessUnits.allowAll || permittedUnits.allowAll;
      allowedBusinessUnits.unitIds = union(allowedBusinessUnits.unitIds, permittedUnits.unitIds);
    }
  }

  if (!allowedBusinessUnits.allowAll) {
    let allowedUnits: BusinessUnit[] = cloneDeep(businessUnits);
    removeNotPermittedBusinessUnits(allowedUnits, allowedBusinessUnits.unitIds);
    return allowedUnits;
  } else {
    return businessUnits || [];
  }
}

export function filterBusinessUnitsByPermissions(businessUnits: BusinessUnit[],
                                                 viewUnitPermissions?: string[][],
                                                 viewAllPermissions?: string[]): BusinessUnit[] {

  let allowedBusinessUnits: AllowedBusinessUnits = {
    allowAll: false,
    unitIds: []
  };

  if (viewUnitPermissions) {
    for (let i = 0; i < viewUnitPermissions.length; i++) {
      let permittedUnits = getAllowedBusinessUnitIds(viewAllPermissions, viewUnitPermissions[i]);
      allowedBusinessUnits.allowAll = allowedBusinessUnits.allowAll || permittedUnits.allowAll;
      allowedBusinessUnits.unitIds = union(allowedBusinessUnits.unitIds, permittedUnits.unitIds);
    }
  }

  if (!allowedBusinessUnits.allowAll) {
    let allowedUnits: BusinessUnit[] = cloneDeep(businessUnits);
    removeNotPermittedBusinessUnits(allowedUnits, allowedBusinessUnits.unitIds);
    return allowedUnits;
  } else {
    return businessUnits || [];
  }
}

function getLevelCodesForUnitIds(permittedUnitIds: string[],
                                 allBusinessUnits: BusinessUnit[],
                                 permittedLevelCodes: string[]) {
  for (let unit of (allBusinessUnits || [])) {
    if (includes(permittedUnitIds, unit.id)) {
      permittedLevelCodes.push(unit.levelCode);
      continue;
    }

    if (unit.children) {
      getLevelCodesForUnitIds(permittedUnitIds, unit.children, permittedLevelCodes);
    }
  }
}

export function findChildBusinessUnitsWithPermissions(selectedBusinessUnits: BusinessUnit[],
                                                      allBusinessUnits: BusinessUnit[],
                                                      viewUnitPermissions?: string[][],
                                                      viewAllPermissions?: string[]): BusinessUnit[] {
  if (viewAllPermissions) {
    if (UserService.instance.checkPermissionsGrantedForClientOrPartner(viewAllPermissions)) {
      return selectedBusinessUnits;
    }
  }

  if (!viewUnitPermissions) {
    return [];
  }

  let permittedUnitIds = UserService.instance.getBusinessUnitIdsWithPermissions(viewUnitPermissions);
  let permittedLevelCodes: string[] = [];
  getLevelCodesForUnitIds(permittedUnitIds, allBusinessUnits, permittedLevelCodes);
  let allowedRootBusinessUnits: BusinessUnit[] = [];
  findAllowedRootBusinessUnits(selectedBusinessUnits, permittedLevelCodes, allowedRootBusinessUnits);
  return allowedRootBusinessUnits;
}

function getAllowedBusinessUnitIds(viewAllPermissions?: string[],
                                   viewUnitPermissions?: string[]): AllowedBusinessUnits {

  if (viewAllPermissions) {
    if (UserService.instance.checkPermissionsGrantedForClientOrPartner(viewAllPermissions)) {
      return {
        allowAll: true,
        unitIds: []
      };
    }
  }

  if (viewUnitPermissions && viewUnitPermissions.length > 0) {
    let permittedUnits = UserService.instance.getBusinessUnitIdsWithPermissions([viewUnitPermissions]);
    return {
      allowAll: false,
      unitIds: permittedUnits
    };
  }

  return {
    allowAll: true,
    unitIds: []
  };
}

function getAllowedVirtualBusinessUnitIds(viewAllPermissions?: string[],
                                          viewUnitPermissions?: string[]): AllowedBusinessUnits {

  if (viewAllPermissions) {
    if (UserService.instance.checkPermissionsGrantedForClientOrPartner(viewAllPermissions)) {
      return {
        allowAll: true,
        unitIds: []
      };
    }
  }

  if (viewUnitPermissions && viewUnitPermissions.length > 0) {
    let permittedUnits = UserService.instance.getVirtualBusinessUnitIdsWithPermissions([viewUnitPermissions]);
    return {
      allowAll: false,
      unitIds: permittedUnits
    };
  }

  return {
    allowAll: true,
    unitIds: []
  };
}

function findAllowedRootBusinessUnits(selectedBusinessUnits: BusinessUnit[],
                                      allowedLevelCodes: string[],
                                      allowedRootBusinessUnits: BusinessUnit[]) {

  if (!selectedBusinessUnits || selectedBusinessUnits.length === 0) {
    return;
  }

  for (let unit of selectedBusinessUnits) {
    let found = find(allowedLevelCodes, allowedCode => unit.levelCode.startsWith(allowedCode));
    if (found) {
      allowedRootBusinessUnits.push(unit);
    } else {
      findAllowedRootBusinessUnits(unit.children, allowedLevelCodes, allowedRootBusinessUnits);
    }
  }
}

function removeNotPermittedBusinessUnits(businessUnits: BusinessUnit[],
                                         allowedUnitIds: string[]): number {
  if (!businessUnits || businessUnits.length === 0) {
    return 0;
  }

  let permittedChildCount = 0;
  for (let i = 0; i < businessUnits.length; i++) {
    let unit = businessUnits[i];
    if (includes(allowedUnitIds, unit.id)) {
      permittedChildCount++;
      continue;

    }

    unit.areChildrenFiltered = true;
    // let childCount = (unit.children || []).length;
    let permittedChildrenCount = removeNotPermittedBusinessUnits(unit.children, allowedUnitIds);

    // if (permittedChildrenCount !== childCount) {
    //   unit.areChildrenFiltered = true;
    // }

    if (permittedChildrenCount > 0) {
      permittedChildCount++;
      continue;
    }

    businessUnits.splice(i, 1);
    i--;
  }

  return permittedChildCount;
}
