export class SecurityHelpers {
  public static validatePasswordComplexity(password: string): true | string {
    const INVALID_MESSAGE = 'At least 10 characters with one or more capital letters and numbers';

    if (password.trim().length < 10) {
      return INVALID_MESSAGE;
    }

    const hasCapitals = /[A-Z]/.test(password);
    if (!hasCapitals) {
      return INVALID_MESSAGE;
    }

    const hasDigits = /[0-9]/.test(password);
    if (!hasDigits) {
      return INVALID_MESSAGE;
    }

    return true;
  }
}
