import * as React from 'react';
import {DemographicsCache} from '../context/DemographicsCache';
import {PropsWithChildren} from 'react';
import DataService from '../../shared/services/DataService';
import {useDemographicsStore} from '../hooks/useDemographicsStore';
import {DemographicData} from '../types/DemographicData';

export interface DemographicsStoreProps {
  clientId: string | undefined;
}

export const DemographicsStore = (props: PropsWithChildren<DemographicsStoreProps>) => {

  const {
    getDemographics,
    getDemographicCategories,
    getDemographicAttributes,
    findDemographicByCode,
    findCategoryByCode,
    findAttributeByCode,
    findAttributesCategoryId,
    clearCache,
  } = useDemographicsStore(
    async (languageCode) => {
      const data = await DataService.tryLoad<DemographicData>(
        '/master-data/get-demographics',
        {
          clientId: props.clientId,
          languageCode: languageCode
        }
      );
      return data;
    },
    [props.clientId]
  );
  return (
    <DemographicsCache.Provider
      value={{
        getDemographics,
        getDemographicCategories,
        getDemographicAttributes,
        findDemographicByCode,
        findCategoryByCode,
        findAttributeByCode,
        findAttributesCategoryId,
        clearCache,
      }}
    >
      {props.children}
    </DemographicsCache.Provider>
  );
};
