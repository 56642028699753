import * as React from 'react';
import {SurveyModelCache} from '../context/SurveyModelCache';
import {PropsWithChildren, useState} from 'react';
import DataService from '../../shared/services/DataService';
import UserService from '../../shared/services/UserService';
import {useSurveyModelStore} from '../hooks/useSurveyModelStore';
import CodeHelpers from '../helpers/CodeHelpers';
import {SurveyModelData} from '../types/SurveyModelData';
import {ProductLine} from '../types/ProductLine';

export interface SurveyModelStoreProps {
  productLine: ProductLine;
}

export const SurveyModelStore = (props: PropsWithChildren<SurveyModelStoreProps>) => {
  const [isProcessing, setIsProcessing] = useState<boolean>();

  const {
    getSurveyModels,
    getConstructs,
    getConstructsAsync,
    findConstructTranslationByCodeAsync,
    getConstructQuestions,
    getAllQuestions,
    getQuestionChoices,
    getEmployeeSelectOptions,
    findConstructByCode,
    findQuestionByCode,
    findQuestionByCodeAsync,
    getAllChoices,
    clearCache,
    isLoaded
  } = useSurveyModelStore(
    async (languageCode) => {
      setIsProcessing(true);
      try {
        return await DataService.tryLoad<SurveyModelData>(
          '/master-data/get-survey-models',
          {
            organisationId: UserService.instance.organisationId,
            productLineCode: CodeHelpers.codeForProductLine(props.productLine),
            languageCode: languageCode
          }
        );
      } finally {
        setIsProcessing(false);
      }
    },
    [UserService.instance.organisationId, props.productLine]
  );
  return (
    <SurveyModelCache.Provider
      value={{
        getSurveyModels,
        getConstructs,
        getConstructsAsync,
        findConstructTranslationByCodeAsync,
        getConstructQuestions,
        getAllQuestions,
        getQuestionChoices,
        getEmployeeSelectOptions,
        findConstructByCode,
        findQuestionByCode,
        findQuestionByCodeAsync,
        getAllChoices,
        clearCache,
        isLoaded,
        isSurveyModelCacheProcessing: isProcessing
      }}
    >
      {props.children}
    </SurveyModelCache.Provider>
  );
};
