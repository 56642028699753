import 'whatwg-fetch';
import DataService, {FetchOperation} from './DataService';
import {DataResponse, StandardResponseData} from '../models/DataResponse';
import jwtDecode from 'jwt-decode';
import {forOwn, isEmpty, concat, intersection, find, isNil, difference, filter, some, includes} from 'lodash-es';
import BusinessUnit from '../models/BusinessUnit';
import {
  filterBusinessUnitsByPermissions,
  filterVirtualStructureBusinessUnitsByPermissions,
  findChildBusinessUnitsWithPermissions
} from '../helpers/SecurityHelpers';
import {safeNav} from '../helpers/StateHelpers';
import {getBusinessUnitsForCurrentClient} from '../helpers/MasterDataHelpers';
import {showCustomErrorMessage} from '../helpers/PopupMessageHelpers';
import SurveyWizardService from './SurveyWizardService';
import {TreeNode} from '../helpers/TreeHelpers';
import {ContextTheme} from '../../app/context/ThemeContext';
import {ComponentUpdater} from './ComponentUpdater/ComponentUpdater';
import UpdatedComponentList from './ComponentUpdater/UpdatedComponentList';
import {Component} from 'react';
import {ProductLine} from '../../shared-common/types/ProductLine';
import {parse} from '@billjs/query-string';

interface UserData {
  email?: string;
  fullName?: string;
  preferredName?: string;
}

export interface ClientDetails extends OrganisationDetails {
  hasOffSiteEmployees: boolean;
  hasContractEmployees: boolean;
  hasManagerEmployees: boolean;
  hasDemographics: boolean;
  partnerName: string;
  partnerLogo: string;
  partnerWebsiteUrl: string;
  marketingMessage: string;
  marketingContactEmail: string;
  marketingEngagementModelDescription: string;
  marketingEngagementModelImageUrl: string;
  marketingIsActive: boolean;
  hasAssessBundles?: boolean;
}

export interface OrganisationDetails extends TreeNode<OrganisationDetails> {
  id: string;
  name: string;
  partnerId?: string;
  logoUrl: string;
  isPartner: boolean;
  children?: OrganisationDetails[];
  parent?: OrganisationDetails;
  shellTheme?: ContextTheme;
  isWizardClient?: boolean;
  indicatorSystemAdmin?: string;
  websiteUrl?: string;
}

export interface EmployeeDataFilter {
  onSite: boolean;
  offSite: boolean;
  permanent: boolean;
  contract: boolean;
  managers: boolean;
  workers: boolean;
}

interface UserProfile {
  clientDetails: ClientDetails[];
  partnerDetails: OrganisationDetails[];
  organisationPermissions: { [entityId: string]: string[] };
  businessUnitPermissions: { [entityId: string]: string[] };
  managedVirtualUnitsBusinessUnitPermissions: { [entityId: string]: string[] };
  datasetModelFingerprints: ClientSurveyModelFingerprint[];
  clientHasSurveyReports: OrganisationReports[];
  clientHasAssessReports: OrganisationReports[];
  clientHasManagerReports: OrganisationReports[];
}

export interface ClientSurveyModelFingerprint {
  clientId: string;
  constructCodes: string[];
}

export interface OrganisationReports {
  clientId: string;
  hasReports: boolean;
}

export default class UserService implements ComponentUpdater {

  private static _instance: UserService;

  private _updatedComponents: UpdatedComponentList;
  private _userData: UserData;
  private _userProfile: UserProfile | undefined;
  private _currentClientOrPartner: OrganisationDetails | undefined;
  private _clientBusinessUnits: BusinessUnit[];
  private _selectedBusinessUnits: BusinessUnit[];
  private _employeeDataFilter: EmployeeDataFilter;

  readonly localStorageKey = 'DSORGANISATIONID';

  public get organisationId(): string | null {
    try {
      const {org} = parse(window.location.search) as {org: string};
      if (!isNil(org)) {
        return org;
      }

      return localStorage.getItem(this.localStorageKey);
    } catch (ex) {
      return null;
    }
  }

  public static get instance() {
    return this._instance || (this._instance = new this());
  }

  public get userFullName(): string {
    return (this._userData && this._userData.fullName) || '';
  }

  public get userPreferredName(): string {
    return (this._userData && this._userData.preferredName) || '';
  }

  public get userEmail(): string {
    return (this._userData && this._userData.email) || '';
  }

  public get userProfile(): UserProfile | undefined {
    return this._userProfile;
  }

  public get currentClientOrPartnerId(): string | undefined {
    if (this._currentClientOrPartner) {
      return this._currentClientOrPartner.id;
    } else {
      return undefined;
    }
  }

  public get currentClientOrPartner(): OrganisationDetails | undefined {
    return this._currentClientOrPartner;
  }

  public get currentClient(): ClientDetails | undefined {
    if (safeNav(this._currentClientOrPartner, x => !x.isPartner)) {
      return this._currentClientOrPartner as ClientDetails;
    }

    return undefined;
  }

  public get selectedBusinessUnits(): BusinessUnit[] {
    return this._selectedBusinessUnits || [];
  }

  public get businessUnits(): BusinessUnit[] {
    return this._clientBusinessUnits;
  }

  public get hasOrganisationAccess(): boolean {
    return this.checkPermissionsGrantedForClientOrPartner(['CLIENT_ORG_ACCESS']);
  }

  public get isOrganisationSelected(): boolean {
    return difference(this._clientBusinessUnits, this._selectedBusinessUnits).length === 0;
  }

  public get employeeDataFilter(): EmployeeDataFilter {
    return this._employeeDataFilter || {
      offSite: true,
      onSite: true,
      permanent: true,
      contract: true,
      managers: true,
      workers: true
    };
  }

  public isUserAPartner(): boolean {
    return this._userProfile && this._userProfile.partnerDetails.length > 0;
  }

  public get isDemoUser(): boolean {
    return this._userProfile && this.checkPermissionsGrantedForClientOrPartner(['DEMO_ACCESS']);
  }

  public getPermittedClientBusinessUnits(permissions: string[][], globalPermission: string[]) {
    return filterBusinessUnitsByPermissions(
      this._clientBusinessUnits,
      permissions,
      globalPermission);
  }

  public getPermittedBusinessUnits(businessUnits: BusinessUnit[], permissions: string[][], globalPermission: string[]) {
    return filterBusinessUnitsByPermissions(
      businessUnits,
      permissions,
      globalPermission);
  }

  public getPermittedVirtualStructureManagerBusinessUnits(
    businessUnits: BusinessUnit[]) {
    return filterVirtualStructureBusinessUnitsByPermissions(
      businessUnits,
      [['VIRTUAL_BUSINESS_UNIT_MANAGER']], // synthetic permission, not stored in db
      );
  }

  public getOnlyPermittedClientBusinessUnits(selectedBusinessUnits: BusinessUnit[], permissions: string[][],
                                             globalPermission: string[]) {
    return findChildBusinessUnitsWithPermissions(
      selectedBusinessUnits,
      this._clientBusinessUnits,
      permissions,
      globalPermission
    );
  }

  public async setCurrentClientOrPartnerId(value: string, productLine: ProductLine) {
    if (!this._userProfile || value.length <= 0) {
      return;
    }
    let client = find(this._userProfile.clientDetails, c => c.id === value);

    if (client) {
      this._currentClientOrPartner = client;
    } else {
      this._currentClientOrPartner = find(this._userProfile.partnerDetails, c => c.id === value);
    }

    localStorage.setItem(this.localStorageKey, value);

    if (this.isSignedIn) {
      await this.refreshClientBusinessUnits(productLine);
      await this.updateRegisteredComponents();
    }
  }

  public async refreshClientBusinessUnits(productLine: ProductLine) {

    let unitPermission: string;
    let orgPermission: string;

    switch (productLine) {
      default:
      case ProductLine.Workplace:
        unitPermission = 'WORKPLACE_VIEW_EMPLOYEE_LIST';
        orgPermission = 'WORKPLACE_VIEW_EMPLOYEE_LIST_ORG';
        break;
      case ProductLine.Lifecycle:
        unitPermission = 'LIFECYCLE_VIEW_EMPLOYEE_LIST';
        orgPermission = 'LIFECYCLE_VIEW_EMPLOYEE_LIST_ORG';
        break;
    }

    let result = await getBusinessUnitsForCurrentClient();
    if (result) {
      this._clientBusinessUnits = result;
      this._selectedBusinessUnits = findChildBusinessUnitsWithPermissions(
        this._clientBusinessUnits,
        this._clientBusinessUnits,
        [[unitPermission]],
        [orgPermission]
      );
    } else {
      this._clientBusinessUnits = [];
    }

    this._employeeDataFilter = {
      offSite: true,
      onSite: true,
      permanent: true,
      contract: true,
      managers: true,
      workers: true
    };
  }

  public setSelectedBusinessUnits(selectedBusinessUnits: BusinessUnit[]) {
    this._selectedBusinessUnits = selectedBusinessUnits;
    this.updateRegisteredComponents();
  }

  public setEmployeeDataFilter(dataFilter: EmployeeDataFilter) {
    this._employeeDataFilter = dataFilter;
    this.updateRegisteredComponents();
  }

  public async changePassword(oldPassword: string, newPassword: string): Promise<DataResponse> {
    try {
      return await DataService.fetch(
        '/authorisation/change-password',
        {},
        FetchOperation.Post,
        {
          oldPassword: oldPassword,
          newPassword: newPassword
        });
    } catch (ex) {
      return ex;
    }
  }

  public async resetPassword(token: string, newPassword: string): Promise<DataResponse> {
    try {
      let result = DataService.fetch(
        '/authorisation/reset-password',
        {},
        FetchOperation.Post,
        {
          token: token,
          newPassword: newPassword
        });

      return result;
    } catch (ex) {
      return ex;
    }
  }

  public userExists(): Promise<DataResponse> {
    try {
      let result = DataService.fetch(
        '/authorisation/user-exists',
        {},
        FetchOperation.Post,
        {
          email: this._userData.email,
        });

      return result;
    } catch (ex) {
      return ex;
    }
  }

  // Send email and receive a token in order to reset the password
  public async resetPasswordRequest(email: string): Promise<DataResponse> {
    return await DataService.fetch(
      '/authorisation/request-password-reset',
      {},
      FetchOperation.Post,
      {
        email: email
      });
  }

  public get isSignedIn() {
    return !isEmpty(this._userData);
  }

  public get isProfileLoaded(): boolean {
    return this._userProfile !== undefined;
  }

  public get isSigningIn() {
    return DataService.accessToken && isEmpty(this._userProfile);
  }

  public async signIn(email: string, password: string): Promise<DataResponse> {
    try {
      let result = await DataService.fetch(
        '/authorisation/login',
        {},
        FetchOperation.Post,
        {
          email: email,
          password: password
        });

      if (result.statusCode === 200) {
        this._userProfile = undefined;
        await this.loadUserInformation();
      } else if (DataService.accessToken) {
        // If local storage is disabled, not access token could be stored,
        // so give the login screen time to display an error by not reloading the UI
        await this.updateRegisteredComponents();
      }

      return result;
    } catch (ex) {
      return ex;
    }
  }

  public async DemoSignIn(location: string, demoType: 'FLOW@WORK' | 'COVID' | 'INDICATOR'): Promise<DataResponse> {
    try {
      let result = await DataService.fetch(
        '/authorisation/login-demo',
        {
          locationISO: location,
          demoType: demoType
        },
        FetchOperation.Post);
      if (result.statusCode === 200) {
        await this.loadUserInformation();
      }
      // If local storage is disabled, not access token could be stored,
      // so give the login screen time to display an error by not reloading the UI
      if (DataService.accessToken) {
        this.updateRegisteredComponents();
      }
      return result;
    } catch (ex) {
      return ex;
    }
  }

  public async loginVerification(email: string, resend: boolean): Promise<DataResponse> {
    return await DataService.fetch(
      '/authorisation/login-verification',
      {},
      FetchOperation.Post,
      {
        email: email,
        resendEmail: resend
      });
  }

  public clearUserData(): void {
    this._userData = {};
    DataService.clearToken();
  }

  public signOut(navigateToLoginScreen?: boolean): void {
    this._userData = {};
    DataService.clearToken();

    if (isNil(navigateToLoginScreen) || navigateToLoginScreen === true) {
      window.location.href = '/';
    }
  }

  public doesCurrentClientHavePublishedDatasetsMatchingFingerprint(fingerprint: { constructCodes: string[] }): boolean {
    return some(
      this._userProfile.datasetModelFingerprints || [],
      f => f.clientId === this.currentClientOrPartnerId
        && intersection(f.constructCodes, fingerprint.constructCodes).length ===
        (fingerprint.constructCodes || []).length
    );
  }

  public doesCurrentUserHaveSurveyReports(): boolean {
    return some(
      this._userProfile.clientHasSurveyReports || [],
      r => r.clientId === this.currentClientOrPartnerId
        && r.hasReports
    );
  }

  public doesCurrentUserHaveAssessReports(): boolean {
    return some(
      this._userProfile.clientHasAssessReports || [],
      r => r.clientId === this.currentClientOrPartnerId
        && r.hasReports
    );
  }

  public doesCurrentUserHaveManagerReports(): boolean {
    return some(
      this._userProfile.clientHasManagerReports || [],
      r => r.clientId === this.currentClientOrPartnerId
        && r.hasReports
    );
  }

  public async loadUserProfile(productLine: ProductLine, hideErrorMessage?: boolean): Promise<void> {
    this._userProfile = await DataService.tryLoad<UserProfile>
    ('/user-profile/load-profile', {}, hideErrorMessage);

    if (this._userProfile) {

      if (isNil(hideErrorMessage) || hideErrorMessage === false) {
        SurveyWizardService.setShowSurveyWizard(true);
      }
      // Sort the partners and clients by name so that they appear sorted in the UI
      this._userProfile.clientDetails.sort((a, b) => a.name.localeCompare(b.name));
      this._userProfile.partnerDetails.sort((a, b) => a.name.localeCompare(b.name));
    }

    this.buildClientAndPartnerTree();

    if (this._userProfile) {
      // First try load the last accessed organisation from local storage
      if (this.organisationId) {
        await this.setCurrentClientOrPartnerId(this.organisationId, productLine);
      }

      // If there was no last accessed organisation, or if the user doesn't have access to that organisation...
      if (!this.currentClientOrPartnerId) {
        let topLevelPartners = filter(
          this._userProfile.partnerDetails,
          (partner: OrganisationDetails) => !partner.parent
        );

        if (topLevelPartners.length > 0) {
          // Try set the current client or partner to the highest level partner the client has access to
          await this.setCurrentClientOrPartnerId(topLevelPartners[0].id, productLine);
        } else if (this._userProfile.clientDetails && this._userProfile.clientDetails.length > 0) {
          // ...else set the current organisation to the first client alphabetically
          await this.setCurrentClientOrPartnerId(this._userProfile.clientDetails[0].id, productLine);
        }
      }
    } else {
      // Clear the current partner or client so that nothing remains behind from the last login
      this._currentClientOrPartner = undefined;
    }

    if (this._userProfile &&
      this._userProfile.partnerDetails.length <= 0 && this._userProfile.clientDetails.length <= 0) {
      this._currentClientOrPartner = {children: [], id: '', isPartner: false, logoUrl: '', name: ''};
    }

  }

  public checkPermissionGrantedForAnyUnit(permission: string): boolean {

    if (!this._userProfile) {
      return false;
    }

    if (!this._userProfile.businessUnitPermissions) {
      return false;
    }

    if (!this._currentClientOrPartner) {
      return false;
    }

    return some(this._userProfile.businessUnitPermissions, unitPermissions => includes(unitPermissions, permission));
  }

  public checkPermissionsGrantedForClientOrPartner(...permissions: string[][]): boolean {


    if (!this._userProfile) {
      return false;
    }

    if (!this._userProfile.organisationPermissions) {
      return false;
    }

    if (!this._currentClientOrPartner) {
      return false;
    }

    let grantedPermissions = this._userProfile.organisationPermissions[this._currentClientOrPartner.id];

    if (!grantedPermissions) {
      return false;
    }

    for (let i = 0; i < permissions.length; i++) {
      let matchedPermissions = intersection(grantedPermissions, permissions[i]);
      if (matchedPermissions.length === permissions[i].length) {
        return true;
      }
    }

    return false;
  }

  public isVirtualBusinessUnitManagerWithOnlyAnalyticAccess(): boolean {
    return UserService.instance.checkPermissionsGrantedForClientOrPartner(
      ['VIRTUAL_STRUCTURE_MANAGER_ANALYTICS_ACCESS']) &&
      !UserService.instance.checkPermissionsGrantedForClientOrPartner(['WORKPLACE_ANALYTICS_ACCESS']);
  }

  public getVirtualBusinessUnitIdsWithPermissions(permissions: string[][]): string[] {
    if (!this._userProfile) {
      return [];
    }

    if (!this._userProfile.businessUnitPermissions) {
      return [];
    }

    let allowedUnitIds: string[] = [];

    for (let p of permissions) {
      for (let businessUnitId in this._userProfile.managedVirtualUnitsBusinessUnitPermissions) {
        if (this._userProfile.managedVirtualUnitsBusinessUnitPermissions.hasOwnProperty(businessUnitId)) {
          const businessUnitPermissions = this._userProfile.managedVirtualUnitsBusinessUnitPermissions[businessUnitId];
          let hasPermissions = intersection(businessUnitPermissions, p);
          if (hasPermissions.length === p.length) {
            allowedUnitIds.push(businessUnitId);
          }
        }
      }
    }

    return allowedUnitIds;
  }

  public getBusinessUnitIdsWithPermissions(permissions: string[][]): string[] {
    if (!this._userProfile) {
      return [];
    }

    if (!this._userProfile.businessUnitPermissions) {
      return [];
    }

    let allowedUnitIds: string[] = [];

    for (let p of permissions) {
      for (let businessUnitId in this._userProfile.businessUnitPermissions) {
        if (this._userProfile.businessUnitPermissions.hasOwnProperty(businessUnitId)) {
          const businessUnitPermissions = this._userProfile.businessUnitPermissions[businessUnitId];
          let hasPermissions = intersection(businessUnitPermissions, p);
          if (hasPermissions.length === p.length) {
            allowedUnitIds.push(businessUnitId);
          }
        }
      }
    }

    return allowedUnitIds;
  }

  public async loadUserInformation(hideErrorMessage?: boolean) {
    if (DataService.accessToken) {
      let decoded = jwtDecode<any>(DataService.accessToken);
      this.parseClaims(decoded);
      await this.updateRegisteredComponents();
    } else {
      this._userData = {};
    }
  }

  public async ChangePartnerLogo(logoUrl: string): Promise<any> {
    if (this.currentClientOrPartner && this.currentClientOrPartner.isPartner) {
      let response = await DataService.fetch<StandardResponseData>(
        '/palims-protected/change-partner-logo', {
          partnerId: UserService.instance.currentClientOrPartnerId,
          imageUrl: logoUrl,
        });

      if (response.statusCode !== 200) {
        showCustomErrorMessage('Could not change image', response.data.code);
      } else {
        this.currentClientOrPartner.logoUrl = logoUrl;
        await this.updateRegisteredComponents();
      }
    }

    return null;
  }

  private parseClaims(decodedToken: any) {
    this._userData = {};
    forOwn(decodedToken, (value: string, key: string) => {
      if (key === 'fullName') {
        this._userData.fullName = value;
      } else if (key === 'preferredName') {
        this._userData.preferredName = value;
      } else if (key === 'sub') {
        this._userData.email = value;
      }
    });
  }

  private buildClientAndPartnerTree(): void {
    if (!this._userProfile) {
      return;
    }

    let treeMap: Map<string, OrganisationDetails> = new Map<string, OrganisationDetails>();
    let clientsAndPartners = concat(this._userProfile.partnerDetails || [], this._userProfile.clientDetails || []);

    for (let i = 0; i < clientsAndPartners.length; i++) {
      treeMap.set(clientsAndPartners[i].id, clientsAndPartners[i]);
    }

    for (let i = 0; i < clientsAndPartners.length; i++) {
      let parent = treeMap.get(clientsAndPartners[i].partnerId || '');
      if (parent) {
        clientsAndPartners[i].parent = parent;
        parent.children = parent.children || [];
        parent.children.push(clientsAndPartners[i]);
      }
    }
  }

  public registerForUpdate(component: Component<any, any>): void {
    this._updatedComponents.register(component);
  }

  public deregisterForUpdate(component: Component<any, any>): void {
    this._updatedComponents.deregister(component);
  }

  public updateRegisteredComponents(): Promise<void> {
    return this._updatedComponents.updateAll();
  }

  private constructor() {
    this._updatedComponents = new UpdatedComponentList();

    this.loadUserInformation(true);
  }
}
