import UserService from '../services/UserService';
import DataService from '../services/DataService';
import DemographicCategory from '../models/DemographicCategory';
import BusinessUnit from '../models/BusinessUnit';
import {NamedEntity} from '../models/NamedEntity';
import Demographic from '../models/Demographic';

export interface MasterDataSurveyModelsResponse {
  code: string;
  surveyModels: NamedEntity[];
}

export interface MasterDataDatasetsResponse {
  code: string;
  datasets: NamedEntity[];
}

export async function getSurveyModels(): Promise<MasterDataSurveyModelsResponse | undefined> {
  // returns true > 0
  let currentId = undefined;
  if (UserService.instance.currentClientOrPartner) {
    currentId = UserService.instance.currentClientOrPartner.id;
  } else {
    return await undefined;
  }

  return await DataService.tryLoad<MasterDataSurveyModelsResponse>(
    '/master-data/get-organisation-survey-models',
    {
      OrganisationId: currentId
    });
}

export async function getDatasets(SurveyModelId: string):
  Promise<MasterDataDatasetsResponse | undefined> {
  let currentId = undefined;
  if (UserService.instance.currentClientOrPartner) {
    currentId = UserService.instance.currentClientOrPartner.id;
  } else {
    return await undefined;
  }

  return await DataService.tryLoad<MasterDataDatasetsResponse>(
    '/master-data/get-client-datasets', {
      SurveyModelId: SurveyModelId,
      ClientId: currentId
    });
}

export async function getDemographicCategoriesForClient(): Promise<DemographicCategory[] | undefined> {
  if (!UserService.instance.currentClientOrPartner || UserService.instance.currentClientOrPartner.isPartner) {
    return undefined;
  }

  return await DataService.tryLoad<DemographicCategory[]>(
    '/master-data/get-client-demographic-categories',
    {
      clientId: UserService.instance.currentClientOrPartnerId
    });
}

export async function getDemographicsForClient(): Promise<Demographic[] | undefined> {
  if (!UserService.instance.currentClientOrPartner || UserService.instance.currentClientOrPartner.isPartner) {
    return undefined;
  }

  return await DataService.tryLoad<Demographic[]>(
    '/master-data/get-client-demographics',
    {
      clientId: UserService.instance.currentClientOrPartnerId
    });
}

export async function getAdditionalInfoFieldsForClient(): Promise<NamedEntity[] | undefined> {
  if (!UserService.instance.currentClientOrPartner || UserService.instance.currentClientOrPartner.isPartner) {
    return undefined;
  }

  return await DataService.tryLoad<NamedEntity[]>(
    '/master-data/get-client-additional-info-fields',
    {
      clientId: UserService.instance.currentClientOrPartnerId
    });
}

export async function getBusinessUnitsForCurrentClient(): Promise<BusinessUnit[] | undefined> {
  if (!UserService.instance.currentClientOrPartner || UserService.instance.currentClientOrPartner.isPartner) {
    return undefined;
  }

  return await DataService.tryLoad<BusinessUnit[]>(
    '/master-data/get-client-business-units',
    {
      clientId: UserService.instance.currentClientOrPartnerId
    });
}