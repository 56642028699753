import {Structure} from './Structure';
import {SurveyModelEntity} from './SurveyModelEntity';
import {QuestionnaireEntity} from './QuestionnaireEntity';
import {DatasetEntity} from './DatasetEntity';
import {VirtualStructureEntity} from './VirtualStructureEntity';
import {BenchmarkEntity} from './BenchmarkEntity';
import BusinessUnit from '../../shared/models/BusinessUnit';
import {EngagementLevel} from './EngagementLevel';
import {Func} from '../../shared-common/types/Func';
import {AnalyticsFilterType} from './AnalyticsFilterType';
import {compact, filter, find, includes, intersection, isNil, orderBy, size, first} from 'lodash-es';
import {sortDescending} from '../../shared/helpers/ArrayHelpers';
import {asMoment} from '../../shared/helpers/MomentHelpers';
import {
  getCovidModelFingerprint,
  getIndicatorModelFingerprint
} from '../../shared/helpers/SurveyModelFingerprintHelpers';
import UserService from '../../shared/services/UserService';
import ProductLineHelpers from '../../shared-common/helpers/ProductLineHelpers';
import {findChildBusinessUnitsWithPermissions} from '../../shared/helpers/SecurityHelpers';
import {ProductLine} from '../../shared-common/types/ProductLine';
import Helpers from '../../shared-common/helpers/Helpers';
import {DemographicAttribute} from '../../shared-common/types/DemographicAttribute';

export class AnalyticsFilter {
  private readonly _type: AnalyticsFilterType;
  private readonly _availableVirtualStructures: VirtualStructureEntity[];
  private readonly _availableSurveyModels: SurveyModelEntity[];
  private readonly _availableDatasets: DatasetEntity[];
  private readonly _changedCallback: Func;
  private _isActive: boolean;
  private _name?: string;
  private _structure: Structure;
  private _surveyModel: SurveyModelEntity | undefined;
  private _questionnaire: QuestionnaireEntity | undefined;
  private _currentDataset: DatasetEntity | undefined;
  private _previousDataset: DatasetEntity | undefined;
  private _trendDatasets: DatasetEntity[];
  private _useLatestUnitStructure: boolean;
  private _virtualStructure: VirtualStructureEntity | undefined;
  private _benchmark?: BenchmarkEntity;
  private _businessUnits: BusinessUnit[];
  private _demographics: DemographicAttribute[];
  private _engagementLevels: EngagementLevel[];
  private _employeeIds: string[];
  private _applyDemographicFilter: boolean;
  private _languageCodes: string[];

  constructor(
    type: AnalyticsFilterType,
    virtualStructures: VirtualStructureEntity[],
    surveyModels: SurveyModelEntity[],
    datasets: DatasetEntity[],
    changedCallback: Func
  ) {
    this._type = type;
    this._availableVirtualStructures = virtualStructures;
    this._availableSurveyModels = surveyModels;
    this._availableDatasets = datasets;

    this._isActive = false;
    this._name = undefined;
    this._structure = 'ACTUAL';
    this._surveyModel = undefined;
    this._questionnaire = undefined;
    this._currentDataset = undefined;
    this._previousDataset = undefined;
    this._trendDatasets = [];
    this._useLatestUnitStructure = true;
    this._virtualStructure = undefined;
    this._benchmark = undefined;
    this._businessUnits = [];
    this._demographics = [];
    this._engagementLevels = [];
    this._employeeIds = [];
    this._applyDemographicFilter = false;
    this._languageCodes = [];

    this._changedCallback = changedCallback;
  }

  public reset(productLine: ProductLine): void {
    let businessUnits: BusinessUnit[];

    if (UserService.instance.checkPermissionsGrantedForClientOrPartner([
      ProductLineHelpers.getOrgAnalyticsPermission(productLine)
    ])) {
      businessUnits = [];
    } else {
      businessUnits = findChildBusinessUnitsWithPermissions(
        UserService.instance.businessUnits,
        UserService.instance.businessUnits,
        [[ProductLineHelpers.getUnitAnalyticsPermission(productLine)]],
        [ProductLineHelpers.getOrgAnalyticsPermission(productLine)]
      );
    }

    this._isActive = this._type !== 'ComparisonColumn2' && this._type !== 'ComparisonColumn3';
    this._useLatestUnitStructure = false;
    this._businessUnits = businessUnits;
    this._demographics = [];
    this._surveyModel = first(this.applicableSurveyModels);
    this._questionnaire = this.getInitialQuestionnaire();
    this._currentDataset = this.getInitialCurrentDataset();
    this._previousDataset = this.getInitialPreviousDataset();
    this._trendDatasets = this.getInitialTrendDatasets();
    this._engagementLevels = [];
    this._employeeIds = [];
    this._applyDemographicFilter = false;
    this._languageCodes = [];
    this._virtualStructure = this.getInitialVirtualStructure();
    this._structure = isNil(this._virtualStructure) ? 'ACTUAL' : 'VIRTUAL';
    this._benchmark = this.getInitialBenchmark();

    this._changedCallback();
  }

  public get currentDatasetModel(): SurveyModelEntity | undefined {
    const currentDataset = this._currentDataset;
    if (!currentDataset) {
      return undefined;
    }

    return find(this._availableSurveyModels, m => m.id === currentDataset.surveyModelId);
  }

  public getInitialCurrentDataset(): DatasetEntity | undefined {
    return first(this.applicableDatasets);
  }

  public getInitialPreviousDataset(): DatasetEntity | undefined {
    const applicableDatasets = this.applicableDatasets;

    if (applicableDatasets.length <= 1) {
      return undefined;
    }

    if (applicableDatasets[1].questionnaireTypeCode !== 'DIAGNOSTIC') {
      return undefined;
    }

    return applicableDatasets[1];
  }

  public getInitialTrendDatasets(): DatasetEntity[] {
    if (this.type !== 'Pulse') {
      return [];
    }

    return this.applicableDatasets;
  }

  public getInitialQuestionnaire() {
    if (this._type === 'Covid19' || this._type === 'Indicator') {
      return first(this.applicableQuestionnaires);
    }

    return undefined;
  }

  public getInitialVirtualStructure(): VirtualStructureEntity | undefined {
    const dataset = Helpers.ifNil(this._currentDataset, this._trendDatasets[0]);
    const datasetVirtualStructureId = Helpers.nillable(dataset).virtualStructureId;

    if (isNil(datasetVirtualStructureId)) {
      return undefined;
    }

    return find(this.applicableVirtualStructures, v => v.id === datasetVirtualStructureId);
  }

  public getInitialBenchmark(): BenchmarkEntity | undefined {
    if (isNil(this.surveyModel)) {
      return undefined;
    }

    return find(this.surveyModel.benchmarks, b => b.id === this.surveyModel.defaultBenchmarkId);
  }

  public get type(): AnalyticsFilterType {
    return this._type;
  }

  public get isActive(): boolean {
    return this._isActive;
  }

  public set isActive(value: boolean) {
    this._isActive = value;
    this._changedCallback();
  }

  public get name(): string | undefined {
    return this._name;
  }

  public set name(value: string | undefined) {
    this._name = value;
    this._changedCallback();
  }

  public get structure(): Structure {
    return this._structure;
  }

  public set structure(value: Structure) {
    if (this._structure !== value) {
      this._businessUnits = [];
    }
    this._structure = value;
    this._changedCallback();
  }

  public get surveyModel(): SurveyModelEntity | undefined {
    return this._surveyModel;
  }

  public set surveyModel(value: SurveyModelEntity | undefined) {
    this._surveyModel = value;
    this._questionnaire = this.getInitialQuestionnaire();
    this._currentDataset = this.getInitialCurrentDataset();
    this._previousDataset = this.getInitialPreviousDataset();
    this._trendDatasets = this.getInitialTrendDatasets();
    this._virtualStructure = this.getInitialVirtualStructure();
    this._structure = isNil(this._virtualStructure) ? 'ACTUAL' : 'VIRTUAL';
    this._benchmark = this.getInitialBenchmark();
    this._changedCallback();
  }

  public get applicableSurveyModels(): SurveyModelEntity[] {
    return this._availableSurveyModels;
  }

  public get questionnaire(): QuestionnaireEntity | undefined {
    return this._questionnaire;
  }

  public set questionnaire(value: QuestionnaireEntity | undefined) {
    this._questionnaire = value;
    this._changedCallback();
  }

  public get applicableQuestionnaires(): QuestionnaireEntity[] {
    if (isNil(this._surveyModel)) {
      return [];
    }

    const connectedSurveyModels: SurveyModelEntity[] = [
      this._surveyModel,
      ...filter(this._availableSurveyModels, m => includes(this._surveyModel.connectedSurveyModels, m.id))
    ];

    const questionnaires =
      sortDescending(
        filter(
          compact(connectedSurveyModels.flatMap(m => m.questionnaires)),
          q => q.hasPublishedDataset
        ),
        q => q.displayDate ? asMoment(q.displayDate).unix() : 0
      );

    // The COVID-19 and Indicator pages allow only pulse survey questionnaires to be selected.
    if (includes(['Covid19', 'Indicator'], this._type)) {
      return filter(questionnaires, q => q.questionnaireTypeCode === 'PULSE');
    }

    return questionnaires;
  }

  public get currentDataset(): DatasetEntity | undefined {
    return this._currentDataset;
  }

  public set currentDataset(value: DatasetEntity | undefined) {
    this._currentDataset = value;
    this._virtualStructure = this.getInitialVirtualStructure();
    this._structure = isNil(this._virtualStructure) ? 'ACTUAL' : 'VIRTUAL';
    this._changedCallback();
  }

  public get previousDataset(): DatasetEntity | undefined {
    return this._previousDataset;
  }

  public set previousDataset(value: DatasetEntity | undefined) {
    this._previousDataset = value;
    this._changedCallback();
  }

  public get datasets(): DatasetEntity[] {
    return compact([this._currentDataset, this._previousDataset, ...this.trendDatasets]);
  }

  public get trendDatasets(): DatasetEntity[] {
    return this._trendDatasets;
  }

  public set trendDatasets(value: DatasetEntity[]) {
    this._trendDatasets = value;
    this._changedCallback();
  }

  public get useLatestUnitStructure(): boolean {
    return this._useLatestUnitStructure;
  }

  public set useLatestUnitStructure(value: boolean) {
    this._useLatestUnitStructure = value;
    this._changedCallback();
  }

  public get virtualStructure(): VirtualStructureEntity | undefined {
    return this._virtualStructure;
  }

  public set virtualStructure(value: VirtualStructureEntity | undefined) {
    this._virtualStructure = value;
    this._changedCallback();
  }

  public get applicableVirtualStructures(): VirtualStructureEntity[] {
    return this._availableVirtualStructures;
  }

  public get allDatasets(): DatasetEntity[] {
  return this._availableDatasets;
  }

  public get applicableDatasets(): DatasetEntity[] {
    // Only datasets using the selected survey model or connected models are applicable
    let datasets = filter(this._availableDatasets, d => {
      if (isNil(this.surveyModel)) {
        return false;
      }

      return (
        d.surveyModelId === this.surveyModel.id ||
        includes(this.surveyModel.connectedSurveyModels, d.surveyModelId)
      );
    });

    // If the filter applies to only COVID-19 or Indicator surveys, filter the applicable datasets accordingly
    if (this.type === 'Covid19') {
      datasets = filter(datasets, d => this.doesDatasetModelMatchFingerprint(d, getCovidModelFingerprint()));
    } else if (this.type === 'Indicator') {
      datasets = filter(datasets, d => this.doesDatasetModelMatchFingerprint(d, getIndicatorModelFingerprint()));
    }

    // If a questionnaire has been selected, filter out datasets that doesn't use that questionnaire
    if (!isNil(this.questionnaire)) {
      datasets = filter(datasets, d => d.questionnaireId === this.questionnaire.id);
    }

    // Sort the applicable datasets by display date descending with diagnostic results at the top
    return orderBy(
      datasets,
      [
        d => d.questionnaireTypeCode === 'DIAGNOSTIC' ? 0 : 1,
        d => asMoment(d.displayDate).unix()
      ],
      [
        'asc',
        'desc'
      ]
    );
  }

  public get benchmark(): BenchmarkEntity {
    return this._benchmark;
  }

  public set benchmark(value: BenchmarkEntity) {
    this._benchmark = value;
    this._changedCallback();
  }

  public get businessUnits(): BusinessUnit[] {
    return this._businessUnits;
  }

  public set businessUnits(value: BusinessUnit[]) {
    this._businessUnits = value;
    this._changedCallback();
  }

  public get demographics(): DemographicAttribute[] {
    return this._demographics;
  }

  public set demographics(value: DemographicAttribute[]) {
    this._demographics = value;
    this._changedCallback();
  }

  public get engagementLevels(): EngagementLevel[] {
    return this._engagementLevels;
  }

  public set engagementLevels(value: EngagementLevel[]) {
    this._engagementLevels = value;
    this._changedCallback();
  }

  public get employeeIds(): string[] {
    return this._employeeIds;
  }

  public set employeeIds(value: string[]) {
    this._employeeIds = value;
    this._changedCallback();
  }

  public get applyDemographicFilter(): boolean {
    return this._applyDemographicFilter;
  }

  public set applyDemographicFilter(value: boolean) {
    this._applyDemographicFilter = value;
    this._changedCallback();
  }

  public get languageCodes(): string[] {
    return this._languageCodes;
  }

  public set languageCodes(value: string[]) {
    this._languageCodes = value;
    this._changedCallback();
  }

  private doesDatasetModelMatchFingerprint = (dataset: DatasetEntity,
                                              fingerprintConstructCodes?: string[]): boolean => {

    const surveyModel = find(this._availableSurveyModels, m => m.id === dataset.surveyModelId);

    if (!surveyModel) {
      return false;
    }

    const overlapWithFingerprint = intersection(
      fingerprintConstructCodes,
      surveyModel.model.constructs.map(c => c.code)
    );

    return size(overlapWithFingerprint) === size(fingerprintConstructCodes);
  };
}
