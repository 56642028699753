import * as React from 'react';
import {Avatar, Dropdown, Menu, Icon, Tooltip} from 'antd';
import styles from './UserMenu.module.css';
import {NavLink} from 'react-router-dom';
import UserService from '../../../shared/services/UserService';
import {ClickParam} from 'antd/lib/menu';
import Caret, {CaretTheme} from '../../../shared/components/Caret/Caret';
import License from '../../../shared/components/License/License';
import {ThemeContext} from '../../../app/context/ThemeContext';
import {classes} from '../../../shared/helpers/RenderHelpers';
import {ReactComponent as PartnerIcon} from '../../images/svg/avatars/Menu-Partner-Portal.svg';
import {ReactComponent as PartnerIconDropDown} from '../../images/svg/UserMenuDropDown/Drop-partner.svg';

import {navigateToExternalLink} from '../../../shared/helpers/NavigationHelpers';

interface UserMenuState {
  isOpen: boolean;
}

interface UserMenuStateProps {
  mobile?: boolean;
}

export default class UserMenu extends React.Component<UserMenuStateProps, UserMenuState> {
  private partnerPortalURL: string = 'https://www.mindsetmanage.com/onboarding';
  constructor(props: UserMenuStateProps) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  onMenuItemClicked = (param: ClickParam) => {
    if (param.key === 'sign-out') {
      UserService.instance.signOut();
    }
  };

  onVisibleChanged = () => {
    this.setState({isOpen: !this.state.isOpen});
  };

  render() {
    let isPartner = UserService.instance.isUserAPartner();
    let menu = (
      <Menu className={this.props.mobile ? styles.mobileDropdown : styles.dropdown} onClick={this.onMenuItemClicked}>
        {
          UserService.instance.checkPermissionsGrantedForClientOrPartner(['DEMO_ACCESS']) ? '' :
            <Menu.Item key="change-password">
              <NavLink  to="/change-password"><Icon type="safety" className={styles.dropDownMenuIcon}/>
                {'Change Password'}
              </NavLink>
            </Menu.Item>

        }
        {
          isPartner ?
          <Menu.Item key="partner-portal">
            <NavLink
              to={this.partnerPortalURL}
              onClick={() => navigateToExternalLink(this.partnerPortalURL)}
            >
              <PartnerIconDropDown className={styles.dropDownMenuIcon}/>Partner Portal
            </NavLink>
          </Menu.Item> : ''
        }
        <Menu.Divider/>
        <Menu.Item key="sign-out"><Icon type="logout" className={styles.dropDownMenuIcon} />Sign Out</Menu.Item>
      </Menu>
    );

    return (<div className={styles.component}>
      <Dropdown
        className={this.props.mobile ? styles.mobileUserMenu : styles.userMenu}
        overlay={menu}
        trigger={['click']}
        onVisibleChange={this.onVisibleChanged}
      >

        <div className={this.props.mobile ? styles.mobileUserMenu : styles.userMenu}>
          {
            isPartner ?
              <NavLink
                to={this.partnerPortalURL}
                onClick={() => navigateToExternalLink(this.partnerPortalURL)}
              >
                <Tooltip title={'Partner Portal'} >
                  <Avatar size="large" children={<PartnerIcon/>} className={styles.mobileAvatar} />
                </Tooltip>
              </NavLink> :
              <Avatar size="large" icon="user" className={styles.mobileAvatar} />
          }
          
          {this.props.mobile ? <span className={styles.userWord}>User</span> : ''}
          <div className={classes(styles.dropdownContainer, this.state.isOpen ? styles.open : null)}>
            <ThemeContext.Consumer>
              {themeContext => (
                <span className={styles.wrapper}>
                <span
                  className={styles.userName}
                  style={!this.props.mobile ? {color: themeContext.theme.mainMenu.primaryTextColor} : {}}
                >
                  {UserService.instance.userPreferredName}
                </span>
              <Caret
                className={styles.dropDownIcon}
                isOpen={this.state.isOpen}
                size={20}
                theme={CaretTheme.None}
                style={!this.props.mobile ? {color: themeContext.theme.mainMenu.primaryTextColor} : {}}
              />
              </span>
              )}
            </ThemeContext.Consumer>
          </div>
        </div>
      </Dropdown>

      {
        !this.props.mobile
          ? <div className={styles.settingContainer}><License/></div> : ''
      }
    </div>);
  }
}
