import * as React from 'react';
import Scrollbars, {positionValues} from 'react-custom-scrollbars';
import styles from './ThemedScrollbars.module.css';
import ReactResizeDetector from 'react-resize-detector';
import {classes} from '../../helpers/RenderHelpers';

export type ScrollPosition = {
  scrollLeft: number;
  scrollTop: number;
};

export interface ScrollbarsHandle {
  scrollTop(top: number): void;

  scrollLeft(left: number): void;

  scrollToTop(): void;

  scrollToBottom(): void;

  scrollToLeft(): void;

  scrollToRight(): void;

  getScrollLeft(): number;

  getScrollTop(): number;

  getScrollWidth(): number;

  getScrollHeight(): number;

  getClientWidth(): number;

  getClientHeight(): number;

  getValues(): positionValues;
}

export enum ScrollbarDirections {
  Vertical,
  Horizontal,
  Both,
  None
}

interface ThemedScrollbarsProps {
  className?: string;
  visibleScrollbars?: ScrollbarDirections;
  autoHideTracks?: boolean;
  onHandleCreated?: (handle: ScrollbarsHandle | null) => void;
  onScroll?: (scrollPosition: ScrollPosition) => void;
  style?: React.CSSProperties;
  thickTracks?: boolean;
  autoHeight?: boolean;
}

interface ThemedScrollbarsState {
  isScrolling: boolean;
}

export default class ThemedScrollbars extends React.Component<ThemedScrollbarsProps, ThemedScrollbarsState> {
  private _scrollLeftSnapshot: number;
  private _scrollTopSnapshot: number;

  constructor(props: ThemedScrollbarsProps) {
    super(props);

    this.state = {
      isScrolling: false
    };
  }

  onScrollStart = () => {
    this.setState({isScrolling: true});
  };

  onScrollEnd = () => {
    this.setState({isScrolling: false});
  };

  render() {
    let showVerticalScrollbars: boolean = true;
    let showHorizontalScrollbars: boolean = false;

    switch (this.props.visibleScrollbars) {
      default:
      case ScrollbarDirections.Vertical:
        showVerticalScrollbars = true;
        showHorizontalScrollbars = false;
        break;
      case ScrollbarDirections.Horizontal:
        showVerticalScrollbars = false;
        showHorizontalScrollbars = true;
        break;
      case ScrollbarDirections.Both:
        showVerticalScrollbars = true;
        showHorizontalScrollbars = true;
        break;
      case ScrollbarDirections.None:
        showVerticalScrollbars = false;
        showHorizontalScrollbars = false;
        break;
    }

    return (
      <Scrollbars
        ref={(me: Scrollbars | null) => {
          if (this.props.onHandleCreated) {
            this.props.onHandleCreated(me);
          }
        }}
        className={[
          this.props.className || '',
          this.state.isScrolling ? styles.scrolling : '',
          this.props.thickTracks ? styles.thickTracks : '',
          showHorizontalScrollbars ? styles.showHorizontal : '',
          showVerticalScrollbars ? styles.showVertical : ''
        ].join(' ')}
        onScroll={() => {
          if (this.props.onScroll) {
            this.props.onScroll({
              scrollLeft: this._scrollLeftSnapshot,
              scrollTop: this._scrollTopSnapshot
            });
          }
        }}
        onScrollStart={this.onScrollStart}
        onScrollStop={this.onScrollEnd}
        onUpdate={(scrollPosition: positionValues) => {
          this._scrollLeftSnapshot = scrollPosition.scrollLeft;
          this._scrollTopSnapshot = scrollPosition.scrollTop;
        }}
        style={this.props.style}
        hideTracksWhenNotNeeded={this.props.autoHideTracks ? this.props.autoHideTracks : true}
        autoHeight={this.props.autoHeight}
        autoHeightMax={10000}
        renderThumbVertical={(props: any) => <div {...props} className={styles.thumb}/>}
        renderThumbHorizontal={(props: any) => <div {...props} className={styles.thumb}/>}
        renderTrackVertical={(props: any) =>
          <div
            {...props}
            className={
              classes(
                styles.verticalTrack,
                this.props.autoHideTracks !== true ? styles.visibleTrack : null
              )}
          />}
        renderTrackHorizontal={(props: any) =>
          <div
            {...props}
            className={
              classes(
                styles.horizontalTrack,
                this.props.autoHideTracks !== true ? styles.visibleTrack : null
              )}
          />}
      >
        <div
          style={{height: '100%', width: '100%'}}
        >
          {this.props.children}
          <ReactResizeDetector
            handleWidth={true}
            handleHeight={true}
            refreshMode="debounce"
            refreshRate={500}
            onResize={() => {
              this.forceUpdate();
            }}
          />
        </div>
      </Scrollbars>);
  }
}