import {useCache} from './useCache';
import {filter, first, isNil} from 'lodash-es';
import {DependencyList, useEffect} from 'react';
import {SurveyModelStringsData} from '../types/SurveyModelStringsData';
import {SurveyModelString} from '../types/SurveyModelString';
import {isEmptyOrWhiteSpace} from '../../shared/helpers/StateHelpers';
import Helpers from '../helpers/Helpers';
import {SurveyModelLanguageKey} from '../types/SurveyModelLanguageKey';

export function useSurveyModelStringsStore(loadSurveyModelStringsCallback:
                                             (key: SurveyModelLanguageKey) => Promise<SurveyModelStringsData>,
                                           deps: DependencyList = []) {
  // Cache the data for all accessible survey models, indexed by language code
  const {
    getValue: getSurveyModelStringData,
    setLocalValue,
    clearCache,
    waitForValue: waitForSurveyModelStringData
  } =
    useCache<SurveyModelLanguageKey, SurveyModelStringsData>(loadSurveyModelStringsCallback);

  // Reload the cache if any dependencies have changed
  useEffect(
    () => {
      clearCache();
    },
    deps
  );

  const getSurveyModelStringByCode = (surveyModelId: string,
                                      languageCode: string,
                                      code: string): SurveyModelString => {

    const languageCodeOrFallback = Helpers.ifNil(languageCode, 'en-US');

    const data = getSurveyModelStringData({
      surveyModelId: surveyModelId,
      languageCode: languageCodeOrFallback
    });

    if (isNil(data)) {
      return {
        code: code,
        languageCode: languageCodeOrFallback,
        content: ''
      };
    }

    const surveyModelString = first(filter(data.surveyModelStrings, s => s.code === code));

    if (isNil(surveyModelString)) {
      return {
        code: code,
        languageCode: languageCodeOrFallback,
        content: ''
      };
    }

    return surveyModelString;
  };

  const getSurveyModelStringByCodeAsync = async (surveyModelId: string,
                                                 languageCode: string,
                                                 code: string): Promise<SurveyModelString> => {

    const languageCodeOrFallback = Helpers.ifNil(languageCode, 'en-US');

    const data = await waitForSurveyModelStringData({
      surveyModelId: surveyModelId,
      languageCode: languageCodeOrFallback
    });

    if (isNil(data)) {
      return {
        code: code,
        languageCode: languageCodeOrFallback,
        content: ''
      };
    }

    const surveyModelString = first(filter(data.surveyModelStrings, s => s.code === code));

    if (isNil(surveyModelString)) {
      return {
        code: code,
        languageCode: languageCodeOrFallback,
        content: ''
      };
    }

    return surveyModelString;
  };

  const getTranslatedStringByCode = (surveyModelId: string,
                                     languageCode: string,
                                     code: string): SurveyModelString => {

    const languageCodeOrFallback = Helpers.ifNil(languageCode, 'en-US');

    const data = getSurveyModelStringData({
      surveyModelId: surveyModelId,
      languageCode: languageCodeOrFallback
    });

    if (isNil(data)) {
      return {
        code: code,
        languageCode: languageCodeOrFallback,
        content: ''
      };
    }

    const surveyModelString = first(filter(data.surveyModelStrings, s => s.code === code));

    if (isNil(surveyModelString)) {
      return {
        code: code,
        languageCode: languageCodeOrFallback,
        content: ''
      };
    }

    if (surveyModelString.languageCode !== languageCodeOrFallback) {
      return {
        code: code,
        languageCode: languageCodeOrFallback,
        content: ''
      };
    }

    return surveyModelString;
  };

  const getTranslatedStringByCodeAsync = async (surveyModelId: string,
                                                languageCode: string,
                                                code: string): Promise<SurveyModelString> => {

    const languageCodeOrFallback = Helpers.ifNil(languageCode, 'en-US');

    const data = await waitForSurveyModelStringData({
      surveyModelId: surveyModelId,
      languageCode: languageCodeOrFallback
    });

    if (isNil(data)) {
      return {
        code: code,
        languageCode: languageCodeOrFallback,
        content: ''
      };
    }

    const surveyModelString = first(filter(data.surveyModelStrings, s => s.code === code));

    if (isNil(surveyModelString)) {
      return {
        code: code,
        languageCode: languageCodeOrFallback,
        content: ''
      };
    }

    if (surveyModelString.languageCode !== languageCodeOrFallback) {
      return {
        code: code,
        languageCode: languageCodeOrFallback,
        content: ''
      };
    }

    return surveyModelString;
  };

  const hasTranslation = (surveyModelId: string,
                          languageCode: string,
                          code: string): boolean => {

    const languageCodeOrFallback = Helpers.ifNil(languageCode, 'en-US');

    const data = getSurveyModelStringData({
      surveyModelId: surveyModelId,
      languageCode: languageCodeOrFallback
    });

    if (isNil(data)) {
      return false;
    }
    const surveyModelString = first(filter(data.surveyModelStrings, s => s.code === code));

    if (isNil(surveyModelString)) {
      return false;
    }

    if (surveyModelString.languageCode !== languageCodeOrFallback || isEmptyOrWhiteSpace(surveyModelString.content)) {
      return false;
    }
    return true;
  };

  const hasTranslationAsync = async (surveyModelId: string,
                                     languageCode: string,
                                     code: string): Promise<boolean> => {

    const languageCodeOrFallback = Helpers.ifNil(languageCode, 'en-US');

    const data = await waitForSurveyModelStringData({
      surveyModelId: surveyModelId,
      languageCode: languageCodeOrFallback
    });

    if (isNil(data)) {
      return false;
    }
    const surveyModelString = first(filter(data.surveyModelStrings, s => s.code === code));

    if (isNil(surveyModelString)) {
      return false;
    }

    if (surveyModelString.languageCode !== languageCodeOrFallback || isEmptyOrWhiteSpace(surveyModelString.content)) {
      return false;
    }
    return true;
  };

  const setLocalSurveyModelString = (surveyModelId: string,
                                     languageCode: string,
                                     code: string,
                                     localString: string) => {

    const languageCodeOrFallback = Helpers.ifNil(languageCode, 'en-US');

    const key: SurveyModelLanguageKey = {
      surveyModelId: surveyModelId,
      languageCode: languageCodeOrFallback
    };

    let localStrings = getSurveyModelStringData(key).surveyModelStrings;

    localStrings = Helpers.addOrReplaceInImmutableWhere(
      localStrings,
      {
        code: code,
        languageCode: languageCodeOrFallback,
        content: localString
      },
      s => s.code === code
    );

    setLocalValue(key, {surveyModelStrings: localStrings});
  };

  return {
    getSurveyModelStringByCode,
    getSurveyModelStringByCodeAsync,
    getTranslatedStringByCode,
    getTranslatedStringByCodeAsync,
    hasTranslation,
    hasTranslationAsync,
    setLocalSurveyModelString
  };
}
