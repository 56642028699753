import Helpers from '../../shared-common/helpers/Helpers';
import {isNil} from 'lodash-es';

type DimensionUnit = 'pixels' | 'percent';
type DimensionLiteral = string | number | undefined | null;

export class Dimension {
  private static readonly VALUE_WITH_UNIT_REGEX = new RegExp(`\\s*([0-9]+)(px|%)?\\s*`, 'i');

  public static readonly Zero = new Dimension(0, 'pixels');
  public static readonly Nil = new Dimension(undefined, undefined);

  private readonly _value: number | undefined;
  private readonly _unit: DimensionUnit | undefined;

  protected constructor(value: DimensionLiteral, defaultUnit: DimensionUnit | undefined) {
    if (isNil(value)) {
      this._value = undefined;
      this._unit = undefined;
      return;
    }

    if (typeof value === 'number') {
      this._value = value;
      this._unit = defaultUnit || 'pixels';
      return;
    }

    if (Helpers.isBlank(value)) {
      this._value = undefined;
      this._unit = undefined;
      return;
    }

    const match = value.match(Dimension.VALUE_WITH_UNIT_REGEX);

    const numValue = parseInt(match[1], 10);
    const unitSymbol = (match[2] || '').toLowerCase();

    switch (unitSymbol) {
      case '%':
        this._value = numValue;
        this._unit = 'percent';
        break;
      case 'px':
        this._value = numValue;
        this._unit = 'pixels';
        break;
      default:
        this._value = numValue;
        this._unit = defaultUnit;
        break;
    }
  }

  public asCss(): string | undefined {
    if (this.isNil()) {
      return undefined;
    }

    if (this._value === 0) {
      return '0';
    }

    switch (this._unit) {
      case 'pixels':
      default:
        return `${this._value}px`;
      case 'percent':
        return `${this._value}%`;
    }
  }

  public asNumber(): number | undefined {
    return this._value;
  }

  public isPercentage(): boolean {
    return this._unit === 'percent';
  }

  public isZero(): boolean {
    return this._value === 0;
  }

  public isNil(): boolean {
    return isNil(this._value);
  }

  public add(other: Dimension | DimensionLiteral, oneHundredPercent?: Dimension | DimensionLiteral): Dimension {
    switch (this._unit) {
      case 'pixels':
        return new Dimension(
          this._value +
          Dimension
            .fromUnknown(other)
            .toPixels(Dimension.fromUnknown(oneHundredPercent))
            .asNumber(),
          'pixels'
        );
      case 'percent':
        return new Dimension(
          this._value +
          Dimension.fromUnknown(other)
            .toPercent(Dimension.fromUnknown(oneHundredPercent))
            .asNumber(),
          'percent'
        );
      default:
        return Dimension.fromUnknown(other);
    }
  }

  public ifNil(other: Dimension | DimensionLiteral): Dimension {
    if (this.isNil()) {
      return Dimension.fromUnknown(other, this._unit);
    } else {
      return this;
    }
  }

  public static inPixels(dimension: DimensionLiteral): Dimension {
    if (typeof dimension === 'string' && !Helpers.isBlank(dimension)) {
      if (dimension.includes('%')) {
        return new Dimension(dimension, 'percent');
      }
    }

    return new Dimension(dimension, 'pixels');
  }

  public scale(factor: number): Dimension {
    if (this._unit === 'pixels') {
      return new Dimension(this._value * factor, 'pixels');
    }

    return this;
  }

  public round(): Dimension {
    if (this._unit === 'pixels') {
      return new Dimension( Math.round(this._value), 'pixels');
    }

    return this;
  }

  public negate(): Dimension {
    return new Dimension(-this._value, 'pixels');
  }

  public toPixels(oneHundredPercent: Dimension | DimensionLiteral): Dimension {
    if (this.isNil()) {
      return Dimension.Nil;
    }

    if (this._unit === 'pixels') {
      return this;
    }

    if (this._unit === 'percent') {
      return new Dimension(Dimension.convertPercentToPixels(this._value, oneHundredPercent), 'pixels');
    }

    return Dimension.Zero;
  }

  public toPercent(oneHundredPercent: Dimension | DimensionLiteral): Dimension {
    if (this.isNil()) {
      return Dimension.Nil;
    }

    if (this._unit === 'percent') {
      return this;
    }

    if (this._unit === 'pixels') {
      return new Dimension(Dimension.convertPixelsToPercent(this._value, oneHundredPercent), 'pixels');
    }

    return Dimension.Zero;
  }

  private static convertPercentToPixels(percent: number, oneHundredPercent: Dimension | DimensionLiteral): number {
    return Dimension.fromUnknown(oneHundredPercent).toPixels(oneHundredPercent).asNumber() * (percent / 100);
  }

  private static convertPixelsToPercent(pixels: number, oneHundredPercent: Dimension | DimensionLiteral): number {
    const onHundredPercentInPixels = Dimension.fromUnknown(oneHundredPercent).toPixels(oneHundredPercent);
    if (onHundredPercentInPixels.isZero()) {
      return 0;
    }
    return pixels / onHundredPercentInPixels.asNumber();
  }

  private static fromUnknown(dimension: Dimension | DimensionLiteral, unit: DimensionUnit = 'pixels'): Dimension {
    if (typeof dimension === 'string' || typeof dimension === 'number' || isNil(dimension)) {
      return new Dimension(dimension, unit);
    }

    return dimension;
  }
}
