import * as React from 'react';
import {CSSProperties, KeyboardEventHandler, LegacyRef, MouseEventHandler, ReactNode} from 'react';
import {VerticalAlignment} from '../../types/VerticalAlignment';
import {HorizontalAlignment} from '../../types/HorizontalAlignment';
import {Position} from '../../types/Position';
import styles from './StackContainer.module.css';
import RenderHelpers from '../../helpers/RenderHelpers';
import {Dimension} from '../../../reports/types/Dimension';
import {flatten, initial} from 'lodash-es';
import Helpers from '../../../shared-common/helpers/Helpers';

export interface StackContainerProps {
  className?: string;
  style?: CSSProperties;
  attributes?: unknown;
  divRef?: LegacyRef<HTMLDivElement>;
  stackFrom?: Position;
  horizontalAlignment?: HorizontalAlignment;
  verticalAlignment?: VerticalAlignment;
  gapWidth?: number | string;
  gapClassName?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onDoubleClick?: MouseEventHandler<HTMLDivElement>;
  onMouseDown?: MouseEventHandler<HTMLDivElement>;
  onMouseUp?: MouseEventHandler<HTMLDivElement>;
  onMouseOver?: MouseEventHandler<HTMLDivElement>;
  onMouseOut?: MouseEventHandler<HTMLDivElement>;
  onKeyUpCapture?: KeyboardEventHandler<HTMLDivElement>;
}

export default class StackContainer extends React.PureComponent<StackContainerProps> {
  private get stackFrom(): Position {
    return this.props.stackFrom || 'top';
  }

  private get gapWidth(): number | string {
    return Dimension.inPixels(this.props.gapWidth).asCss();
  }

  private renderSpacedChildren = (): ReactNode[] => {

    const hasNoGapWidth = (typeof this.gapWidth === 'string' && Helpers.isBlank(this.gapWidth)) || this.gapWidth === 0;
    const hasNoGapClassName = Helpers.isBlank(this.props.gapClassName);

    if (hasNoGapWidth && hasNoGapClassName) {
      return React.Children.toArray(this.props.children);
    }

    const childCount = React.Children.count(this.props.children);

    if (childCount < 2) {
      return React.Children.toArray(this.props.children);
    }

    let gapWidth: number | string | undefined = '100%';
    let gapHeight: number | string | undefined = '100%';

    if (this.stackFrom === 'left' || this.stackFrom === 'right') {
      gapWidth = this.gapWidth;
    } else {
      // noinspection JSSuspiciousNameCombination
      gapHeight = this.gapWidth;
    }

    return initial(
      flatten(
        React.Children.toArray(this.props.children)
          .map((c, i) =>
            [
              c,
              <div
                key={i}
                className={
                  RenderHelpers.classes(
                    styles.gap,
                    this.props.gapClassName
                  )
                }
                style={{width: gapWidth, height: gapHeight}}
              />
            ])
      )
    );
  };

  render() {
    return (
      <div
        ref={this.props.divRef}
        className={RenderHelpers.classes(
          styles.container,
          this.props.className,
          RenderHelpers.switch(
            this.stackFrom,
            {when: 'left', then: styles.stackFromLeft},
            {when: 'top', then: styles.stackFromTop},
            {when: 'right', then: styles.stackFromRight},
            {when: 'bottom', then: styles.stackFromBottom}
          ),
          RenderHelpers.switch(
            this.props.horizontalAlignment,
            {when: 'left', then: styles.horizontalAlignLeft},
            {when: 'center', then: styles.horizontalAlignCenter},
            {when: 'right', then: styles.horizontalAlignRight},
            {then: styles.horizontalAlignStretch}
          ),
          RenderHelpers.switch(
            this.props.verticalAlignment,
            {when: 'top', then: styles.verticalAlignTop},
            {when: 'center', then: styles.verticalAlignCenter},
            {when: 'bottom', then: styles.verticalAlignBottom},
            {then: styles.verticalAlignStretch}
          )
        )}
        style={this.props.style}
        {...this.props.attributes}
        onClick={this.props.onClick}
        onDoubleClick={this.props.onDoubleClick}
        onMouseDown={this.props.onMouseDown}
        onMouseUp={this.props.onMouseUp}
        onMouseOver={this.props.onMouseOver}
        onMouseOut={this.props.onMouseOut}
        onKeyUpCapture={this.props.onKeyUpCapture}
      >
        {this.renderSpacedChildren()}
      </div>
    );
  }
}
