import {DataResponse, StandardResponseData} from '../models/DataResponse';
import ConfigService from './ConfigService';
import UserService from './UserService';
import {find, isNil, merge, startsWith} from 'lodash-es';
import {handleError, handleFetchError} from '../helpers/ErrorHandlerHelpers';
import {showErrorIfRequestFailed, showErrorMessage} from '../helpers/PopupMessageHelpers';
import {createUniqueCode} from '../helpers/IdentifierHelpers';
import Helpers from '../../shared-common/helpers/Helpers';

export enum FetchOperation {
  Post = 'POST',
  Get = 'GET'
}

export default class DataService {

  private static readonly LOCAL_STORAGE_KEY = 'DSACCESSTOKEN';

  public static clearToken() {
    try {
      document.cookie =
        `${ConfigService.config.authCookieName}=; ` +
        `expires=Thu, 01 Jan 1970 00:00:00 UTC; ` +
        `path=/; ` +
        `domain=${ConfigService.config.authCookieDomain}; `;

      // SDV 2022-02-24: Left in to clear the previous token from local storage - it may be removed later
      localStorage.removeItem(DataService.LOCAL_STORAGE_KEY);
    } catch (ex) {
      // Intentionally left blank
    }
  }

  public static get accessToken(): string | null {
    const token = find(
      (document.cookie || '').split(';'),
      cookie => startsWith((cookie || '').trim(), `${ConfigService.config.authCookieName}=`)
    );

    if (isNil(token)) {
      return null;
    }

    return token.replace(`${ConfigService.config.authCookieName}=`, '');
  }

  public static get isLoggedIn(): boolean {
    return document.cookie.includes(Helpers.ifBlank(ConfigService.config.authCookieName, 'mindset_auth'));
  }

  public static async trySave(url: string,
                              requestBody?: any,
                              hideErrorMessages: boolean = false,
                              abortSignal?: AbortSignal): Promise<boolean> {

    try {
      let response = await this.fetch(url, requestBody, FetchOperation.Post, undefined, abortSignal);
      if (!hideErrorMessages) {
        showErrorIfRequestFailed(response);
      }
      return response.statusCode === 200;
    } catch (err) {
      if (err.name === 'AbortError') {
        return false;
      }

      if (!hideErrorMessages) {
        showErrorMessage();
      }
      handleError(err);
      return false;
    }
  }

  public static async tryLoadWithRetries<T = StandardResponseData>(url: string,
                                                                   requestBody?: any,
                                                                   hideErrorMessages: boolean = false,
                                                                   abortSignal?: AbortSignal): Promise<T | undefined> {
    for (let retries = 2; retries >= 0; retries--) {
      if (retries < 2) {
        await DataService.awaitDelay(3000);
      }

      try {
        let response = await this.fetch<T>(url, requestBody, FetchOperation.Post, undefined, abortSignal);
        if (retries <= 0) {
          if (!hideErrorMessages) {
            showErrorIfRequestFailed(response);
          }
        }
        if (response.statusCode === 200) {
          return response.data;
        }
      } catch (err) {
        if (!isNil(abortSignal) && abortSignal.aborted) {
          break;
        }

        if (err.name === 'AbortError') {
          break;
        }

        if (retries <= 0) {
          if (!hideErrorMessages) {
            showErrorMessage();
          }
          handleError(err);
        }
      }
    }

    return undefined;
  }

  private static awaitDelay(milliseconds: number): Promise<void> {
    return new Promise(resolve => {
      setTimeout(resolve, milliseconds);
    });
  }

  public static async tryLoad<T = StandardResponseData>(url: string,
                                                        requestBody?: any,
                                                        hideErrorMessages: boolean = false,
                                                        abortSignal?: AbortSignal): Promise<T | undefined> {
    try {
      let response = await this.fetch<T>(url, requestBody, FetchOperation.Post, undefined, abortSignal);
      if (!hideErrorMessages) {
        showErrorIfRequestFailed(response);
      }
      if (response.statusCode === 200) {
        return response.data;
      } else {
        return undefined;
      }
    } catch (err) {
      if (!isNil(abortSignal) && abortSignal.aborted) {
        return undefined;
      }

      if (err.name === 'AbortError') {
        return undefined;
      }

      if (!hideErrorMessages) {
        showErrorMessage();
      }
      handleError(err);
      return undefined;
    }
  }

  public static async fetch<T = StandardResponseData>(url: string,
                                                      requestBody?: any,
                                                      operation: FetchOperation = FetchOperation.Post,
                                                      confidentialData: any = undefined,
                                                      abortSignal?: AbortSignal): Promise<DataResponse<T>> {

    let baseUrl = ConfigService.config.backendUrl;
    let requestUrl = baseUrl + url;

    let headers: Headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('crossorigin', 'true');

    let allRequestData = {};
    merge(allRequestData, requestBody, confidentialData);

    return new Promise<DataResponse<T>>((resolve, reject) => {
      fetch(requestUrl, {
        method: operation.toString(),
        headers: headers,
        body: confidentialData ? JSON.stringify(allRequestData) : JSON.stringify(requestBody),
        signal: abortSignal,
        credentials: 'include'
      }).then((response: Response) => {
        response.json().then((data: T) => {
          let dataResponse = new DataResponse(data);

          if (response.status === 401) {
            UserService.instance.signOut();
            dataResponse.statusCode = response.status;
            reject(dataResponse);
          } else if (response.status === 400) {
            dataResponse.statusCode = response.status;
            resolve(dataResponse);
          } else if (response.status !== 200) {
            handleFetchError(operation, url, requestBody, response.status);
            dataResponse.statusCode = response.status;
            reject(dataResponse);
          } else {
            dataResponse.statusCode = response.status;
            resolve(dataResponse);
          }
        }).catch(reason => {
          handleFetchError(operation, url, requestBody, response.status, reason);
          reject(
            {
              statusCode: response.status,
              hasNetworkFailure: false,
              errorMessage: 'Fetch failed - ' + reason
            }
          );
        });
      }).catch(() => {
        this.checkInternetAccess().then(isConnected => {
          if (isConnected) {
            reject(
              {
                statusCode: 500, // Static hosting is responding, so our server must be down
                hasNetworkFailure: false,
                errorMessage: 'Server is not responding'
              });
          } else {
            reject(
              {
                statusCode: 500, // Network failure
                hasNetworkFailure: true,
                errorMessage: 'Unable to connect to internet'
              });
          }
        });
      });
    });
  }

  public static async checkInternetAccess(): Promise<boolean> {
    if (window.navigator.onLine === false) {
      return false;
    }

    try {
      await fetch(`/config.json?bust=${createUniqueCode()}`, {
        method: 'GET'
      });
      return true;
    } catch (ex) {
      return false;
    }
  }

  public static async downloadBlob(url: string,
                                   mimeType: string,
                                   requestBody?: any,
                                   operation: FetchOperation = FetchOperation.Post)
    : Promise<{ blob: Blob, statusCode: number }> {

    let baseUrl = ConfigService.config.backendUrl;
    let requestUrl = baseUrl + url;

    let headers: Headers = new Headers();
    headers.append('Content-Type', 'application/json');

    return new Promise<{ blob: Blob | undefined, statusCode: number }>((resolve, reject) => {

      let responseStatusCode: number = 500;

      fetch(requestUrl, {
        method: operation.toString(),
        headers: headers,
        body: JSON.stringify(requestBody || {}),
        credentials: 'include'
      }).then((response: Response) => {
        if (response.status !== 200) {
          reject({
            blob: undefined,
            statusCode: response.status
          });
          responseStatusCode = response.status;
          return undefined;
        } else {
          return response.blob();
        }
      }).then((file: Blob) => {
        let newBlob: Blob = new Blob([file], {type: mimeType});
        resolve({blob: newBlob, statusCode: 200});
      }).catch(() => {
        reject({
          blob: undefined,
          statusCode: responseStatusCode
        });
      });
    });
  }
}
