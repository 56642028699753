import * as React from 'react';
import {PropsWithChildren, useContext, useState} from 'react';
import {useCache} from '../../shared-common/hooks/useCache';
import {Person} from '../../shared-common/types/Person';
import DataService from '../../shared/services/DataService';
import {ProductLineFilter} from '../../shell/context/ProductLineFilter';
import CodeHelpers from '../../shared-common/helpers/CodeHelpers';
import {PersonFilter} from '../../shared-common/types/PersonFilter';
import {PersonCache} from '../../shared-common/context/PersonCache';

export const PersonStore = (props: PropsWithChildren<{}>) => {
  const {currentProductLine} = useContext(ProductLineFilter);
  const [isProcessing, setIsProcessing] = useState<boolean>();

  const {clearCache, getValue} = useCache<PersonFilter, Person[]>(
    async (key: PersonFilter) => {
      setIsProcessing(true);
      try {
        return await DataService.tryLoad<Person[]>(
          '/master-data/get-people', {
            clientId: key.clientId,
            productLineCode: CodeHelpers.codeForProductLine(currentProductLine),
            virtualStructureId: key.virtualStructureId,
            useLatestStructure: key.useLatestStructure,
            unitIds: key.unitIds,
            datasetIds: key.datasetIds,
            onlyWithResponses: key.onlyWithResponses
          });
      } finally {
        setIsProcessing(false);
      }
    }
  );

  const getPeople = (filter: PersonFilter) => {
    return getValue(filter);
  };

  const clearEmployeeCache = () => {
    clearCache();
  };

  return (
    <PersonCache.Provider
      value={{
        getPeople: getPeople,
        clearPersonCache: clearEmployeeCache,
        isPersonCacheProcessing: isProcessing
      }}
    >
      {props.children}
    </PersonCache.Provider>
  );
};
