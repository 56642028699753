import * as React from 'react';
import {isEmptyOrWhiteSpace} from '../../helpers/StateHelpers';
import styles from './FormErrorMessage.module.css';
import {CSSProperties} from 'react';

interface FormErrorMessageProps {
  className?: string;
  style?: CSSProperties;
  errorMessage: string | undefined;
}

export default class FormErrorMessage extends React.PureComponent<FormErrorMessageProps> {
  render() {
    let message = undefined;
    if (!isEmptyOrWhiteSpace(this.props.errorMessage)) {
      message = (this.props.errorMessage as string).trim();
      if (message[message.length - 1] !== '.' && message[message.length - 2] !== '.' &&
        message[message.length - 3] !== '.' ) {
        message = message + '...';
      }
    }

    return (<div
      className={[
        styles.errorBlock,
        this.props.className,
        !isEmptyOrWhiteSpace(this.props.errorMessage) ? styles.hasError : null
      ].join(' ')}
      style={this.props.style}
    >
      <span className={styles.errorMessage}>{message}</span>
    </div>);
  }
}