import * as React from 'react';
import Col from 'antd/es/grid/col';
import Row from 'antd/lib/grid/row';
import styles from './LicenceExpiredPage.module.css';

export default class LicenceExpiredPage extends React.Component<{}, {}> {
  render() {
    return (
      <Row type="flex" justify="space-around" align="middle" className={styles.page}>
        <Col span={24}>
          <h2>You do not have an active licence to view this page</h2>
        </Col>
      </Row>
    );
  }
}