import * as React from 'react';
import {Route, RouteComponentProps, Switch} from 'react-router';
import {Page} from '../../../shared/models/Page';
import {Module} from '../../../shared/models/Module';
import {filter} from 'lodash-es';
import ErrorBoundary from '../../../shared/components/ErrorBoundary/ErrorBoundary';
import styles from './SideModuleMenuContent.module.css';
import MobileMenuService from '../../../shared/services/MobileMenuService';
import ReactResizeDetector from 'react-resize-detector';
import SetupError from '../../../shared/components/SetupError/SetupError';

interface SideModuleMenuContentProps {
  modules: Module[];
}

export default class SideModuleMenuContent extends React.Component<SideModuleMenuContentProps, {}> {
  private currentShowState: boolean | undefined;

  render() {
    return [
      <Switch key={1}>
        <>
          {this.renderDefaultRoute()}
          {this.renderModuleRoutes()}
        </>
      </Switch>,
      <ReactResizeDetector
        key={2}
        handleWidth={true}
        onResize={() => {
          if (this.currentShowState !== MobileMenuService.showAllPages) {
            this.currentShowState = MobileMenuService.showAllPages;
            this.forceUpdate();
          }
        }}
      />
    ];
  }

  private static renderPage(page: Page<any>, props: RouteComponentProps<any>) {

    if (MobileMenuService.showAllPages === false && page.showOnMobile !== true) {
      return <div className={styles.component}>
        <div className={styles.pageContent}>
          <SetupError text={'Page not supported on mobile devices'}/>
        </div>
      </div>;
    }

    return (
      <div className={styles.component}>
        <div className={styles.pageContent}>
          <ErrorBoundary>
            {React.createElement(page.content, {pages: page.pages, ...props} as any)}
          </ErrorBoundary>
        </div>
      </div>
    );
  }

  private static renderLicencePromptPage(page: Page<any>, props: RouteComponentProps<any>) {
    let content = <div/>;

    if (page.licenceRequiredContent) {
      content = React.createElement(page.licenceRequiredContent, props);
    }

    return <div className={styles.component}>
      <div className={styles.pageContent}>
        {content}
      </div>
    </div>;
  }

  private renderDefaultRoute() {
    if (!this.props.modules || this.props.modules.length === 0) {
      return null;
    }

    let activePages = filter<Page<any>>(
      this.props.modules[0].pages,
      (page: Page<any>) => page.isVisible()
    );

    if (!activePages) {
      return null;
    }

    return <Route
      exact
      path="/"
      render={(props: RouteComponentProps<any>) => SideModuleMenuContent.renderPage(activePages[0], props)}
    />;
  }

  private renderModuleRoutes() {
    let routes: JSX.Element[] = [];

    for (let module of this.props.modules) {

      let activePages = filter<Page<any>>(
        module.pages,
        (page: Page<any>) => page.isVisible()
      );

      if (!activePages || activePages.length === 0) {
        continue;
      }

      routes.push(this.createPageRoute(module.urlSlug, '', activePages[0], true));
      // Create Sub Route

      for (let page of activePages) {
        if (page.pages) {
          // make a default route for module/page/{firstPageSider}
          if (page.pages.length > 0) {
            routes.push(
              this.createPageRoute(
                module.urlSlug + '/' + page.urlSlug,
                '',
                page.pages[0],
                false,
                page));
          }
          for (let subpage of page.pages) {
            routes.push(
              this.createPageRoute(
                module.urlSlug + '/' + page.urlSlug,
                subpage.urlSlug,
                subpage,
                false,
                page));
          }
        } else {
          routes.push(this.createPageRoute(module.urlSlug, page.urlSlug, page, false));
        }
      }
    }

    return routes;
  }

  private createPageRoute(moduleUrlSlug: string,
                          pageUrlSlug: string,
                          page: Page<any>,
                          first: boolean,
                          pageSiderConent?: Page<any>
  ): JSX.Element {

    if (page.showLicenceRequired && page.showLicenceRequired()) {

      return <Route
        key={moduleUrlSlug}
        path={'/' + moduleUrlSlug + '/' + pageUrlSlug}
        render={(props: RouteComponentProps<any>) => SideModuleMenuContent.renderLicencePromptPage(page, props)}
      />;
    } else {

      let path = '/' + moduleUrlSlug;
      if (pageUrlSlug) {
        path += '/' + pageUrlSlug;
      }

      if (first) {
        return <Route
          exact
          key={path}
          path={path}
          render={(props: RouteComponentProps<any>) =>
            pageSiderConent ?
              SideModuleMenuContent.renderPage(pageSiderConent, props)
              :
              SideModuleMenuContent.renderPage(page, props)}
        />;
      }

      if (page.subURL && page.subURL === true) {
        return <Route
          key={path + '/'}
          path={path + '/'}
          render={(props: RouteComponentProps<any>) =>
            pageSiderConent ?
              SideModuleMenuContent.renderPage(pageSiderConent, props)
              :
              SideModuleMenuContent.renderPage(page, props)}
        />;
      }

      return <Route
        exact
        key={path}
        path={path}
        render={(props: RouteComponentProps<any>) =>
          pageSiderConent ?
            SideModuleMenuContent.renderPage(pageSiderConent, props)
            :
            SideModuleMenuContent.renderPage(page, props)}
      />;
    }
  }
}
