import * as React from 'react';
import {setValueInArray, unsetValueInArray} from '../../helpers/StateHelpers';

export default class UpdatedComponentList {
  private _registeredComponents: React.Component<any, any>[];

  constructor() {
    this._registeredComponents = [];
  }

  public register(component: React.Component<any, any>) {
    this._registeredComponents = setValueInArray(this._registeredComponents, component);
  }

  public deregister(component: React.Component<any, any>) {
    this._registeredComponents = unsetValueInArray(this._registeredComponents, component);
  }

  public async updateAll(): Promise<void> {
    await Promise.all(this._registeredComponents.map(component => {
      return new Promise<void>((resolve) => {
        component.forceUpdate(() => resolve());
      });
    }));
  }

  public get registeredComponentCount(): number {
    return this._registeredComponents.length;
  }
}
