import * as React from 'react';
import styles from './LoadingAnimation.module.css';
import RenderHelpers from '../../../shared-ui/helpers/RenderHelpers';

interface LoadingAnimationProps {
  className?: string;
  size?: number;
  pauseAnimation?: boolean;
  fadeIn?: boolean;
  showLoadingAtTop?: boolean;
}

export default class LoadingAnimation extends React.PureComponent<LoadingAnimationProps> {
  private get size(): number {
    return this.props.size || 100;
  }

  render() {
    return (
      <div
        className={
          RenderHelpers.classes(
            this.props.showLoadingAtTop ? styles.topPanel : styles.panel,
            this.props.className,
            this.props.fadeIn === false ? null : styles.fadeIn,
            this.props.pauseAnimation ? styles.paused : null
          )
        }
      >
        <div
          className={styles.circle}
          style={{width: this.size, height: this.size}}
        >
          <svg
            className={styles.hourglass}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 745 1113"
            width={this.size}
          >
            <g>
              <path
                className={styles.sand}
                d={'M143 684c37,-38 69,-72 69,-128 0,-55 -32,-89 -69,-128 -69,-73 -149,-156 -143,-364l2 -64 370 0'
                  + ' 371 0 2 64c6,208 -74,291 -143,364 -37,39 -69,73 -69,128 0,56 32,90 69,128 69,73 149,157 143,'
                  + '365l-2 64 -371 0 -370 0 -2 -64c-6,-208 74,-292 143,-365z'}
              />
              <path
                d={'M343 556c0,108 -49,160 -105,219 -44,46 -94,98 -105,207l479 0c-12,-109 -61,-161 -105,-207 -56,'
                  + '-59 -105,-111 -105,-219 0,-107 49,-159 105,-218 44,-46 93,-98 105,-207l-479 0c11,109 61,161'
                  + ' 105,207 56,59 105,111 105,218zm-200 128c37,-38 69,-72 69,-128 0,-55 -32,-89 -69,-128 -69,-73'
                  + ' -149,-156 -143,-364l2 -64 370 0 371 0 2 64c6,208 -74,291 -143,364 -37,39 -69,73 -69,128 0,56'
                  + ' 32,90 69,128 69,73 149,157 143,365l-2 64 -371 0 -370 0 -2 -64c-6,-208 74,-292 143,-365z'}
              />
            </g>
          </svg>
        </div>
      </div>
    );
  }
}
