import {createUniqueCode} from '../helpers/IdentifierHelpers';

export interface Configuration {
  environment: string;
  backendUrl: string;
  bypassLogin?: boolean;
  airbrakeProjectId: string;
  airbrakeProjectKey: string;
  oldSystemUrl: string;
  paypalClientId: string;
  ftpEndpoint: string;
  authCookieName: string;
  authCookieDomain: string;
  frontendBuild: string;
}

export default class ConfigService {
  private static _config: Configuration;

  public static loadConfig(): Promise<Configuration | null> {
    return new Promise<Configuration | null>((resolve) => {
      fetch(`/config.json?bust=${createUniqueCode()}`, {
        method: 'get'
      }).then((response: Response) => {
        response.json().then((data: Configuration) => {
          this._config = data;
          resolve(data);
        });
      }).catch(reason => {
        resolve(null);
      });
    });
  }

  public static get config(): Configuration {
    return this._config;
  }
}
