import React from 'react';
import {ProductLine} from '../../shared-common/types/ProductLine';

export type ProductLineFilterContextProps = {
  currentProductLine: ProductLine;
  getAccessibleProductLines: () => ProductLine[];
  setCurrentProductLine: (productLine: ProductLine) => void;
};

export const ProductLineFilter = React.createContext<ProductLineFilterContextProps>({
  currentProductLine: ProductLine.Workplace,
  getAccessibleProductLines: () => {
    throw new Error('Not implemented');
  },
  setCurrentProductLine: () => {
    throw new Error('Not implemented');
  }
});
