import {filter, isNil, some} from 'lodash-es';

export default class Helpers {
  // Checks if the string is empty or whitespace
  public static isBlank(value: string | undefined | null) {
    if (isNil(value)) {
      return true;
    }

    if (value.length === 0) {
      return true;
    }

    return value.trim().length === 0;
  }

  // Returns a fallback value if the value is nil
  public static ifNil<T>(value: T, ...then: T[]) {
    if (!isNil(value)) {
      return value;
    }

    for (let item of then) {
      if (!isNil(item)) {
        return item;
      }
    }

    return undefined;
  }

  // Returns a fallback value if the value is NaN
  public static ifNaN<T>(value: number, ...then: number[]) {
    if (!isNaN(value)) {
      return value;
    }

    for (let item of then) {
      if (!isNaN(item)) {
        return item;
      }
    }

    return undefined;
  }

  // Returns a fallback value if the string is empty or whitespace
  public static ifBlank(value: string | undefined | null, fallbackValue: string = undefined) {
    if (this.isBlank(value)) {
      return fallbackValue;
    } else {
      return value;
    }
  }

  // Used to replace Optional Chaining until supported in the project.
  // Returns an empty object cast as T if the value is nil, moving the nil access error up the chain.
  public static nillable<T>(value: T | undefined | null): T {
    return value || {} as T;
  }

  // Returns the only item in an array, otherwise return undefined
  public static only<T>(arr: T[]): T | undefined {
    if (isNil(arr)) {
      return undefined;
    }

    if (arr.length === 1) {
      return arr[0];
    }
    return undefined;
  }

  public static updateInImmutableAt<T extends object>(arr: T[],
                                                      changes: Partial<T>,
                                                      atIndex: number): T[] {
    return arr.map((item, index) => {
      if (index === atIndex) {
        return {
          ...item,
          ...changes
        };
      } else {
        return item;
      }
    });
  }
  // Applies changes to the item(s) in an array where the predicate is true, returning a new array with shallow
  // copies of original items.
  public static updateImmutableArray<T>(arr: T[], updateWhere: (item: T) => boolean, changes: Partial<T>): T[] {
    return arr.map(item => {
      if (updateWhere(item)) {
        return {
          ...item,
          ...changes
        };
      } else {
        return {
          ...item
        };
      }
    });
  }

  // Applies changes to the item(s) in an array where the predicate is true, returning a new array. If the predicate
  // is not matched, a new item is appended to the new array.
  public static updateOrAppendToImmutableArray<T>(arr: T[], updateWhere: (item: T) => boolean, changes: T): T[] {
    const wasFound = some(arr, updateWhere);

    return [
      ...arr.map(
        item => {
          if (updateWhere(item)) {
            return {
              ...item,
              ...changes
            };
          } else {
            return {
              ...item
            };
          }
        }),
      ...(wasFound ? [] : [changes])
    ];
  }

  public static updateInImmutableWhere<T extends object>(arr: T[],
                                                         changes: Partial<T>,
                                                         updateWhere: (item: T, index: number) => boolean): T[] {
    return arr.map((item, index) => {
      if (updateWhere(item, index)) {
        return {
          ...item,
          ...changes
        };
      } else {
        return item;
      }
    });
  }

  public static addOrReplaceInImmutableWhere<T extends object | number | string | boolean>(
    arr: T[],
    newItem: T,
    replaceWhere?: (item: T, index: number) => boolean): T[] {

    if (isNil(replaceWhere)) {
      replaceWhere = item => item === newItem;
    }

    const wasFound = some(arr, replaceWhere);
    return [
      ...arr.map(
        (item, index) => {
          if (replaceWhere(item, index)) {
            return newItem;
          } else {
            return item;
          }
        }),
      ...(wasFound ? [] : [newItem])
    ];
  }

  public static replaceInImmutableAt<T extends object | number | string | boolean>(
    arr: T[],
    newItem: T,
    atIndex: number): T[] {
    return this.addOrReplaceInImmutableWhere(arr, newItem, (_, i) => i === atIndex);
  }

  public static addToImmutable<T extends object | number | string | boolean>(
    arr: T[],
    newItem: T): T[] {
    return [...arr, newItem];
  }

  public static removeFromImmutableWhere<T extends object | number | string | boolean>(
    arr: T[],
    removeWhere: (item: T, index: number) => boolean): T[] {
    return filter(arr, (item, index) => !removeWhere(item, index));
  }

  public static removeFromImmutableAt<T extends object | number | string | boolean>(
    arr: T[],
    atIndex: number): T[] {
    return filter(arr, (_, i) => i !== atIndex);
  }
}
