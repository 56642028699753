import React from 'react';

export class ContextTheme {
  public mainMenu: {
    primaryBackgroundColor?: string;
    secondaryBackgroundColor?: string;
    primaryTextColor?: string;
    secondaryTextColor?: string;
  };

  public subMenu: {
    primaryBackgroundColor?: string;
    primaryTextColor?: string;
    primaryTextUnderlineColor?: string;
    iconPrimaryColor?: string;
    iconSecondaryColor?: string;
    iconTertiaryColor?: string;
  };

  public sidePanel: {
    primaryBackgroundColor?: string;
    secondaryBackgroundColor?: string;
  };

  public icons: {
    primaryColor?: string;
    secondaryColor?: string;
    tertiaryColor?: string;
  };
}

export const BaseContextTheme: ContextTheme = {
  mainMenu: {
    primaryBackgroundColor: 'rgb(117, 117, 117)',
    secondaryBackgroundColor: 'rgb(43, 43, 43)',
    primaryTextColor: 'rgb(255, 255, 255)',
    secondaryTextColor: '#FFE575'
  },
  subMenu: {
    primaryBackgroundColor: '#eaeaea',
    primaryTextUnderlineColor: '#ECAEC6',
    primaryTextColor: '#000000',
  },
  sidePanel: {
    primaryBackgroundColor: '',
    secondaryBackgroundColor: ''
  },
  icons: {
    primaryColor: 'rgb(236,174,198)',
    secondaryColor: 'rgb(173,185,202)',
    tertiaryColor: 'rgb(0,32,96)'
  }
};

export type ThemeContextProps = {
  theme?: ContextTheme;
  setTheme?: (newTheme: Partial<ContextTheme>) => ContextTheme;
};

export const ThemeContext = React.createContext<Partial<ThemeContextProps>>({});