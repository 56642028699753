import {useCallback, useEffect, useState} from 'react';
import DataService from '../../shared/services/DataService';
import {Func} from '../types/Func';
import Helpers from '../helpers/Helpers';
import {isUndefined, some} from 'lodash-es';

type FieldValue = string | number | boolean | string[] | number[] | boolean[];

type Parameters = { [key: string]: FieldValue };

export interface LoadDataOptions {
  enableLoading: boolean;
  hideErrorMessages: boolean;
  retryOnFailure: boolean;
}

export function useLoadedData<T>(url: string,
                                 parameters: Parameters,
                                 options?: Partial<LoadDataOptions>): [T, boolean, Func] {

  const [data, setData] = useState<T | null | undefined>(undefined);

  const optionsWithDefaults = Object.assign(
    {
      hideErrorMessages: false,
      retryOnFailure: false,
      enableLoading: true
    },
    options
  );

  useEffect(
    () => {
      setData(undefined);
    },
    [...Object.values(parameters)]
  );

  useEffect(
    (): void | (() => void | undefined) => {
      if (!isUndefined(data)) {
        return undefined;
      }

      if (some(Object.values(parameters), p => isUndefined(p))) {
        return undefined;
      }

      if (optionsWithDefaults.enableLoading === false) {
        return undefined;
      }

      const abortController = new AbortController();

      const fetchFunction = optionsWithDefaults.retryOnFailure
        ? () => DataService.tryLoadWithRetries<T>(
          url,
          parameters,
          optionsWithDefaults.hideErrorMessages,
          abortController.signal
        )
        : () => DataService.tryLoad<T>(
          url,
          parameters,
          optionsWithDefaults.hideErrorMessages,
          abortController.signal
        );

      // POST to the specified URL passing the required parameters to load the data
      fetchFunction().then(result => {
        setData(Helpers.ifNil(result, null));
      }).catch(() => {
        setData(null);
      });

      // Abort the running load if the component unmounts, or if the URL or parameters change making the loaded
      // value out of date
      return () => {
        abortController.abort();
      };
    },
    [url, data, optionsWithDefaults.enableLoading, ...Object.values(parameters)]
  );

  const reloadData = useCallback(
    () => {
      setData(undefined);
    },
    [setData]
  );

  return [
    // The loaded data; otherwise undefined if not loaded yet
    data,
    // true if the hook is currently loading data; otherwise false
    optionsWithDefaults.enableLoading === true && isUndefined(data),
    // A callback to trigger a reload of the data
    reloadData
  ];
}
