import {isEmpty, isNil, isString, union, without} from 'lodash-es';
import {ComponentType} from 'react';

export function setValueInArray<T>(arr: T[], value: T): T[] {
  let index = arr.indexOf(value);
  if (index >= 0) {
    return arr;
  } else {
    return [...arr, value];
  }
}

export function setValuesInArray<T>(arr: T[], values: T[]): T[] {
  return union(arr, values);
}

export function unsetValueInArray<T>(arr: T[], value: T): T[] {
  let index = arr.indexOf(value);
  if (index >= 0) {
    let newArr = [...arr];
    newArr.splice(index, 1);
    return newArr;
  } else {
    return arr;
  }
}

export function unsetValuesInArray<T>(arr: T[], values: T[]): T[] {
  return without(arr, ...values);
}

export function toggleValueInArray<T>(arr: T[], value: T): T[] {
  let index = (arr || []).indexOf(value);
  if (index >= 0) {
    let newArr = [...arr];
    newArr.splice(index, 1);
    return newArr;
  } else {
    return [...arr, value];
  }
}

export function insertValueIntoArray<T>(arr: T[], value: T, index: number): T[] {
  let newArr = [...arr];
  newArr.splice(index, 0, value);
  return newArr;
}

export function moveValueInArray<T>(arr: T[], value: T, fromIndex: number, toIndex: number): T[] {
  if (fromIndex < toIndex) {
    toIndex--;
  }

  let newArr = [...arr];
  newArr.splice(fromIndex, 1);
  newArr.splice(toIndex, 0, value);
  return newArr;
}

export function safeNav<TInput, TOutput>(
  obj: TInput | undefined,
  accessor: (obj: TInput) => TOutput): TOutput | undefined {

  if (isNil(obj)) {
    return undefined;
  }

  return accessor(obj);
}

export function safeCall<TInput, TParam, TOutput>(
  func: ((p?: TParam) => TOutput) | undefined,
  p: TParam | undefined = undefined): TOutput | undefined {

  if (isNil(func)) {
    return undefined;
  }

  return func(p);
}

export function reorderArray<T>(arr: T[], sourceIndex: number, destinationIndex: number): T[] {
  let reordered = [...arr];

  let source = reordered[sourceIndex];
  reordered.splice(sourceIndex, 1);
  reordered.splice(destinationIndex, 0, source);

  return reordered;
}

export function isEmptyOrWhiteSpace(value: any) {
  return (isEmpty(value) || (isString(value) && value.trim().length === 0));
}

export function checkArraysAreEqual<T>(
  a: T[],
  b: T[],
  matchCallback: (a: T, b: T) => boolean,
  compareCallback: (a: T, b: T) => boolean): boolean {

  // If we have two pointers to the same array, they are equal
  if (a === b) {
    return true;
  }

  // If only one of the arrays is null or undefined (since a !== b was determined above), they are not equal
  if (isNil(a) || isNil(b)) {
    return false;
  }

  // If the arrays have different lengths, they are not equal
  if (a.length !== b.length) {
    return false;
  }

  // Loop through both arrays comparing items that match
  for (let i = 0; i < a.length; i++) {
    for (let j = 0; j < a.length; j++) {
      if (matchCallback(a[i], b[j])) {
        if (!compareCallback(a[i], b[j])) {
          return false;
        }
      }
    }
  }

  return true;
}

// Turns a nillable value into a non-nillable value. Throws an error if a default value is not provided.
export function val<T>(optional: T | undefined | null, defaultValue?: T): T {
  if (isNil(optional)) {
    if (isNil(defaultValue)) {
      throw Error('Non-nil value required');
    } else {
      return defaultValue;
    }
  }

  return optional;
}

// TODO: Merge this with checkArraysAreEqual
export function areRowArraysIdentical(a: any[], b: any[]): boolean {
  if (isNil(a) && isNil(b)) {
    return true;
  }

  if (isNil(a) || isNil(b)) {
    return false;
  }

  for (let i = 0; i < a.length || i < b.length; i++) {
    if (i >= a.length || i >= b.length) {
      return false;
    }

    if (a[i] !== b[i]) {
      return false;
    }
  }

  return true;
}

export function len<T>(array: T[] | undefined | null): number {
  if (isNil(array)) {
    return 0;
  }

  return array.length;
}

export function isInstanceOfComponent(element: any, componentType: ComponentType<any>): boolean {
  return safeNav(element, x => x.type) === componentType;
}
