import * as React from 'react';
import {Person} from '../types/Person';
import CallbackHelpers from '../helpers/CallbackHelpers';
import {Func} from '../types/Func';
import {PersonFilter} from '../types/PersonFilter';

export interface PersonCacheProps {
  getPeople: (filter: PersonFilter) => Person[];
  clearPersonCache: Func;
  isPersonCacheProcessing: boolean;
}

export const PersonCache = React.createContext<PersonCacheProps>({
  getPeople: CallbackHelpers.NotImplemented,
  clearPersonCache: CallbackHelpers.NoOp,
  isPersonCacheProcessing: false
});
