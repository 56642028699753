import * as React from 'react';
import {Navigator} from '../context/Navigator';
import {PropsWithChildren, useCallback, useEffect, useState} from 'react';
import {History} from 'history';
import {useTimer} from '../../shared-common/hooks/useTimer';
import {createUniqueCode} from '../../shared/helpers/IdentifierHelpers';
import ConfigService, {Configuration} from '../../shared/services/ConfigService';
import StringHelpers from '../../shared-common/helpers/StringHelpers';
import CallbackHelpers from '../../shared-common/helpers/CallbackHelpers';
import {isNil} from 'lodash-es';

interface NavigatorInstanceProps {
  history: History;
}

export const NavigatorInstance = (props: PropsWithChildren<NavigatorInstanceProps>) => {
  const [frontendBuild, setFrontendBuild] = useState<string>(undefined);
  const [pageReloadRequired, setPageReloadRequired] = useState<boolean>(false);

  useEffect(
    () => {
      if (isNil(ConfigService.config)) {
        return;
      }

      setFrontendBuild(ConfigService.config.frontendBuild);
    },
    [ConfigService.config]
  );

  useTimer(60000, () => {
    fetch(`/config.json?bust=${createUniqueCode()}`, {
      method: 'get'
    }).then((response: Response) => {
      response.json().then((data: Configuration) => {
        const newFrontendBuild = data.frontendBuild;
        if (!StringHelpers.equalsIgnoringCase(newFrontendBuild, frontendBuild)) {
          setPageReloadRequired(true);
        }
      });
    }).catch(CallbackHelpers.NoOp);
  });

  const navigateTo = useCallback(
    (url: string) => {
      if (pageReloadRequired) {
        window.location.href = url;
      } else {
        props.history.push(url);
      }
    },
    [pageReloadRequired]
  );

  return (
    <Navigator.Provider
      value={{
        navigateTo: navigateTo
      }}
    >
      {props.children}
    </Navigator.Provider>
  );
};
