import * as React from 'react';
import CallbackHelpers from '../../shared-common/helpers/CallbackHelpers';

export interface MercuryCacheProps {
  executeAllPendingQueries: () => Promise<void>;
  flushCache: () => void;
  hasPendingQueries: boolean;
  cancelPendingQueries: () => void;
}

export const MercuryCache = React.createContext<MercuryCacheProps>({
  executeAllPendingQueries: CallbackHelpers.AsyncNoOp,
  flushCache: CallbackHelpers.NotImplemented,
  hasPendingQueries: false,
  cancelPendingQueries: CallbackHelpers.NoOp
});
