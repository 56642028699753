import * as React from 'react';
import {MercuryCache} from '../context/MercuryCache';
import {Mercury} from '../context/Mercury';
import {useTimer} from '../../shared-common/hooks/useTimer';
import {isNil} from 'lodash-es';
import {loadDataOrThrow} from '../../engage-analytics/helpers/DataHelpers';
import {MercuryQuery} from '../types/MercuryQuery';
import {MercuryResult} from '../types/MercuryResult';
import {PropsWithChildren, useCallback, useRef} from 'react';
import {useForceUpdate} from '../../shared-common/hooks/useForceUpdate';
import {useConstant} from '../../shared-ui/hooks/useConstant';
import {MercuryQueryHelpers} from '../helpers/MercuryQueryHelpers';

export const MercuryEndpoint = (props: PropsWithChildren<{}>) => {
  const MAXIMUM_CONCURRENT_QUERIES = 6;
  const QUERY_BATCH_SIZE = 10;
  const AUTO_REFRESH_FREQUENCY_MILLISECONDS = 100;

  const pendingQueries = useConstant<Map<number, MercuryQuery>>(new Map<number, MercuryQuery>());
  const loadingQueries = useConstant<Map<number, MercuryQuery>>(new Map<number, MercuryQuery>());
  const processedQueries = useConstant<Map<number, MercuryResult>>(new Map<number, MercuryResult>());
  const forceUpdate = useForceUpdate();
  const currentQueryCountRef = useRef<number>(0);
  const abortControllerRef = useRef<AbortController>(new AbortController());

  const checkForUnprocessedQueries = (): boolean => {
    return pendingQueries.size > 0 || loadingQueries.size > 0;
  };

  const executePendingQueries = async (batchSize?: number): Promise<void> => {
    const queryBatch: {
      hash: number,
      query: MercuryQuery
    } [] = [];

    if (!isNil(batchSize) && currentQueryCountRef.current >= MAXIMUM_CONCURRENT_QUERIES) {
      return;
    }

    for (let entry of pendingQueries.entries()) {
      const hash = entry[0];
      const pendingQuery = entry[1];

      queryBatch.push({
        hash: hash,
        query: pendingQuery
      });

      loadingQueries.set(hash, pendingQuery);
      pendingQueries.delete(hash);

      if (!isNil(batchSize) && queryBatch.length >= batchSize) {
        break;
      }
    }

    if (queryBatch.length === 0) {
      return;
    }

    currentQueryCountRef.current++;

    try {
      const results = await loadDataOrThrow<unknown[]>(
        '/mercury/execute-query-batch',
        queryBatch.map(e => e.query),
        abortControllerRef.current.signal
      );

      if (isNil(results)) {
        for (let i = 0; i < queryBatch.length; i++) {
          loadingQueries.delete(queryBatch[i].hash);
        }
      } else {
        for (let i = 0; i < results.length; i++) {

          const hash = queryBatch[i].hash;

          if (isNil(results[i])) {
            processedQueries.set(
              hash,
              {
                availability: 'insufficientResponses',
                value: undefined
              }
            );
          } else if (results[i] === 0) {
            processedQueries.set(
              hash,
              {
                availability: 'noResponses',
                value: results[i]
              }
            );
          } else {
            processedQueries.set(
              hash,
              {
                availability: 'available',
                value: results[i]
              }
            );
          }

          loadingQueries.delete(hash);
        }
      }
    } catch {
      for (let i = 0; i < queryBatch.length; i++) {
        const hash = queryBatch[i].hash;
        processedQueries.set(
          hash,
          {
            availability: 'failed',
            value: undefined
          }
        );
        loadingQueries.delete(hash);
      }
    }

    if (!checkForUnprocessedQueries()) {
      forceUpdate();
    }

    currentQueryCountRef.current--;
  };

  useTimer(AUTO_REFRESH_FREQUENCY_MILLISECONDS, () => executePendingQueries(QUERY_BATCH_SIZE), []);

  const executeQuery = useCallback(
    (query: MercuryQuery): MercuryResult => {
      const queryHash = MercuryQueryHelpers.hash(query);

      // If this query is already in the cache, return the query result immediately. The result is always returned
      // immediately - the availability field of the result will show the loading status of the result.
      const processedQuery = processedQueries.get(queryHash);
      if (!isNil(processedQuery)) {
        return processedQuery;
      }

      const isPending = pendingQueries.has(queryHash);
      if (isPending) {
        return {
          availability: 'pending',
          value: undefined
        };
      }

      const isLoading = loadingQueries.has(queryHash);
      if (isLoading) {
        return {
          availability: 'loading',
          value: undefined
        };
      }

      const hasExistingPendingQueries = checkForUnprocessedQueries();

      // The query is not in the cache, so add it, and return a 'pending' result so long.
      pendingQueries.set(queryHash, query);

      // If no existing queries were pending before the new query was executed, we probably need to update
      // the UI to show loading indicators
      if (!hasExistingPendingQueries) {
        forceUpdate();
      }

      return {
        availability: 'pending',
        value: undefined
      };
    },
    []
  );

  const flushCache = useCallback(
    () => {
      // Forget all cached query results
      processedQueries.clear();
      pendingQueries.clear();
      loadingQueries.clear();
    },
    []
  );

  const executeAllPendingQueries = useCallback(
    async (): Promise<void> => {
      // Keep processing pending queries until there are none left
      while (checkForUnprocessedQueries()) {
        // Process one batch of pending queries
        await executePendingQueries(QUERY_BATCH_SIZE);
        // Return control to the Browser event loop briefly so that pending events can be processed.
        // This prevents the browser from deciding that the webpage has stopped responding.
        await new Promise<void>(resolve => {
          setTimeout(resolve, 0);
        });
      }
    },
    []
  );

  const cancelPendingQueries = useCallback(
    () => {
      // Abort any running queries
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
      loadingQueries.clear();
      currentQueryCountRef.current = 0;

      // Cancel any pending queries
      pendingQueries.clear();
    },
    [abortControllerRef]
  );

  // Expose the Mercury and MercuryCache contexts to downstream components
  return (
    <MercuryCache.Provider
      value={{
        flushCache: flushCache,
        executeAllPendingQueries: executeAllPendingQueries,
        hasPendingQueries: checkForUnprocessedQueries(),
        cancelPendingQueries: cancelPendingQueries
      }}
    >
      <Mercury.Provider
        value={{
          executeQuery: executeQuery
        }}
      >
        {props.children}
      </Mercury.Provider>
    </MercuryCache.Provider>
  );
};
