import {DependencyList, useEffect} from 'react';
import {MessageBus, MessageFilter, SubscriptionCallback} from '../messaging/MessageBus';

export const useMessageHandler = <T>(filter: MessageFilter,
                                     callback: SubscriptionCallback,
                                     deps: DependencyList = []) => {
  useEffect(
    () => {
      MessageBus.subscribe(filter, callback);
      return () => {
        MessageBus.unsubscribe(callback);
      };
    },
    deps
  );
};
