import * as React from 'react';
import BusinessUnit from '../models/BusinessUnit';
import {TwoWayBinding} from '../models/TwoWayBinding';
import {InputProps, TextAreaProps} from 'antd/es/input';
import {InputNumberProps} from 'antd/es/input-number';
import {SelectProps, SelectValue} from 'antd/es/select';
import {SwitchProps} from 'antd/es/switch';
import {Moment} from 'moment';
import {isFinite, isNil} from 'lodash-es';
import {HtmlEditorProps} from '../components/HtmlEditor/HtmlEditor';
import {TimePickerProps} from 'antd/es/time-picker';
import {AutoSizedInputProps} from '../components/AutoSizedInput/AutoSizedInput';
import {DateTimePickerProps} from '../components/DateTimePicker/DateTimePicker';
import {CodePickerProps} from '../components/CodePicker/CodePicker';
import {ImageUploaderProps} from '../components/ImageUploader/ImageUploader';
import {SearchBoxProps} from '../components/SearchBox/SearchBox';
import {val} from './StateHelpers';
import {ChangeEvent} from 'react';
import {CheckboxProps} from 'antd/es/checkbox/Checkbox';
import {RadioGroupProps, RadioProps} from 'antd/es/radio';
import {DatePickerProps} from 'antd/es/date-picker/interface';
import {HTMLTextareaProps} from 'antd/es/input/TextArea';
import {SemiControlledInputProps} from '../components/SemiControlledInput/SemiControlledInput';
import {SemiControlledTextAreaProps} from '../components/SemiControlledTextArea/SemiControlledTextArea';
import {LanguageSelectorProps} from '../components/LanguageSelector/LanguageSelector';

interface BindingProps {
  binding: TwoWayBinding;
}

export enum ValidateOn {
  Validate,
  Blur,
  Change
}

export function bindToImageUploader(binding: TwoWayBinding): Partial<ImageUploaderProps> & BindingProps {
  return {
    binding: binding,
    imageUrl: binding.value,
    onChange: (imageUrl: string | undefined) => {
      binding.applyChanges(imageUrl, undefined, binding.validate);
    }
  };
}

export function bindToRadioGroup(binding: TwoWayBinding): Partial<RadioGroupProps> & BindingProps {
  return {
    binding: binding,
    value: binding.value,
    onChange: (event: ChangeEvent<HTMLInputElement>) => {
      binding.applyChanges(event.target.value, undefined, binding.validate);
    }
  };
}

export function bindToRadio(binding: TwoWayBinding, invertValue?: boolean): Partial<RadioProps> & BindingProps {
  return {
    binding: binding,
    checked: invertValue ? !binding.value : binding.value,
    onChange: (event: ChangeEvent<HTMLInputElement>) => {
      binding.applyChanges(invertValue ? !event.target.checked : event.target.checked, undefined, binding.validate);
    }
  };
}

export function bindToCheckbox(binding: TwoWayBinding): Partial<CheckboxProps> & BindingProps {
  return {
    binding: binding,
    checked: binding.value,
    onChange: (event: ChangeEvent<HTMLInputElement>) => {
      binding.applyChanges(event.target.checked, undefined, binding.validate);
    }
  };
}

export function bindToDatePicker(binding: TwoWayBinding): Partial<DatePickerProps> & BindingProps {
  return {
    binding: binding,
    value: binding.value,
    onChange: (value: Moment) => {
      binding.applyChanges(value, undefined, binding.validate);
    }
  };
}

export function bindToDateTimePicker(binding: TwoWayBinding): Partial<DateTimePickerProps> & BindingProps {
  return {
    binding: binding,
    value: binding.value,
    onChange: (value: Moment) => {
      binding.applyChanges(value, undefined, binding.validate);
    }
  };
}

export function bindToTimePicker(binding: TwoWayBinding): Partial<TimePickerProps> & BindingProps {
  return {
    binding: binding,
    value: binding.value,
    onChange: (value: Moment) => {
      binding.applyChanges(value, undefined, binding.validate);
    }
  };
}

export function bindToSwitch(binding: TwoWayBinding): Partial<SwitchProps> & BindingProps {
  return {
    binding: binding,
    checked: binding.value,
    onChange: (checked: boolean) => {
      binding.applyChanges(checked, undefined, binding.validate);
    }
  };
}

export function bindToSelect(binding: TwoWayBinding, validateOn?: ValidateOn): Partial<SelectProps> & BindingProps {
  return {
    binding: binding,
    value: isNil(binding.value) ? null : binding.value.toString(),
    onChange: (value: SelectValue) => {
      const valueString = isNil(value) ? null : value.toString();

      let convertedValue: any;
      if (isFinite(valueString)) {
        convertedValue = Number(valueString);
      } else if ((valueString || '').toLowerCase() === 'true') {
        convertedValue = true;
      } else if ((valueString || '').toLowerCase() === 'false') {
        convertedValue = false;
      } else {
        convertedValue = valueString;
      }

      binding.applyChanges(convertedValue, undefined, () => {
        if (validateOn === ValidateOn.Change || binding.errorMessage) {
          binding.validate();
        }
      });
    },
    onBlur: validateOn === ValidateOn.Change || validateOn === ValidateOn.Blur || binding.errorMessage ? () => {
      binding.validate();
    } : undefined
  };
}

export function bindToInput(binding: TwoWayBinding,
                            validateOn: ValidateOn = ValidateOn.Validate): Partial<InputProps> & BindingProps {
  return {
    binding: binding,
    value: binding.value,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      binding.applyChanges(event.currentTarget.value, undefined, () => {
        if (validateOn === ValidateOn.Change || binding.errorMessage) {
          binding.validate();
        }
      });
    },
    onBlur: validateOn === ValidateOn.Change || validateOn === ValidateOn.Blur || binding.errorMessage ? () => {
      binding.validate();
    } : undefined
  };
}

export function bindToLanguageSelector(binding: TwoWayBinding): LanguageSelectorProps & BindingProps {
  return {
    binding: binding,
    value: binding.value,
    onChange: (newValue: string) => {
      binding.applyChanges(newValue, undefined, () => {
       binding.validate();
      });
    },
  };
}

export function bindToSemiControlledInput(binding: TwoWayBinding,
                                          validateOn: ValidateOn = ValidateOn.Validate)
  : Partial<SemiControlledInputProps> & BindingProps {

  return {
    binding: binding,
    value: binding.value,
    onChange: (value: string) => {
      binding.applyChanges(value, undefined, () => {
        if (validateOn === ValidateOn.Change || binding.errorMessage) {
          binding.validate();
        }
      });
    },
    onBlur: validateOn === ValidateOn.Change || validateOn === ValidateOn.Blur || binding.errorMessage ? () => {
      binding.validate();
    } : undefined
  };
}

export function bindToSearchBox(binding: TwoWayBinding): SearchBoxProps & BindingProps {
  return {
    binding: binding,
    searchString: val(binding.value, '').toString(),
    onSearch: (searchString: string) => {
      binding.applyChanges(searchString, undefined, binding.validate);
    }
  };
}

export function bindToAutoSizedInput(binding: TwoWayBinding, validateOn?: ValidateOn)
  : Partial<AutoSizedInputProps> & BindingProps {
  return {
    binding: binding,
    value: binding.value,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      binding.applyChanges(event.currentTarget.value, undefined, () => {
        if (validateOn === ValidateOn.Change || binding.errorMessage) {
          binding.validate();
        }
      });
    },
    onBlur: validateOn === ValidateOn.Change || validateOn === ValidateOn.Blur || binding.errorMessage ? () => {
      binding.validate();
    } : undefined
  };
}

export function bindToTextArea(binding: TwoWayBinding, validateOn?: ValidateOn)
  : Partial<TextAreaProps & HTMLTextareaProps> & BindingProps {

  return {
    binding: binding,
    value: binding.value,
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      binding.applyChanges(event.currentTarget.value, undefined, () => {
        if (validateOn === ValidateOn.Change || binding.errorMessage) {
          binding.validate();
        }
      });
    },
    onBlur: validateOn === ValidateOn.Change || validateOn === ValidateOn.Blur || binding.errorMessage ? () => {
      binding.validate();
    } : undefined
  };
}

export function bindToSemiControlledTextArea(binding: TwoWayBinding, validateOn?: ValidateOn)
  : Partial<SemiControlledTextAreaProps> & BindingProps {

  return {
    binding: binding,
    value: binding.value,
    onChange: (value: string) => {
      binding.applyChanges(value, undefined, () => {
        if (validateOn === ValidateOn.Change || binding.errorMessage) {
          binding.validate();
        }
      });
    },
    onBlur: validateOn === ValidateOn.Change || validateOn === ValidateOn.Blur || binding.errorMessage ? () => {
      binding.validate();
    } : undefined
  };
}

export function bindToHtmlEditor(binding: TwoWayBinding, validateOn?: ValidateOn)
  : Partial<HtmlEditorProps> & BindingProps {
  return {
    binding: binding,
    value: binding.value,
    onChange: (value: string) => {
      binding.applyChanges(value, undefined, () => {
        if (validateOn === ValidateOn.Change || binding.errorMessage) {
          binding.validate();
        }
      });
    },
    onBlur: validateOn === ValidateOn.Change || validateOn === ValidateOn.Blur || binding.errorMessage ? () => {
      binding.validate();
    } : undefined
  };
}

export function bindToInputNumber(binding: TwoWayBinding): Partial<InputNumberProps> & BindingProps {
  return {
    binding: binding,
    value: binding.value,
    onChange: (value: number | string | undefined) => {
      binding.applyChanges(value, undefined, binding.validate);
    }
  };
}

export function bindToBusinessUnitInput(binding: TwoWayBinding) {
  return {
    binding: binding,
    selectedBusinessUnits: binding.value,
    isOrganisationSelected: binding.additionalValue,
    onChanged: (selectedBusinessUnits: BusinessUnit[], isOrganisationSelected: boolean) => {
      binding.applyChanges(selectedBusinessUnits, isOrganisationSelected, binding.validate);
    }
  };
}

export function bindToCodePicker(binding: TwoWayBinding)
  : Partial<CodePickerProps> & BindingProps {
  return {
    binding: binding,
    value: binding.value,
    onChange: (value: string) => {
      binding.applyChanges(value, undefined, binding.validate);
    }
  };
}

export async function validate(...bindings: TwoWayBinding[]): Promise<boolean> {
  let isValid: boolean = true;
  for (let binding of bindings) {
    await binding.validate();
    isValid = isValid && binding.isValid;
  }
  return isValid;
}

export function getBindingErrorMessage(...bindings: TwoWayBinding[]): string {
  for (let binding of bindings) {
    let errorMessage = binding.errorMessage;
    if (errorMessage && errorMessage.length > 0) {
      return errorMessage;
    }
  }

  return '';
}

export function createTwoWayBinding<TState>(component: React.Component<any, TState>,
                                            field: keyof TState,
                                            additionalField?: keyof TState): TwoWayBinding {
  return new TwoWayBinding(
    () => component.state[field],
    () => {
      if (additionalField) {
        return component.state[additionalField];
      } else {
        return undefined;
      }
    },
    (value: any, additionalValue: any, afterChangesCallback: () => void) => {
      if (additionalField) {
        component.setState({[field]: value, [additionalField]: additionalValue} as any, afterChangesCallback);
      } else {
        component.setState({[field]: value} as any, afterChangesCallback);
      }
    },
    () => {
      component.forceUpdate();
    }
  );
}
