import * as React from 'react';
import {useEffect, useState} from 'react';
import {Route, RouteComponentProps, Switch} from 'react-router-dom';
import PartnerLogo from './components/PartnerLogo/PartnerLogo';
import {ModuleMenu} from './components/ModuleMenu/ModuleMenu';
import UserMenu from './components/UserMenu/UserMenu';
import ModuleContent from './pages/ModuleContent/ModuleContent';
import styles from './Shell.module.css';
import {Redirect} from 'react-router';
import ChangePassword from './pages/ChangePasswordPage/ShellChangePassword';
import getModulesForProduct from './modules';
import {isModuleAccessAllowed} from './controllers/SecurityHelpers';
import {Module} from '../shared/models/Module';
import OrganisationSelector from './components/OrganisationSelector/OrganisationSelector';
import Col from 'antd/es/grid/col';
import Icon from 'antd/es/icon';
import SideModuleMenu from './components/SideModuleMenu/SideModuleMenu';
import Row from 'antd/es/grid/row';
import ThemedScrollbars from '../shared/components/ThemedScrollbars/ThemedScrollbars';
import MobileMenuService from '../shared/services/MobileMenuService';
import NoPermissions from './pages/NoPermissions/NoPermissions';
import SideModuleMenuContent from './components/SideModuleMenuContent/SideModuleMenuContent';
import License from '../shared/components/License/License';
import {History, Location} from 'history';
import {getScreenSize, ScreenSize} from '../shared/helpers/ScreenSizeHelpers';
import {filter, find, findIndex, includes, isEmpty, isNil, without} from 'lodash-es';
import {Page} from '../shared/models/Page';
import UserService from '../shared/services/UserService';
import LoadingAnimation from '../shared/components/LoadingAnimation/LoadingAnimation';
import {ThemeContext} from '../app/context/ThemeContext';
import {classes} from '../shared/helpers/RenderHelpers';
import RenderHelpers from '../shared-ui/helpers/RenderHelpers';
import ProductLineSelector from './components/ProductLineSelector/ProductLineSelector';
import {ProductLine} from '../shared-common/types/ProductLine';
import {ProductLineFilter} from './context/ProductLineFilter';
import LanguageStore from '../shared-common/stores/LanguageStore';
import {SurveyModelStore} from '../shared-common/stores/SurveyModelStore';
import {DemographicsStore} from '../shared-common/stores/DemographicsStore';
import {MercuryEndpoint} from '../mercury/components/MercuryEndpoint';
import {SurveyModelStringStore} from '../shared-common/stores/SurveyModelStringsStore';
import {NavigatorInstance} from './stores/NavigatorInstance';
import {ReportFilterStore} from '../engage-analytics/stores/ReportFilterStore';
import {AppClipboardStore} from '../shared-common/stores/AppClipboardStore';
import {useForceUpdate} from '../shared-common/hooks/useForceUpdate';
import {useQueryString} from '../shared-navigation/hooks/useQueryString';
import {CohortStore} from '../reports/stores/CohortStore';
import {RaterStore} from '../reports/stores/RaterStore';
import {PersonStore} from '../reports/stores/PersonStore';
import {CampaignStore} from '../shared-common/stores/CampaignStore';

export interface ShellProps {
  history: History;
  location: Location;
}

export interface ShellState {
  currentOrganisationId?: string;
  currentProductLine?: ProductLine;
}

export const Shell = (props: ShellProps) => {

  const PRODUCT_LINE_LOCAL_STORAGE_KEY = 'PRODUCTLINE';

  const [currentProductLine, setCurrentProductLine] = useState<ProductLine>(ProductLine.Workplace);
  const forceUpdate = useForceUpdate();
  const {org} = useQueryString<{ org: string }>();

  const changeProductLineAndPersist = (productLine: ProductLine) => {
    setCurrentProductLine(productLine);
    try {
      localStorage.setItem(PRODUCT_LINE_LOCAL_STORAGE_KEY, ProductLine[productLine]);
    } catch (ex) {
      // Intentionally do nothing - if this fails, oh well
    }
  };

  useEffect(
    () => {
      if (isNil(org)) {
        return;
      }

      if (!UserService.instance.isProfileLoaded) {
        return;
      }

      UserService.instance.setCurrentClientOrPartnerId(org, currentProductLine);
    },
    [
      org,
      UserService.instance.isProfileLoaded
    ]
  );

  useEffect(
    () => {
      UserService.instance.loadUserProfile(currentProductLine, true)
        .then(() => {
          forceUpdate();
        });
    },
    []
  );

  useEffect(
    () => {
      const pathname = window.location.pathname;

      if (pathname.startsWith('/engage-survey/define-survey/')) {
        if (pathname === '/engage-survey/define-survey/') {
          return;
        }

        props.history.push(`/engage-survey/define-survey`);
      }

      if (pathname.startsWith('/manage-participants/participants/virtual-structures/')) {
        if (pathname === '/manage-participants/participants/virtual-structures/') {
          return;
        }

        props.history.push(`/manage-participants/participants/virtual-structures`);
      }

      if (pathname.startsWith('/engage-survey/survey-model/')) {
        if (pathname === '/engage-survey/survey-model/') {
          return;
        }

        if (pathname.startsWith('/engage-survey/survey-model/templates')) {
          return;
        }

        if (pathname.startsWith('/engage-survey/survey-model/models')) {
          return;
        }

        props.history.push(`/engage-survey/survey-model/models`);
      }

      if (pathname.startsWith('/engage-survey/run-survey/')) {
        props.history.push(`/engage-survey/run-survey`);
      }

      if (pathname.startsWith('/engage-reports')) {
        props.history.push(`/engage-reports/reports`);
      }
    },
    [UserService.instance.currentClientOrPartnerId, currentProductLine]
  );

  /***/

  const onModuleMenuResize = (hasSufficientSpace: boolean) => {
    let isNowInMobile = false;

    if (!hasSufficientSpace) {
      isNowInMobile = true;
    } else {
      let size = getScreenSize();
      if (size === ScreenSize.xs || size === ScreenSize.sm) {
        isNowInMobile = true;
      }
    }

    if (MobileMenuService.isMobile !== isNowInMobile) {
      MobileMenuService.setMobile(isNowInMobile);
      forceUpdate();
    }
  };

  // Strips any parts of a route that start with :
  const removeRouteParameters = (routeUrl: string) => {
    const parts = routeUrl.split('/');
    const staticRouteParts: string[] = [];
    for (let i = 0; i < parts.length; i++) {
      if (parts[i][0] !== ':') {
        staticRouteParts.push(parts[i]);
      } else {
        // The moment we've passed one parameter, the rest of the URL depends on it, and so should also be stripped
        break;
      }
    }

    return staticRouteParts.join('/');
  };

  const defaultURL = (module: Module[]): string => {
    if (!module || module.length === 0) {
      return '';
    }

    // MANAGE_ORG_PROFILE means users is a System Admin
    if (UserService.instance.checkPermissionsGrantedForClientOrPartner(['MANAGE_ORG_PROFILE'])) {
      let index = findIndex(module, x => x.urlSlug === 'manage-participants');

      if (index >= 0) {
        return '/manage-participants/participants';
      }
    }

    // MANAGE_EMPLOYEE_LIST means user is a BU Admin
    if (UserService.instance.checkPermissionsGrantedForClientOrPartner(['MANAGE_EMPLOYEE_LIST'])) {
      let index = findIndex(module, x => x.urlSlug === 'engage-analytics');

      if (index >= 0) {
        return '/engage-analytics';
      }
    }

    let defaultPage = module.length > 0
      ? find(module[0].pages, (page: Page<RouteComponentProps<any>>) => (page.showInMenu !== false))
      : undefined;

    if (defaultPage) {
      return removeRouteParameters(
        '/' + module[0].urlSlug + '/' + defaultPage.urlSlug
      );
    }

    return '';
  };

  const mayAccessProductLine = (productLine: ProductLine) => {
    const hasPermissions = (...permissions: string[]): boolean => {
      return UserService.instance.checkPermissionsGrantedForClientOrPartner(permissions);
    };

    if (productLine === ProductLine.Lifecycle) {
      return hasPermissions('LIFECYCLE_LICENCE')
        && (
          hasPermissions('LIFECYCLE_SETTINGS')
          || hasPermissions('LIFECYCLE_MANAGE_EMPLOYEE_TRIGGERS')
          || hasPermissions('LIFECYCLE_MANAGE_CANDIDATE_TRIGGERS')
          || hasPermissions('LIFECYCLE_VIEW_EMPLOYEE_LIST')
          || hasPermissions('LIFECYCLE_ANALYTICS_ACCESS')
        );
    }

    if (UserService.instance.currentClient !== undefined && productLine === ProductLine.Assess) {
      return UserService.instance.currentClient.hasAssessBundles;
    }

    return true;
  };

  const getAccessibleModulesForProductLine = (currentProduct: ProductLine): Module[] => {
    let modules: Module[] = [];
    const productModules = getModulesForProduct(currentProduct);
    for (let module of productModules) {
      let FilteredModule = isModuleAccessAllowed(module);
      if (FilteredModule) {
        modules.push(FilteredModule);
      }
    }

    return modules;
  };

  const getAccessibleProductLines = (): ProductLine[] => {
    return filter(
      [ProductLine.Workplace, ProductLine.Lifecycle, ProductLine.Assess],
      productLine =>
        !isEmpty(getAccessibleModulesForProductLine(productLine)) && mayAccessProductLine(productLine)
    );
  };

  const getAccessibleModules = (currentProduct: ProductLine): Module[] | 'switching' => {
    let modules = getAccessibleModulesForProductLine(currentProduct);

    if (!isEmpty(modules)) {
      return modules;
    }

    // If no modules are accessible for the current product line, try any other product lines
    const otherProductLines = without(getAccessibleProductLines(), currentProduct);
    for (let productLine of otherProductLines) {
      let otherModules = getAccessibleModulesForProductLine(productLine);

      if (!isEmpty(otherModules)) {
        changeProductLineAndPersist(productLine);
        return 'switching';
      }
    }

    return [];
  };

  const loadStoredProductLine = (): ProductLine => {

    const FALLBACK_PRODUCT_LINE = ProductLine.Workplace;

    try {
      const productFromLocalStorage = localStorage.getItem(PRODUCT_LINE_LOCAL_STORAGE_KEY);
      if (!productFromLocalStorage) {
        return FALLBACK_PRODUCT_LINE;
      }

      const storedProduct = ProductLine[productFromLocalStorage as keyof typeof ProductLine];
      const accessibleProductLines = getAccessibleProductLines();
      if (!includes(accessibleProductLines, storedProduct)) {
        return FALLBACK_PRODUCT_LINE;
      }

      return storedProduct;
    } catch (ex) {
      return FALLBACK_PRODUCT_LINE;
    }
  };

  useEffect(
    () => {
      if (isNil(UserService.instance.currentClientOrPartner)) {
        return;
      }

      setCurrentProductLine(loadStoredProductLine());
    },

    [UserService.instance.currentClientOrPartner]
  );

  const renderDesktopMenu = (history: History,
                             modules: Module[],
                             forMeasuringPurposesOnly: boolean) => {
    return (
      <ThemeContext.Consumer>
        {context => (
          <div
            className={classes(
              styles.header,
              forMeasuringPurposesOnly ? styles.forMeasuringPurposesOnly : ''
            )}
            style={{
              background: `linear-gradient(${context.theme.mainMenu.primaryBackgroundColor} 6%, ` +
                `${context.theme.mainMenu.secondaryBackgroundColor} 97%)`
            }}
          >
            <div className={styles.leftSection}>
              <div className={styles.organisationSection}>
                <PartnerLogo isMobile={false}/>
                <OrganisationSelector/>
              </div>
              <ProductLineSelector/>
            </div>
            <ModuleMenu
              className={styles.moduleMenu}
              modules={modules}
              history={history}
              location={props.location}
              onResize={onModuleMenuResize}
            />
            <UserMenu/>
          </div>
        )}
      </ThemeContext.Consumer>);
  };

  const renderDesktopShell = (modules: Module[]) => {
    return <Route
      render={({history}) => {
        return (
          <div
            id="shell"
            className={styles.shell}
          >
            {renderDesktopMenu(history, modules, false)}
            {
              modules.length <= 0
                ? <NoPermissions/>
                : <ModuleContent modules={modules}/>
            }
          </div>
        );
      }}
    />;
  };

  const toggleMobileMenu = () => {
    if (MobileMenuService.isOpen) {
      MobileMenuService.setIsOpen(false);
    } else {
      MobileMenuService.setIsOpen(true);
    }
    forceUpdate();
  };

  const userSelectedItemFromMobileMenu = (nav: boolean) => {
    // auto close menu
    if (nav) {
      toggleMobileMenu();
    }
  };

  const renderMobileShell = (modules: Module[]) => {

    const mobileLayout = {
      xs: {span: 24},
      sm: {span: 24},
      md: {span: 0},
      xl: {span: 0}
    };

    return (
      <Route
        render={({history}) => {
          return (
            <div
              className={
                RenderHelpers.classes(
                  styles.shell
                )
              }
              {...mobileLayout}
            >
              {renderDesktopMenu(history, modules, true)}
              <ThemeContext.Consumer>
                {context => {
                  return (<div
                    key={1}
                    className={classes(styles.header, 'topNavigation')}
                    style={{
                      background: `linear-gradient(${context.theme.mainMenu.primaryBackgroundColor} 6%, ` +
                        `${context.theme.mainMenu.secondaryBackgroundColor} 97%)`
                    }}
                  >
                    {MobileMenuService.isOpen
                      ? <div className={styles.toggleMenuHoverContainer} onClick={toggleMobileMenu}>
                        <div className={styles.toggleMenuContainer}>
                          <Icon type="close" className={styles.closeSliderButton}/>
                        </div>
                      </div>
                      : <div className={styles.toggleMenuHoverContainer} onClick={toggleMobileMenu}>
                        <div className={styles.toggleMenuContainer}>
                          <div className={styles.hamburger}>
                            <div/>
                            <div/>
                            <div/>
                          </div>
                        </div>
                      </div>
                    }
                    <div className={styles.mobileLogoWrapper}>
                      <PartnerLogo isMobile={true}/>
                    </div>
                    <div className={styles.settingContainer}>
                      <License/>
                    </div>
                  </div>);
                }}
              </ThemeContext.Consumer>
              <div
                className={[
                  styles.sideMenu,
                  MobileMenuService.isOpen ? styles.active : ''
                ].join(' ')}
              >
                <ThemedScrollbars>
                  <div>
                    <Row type={'flex'} align={'middle'} className={styles.topSelectors}>
                      <Col>
                        <Icon type="contacts" className={styles.sideIcon}/>
                      </Col>
                      <Col className={styles.topSelectorsLabel}>
                        {'Client account'}
                      </Col>
                      <OrganisationSelector className={styles.orgSelector} mobile={true}/>
                    </Row>
                  </div>
                  <ProductLineSelector mobile={true}/>
                  <UserMenu mobile={true}/>
                  <SideModuleMenu
                    onNavigate={userSelectedItemFromMobileMenu}
                    modules={modules}
                    history={history}
                    location={window.location}
                  />
                </ThemedScrollbars>
              </div>
              {
                accessibleModules.length <= 0
                  ? <NoPermissions/>
                  : <SideModuleMenuContent modules={modules}/>
              }
            </div>);
        }}
      />
    );
  };

  if (!UserService.instance.isSignedIn
    || !UserService.instance.isProfileLoaded
    || !UserService.instance.currentClientOrPartner) {
    return <LoadingAnimation/>;
  }

  const accessibleModules = getAccessibleModules(currentProductLine);

  if (accessibleModules === 'switching') {
    return null;
  }

  let defaultPageUrl = defaultURL(accessibleModules);
  return (
    <NavigatorInstance history={props.history}>
      <ProductLineFilter.Provider
        value={{
          currentProductLine: currentProductLine,
          getAccessibleProductLines: getAccessibleProductLines,
          setCurrentProductLine: changeProductLineAndPersist
        }}
      >
        <AppClipboardStore>
          <LanguageStore>
            <CampaignStore productLine={currentProductLine}>
              <SurveyModelStore productLine={currentProductLine}>
                <DemographicsStore clientId={UserService.instance.currentClientOrPartnerId}>
                  <SurveyModelStringStore>
                    <MercuryEndpoint>
                      <ReportFilterStore>
                        <CohortStore>
                          <RaterStore>
                            <PersonStore>
                              <Switch>
                                <Route path="/change-password" component={ChangePassword}/>
                                {
                                  accessibleModules.length > 0
                                    ? <Redirect
                                      exact
                                      from="/"
                                      to={defaultPageUrl}
                                    />
                                    : null
                                }
                                {
                                  MobileMenuService.isMobile
                                    ? renderMobileShell(accessibleModules)
                                    : renderDesktopShell(accessibleModules)
                                }
                              </Switch>
                            </PersonStore>
                          </RaterStore>
                        </CohortStore>
                      </ReportFilterStore>
                    </MercuryEndpoint>
                  </SurveyModelStringStore>
                </DemographicsStore>
              </SurveyModelStore>
            </CampaignStore>
          </LanguageStore>
        </AppClipboardStore>
      </ProductLineFilter.Provider>
    </NavigatorInstance>
  );
};
