import {ResponsiveLength} from '../models/ResponsiveLength';
import {isNil} from 'lodash-es';

export enum ScreenSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl'
}

export function isScreenAtLeast(desiredSize: ScreenSize): boolean {
  let actualSize = getScreenSize();

  switch (desiredSize) {
    case ScreenSize.xs:
      return true;
    case ScreenSize.sm:
      switch (actualSize) {
        case ScreenSize.sm:
          return true;
        case ScreenSize.md:
          return true;
        case ScreenSize.lg:
          return true;
        case ScreenSize.xl:
          return true;
        case ScreenSize.xxl:
          return true;
        default:
          return false;
      }
    case ScreenSize.md:
      switch (actualSize) {
        case ScreenSize.md:
          return true;
        case ScreenSize.lg:
          return true;
        case ScreenSize.xl:
          return true;
        case ScreenSize.xxl:
          return true;
        default:
          return false;
      }
    case ScreenSize.lg:
      switch (actualSize) {
        case ScreenSize.lg:
          return true;
        case ScreenSize.xl:
          return true;
        case ScreenSize.xxl:
          return true;
        default:
          return false;
      }
    case ScreenSize.xl:
      switch (actualSize) {
        case ScreenSize.xl:
          return true;
        case ScreenSize.xxl:
          return true;
        default:
          return false;
      }
    case ScreenSize.xxl:
      return actualSize === ScreenSize.xxl;
    default: // Default will never be hit
      return false;
  }
}

export function isScreenAtMost(desiredSize: ScreenSize): boolean {
  let actualSize = getScreenSize();

  switch (desiredSize) {
    case ScreenSize.xs:
      return actualSize === ScreenSize.xs;
    case ScreenSize.sm:
      switch (actualSize) {
        case ScreenSize.xs:
          return true;
        case ScreenSize.sm:
          return true;
        default:
          return false;
      }
    case ScreenSize.md:
      switch (actualSize) {
        case ScreenSize.xs:
          return true;
        case ScreenSize.sm:
          return true;
        case ScreenSize.md:
          return true;
        default:
          return false;
      }
    case ScreenSize.lg:
      switch (actualSize) {
        case ScreenSize.xs:
          return true;
        case ScreenSize.sm:
          return true;
        case ScreenSize.md:
          return true;
        case ScreenSize.lg:
          return true;
        default:
          return false;
      }
    case ScreenSize.xl:
      switch (actualSize) {
        case ScreenSize.xs:
          return true;
        case ScreenSize.sm:
          return true;
        case ScreenSize.md:
          return true;
        case ScreenSize.lg:
          return true;
        case ScreenSize.xl:
          return true;
        default:
          return false;
      }
    case ScreenSize.xxl:
      return true;
    default: // Default will never be hit
      return false;
  }
}

export function getScreenSize(): ScreenSize {

  if (window.matchMedia('(min-width: 1600px)').matches) {
    return ScreenSize.xxl;
  }

  if (window.matchMedia('(min-width: 1200px)').matches) {
    return ScreenSize.xl;
  }

  if (window.matchMedia('(min-width: 992px)').matches) {
    return ScreenSize.lg;
  }

  if (window.matchMedia('(min-width: 768px)').matches) {
    return ScreenSize.md;
  }

  if (window.matchMedia('(min-width: 576px)').matches) {
    return ScreenSize.sm;
  }

  return ScreenSize.xs;
}

export function calculateResponsiveLength(length: number | ResponsiveLength | undefined): number | undefined {
  if (isNil(length)) {
    return undefined;
  }

  if (typeof length === 'number') {
    return length;
  } else {
    const screenSize = getScreenSize();

    switch (screenSize) {
      default: // Default will never be hit
      case ScreenSize.xxl:
        return length.xxl || length.xl || length.lg || length.md || length.sm || length.xs || 0;
      case ScreenSize.xl:
        return length.xl || length.lg || length.md || length.sm || length.xs || 0;
      case ScreenSize.lg:
        return length.lg || length.md || length.sm || length.xs || 0;
      case ScreenSize.md:
        return length.md || length.sm || length.xs || 0;
      case ScreenSize.sm:
        return length.sm || length.xs || 0;
      case ScreenSize.xs:
        return length.xs || 0;
    }
  }
}