export default class CallbackHelpers {

  public static get NoOp() {
    return () => {/* Intentionally left blank*/};
  }

  public static get AsyncNoOp() {
    return async () => {/* Intentionally left blank*/};
  }

  public static get Never() {
    return () => false;
  }

  public static get Always() {
    return () => true;
  }

  public static get Nil() {
    return () => undefined as undefined;
  }

  public static get EmptyArray() {
    return (): any[] => [];
  }

  public static get NotImplemented() {
    return () => {
      throw new Error('Not implemented');
    };
  }
}
