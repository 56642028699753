import * as React from 'react';
import {Icon} from 'antd';
import styles from './Caret.module.css';
import {CSSProperties} from 'react';

export enum CaretTheme {
  Light,
  Dark,
  None
}

interface CaretProps {
  className?: string;
  style?: CSSProperties;
  size: number;
  isOpen?: boolean;
  theme: CaretTheme;
  overrideIconType?: string;
}

export default class Caret extends React.PureComponent<CaretProps> {
  render() {
    return (
      <Icon
        className={[
          this.props.theme === CaretTheme.Dark
            ? styles.darkTheme
            : this.props.theme === CaretTheme.Light ? styles.lightTheme : null,
          this.props.className || '',
          styles.component,
          this.props.isOpen ? styles.open : ''].join(' ')}
        type={!this.props.isOpen && this.props.overrideIconType ? this.props.overrideIconType : 'down'}
        style={{fontSize: this.props.size, ...this.props.style}}
      />
    );
  }
}
