import * as React from 'react';
import CallbackHelpers from '../helpers/CallbackHelpers';
import {Func} from '../types/Func';
import {RaterFilter} from '../types/RaterFilter';
import {Rater} from '../types/Rater';

export interface RaterCacheProps {
  getRaters: (filter: RaterFilter) => Rater[];
  clearRaterCache: Func;
  isRaterCacheProcessing: boolean;
}

export const RaterCache = React.createContext<RaterCacheProps>({
  getRaters: CallbackHelpers.NotImplemented,
  clearRaterCache: CallbackHelpers.NoOp,
  isRaterCacheProcessing: false
});
