import {SurveyModel} from '../types/SurveyModel';
import {Construct} from '../types/Construct';
import {Question} from '../types/Question';
import {QuestionChoice} from '../types/QuestionChoice';
import {useCache} from './useCache';
import {filter, find, first, isNil} from 'lodash-es';
import {DependencyList, useEffect} from 'react';
import {SurveyModelData} from '../types/SurveyModelData';
import {EmployeeSelectOptions} from '../types/EmployeeSelectOptions';

export function useSurveyModelStore(loadSurveyModelCallback: (languageCode: string) => Promise<SurveyModelData>,
                                    deps: DependencyList = []) {
  // Cache the data for all accessible survey models, indexed by language code
  const {
    getValue: getSurveyModelData,
    clearCache,
    waitForValue: waitForSurveyModelData
  } =
    useCache<string, SurveyModelData>(loadSurveyModelCallback);

  // Reload the cache if any dependencies have changed
  useEffect(
    () => {
      clearCache();
    },
    deps
  );

  const getSurveyModels = (languageCode: string): SurveyModel[] => {
    const data = getSurveyModelData(languageCode);
    return isNil(data) ? [] : data.surveyModels;
  };

  const findSurveyModelById = (surveyModelId: string, languageCode: string): SurveyModel | undefined => {
    const data = getSurveyModelData(languageCode);
    return find(data.surveyModels, m => m.id === surveyModelId);
  };

  const getConstructs = (surveyModelId: string, languageCode: string): Construct[] => {
    const data = getSurveyModelData(languageCode);
    if (isNil(data)) {
      return [];
    }

    return filter(data.constructs, q => q.surveyModelId === surveyModelId);
  };

  const getConstructsAsync = async (surveyModelId: string, languageCode: string): Promise<Construct[]> => {
    const data = await waitForSurveyModelData(languageCode);
    if (isNil(data)) {
      return [];
    }

    return filter(data.constructs, q => q.surveyModelId === surveyModelId);
  };

  const getConstructQuestions = (surveyModelId: string, constructCode: string, languageCode: string): Question[] => {
    const data = getSurveyModelData(languageCode);
    if (isNil(data)) {
      return [];
    }

    return filter(data.questions, q => q.surveyModelId === surveyModelId && q.constructCode === constructCode);
  };

  const findConstructTranslationByCodeAsync = async (
    surveyModelId: string, constructCode: string, languageCode: string): Promise<Construct> => {
    const data = await waitForSurveyModelData(languageCode);

    if (isNil(data)) {
      return {
        surveyModelId: surveyModelId,
        id: '',
        code: '',
        displayText: '',
        description: '',
        displayOrder: 0,
        isQuantified: false,
        languageCode: languageCode
      };
    }

    const translatedConstruct = first(filter(data.constructs, q => q.surveyModelId === surveyModelId
      && q.code === constructCode));

    if (isNil(translatedConstruct)) {
      return {
        surveyModelId: surveyModelId,
        id: '',
        code: '',
        displayText: '',
        description: '',
        displayOrder: 0,
        isQuantified: false,
        languageCode: languageCode
      };
    }

    if (translatedConstruct.languageCode !== languageCode) {
      return {
        surveyModelId: surveyModelId,
        id: '',
        code: '',
        displayText: '',
        description: '',
        displayOrder: 0,
        isQuantified: false,
        languageCode: languageCode
      };
    }

    return translatedConstruct;
  };

  const getAllQuestions = (surveyModelId: string, languageCode: string): Question[] => {

    const data = getSurveyModelData(languageCode);
    if (isNil(data)) {
      return [];
    }

    return filter(data.questions, q => q.surveyModelId === surveyModelId);
  };

  const getQuestionChoices = (surveyModelId: string,
                              questionCode: string,
                              languageCode: string): QuestionChoice[] => {
    const data = getSurveyModelData(languageCode);
    if (isNil(data)) {
      return [];
    }

    return filter(
      data.questionChoices,
      q => q.surveyModelId === surveyModelId
        && q.questionCode === questionCode
    );
  };
  
  const getEmployeeSelectOptions = (surveyModelId: string,
                                    questionCode: string,
                                    languageCode: string): EmployeeSelectOptions => {
    const data = getSurveyModelData(languageCode);
    if (isNil(data)) {
      return null;
    }
    
    return find(
      data.employeeSelectOptions,
      o => o.surveyModelId === surveyModelId
        && o.questionCode === questionCode);
  };

  const getAllChoices = (surveyModelId: string,
                         languageCode: string): QuestionChoice[] => {
    const data = getSurveyModelData(languageCode);
    if (isNil(data)) {
      return [];
    }

    return filter(
      data.questionChoices,
      q => q.surveyModelId === surveyModelId
    );
  };

  const findConstructByCode = (surveyModelId: string,
                               constructCode: string,
                               languageCode: string): Construct | undefined => {
    const data = getSurveyModelData(languageCode);
    if (isNil(data)) {
      return undefined;
    }

    return first(filter(data.constructs, c => c.surveyModelId === surveyModelId && c.code === constructCode));
  };

  const findQuestionByCode = (surveyModelId: string,
                              questionCode: string,
                              languageCode: string): Question | undefined => {
    const data = getSurveyModelData(languageCode);
    if (isNil(data)) {
      return undefined;
    }

    return first(filter(data.questions, q => q.surveyModelId === surveyModelId && q.code === questionCode));
  };

  const findQuestionByCodeAsync = async (surveyModelId: string,
                                         questionCode: string,
                                         languageCode: string): Promise<Question> => {
    const data = await waitForSurveyModelData(languageCode);;
    if (isNil(data)) {
      return undefined;
    }

    return first(filter(data.questions, q => q.surveyModelId === surveyModelId && q.code === questionCode));
  };

  const findQuestionChoiceByCode = (surveyModelId: string,
                                    questionCode: string,
                                    choiceCode: string,
                                    languageCode: string): QuestionChoice | undefined => {

    const data = getSurveyModelData(languageCode);
    if (isNil(data)) {
      return undefined;
    }

    return first(
      filter(
        data.questionChoices,
        c => c.surveyModelId === surveyModelId && c.questionCode === questionCode && c.code === choiceCode
      )
    );
  };

  const isLoaded = (languageCode: string) => {
    const data = getSurveyModelData(languageCode);
    return !isNil(data);
  };

  return {
    getSurveyModels,
    findSurveyModelById,
    getConstructs,
    getConstructsAsync,
    findConstructTranslationByCodeAsync,
    getConstructQuestions,
    getAllQuestions,
    getQuestionChoices,
    getEmployeeSelectOptions,
    getAllChoices,
    findConstructByCode,
    findQuestionByCode,
    findQuestionByCodeAsync,
    findQuestionChoiceByCode,
    clearCache,
    isLoaded
  };
}
