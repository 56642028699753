import * as React from 'react';
import {PropsWithChildren, useCallback, useState} from 'react';
import {cloneDeep, isNil} from 'lodash-es';
import {AppClipboard, AppClipboardDataType} from '../context/AppClipboard';

interface ClipboardData {
  type: AppClipboardDataType;
  data: unknown;
}

export const AppClipboardStore = (props: PropsWithChildren<{}>) => {
  const [currentData, setCurrentData] = useState<ClipboardData | undefined>(undefined);

  const copyToClipboard = useCallback(
    (type: AppClipboardDataType, data: unknown) => {
      setCurrentData({
        type: type,
        data: data
      });
    },
    [setCurrentData]
  );

  const pasteFromClipboard = useCallback(
    (type: AppClipboardDataType) => {
      if (isNil(currentData)) {
        return undefined;
      }

      if (currentData.type !== type) {
        return undefined;
      }

      return cloneDeep(currentData.data);
    },
    [currentData]
  );

  const clearClipboard = useCallback(
    () => {
      setCurrentData(undefined);
    },
    [setCurrentData]
  );

  const hasCopiedData = useCallback(
    (type: AppClipboardDataType): boolean => {
      if (isNil(currentData)) {
        return false;
      }

      return currentData.type === type;
    },
    [currentData]
  );

  return (
    <AppClipboard.Provider
      value={{
        copyToClipboard,
        pasteFromClipboard,
        clearClipboard,
        hasCopiedData
      }}
    >
      {props.children}
    </AppClipboard.Provider>
  );
};
