import {Moment} from 'moment';
import {ProductsPaymentDetails} from './PalimsPublicService';

export enum QuoteSource {
  ONLINE_PURCHASE = 'ONLINE_PURCHASE',
  ONLINE_QUOTE_REQUEST = 'ONLINE_QUOTE_REQUEST',
  INTERNALLY_GENERATED_FROM_ONLINE_QUOTE_REQUEST = 'INTERNALLY_GENERATED_FROM_ONLINE_QUOTE_REQUEST',
  INTERNALLY_GENERATED = 'INTERNALLY_GENERATED',
  ONLINE_EFT_PURCHASE = 'ONLINE_EFT_PURCHASE',
  PAYPAL_PURCHASE = 'PAYPAL_PURCHASE',
  ALL = 'ALL'
}

export enum QuoteRequestType {
  RENEWAL = 'RENEWAL',
  ADDITION = 'ADDITION',
  NEW_CLIENT = 'NEW_CLIENT',
  UPGRADE = 'UPGRADE',
  ALL = 'ALL'
}

export enum QuoteStatus {
  NOT_VIEWED = 'NOT_VIEWED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  ALL = 'ALL'
}

export enum ClientLicenseState {
  Expires30daysLicense = 'Expires30days',
  ExpiredLicense = 'ExpiredLicense',
  TrialLicense = 'TrialLicense',
  ValidLicense = 'ValidLicense',
  NotSet = 'NotSet'
}

export enum PaymentMethod {
  ONLINE= 'ONLINE',
  EFT= 'EFT',
  NOT_SET = 'NOT_SET'
}
export enum UserPaymentTypes {
  UNREGISTERED_CLIENT = 'UNREGISTERED_CLIENT',
  EXISTING_CLIENT = 'EXISTING_CLIENT',
}

export enum QuoteLicenceTypes {
  STARTER= 'STARTER',
  PREMIUM = 'PREMIUM',
  ENTERPRISE= 'ENTERPRISE'
}

export enum LicenceTypes {
  STARTER= 'STARTER',
  PREMIUM = 'PREMIUM',
  LIFECYCLE = 'LIFECYCLE',
  FREE = 'FREE',
  NOT_SET  = 'NOT_SET',
  ENGAGEMENT_SURVEY = 'ENGAGEMENT_SURVEY'
}

export enum PaymentStatus {
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PAID = 'PAID',
  UNPAID= 'UNPAID',
  NOT_SET = 'NOT_SET'
}

export enum Currencies {
  USD= 'USD',
  ZAR = 'ZAR'
}

export interface QuoteDetails {
  client_email: string;
  licence_code: QuoteLicenceTypes;
  currency: Currencies;
  headcount: number;
  total_amount_due: number;
  product_amount: number;
  quote_pdf_url: string;
  error_code: string;
  quote_number: string;
  existing_client: boolean;
  credit_amount: number;
  partner_commission_percentage: number;
  partner_commission_amount: number;
  created_timestamp: Moment;
  source: QuoteSource;
  new_client_email: string;
  client_name: string;
  partner_name: string;
  request_type: QuoteRequestType;
  discount_amount: number;
  discount_percentage: number;
  client_id: string;
  partner_id: string;
  status: QuoteStatus;
  creator_email?: string;
  id: string;
}

export interface InvoiceDetails {
  client_email: string;
  licence_code: QuoteLicenceTypes;
  currency: Currencies;
  headcount: number;
  total_amount_due: number;
  product_amount: number;
  quote_pdf_url: string;
  error_code: string;
  quote_number: string;
  existing_client: boolean;
  credit_amount: number;
  partner_commission_percentage: number;
  partner_commission_amount: number;
  created_timestamp: Moment;
  source: QuoteSource;
  client_name: string;
  partner_name: string;
  request_type: QuoteRequestType;
  discount_amount: number;
  discount_percentage: number;
  client_id: string;
  partner_id: string;
  status: QuoteStatus;
  creator_email?: string;
  new_client_name: string;
  subscription_id: string;
  id: string;
  paymentStatus: PaymentStatus;
  newClientEmail: string;
  newClientContactNumber: string;
  surveyCampaignName: string;
  surveyPricePresented: string;
  newClientFirstName: string;
  newClientLastName: string;
  newClientAddressLine1: string;
  newClientAddressLine2: string;
  newClientCity: string;
  region: string;
  otpSentTimestamp?: Moment | undefined;
}

export interface ProductsPaymentData {
  starter: ProductsPaymentDetails;
  premium: ProductsPaymentDetails;
}

export default class PalimsSharedService {

  public static convertQuoteSourceToName (name: QuoteSource): string {
    switch (name) {
      case QuoteSource.ONLINE_QUOTE_REQUEST :
        return 'Quote Requests';
      case QuoteSource.ONLINE_PURCHASE :
        return 'Online purchases';
      case QuoteSource.INTERNALLY_GENERATED :
        return 'Mindset Generated';
      case QuoteSource.INTERNALLY_GENERATED_FROM_ONLINE_QUOTE_REQUEST :
        return 'Generated From Quote';
      case QuoteSource.ONLINE_EFT_PURCHASE :
        return 'EFT purchase request';
      case QuoteSource.PAYPAL_PURCHASE :
        return 'Paypal purchase';
      case QuoteSource.ALL :
        return 'All';
      default :
        return '';
    }
  }

  public static convertQuoteStatusToName (name: QuoteStatus): string {
    switch (name) {
      case QuoteStatus.ACCEPTED :
        return 'Accepted';
      case QuoteStatus.REJECTED :
        return 'Rejected';
      case QuoteStatus.PENDING :
        return 'Pending';
      case QuoteStatus.ALL :
        return 'All';
      default :
        return '';
    }
  }

  public static convertRequestTypeToName (name: QuoteRequestType): string {
    switch (name) {
      case QuoteRequestType.ADDITION :
        return 'Additional';
      case QuoteRequestType.NEW_CLIENT :
        return 'New Client';
      case QuoteRequestType.RENEWAL :
        return 'Renewal';
      case QuoteRequestType.UPGRADE :
        return 'Upgrade';
      default :
        return '';
    }
  }
}
