import * as React from 'react';
import {compact} from 'lodash-es';
import moment, {Moment} from 'moment';

export function classes(...classNames: string[]) {
  return compact(classNames).join(' ');
}

export function renderIf(render: React.ReactNode, condition: boolean = true) {
  return condition ? render : null;
}

export function generalTimeFormatString(date?: Moment): string {
  if (date) {
    return moment(date).format('D MMM YYYY h:mmA');
  }
  return 'D MMM YYYY h:mmA';
}