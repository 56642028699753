import * as React from 'react';

interface AsyncComponentProps {
  getComponent: any;
}

interface AsyncComponentState {
  component: any;
}

export default class AsyncComponent extends React.Component<AsyncComponentProps, AsyncComponentState> {
  _isUnmounted: boolean;

  constructor(props: AsyncComponentProps) {
    super(props);

    this.state = {
      component: null
    };
  }

  componentWillMount() {
    this._isUnmounted = true;
  }

  componentDidMount() {
    this._isUnmounted = false;
    this.props.getComponent().then((loaded: any) => {
      if (this._isUnmounted) {
        return;
      }
      const {default: Component} = loaded;
      this.setState({
        component: Component
      });
    });
  }

  render() {
    const C = this.state.component;
    return C ? <C {...this.props}/> : null;
  }

  componentWillUnmount() {
    this._isUnmounted = true;
  }
}