import * as React from 'react';
import {PropsWithChildren, useCallback} from 'react';
import DataService from '../../shared/services/DataService';
import UserService from '../../shared/services/UserService';
import {useCache} from '../../shared-common/hooks/useCache';
import {ReportCache} from '../context/ReportCache';
import {Report} from '../types/Report';

export const ReportStore = (props: PropsWithChildren<unknown>) => {
  const {
    getValue: getPublishedReport,
    clearCache: clearPublishedReportCache
  } = useCache<string, Report>(async (reportId) => {
      return await DataService.tryLoad<Report>(
        '/engage-reports/load-published-report',
        {
          organisationId: UserService.instance.organisationId,
          reportId: reportId
        }
      );
    }
  );

  const clearReportCache = useCallback(
    (reportId: string) => {
      clearPublishedReportCache(reportId);
    },
    []
  );

  return (
    <ReportCache.Provider
      value={{
        getPublishedReport: getPublishedReport,
        clearReportCache: clearReportCache
      }}
    >
      {props.children}
    </ReportCache.Provider>
  );
};
