import * as React from 'react';
import CallbackHelpers from '../helpers/CallbackHelpers';
import {ModelDataset} from '../types/ModelDataset';

export interface DatasetCacheProps {
  getDatasets: (surveyModelId: string) => ModelDataset[];
  clearCache: () => void;
  isLoaded: (surveyModelId: string) => boolean;
  isDatasetCacheProcessing: boolean;
}

export const DatasetCache = React.createContext<DatasetCacheProps>({
  getDatasets: CallbackHelpers.NotImplemented,
  clearCache: CallbackHelpers.NotImplemented,
  isLoaded: CallbackHelpers.NotImplemented,
  isDatasetCacheProcessing: false
});
