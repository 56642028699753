import * as React from 'react';
import {Dropdown, Menu} from 'antd';
import styles from './ProductLineSelector.module.css';
import {ClickParam} from 'antd/lib/menu';
import Caret, {CaretTheme} from '../../../shared/components/Caret/Caret';
import {ThemeContext} from '../../../app/context/ThemeContext';
import {classes} from '../../../shared/helpers/RenderHelpers';
import {ReactComponent as WorkplaceIcon} from './images/product-workplace.svg';
import {ReactComponent as LifecycleIcon} from './images/product-lifecycle.svg';
import {ReactComponent as AssessIcon} from './images/product-assessment.svg';
import {ProductLine} from '../../../shared-common/types/ProductLine';
import {ProductLineFilter} from '../../context/ProductLineFilter';
import RenderHelpers from '../../../shared-ui/helpers/RenderHelpers';
import {size} from 'lodash-es';
import If from '../../../shared-ui/components/If/If';
import StackContainer from '../../../shared-ui/components/StackContainer/StackContainer';

interface ProductSelectorState {
  isOpen: boolean;
}

interface ProductSelectorStateProps {
  mobile?: boolean;
}

export default class ProductLineSelector extends React.Component<ProductSelectorStateProps, ProductSelectorState> {
  constructor(props: ProductSelectorStateProps) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  onMenuItemClicked = (param: ClickParam,
                       availableProducts: ProductLine[],
                       setCurrentProduct: (product: ProductLine) => void) => {
    for (let product of availableProducts) {
      if (param.key === ProductLine[product]) {
        setCurrentProduct(product);
        return;
      }
    }
  };

  onVisibleChanged = () => {
    this.setState({isOpen: !this.state.isOpen});
  };

  private renderProductIcon = (product: ProductLine) => {
    switch (product) {
      case ProductLine.Workplace:
        return <WorkplaceIcon className={styles.productIcon} style={{width: 48}}/>;
      case ProductLine.Lifecycle:
        return <LifecycleIcon className={styles.productIcon} style={{width: 48}}/>;
      case ProductLine.Assess:
        return <AssessIcon className={styles.productIcon} style={{width: 48}}/>;
      default:
        return undefined;
    }
  };

  private getProductName = (product: ProductLine) => {
    switch (product) {
      case ProductLine.Workplace:
        return 'Surveys';
      case ProductLine.Lifecycle:
        return 'Lifecycle';
      case ProductLine.Assess:
        return 'Assessments';
      default:
        return 'Unknown';
    }
  };

  render() {
    return (
      <ProductLineFilter.Consumer>
        {({currentProductLine, getAccessibleProductLines, setCurrentProductLine}) => {

          const accessibleProductLines = getAccessibleProductLines();
          const showDropdown = size(accessibleProductLines) > 1;

          return (
            <>
              <div
                className={
                  RenderHelpers.classes(
                    styles.component,
                    this.props.mobile ? styles.isMobile : styles.isDesktop
                  )
                }
              >
                <Dropdown
                  className={styles.productDropdown}
                  overlay={(
                    <Menu
                      className={
                        RenderHelpers.classes(
                          styles.dropdown,
                          this.props.mobile ? styles.isMobile : styles.isDesktop
                        )
                      }
                      onClick={
                        param => this.onMenuItemClicked(param, getAccessibleProductLines(), setCurrentProductLine)
                      }
                    >
                      {
                        accessibleProductLines.map((product) => {
                          return (<Menu.Item key={ProductLine[product]}>
                            <StackContainer stackFrom="left" verticalAlignment="center">
                              {this.renderProductIcon(product)}
                              <div className={styles.productName}>{this.getProductName(product)}</div>
                            </StackContainer>
                          </Menu.Item>);
                        })
                      }
                    </Menu>
                  )}
                  disabled={!showDropdown}
                  trigger={['click']}
                  onVisibleChange={this.onVisibleChanged}
                >
                  <div
                    className={
                      RenderHelpers.classes(
                        styles.productMenu,
                        this.props.mobile ? styles.isMobile : styles.isDesktop
                      )}
                  >
                    <If condition={this.props.mobile}>
                        <span className={styles.productPrompt}>
                          {'Product'}
                        </span>
                    </If>
                    <div className={classes(styles.dropdownContainer, this.state.isOpen ? styles.open : null)}>
                      <ThemeContext.Consumer>
                        {themeContext => (
                          <span className={styles.wrapper}>
                            <>
                              {this.renderProductIcon(currentProductLine)}
                              <span
                                className={styles.productName}
                                style={!this.props.mobile ? {color: themeContext.theme.mainMenu.primaryTextColor} : {}}
                              >
                                {this.getProductName(currentProductLine)}
                              </span>
                            </>
                            <If condition={showDropdown}>
                              <Caret
                                className={styles.dropDownIcon}
                                isOpen={this.state.isOpen}
                                size={20}
                                theme={CaretTheme.None}
                                style={!this.props.mobile ? {color: themeContext.theme.mainMenu.primaryTextColor} : {}}
                              />
                            </If>
                            <If condition={!showDropdown}>
                              <div style={{width: 10}}/>
                            </If>
                          </span>
                        )}
                      </ThemeContext.Consumer>
                    </div>
                  </div>
                </Dropdown>
                <div className={styles.triangle}/>
              </div>
            </>
          );
        }}
      </ProductLineFilter.Consumer>);
  }
}
