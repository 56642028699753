import * as React from 'react';
import {Dialect, LanguageProps, LanguageRoot, Languages, Territory} from '../context/Languages';
import DataService from '../../shared/services/DataService';
import {find, isNil} from 'lodash-es';
import Helpers from '../helpers/Helpers';

interface LanguageStoreState {
  languageRoots: LanguageRoot[];
  dialects: Dialect[];
  territories: Territory[];
}

export default class LanguageStore extends React.PureComponent<{}, LanguageStoreState> {

  constructor(props: {}) {
    super(props);

    this.state = {
      languageRoots: [],
      dialects: [],
      territories: []
    };
  }

  async componentDidMount() {
    const result = await DataService.tryLoad<LanguageProps>(
      '/master-data/get-supported-languages');

    if (!isNil(result)) {
      this.setState({...result});
    }
  }

  private static splitLanguageCode = (languageCode: string | undefined)
    : { rootCode: string | undefined, territoryCode: string | undefined } => {
    if (Helpers.isBlank(languageCode)) {
      return {
        rootCode: undefined,
        territoryCode: undefined
      };
    }

    const split: string [] = languageCode.split('-');

    const regexSearchCode = '([A-Z]{2}|[0-9]{3})';
    const regex = new RegExp(regexSearchCode);

    if (regex.test(split[split.length - 1])) {
      const territoryCodeIndex = languageCode.indexOf(split[split.length - 1]);

      return {
        rootCode: languageCode.substring(0, territoryCodeIndex - 1),
        territoryCode: split[split.length - 1]
      };
    }

    return {
      rootCode: languageCode,
      territoryCode: undefined
    };
  };

  private getNameForLanguageCode = (languageCode: string): string | undefined => {

    const split = LanguageStore.splitLanguageCode(languageCode);

    const rootLanguage = find(this.state.languageRoots, r => r.code === split.rootCode);
    const territory = find(this.state.territories, t => t.code === split.territoryCode);

    if (!isNil(rootLanguage) && !isNil(territory)) {
      return `${rootLanguage.name} (${territory.name})`;
    }

    if (!isNil(rootLanguage)) {
      return `${rootLanguage.name}`;
    }

    return undefined;
  };

  render() {
    return (
      <Languages.Provider
        value={{
          ...this.state,
          splitLanguageCode: LanguageStore.splitLanguageCode,
          getNameForLanguageCode: this.getNameForLanguageCode,
        }}
      >
        {this.props.children}
      </Languages.Provider>
    );
  }
}
