import * as React from 'react';
import styles from './SimpleTree.module.css';
import Caret, {CaretTheme} from '../Caret/Caret';
import {
  safeNav,
  setValueInArray,
  toggleValueInArray
} from '../../helpers/StateHelpers';
import {includes, union, filter} from 'lodash-es';
import {findAllNodesInPrunedTree, TreeNode} from '../../helpers/TreeHelpers';

export enum SimpleTreeClickBehaviour {
  Ignore,
  SelectNode,
  ExpandNode,
  SelectAndExpandNode
}

interface SimpleTreeProps {
  className?: string;
  nodes: TreeNode<any>[];
  selectedNodes?: TreeNode<any>[];
  onRenderNode: RenderNodeMethod<TreeNode<any>>;
  onRenderDragGap?: RenderGapMethod<TreeNode<any>>;
  onNodeSelectedChanged?: (node: TreeNode<any>, isNowSelected: boolean) => void;
  onNodeExpandedChanged?: (node: TreeNode<any>, isNowExpanded: boolean) => void;
  onNodeMoved?: (fromNode: TreeNode<any>, toNode: TreeNode<any>, movedUnderneath: boolean) => void;
  onDragDropped?: (toNode: TreeNode<any>, data: any) => void;
  expandedNodes?: TreeNode<any>[];
  highlightedNodes?: TreeNode<any>[];
  notSelectableNodes?: TreeNode<any>[];
  notDraggableNodes?: TreeNode<any>[];
  notDropTargetNodes?: TreeNode<any>[];
  notExpandableNodes?: TreeNode<any>[];
  visibleNodes?: TreeNode<any>[];
  allowDraggingNodes?: boolean;
  singleClickBehaviour?: SimpleTreeClickBehaviour;
  doubleClickBehaviour?: SimpleTreeClickBehaviour;
  noResultsContent?: React.ReactNode | string;
}

interface SimpleTreeState {
  expandedIds: string[];
  draggedNode?: TreeNode<any>;
  dragTargetId?: string;
  dragTargetIsGap: boolean;
}

type RenderNodeMethod<T extends TreeNode<T>> =
  (node: T,
   isSelected: boolean,
   isExpanded: boolean,
   isDragTarget: boolean,
   isDragging: boolean,
   isHighlighted: boolean,
   isSelectable: boolean,
   isDraggable: boolean,
   isExpandable: boolean,
   isDroppable: boolean,
   depth: number,
   onToggleExpand: (node: T) => void) => JSX.Element;

type RenderGapMethod<T extends TreeNode<T>> =
  (node: T,
   isDraggedOver: boolean) => JSX.Element;

export default class SimpleTree extends React.Component<SimpleTreeProps, SimpleTreeState> {
  public static defaultProps: Partial<SimpleTreeProps> = {
    allowDraggingNodes: false,
    singleClickBehaviour: SimpleTreeClickBehaviour.SelectNode,
    doubleClickBehaviour: SimpleTreeClickBehaviour.Ignore,
    onRenderDragGap: SimpleTree.createSimpleGapRenderer<TreeNode<any>>()
  };

  private _dragCounter: number = 0;
  private _doubleClickTimer: number | undefined;
  private _dragOverTimer: number | undefined;
  private _dragCursor: Element | null;

  public static createSimpleNodeRenderer<T extends TreeNode<T>>
  (renderNode: (node: T) => React.ReactNode | string): RenderNodeMethod<T> {
    return (node: T,
            isSelected: boolean,
            isExpanded: boolean,
            isDragTarget: boolean,
            isDragging: boolean,
            isHighlighted: boolean,
            isSelectable: boolean,
            isDraggable: boolean,
            isExpandable: boolean,
            isDroppable: boolean,
            depth: number,
            onToggleExpand: (node: T) => void) => {
      return (<span
        className={[
          styles.defaultItem,
          isSelected ? styles.selected : '',
          isSelectable ? styles.selectable : '',
          isHighlighted ? styles.highlighted : '',
          isDraggable ? styles.draggable : '',
          isDroppable && isDragTarget ? styles.dragOver : ''
        ].join(' ')}
      >
        <span>{renderNode(node)}</span>
        <span className={styles.expander}>
          {(node.children && isExpandable)
            ? <span
              onClick={(event: React.MouseEvent<any>) => {
                event.stopPropagation();
                onToggleExpand(node);
              }}
            >
            <Caret
              size={20}
              isOpen={isExpanded}
              theme={CaretTheme.Light}
            />
            </span>
            : null}
        </span>
      </span>);
    };
  }

  public static createSimpleGapRenderer<T extends TreeNode<T>>(): RenderGapMethod<T> {
    return (node: T, isDraggedOver: boolean) => {
      return (<div
        className={[styles.defaultGap, isDraggedOver ? styles.dragOver : null].join(' ')}
      />);
    };
  }

  constructor(props: SimpleTreeProps) {
    super(props);

    this.state = {
      expandedIds: this.getExpandedIds(props),
      dragTargetIsGap: false
    };
  }

  componentWillReceiveProps(props: SimpleTreeProps) {
    this.setState({expandedIds: this.getExpandedIds(props)});
  }

  render() {
    let prunedNodes = this.pruneNodes(this.props.nodes);

    return (
      <div className={[styles.component, this.props.className].join(' ')}>
        {prunedNodes.length === 0 ? this.props.noResultsContent :
          <ul>
            {prunedNodes.map((item: TreeNode<any>) => this.renderNode(item))}
          </ul>}
        <div ref={me => this._dragCursor = me}/>
      </div>);
  }

  private getExpandedIds(props: SimpleTreeProps) {
    let expandedNodeIds: string[] = [...safeNav(this.state, x => x.expandedIds) || []];

    let pathIds = findAllNodesInPrunedTree(
      props.nodes,
      (n: TreeNode<any>) => includes(props.expandedNodes, n)
    ).map((n: TreeNode<any>) => n.id);

    return union(expandedNodeIds, pathIds);
  }

  private pruneNodes = (nodes: TreeNode<any>[]) => {
    if (this.props.visibleNodes) {
      let prunedNodes = filter(nodes, (n: TreeNode<any>) => includes(this.props.visibleNodes, n));
      return prunedNodes;
    }

    return nodes;
  };

  onDragEnter = (event: React.DragEvent<any>, isGap: boolean) => {
    event.preventDefault();
    let targetNode = this.getEventTarget(event);

    this._dragCounter++;

    if (targetNode) {
      if (isGap) {
        if (includes(this.props.notDropTargetNodes, targetNode)) {
          return;
        }

        // Don't allow dropping into the gap if the parent node doesn't allow dropping
        let parentNode = this.findParentNode(targetNode, this.props.nodes);
        if (parentNode) {
          if (includes(this.props.notDropTargetNodes, parentNode)) {
            return;
          }
        }
      } else {
        if (includes(this.props.notDropTargetNodes, targetNode)) {
          return;
        }
      }

      this.setState({
        dragTargetId: targetNode.id,
        dragTargetIsGap: isGap
      });
    }
  };

  onDragLeave = (event: React.DragEvent<any>) => {
    event.preventDefault();
    if (this._dragOverTimer) {
      window.clearTimeout(this._dragOverTimer);
    }
    this._dragCounter++;
    if (this._dragCounter <= 0) {
      this._dragCounter = 0;
      this.setState({
        dragTargetId: undefined,
        dragTargetIsGap: false
      });
    }
  };

  onDragEnd = () => {
    this.setState({
      draggedNode: undefined,
      dragTargetId: undefined,
      dragTargetIsGap: false
    });
  };

  onDrop = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    let targetNode = this.getEventTarget(event);

    if (includes(this.props.notDropTargetNodes, targetNode)) {
      this.setState({
        draggedNode: undefined,
        dragTargetId: undefined,
        dragTargetIsGap: false
      });
      return;
    }

    if (this.state.draggedNode && targetNode && this.props.onNodeMoved) {
      this.props.onNodeMoved(this.state.draggedNode, targetNode, this.state.dragTargetIsGap);
    } else if (targetNode && this.props.onDragDropped) {
      let dragData = this.getDragData(event);
      this.props.onDragDropped(targetNode, dragData);
    }

    this.setState({
      draggedNode: undefined,
      dragTargetId: undefined,
      dragTargetIsGap: false
    });
  };

  onDragStart = (event: React.DragEvent<HTMLElement>, draggedNode: TreeNode<any>) => {
    // Firefox won't allow a drag unless some data is set - this id is not used anywhere
    event.dataTransfer.setData('text', draggedNode.id);

    // On browsers that support it, hide the drag preview
    if (this._dragCursor && event.dataTransfer.setDragImage) {
      event.dataTransfer.setDragImage(this._dragCursor, 0, 0);
    }

    this.setState({
      draggedNode: draggedNode
    });
  };

  onDragOver = (event: React.DragEvent<any>, isGap: boolean) => {
    event.preventDefault();
    let targetNode = this.getEventTarget(event);

    this._dragOverTimer = window.setTimeout(
      () => {
        if (targetNode && targetNode.id === this.state.dragTargetId) {
          this.setState({
            expandedIds: setValueInArray(this.state.expandedIds, this.state.dragTargetId)
          });
        }
      },
      600);
  };

  onDoubleClick = (event: React.MouseEvent<any>, node: TreeNode<any>) => {
    event.stopPropagation();
    event.preventDefault();
    if (this._doubleClickTimer) {
      window.clearTimeout(this._doubleClickTimer);
      this._doubleClickTimer = undefined;
    }

    this.performClickAction(node, this.props.doubleClickBehaviour);
  };

  onSingleClick = (event: React.MouseEvent<any>, node: TreeNode<any>) => {
    event.stopPropagation();

    if (this.props.singleClickBehaviour === SimpleTreeClickBehaviour.SelectNode &&
      this.props.doubleClickBehaviour === SimpleTreeClickBehaviour.SelectAndExpandNode) {
      this.performClickAction(node, this.props.singleClickBehaviour);
      return;
    }

    if (this.props.doubleClickBehaviour === SimpleTreeClickBehaviour.Ignore) {
      this.performClickAction(node, this.props.singleClickBehaviour);
      return;
    }

    if (this._doubleClickTimer) {
      return;
    }

    this._doubleClickTimer = window.setTimeout(
      () => {
        this.performClickAction(node, this.props.singleClickBehaviour);
        this._doubleClickTimer = undefined;
      },
      300);
  };

  private performClickAction = (node: TreeNode<any>, behaviour: SimpleTreeClickBehaviour | undefined) => {
    switch (behaviour) {
      default:
      case SimpleTreeClickBehaviour.Ignore:
        break;
      case SimpleTreeClickBehaviour.ExpandNode:
        this.toggleNodeExpanded(node);
        break;
      case SimpleTreeClickBehaviour.SelectNode:
        this.selectNode(node);
        break;
      case SimpleTreeClickBehaviour.SelectAndExpandNode:
        this.selectNode(node);
        this.toggleNodeExpanded(node);
        break;
    }
  };

  private selectNode = (node: TreeNode<any>) => {
    if (includes(this.props.notSelectableNodes, node)) {
      return;
    }

    let isSelected = includes(this.props.selectedNodes, node);

    if (this.props.onNodeSelectedChanged) {
      this.props.onNodeSelectedChanged(node, !isSelected);
    }
  };

  private toggleNodeExpanded = (node: TreeNode<any>) => {
    if (includes(this.props.notExpandableNodes, node)) {
      return;
    }

    let isExpanded = includes(this.props.expandedNodes, node);

    if (this.props.onNodeExpandedChanged) {
      this.props.onNodeExpandedChanged(node, !isExpanded);
    }

    this.setState({expandedIds: toggleValueInArray(this.state.expandedIds, node.id)});
  };

  renderNode = (node: TreeNode<any>, depth: number = 0): JSX.Element => {
    let isExpanded = includes(this.state.expandedIds, node.id);
    let isSelected = includes(this.props.selectedNodes, node);
    let isDragging = this.state.draggedNode === node;
    let isHighlighted = includes(this.props.highlightedNodes, node);

    let notSelectable = includes(this.props.notSelectableNodes, node);
    let notDraggable = !this.props.allowDraggingNodes || includes(this.props.notDraggableNodes, node);
    let notExpandable = includes(this.props.notExpandableNodes, node);
    let notDropTarget = includes(this.props.notDropTargetNodes, node);

    let isDragOntoTarget = this.state.dragTargetId === node.id && !this.state.dragTargetIsGap;
    let isDragOverGapTarget = this.state.dragTargetId === node.id && this.state.dragTargetIsGap;

    return <div
      key={node.id}
      className={[styles.node].join(' ')}
    >
      <div
        onDragEnter={(event: React.DragEvent<any>) => this.onDragEnter(event, true)}
        onDragLeave={this.onDragLeave}
        onDragEnd={this.onDragEnd}
        onDrop={this.onDrop}
        onDragOver={(event: React.DragEvent<any>) => this.onDragOver(event, true)}
        data-id={node.id}
      >
        {this.props.onRenderDragGap ?
          this.props.onRenderDragGap(node, isDragOverGapTarget) : null
        }
      </div>
      <li
        key={depth}
        onDragStart={(event: React.DragEvent<any>) => this.onDragStart(event, node)}
        onDragEnter={(event: React.DragEvent<any>) => this.onDragEnter(event, false)}
        onDragLeave={this.onDragLeave}
        onDragEnd={this.onDragEnd}
        onDragOver={(event: React.DragEvent<any>) => this.onDragOver(event, false)}
        onDrop={this.onDrop}
        draggable={this.props.allowDraggingNodes && !notDraggable}
        data-id={node.id}
        onClick={(event: React.MouseEvent<any>) => this.onSingleClick(event, node)}
        onDoubleClick={(event: React.MouseEvent<any>) => this.onDoubleClick(event, node)}
      >
        {this.props.onRenderNode(
          node,
          isSelected,
          isExpanded,
          isDragOntoTarget,
          isDragging,
          isHighlighted,
          !notSelectable,
          !notDraggable,
          !notExpandable,
          !notDropTarget,
          depth,
          this.toggleNodeExpanded)}
      </li>
      <ul>
        {node.children && isExpanded ?
          this.pruneNodes(node.children).map((childNode: TreeNode<any>) =>
            this.renderNode(childNode, depth + 1)
          ) : null}
      </ul>
    </div>;
  };

  private getEventTarget(event: React.MouseEvent<HTMLElement>): TreeNode<any> | undefined {
    return this.findNodeById(this.props.nodes, event.currentTarget.getAttribute('data-id') || '');
  }

  private getDragData(event: React.DragEvent<any>): any {
    try {
      return JSON.parse(event.dataTransfer.getData('text'));
    } catch (ex) {
      return undefined;
    }
  }

  private findNodeById(nodes: TreeNode<any>[], nodeId: string): TreeNode<any> | undefined {
    if (!nodes) {
      return undefined;
    }

    for (let node of nodes) {
      if (node.id === nodeId) {
        return node;
      }

      if (node.children) {
        let matchingChild = this.findNodeById(node.children, nodeId);
        if (matchingChild) {
          return matchingChild;
        }
      }
    }

    return undefined;
  }

  private findParentNode(node: TreeNode<any>,
                         allNodes: TreeNode<any>[],
                         parentNode?: TreeNode<any>): TreeNode<any> | undefined {
    for (let n of allNodes) {
      if (n.id === node.id) {
        return parentNode;
      }

      if (!n.children || n.children.length === 0) {
        return undefined;
      }

      let foundNode = this.findParentNode(node, n.children, n);
      if (foundNode) {
        return foundNode;
      }
    }

    return undefined;
  }
}