import React from 'react';
import CallbackHelpers from '../../shared-common/helpers/CallbackHelpers';

export type NavigatorProps = {
  navigateTo: (url: string) => void;
};

export const Navigator = React.createContext<NavigatorProps>({
  navigateTo: CallbackHelpers.NoOp
});
