export class TwoWayBinding {
  private readonly _valueAccessor: () => any;
  private readonly _additionalValueAccessor: () => any;
  private _validator: () => Promise<string>;
  private readonly _applyChanges: (value: any, additionalValue: any, afterChangesCallback: () => void) => void;
  private readonly _updateView: () => void;
  private _errorMessage: string | undefined;
  private _isValidating: boolean;
  private _onChanging: (
    value: any,
    additionalValue: any,
    applyChanges: (value: any, additionalValue: any) => void) => void;
  private _onChanged: (isValid: boolean) => void;

  constructor(valueAccessor: () => any,
              additionalValueAccessor: () => any,
              applyChanges: (value: any, additionalValue: any, afterChangesCallback: () => void) => void,
              updateView: () => void) {
    this._valueAccessor = valueAccessor;
    this._additionalValueAccessor = additionalValueAccessor;
    this._applyChanges = applyChanges;
    this._updateView = updateView;
    this._isValidating = false;
  }

  public get value() {
    return this._valueAccessor();
  }

  public get additionalValue() {
    return this._additionalValueAccessor();
  }

  public validate = async (): Promise<void> => {
    this._errorMessage = undefined;
    this._isValidating = true;
    this._updateView();
    try {
      this._errorMessage = await this._validator();
      this._isValidating = false;
      this._updateView();
    } catch (ex) {
      this._errorMessage = undefined;
      this._isValidating = false;
      this._updateView();
    }
  };

  public get isValid(): boolean {
    return (!this._errorMessage || this._errorMessage.length === 0);
  }

  public get isValidating(): boolean {
    return this._isValidating;
  }

  public get errorMessage() {
    return this._errorMessage;
  }

  public applyChanges(value: any, additionalValue: any, afterChangesCallback: () => void) {
    if (this._onChanging) {
      this._onChanging(
        value,
        additionalValue,
        (newValue: any, newAdditionalValue: any) =>
          this._applyChanges(newValue, newAdditionalValue, () => {
            afterChangesCallback();
            if (this._onChanged) {
              this._onChanged(this.isValid);
            }
          }));
    } else {
      this._applyChanges(value, additionalValue, () => {
        afterChangesCallback();
        if (this._onChanged) {
          this._onChanged(this.isValid);
        }
      });
    }
  }

  public withValidation(validator: () => Promise<string>): TwoWayBinding {
    this._validator = validator;
    return this;
  }

  public withChangedHandler(handler: (isValid: boolean) => void): TwoWayBinding {
    this._onChanged = handler;
    return this;
  }

  public withChangingHandler(
    handler: (
      value: any,
      additionalValue: any,
      applyChanges: (value: any, additionalValue: any) => void
    ) => void
  ): TwoWayBinding {
    this._onChanging = handler;
    return this;
  }

  public clearValidation() {
    this._errorMessage = undefined;
  }
}