import * as React from 'react';
import {Redirect, Route, RouteComponentProps, Switch} from 'react-router';
import {PageMenu} from '../../../shared/components/PageMenu/PageMenu';
import {Page} from '../../../shared/models/Page';
import {Module} from '../../../shared/models/Module';
import {filter} from 'lodash-es';
import ErrorBoundary from '../../../shared/components/ErrorBoundary/ErrorBoundary';
import styles from './ModuleContent.module.css';

interface ModuleContentProps {
  modules: Module[];
}

export default class ModuleContent extends React.Component<ModuleContentProps, {}> {

  render() {
    return (
      <Switch>
        {this.renderDefaultRoute()}
        {this.renderModuleRoutes()}
        <Redirect to="/"/>
      </Switch>
    );
  }

  private static renderPage(
    moduleUrlSlug: string, activePages: Page<any>[], page: Page<any>, props: RouteComponentProps<any>) {

    let visiblePages = filter(activePages, p => (p.showInMenu !== false));

    return <div
      className={styles.component}
      onMouseUpCapture={ModuleContent.removeUnnecessarySelectionInEdge}
    >
      <PageMenu moduleUrlSlug={moduleUrlSlug} pages={visiblePages} {...props}/>
      <div className={styles.pageContent}>
        <ErrorBoundary>
          {React.createElement(page.content, {pages: page.pages, ...props})}
        </ErrorBoundary>
      </div>
    </div>;
  }

  // HACK: After navigating between pages, Edge sometimes selects the text in the entire page,
  // from the Shell or Root downwards. The code below detects and kills this unnecessary selection.
  private static removeUnnecessarySelectionInEdge = () => {
    // This hack applies only to Spartan-based Edge
    if (window.navigator.userAgent.indexOf('Edge') === -1) {
      return;
    }

    // Get the current selection
    const selection = window.getSelection();
    if (!selection) {
      return;
    }

    // Find the element where the selection starts
    const anchorElement = selection.anchorNode as Element;
    if (!anchorElement) {
      return;
    }

    // If the selection starts at the React root element, or the shell, it is unnecessary, and should be cancelled
    if (anchorElement.id === 'shell' || anchorElement.id === 'root') {
      selection.collapseToEnd();
    }
  };

  private static renderLicencePromptPage(moduleUrlSlug: string,
                                         activePages: Page<any>[],
                                         page: Page<any>,
                                         props: RouteComponentProps<any>) {

    let visiblePages = filter(activePages, p => (p.showInMenu !== false));

    let content = <div/>;

    if (page.licenceRequiredContent) {
      content = React.createElement(page.licenceRequiredContent, props);
    }

    return <div className={styles.component}>
      <PageMenu moduleUrlSlug={moduleUrlSlug} pages={visiblePages} {...props}/>
      <div className={styles.pageContent}>
        {content}
      </div>
    </div>;
  }

  private renderDefaultRoute() {
    if (!this.props.modules || this.props.modules.length === 0) {
      return null;
    }

    let activePages = filter<Page<any>>(
      this.props.modules[0].pages,
      (page: Page<any>) => page.isVisible()
    );

    if (!activePages) {
      return null;
    }

    return <Route
      exact
      path="/"
      render={(props: RouteComponentProps<any>) =>
        ModuleContent.renderPage(this.props.modules[0].urlSlug, activePages, activePages[0], props)}
    />;
  }

  private renderModuleRoutes() {
    let routes: JSX.Element[] = [];

    for (let module of this.props.modules) {

      let activePages = filter<Page<any>>(module.pages, (page: Page<any>) => page.isVisible());
      if (!activePages || activePages.length === 0) {
        continue;
      }

      // Create main Route
      routes.push(this.createPageRoute(module.urlSlug, '', activePages, activePages[0], true));

      // Create Sub Route
      for (let page of activePages) {
        routes.push(this.createPageRoute(module.urlSlug, page.urlSlug, activePages, page, false));
      }
    }

    return routes;
  }

  private createPageRoute(moduleUrlSlug: string, pageUrlSlug: string, activePages: Page<any>[], page: Page<any>,
                          first: boolean): JSX.Element {

    if (page.showLicenceRequired && page.showLicenceRequired()) {
      return <Route
        exact
        key={moduleUrlSlug}
        path={'/' + moduleUrlSlug + '/' + pageUrlSlug}
        render={(props: RouteComponentProps<any>) =>
          ModuleContent.renderLicencePromptPage(moduleUrlSlug, activePages, page, props)}

      />;
    } else {
      let path = '/' + moduleUrlSlug;
      if (pageUrlSlug) {
        path += '/' + pageUrlSlug;
      }

      // main route
      if (first) {
        return <Route
          exact
          key={path}
          path={path}
          render={(props: RouteComponentProps<any>) =>
            ModuleContent.renderPage(moduleUrlSlug, activePages, page, props)}
        />;
      }

      // do not render fixed path
      if (page.subURL) {
        return <Route
          key={path + '/'}
          path={path + '/'}
          render={(props: RouteComponentProps<any>) =>
            ModuleContent.renderPage(moduleUrlSlug, activePages, page, props)}
        />;
      }

      return <Route
        exact
        key={path}
        path={path}
        render={(props: RouteComponentProps<any>) =>
          ModuleContent.renderPage(moduleUrlSlug, activePages, page, props)}
      />;
    }
  }
}
