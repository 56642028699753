import * as React from 'react';
import {Router} from 'react-router-dom';
import {Route, Switch} from 'react-router';
import UserService from '../shared/services/UserService';
import {LocaleProvider} from 'antd';
import ThemeProvider, {
  Theme, MenuStates,
  ImplicationIconStyle
} from '../shared/components/ThemeProvider/ThemeProvider';
import LoadingAnimation from '../shared/components/LoadingAnimation/LoadingAnimation';
import AsyncComponent from '../shared/components/AsyncComponent/AsyncComponent';
import {Shell} from '../shell/Shell';
import LoginPage from './pages/LoginPage/LoginPage';
import Demo from './pages/Demo/Demo';
import {createBrowserHistory, History, Location} from 'history';
import TranslationService from '../shared/services/TranslationService';
import {ThemeContext, ContextTheme, BaseContextTheme} from './context/ThemeContext';
import {merge} from 'lodash-es';
import {safeNav} from '../shared/helpers/StateHelpers';
import IndicatorDemo from './pages/IndicatorDemo/IndicatorDemo';
import {MercuryEndpoint} from '../mercury/components/MercuryEndpoint';
import {ReportStore} from '../reports/stores/ReportStore';

// Work around for TypeScript
import * as enUS from 'antd/lib/locale-provider/en_US';

const enUSLocale: any = enUS;

export default class App extends React.Component<{}, {}> {
  private readonly _browserHistory: History;
  private readonly _unregisterHistoryListenCallback: () => void;
  private readonly _baseTheme: ContextTheme;

  constructor(props: {}) {
    super(props);

    this._browserHistory = createBrowserHistory();
    this._unregisterHistoryListenCallback = this._browserHistory.listen(
      location => this.trackLocation(location)
    );

    this._baseTheme = BaseContextTheme;
  }

  private trackLocation = (location: Location) => {
    // TODO: Track location here
  };

  async componentDidMount() {
    UserService.instance.registerForUpdate(this);
    await TranslationService.loadMessages();
  }

  componentWillUnmount() {
    this._unregisterHistoryListenCallback();
    UserService.instance.deregisterForUpdate(this);
  }

  render() {

    let InsightIcon1: MenuStates = {
      normal: {
        icon: {
          primary: 'rgba(191,191,191, 1)',
          secondary: 'rgba(217,217,217,1)',
          tertiary: 'rgba(166,166,166,1)'
        },
        textColor: 'rgba(136, 86, 100, 1)',
        background: 'rgba(248, 248, 248, 1)'
      },
      selected: {
        icon: {
          primary: 'rgba(153,198,71, 1)',
          secondary: 'rgba(132,151,176,1)',
          tertiary: 'rgba(255,0,0,1)'
        },
        textColor: 'rgba(136, 86, 100, 1)',
        background: 'rgba(248, 248, 248, 1)'
      },
      hover: {
        icon: {
          primary: 'rgba(153,198,71, 0.5)',
          secondary: 'rgba(132,151,176,0.5)',
          tertiary: 'rgba(255,0,0,0.5)'
        },
        textColor: 'rgba(127, 127, 127, 1)',
        background: 'rgba(248, 248, 248, 1)'
      }
    };

    let InsightIcon2: MenuStates = {
      normal: {
        icon: {
          primary: 'rgba(191,191,191, 1)',
          secondary: 'rgba(242,242,242,1)',
          tertiary: 'rgba(166,166,166,1)',
          quaternary: 'rgba(166,166,166,1)'
        },
        textColor: 'rgba(136, 86, 100, 1)',
        background: 'rgba(248, 248, 248, 1)'
      },
      selected: {
        icon: {
          primary: 'rgba(153,198,71, 1)',
          secondary: 'rgba(255,255,255,1)',
          tertiary: 'rgba(132,151,176,1)',
          quaternary: 'rgba(89,89,89,1)'
        },
        textColor: 'rgba(136, 86, 100, 1)',
        background: 'rgba(248, 248, 248, 1)'
      },
      hover: {
        icon: {
          primary: 'rgba(153,198,71, 0.5)',
          secondary: 'rgba(255,255,255, 0.5)',
          tertiary: 'rgba(132,151,176, 0.5)',
          quaternary: 'rgba(89,89,89, 0.5)'
        },
        textColor: 'rgba(127, 127, 127, 1)',
        background: 'rgba(248, 248, 248, 1)'
      }
    };

    let implicationColors: ImplicationIconStyle = {
      High: {
        primary: 'rgba(152,176,111, 1)',
        secondary: 'rgba(123,149,81,1)',
        tertiary: 'rgba(255,255,255,1)'
      },
      Middle: {
        primary: 'rgba(182,220,118, 1)',
        secondary: 'rgba(145,202,52,1)',
        tertiary: 'rgba(255,255,255,1)'
      },
      Low: {
        primary: 'rgba(233,180,76, 1)',
        secondary: 'rgba(219,155,27,1)',
        tertiary: 'rgba(255,255,255,1)'
      },
      Lowest: {
        primary: 'rgba(233,79,55, 1)',
        secondary: 'rgba(203,49,23,1)',
        tertiary: 'rgba(255,255,255,1)'
      }
    };

    let toolbox: MenuStates = {
      normal: {
        icon: {
          primary: 'rgba(236,174,198,1)',
          secondary: 'rgba(173,185,202,1)',
          tertiary: 'rgba(0,32,96,1)'
        },
        textColor: 'rgba(136, 86, 100, 1)',
        background: 'rgba(248, 248, 248, 1)'
      },
      selected: {
        icon: {
          primary: 'rgba(236,174,198,1)',
          secondary: 'rgba(173,185,202,1)',
          tertiary: 'rgba(0,32,96,1)'
        },
        textColor: 'rgba(136, 86, 100, 1)',
        background: 'rgba(248, 248, 248, 1)'
      },
      hover: {
        icon: {
          primary: 'rgba(236,174,198,1)',
          secondary: 'rgba(173,185,202,0.5)',
          tertiary: 'rgba(0,32,96,0.5)'
        },
        textColor: 'rgba(127, 127, 127, 1)',
        background: 'rgba(248, 248, 248, 1)'
      }
    };

    let sideMenu: MenuStates = {
      normal: {
        icon: {
          primary: 'rgba(217,217,217,1)',
          secondary: 'rgba(242,242,242,1)',
          tertiary: 'rgba(166,166,166,1)'
        },
        textColor: 'rgba(85,106,133,1)',
        background: '#d6dce5'
      },
      selected: {
        icon: {
          primary: 'rgba(236,174,198,1)',
          secondary: 'rgba(173,185,202,1)',
          tertiary: 'rgba(0,32,96,1)'
        },
        textColor: 'rgba(255, 255, 255, 1)',
        background: 'rgb(132,151,176)'
      },
      hover: {
        icon: {
          primary: 'rgba(236,174,198,1)',
          secondary: 'rgba(173,185,202,0.5)',
          tertiary: 'rgba(0,32,96,0.5)'
        },
        textColor: 'rgba(85,106,133,0.5)',
        background: '#d6dce5'
      }
    };

    let subMenu: MenuStates = {
      normal: {
        icon: {

          primary: 'rgba(236,174,198,1)',
          secondary: 'rgba(173,185,202,1)',
          tertiary: 'rgba(0,32,96,1)'
        },
        textColor: 'rgba(85,106,133,1)',
        background: '#d6dce5'
      },
      selected: {
        icon: {
          primary: 'rgba(217,217,217,1)',
          secondary: 'rgba(242,242,242,1)',
          tertiary: 'rgba(166,166,166,1)'
        },
        textColor: 'rgba(255, 255, 255, 1)',
        background: 'rgba(136, 86, 100, 1)'
      },
      hover: {
        icon: {
          primary: 'rgba(236,174,198,1)',
          secondary: 'rgba(173,185,202,0.5)',
          tertiary: 'rgba(0,32,96,0.5)'
        },
        textColor: 'rgba(85,106,133,0.5)',
        background: '#d6dce5'
      }
    };

    let tabs: MenuStates = {
      normal: {
        icon: {
          primary: 'rgba(217,217,217,1)',
          secondary: 'rgba(242,242,242,1)',
          tertiary: 'rgba(166,166,166,1)'
        },
        textColor: 'rgba(158,158,158,1)',
        background: '#d6dce5'
      },
      selected: {
        icon: {
          primary: 'rgba(236,174,198, 1)',
          secondary: 'rgba(173,185,202, 1)',
          tertiary: 'rgba(0,32,96,1)'
        },
        textColor: 'rgba(0, 0, 0, 1)',
        background: '#d6dce5'
      },
      hover: {
        icon: {
          primary: 'rgba(236,174,198, 0.5)',
          secondary: 'rgba(173,185,202, 0.5)',
          tertiary: 'rgba(0,32,96,0.5)'
        },
        textColor: 'rgba(0, 0, 0, 0.5)',
        background: '#d6dce5'
      }
    };

    let theme: Theme = {
      baseFontSize: '14px',
      buttonBorderRadius: 20,
      inputBorderRadius: 0,
      popupBorderRadius: 0,
      dialogBorderRadius: 5,
      tabsBorderRadius: 0,
      moduleMenuBackgroundColour1: 'rgb(117, 117, 117)',
      moduleMenuBackgroundColour2: 'rgb(43, 43, 43)',
      moduleMenuTextColour1: 'rgb(255, 255, 255)',
      moduleMenuTextColour2: '#FFE575',
      siderNavigationItem: sideMenu,
      toolBoxNavigation: toolbox,
      subMenuNavigation: subMenu,
      tabs: tabs,
      defaultSiderToolboxIcons: {
        primary: 'rgba(173, 185, 202, 1)',
        secondary: 'rgba(224, 47, 47, 1)',
        tertiary: 'rgba(0, 32, 96,1)'
      },
      defaultSiderToolboxIconsHover: {
        primary: 'rgba(217, 217, 217, 1)',
        secondary: 'rgba(217, 217, 217, 1)',
        tertiary: 'rgba(166, 166, 166, 1)'
      },
      dropShadowColor: 'rgba(222, 222, 222, 1)',
      siderMenuSize: 250,
      siderMenuCollapseSize: 150,
      pageContentPadding: 0,
      questionnairePrimaryColour: 'rgb(141, 78, 100)',
      questionnairePrimaryTextColour: 'rgb(255, 255, 255)',
      questionnaireSecondaryColour: 'rgb(222, 235, 247)',
      questionnaireSecondaryTextColour: 'rgb(0, 0, 0)',
      questionnaireTertiaryColour: 'rgb(153, 168, 190)',
      questionnaireTertiaryTextColour: 'rgb(255, 255, 255)',
      dropdownHighlightColour: 'rgb(153, 168, 190)',
      implication: implicationColors,
      InsightIcon1: InsightIcon1,
      InsightIcon2: InsightIcon2
    };

    let asyncRoute = (path: string | undefined, getComponent: () => any) => {
      return (
        <Route
          path={path}
          render={(routeInfo) =>
            <AsyncComponent key={path} getComponent={getComponent} {...routeInfo}/>
          }
        />
      );
    };

    let currentTheme = safeNav(UserService.instance.currentClientOrPartner, x => x.shellTheme)
      || this._baseTheme;

    return (
      <ThemeContext.Provider
        value={{
          theme: currentTheme,
          setTheme: (changes: Partial<ContextTheme>) => {
            UserService.instance.currentClientOrPartner.shellTheme = merge(
              {},
              UserService.instance.currentClientOrPartner.shellTheme || this._baseTheme,
              changes);
            this.forceUpdate();
            return UserService.instance.currentClientOrPartner.shellTheme;
          }
        }}
      >
        <LocaleProvider locale={enUSLocale}>
          <ThemeProvider theme={theme} themeContext={currentTheme}>
            <Router history={this._browserHistory}>
              <Switch>
                {/* Public entry points */}
                <Route path="/please-wait" component={LoadingAnimation}/>
                <Route path="/demo/:locationISO?" component={Demo}/>
                {/*<Route path="/covid-19-demo/:locationISO?" component={CovidDemo}/>*/}
                <Route path="/indicator-demo/:locationISO?" component={IndicatorDemo}/>
                {asyncRoute(
                  '/s/:token',
                  () => import('./pages/ShortLinkPage/ShortLinkPage'))
                }
                {asyncRoute(
                  '/request-a-quote/:license/:headcount',
                  () => import('./pages/RequestQuote/RequestQuote'))
                }
                {asyncRoute(
                  '/products/:currency/:productType?/:hideCalculation?',
                  () => import('./pages/ProductsCalculations/ProductsCalculation'))
                }
                {asyncRoute(
                  '/products-confirm',
                  () => import('../partner-management/pages/ProductConfirm/ProductConfirm'))
                }
                {asyncRoute(
                  '/register-new-client',
                  () => import('./pages/RegisterNewClient/RegisterNewClient'))
                }
                {asyncRoute(
                  '/new-client-password-reset/:token',
                  () =>
                    import('../partner-management/pages/RegisterNewClientPasswordReset/RegisterNewClientPasswordReset'))
                }
                {asyncRoute(
                  '/reset-password',
                  () => import('./pages/ResetPasswordPage/RequestPasswordReset'))
                }
                {asyncRoute(
                  '/reset-unregistered-user/:token',
                  () => import('./pages/PasswordResetUnregisteredUser/PasswordResetUnregisteredUser'))
                }
                {asyncRoute(
                  '/change-password/:token',
                  () => import('./pages/ChangePasswordPage/ChangePasswordPage'))
                }
                {asyncRoute(
                  '/new-user-password-reset/:token',
                  () => import('./pages/NewUserPasswordReset/NewUserPasswordReset'))
                }
                {asyncRoute(
                  '/preview-questionnaire/:clientId/:questionnaireId/:languageCode?/:region?/:questionnaireCode?',
                  () => import('../questionnaire/pages/QuestionnairePage/QuestionnairePage'))
                }
                {asyncRoute(
                  '/questionnaire/:surveyRecipientId/:linkType?/:languageCode?',
                  () => import('../questionnaire/pages/QuestionnairePage/QuestionnairePage'))
                }
                {asyncRoute(
                  '/preview-report/:questionnaireId/:languageCode',
                  () => import('../questionnaire/pages/PersonalReport/PersonalReport'))
                }
                {asyncRoute(
                  '/report/:surveyRecipientId/:languageCode/:linkType?',
                  () => import('../questionnaire/pages/PersonalReport/PersonalReport'))
                }
                {asyncRoute(
                  '/register/:surveyRecipientId/:languageCode/:datasetId?',
                  () => import('../questionnaire/pages/RegisterPage/RegisterPage'))
                }
                {asyncRoute(
                  '/client-setup-email-confirmation/:token?',
                  () => import('../partner-management/pages/QuickRegisterEmailConfirm/QuickRegisterEmailConfirm'))
                }
                {asyncRoute(
                  '/select-payment-method/:userType/:codeOrEmail',
                  () => import('../partner-management/pages/SelectPaymentMethod/SelectPaymentMethod'))
                }
                {asyncRoute(
                  '/survey-progress/:accessToken',
                  () => import('../engage-survey/pages/SurveyProgressPage/SurveyProgressPage'))
                }
                {asyncRoute(
                  '/:partnerCode/indicator/:accessToken?',
                  () => import('../engage-survey/pages/IndicatorSurveyPage/IndicatorLandingPage'))
                }
                {asyncRoute(
                  '/indicator/:accessToken?',
                  () => import('../engage-survey/pages/IndicatorSurveyPage/IndicatorLandingPage'))
                }
                {/*{asyncRoute(*/}
                {/*  '/covid-19/:accessToken?',*/}
                {/*  () => import('../engage-survey/pages/IndicatorSurveyPage/SurveyWizard'))*/}
                {/*}*/}
                {asyncRoute(
                  '/engagement-survey/:accessToken?',
                  () => import('../engage-survey/pages/IndicatorSurveyPage/EngagementSurveyLandingPage'))
                }
                {/*{asyncRoute(*/}
                {/*  '/covid-19-surveys/:surveyCode/:ClientId',*/}
                {/*  () => import('../engage-survey/pages/IndicatorSurveyPage/IndicatorInternalSurveyPage'))*/}
                {/*}*/}
                {asyncRoute(
                  '/register-new-client',
                  () => import('./pages/RegisterNewClient/RegisterNewClient'))
                }
                {asyncRoute(
                  '/rater-nomination/:accessToken',
                  () => import('./../engage-survey/pages/RaterNominationPage/RaterNominationPage'))
                }
                <Route
                  render={() => {
                    return !UserService.instance.isSignedIn
                      ? <Route
                        render={(routeInfo) => <LoginPage {...routeInfo}/>}
                      />
                      : <Switch>
                        {/* Protected entry points */}
                        <Route
                          render={({history}) => {
                            return (
                              <MercuryEndpoint>
                                <ReportStore>
                                  <Shell history={history} location={history.location}/>
                                </ReportStore>
                              </MercuryEndpoint>
                            );
                          }}
                        />
                      </Switch>;
                  }}
                />
              </Switch>
            </Router>
          </ThemeProvider>
        </LocaleProvider>
      </ThemeContext.Provider>
    );
  }
}
