import * as React from 'react';
import Form from 'antd/es/form/Form';
import styles from './FormItem.module.css';
import {TwoWayBinding} from '../../models/TwoWayBinding';
import {ReactChild, ReactElement} from 'react';
import {isEmptyOrWhiteSpace} from '../../helpers/StateHelpers';
import {CSSProperties} from 'react';
import RenderHelpers from '../../../shared-ui/helpers/RenderHelpers';
import {isNil} from 'lodash-es';
import If from '../../../shared-ui/components/If/If';

interface FormItemProps {
  style?: CSSProperties;
  className?: string;
  label?: React.ReactNode | string;
  controlWidth?: number;
  formControlWidth?: number;
  formLayout?: 'horizontal' | 'inline' | 'vertical' | 'toolbox';
  isHidden?: boolean;
  hideLoadingAnimations?: boolean;
  suffix?: React.ReactNode;
  suffixWidth?: number;
  required?: boolean;
}

type ValidationStatus = 'none' | 'validating' | 'error';

interface ChildWithValidationStatus {
  child: ReactChild;
  validationStatus: ValidationStatus;
  binding?: TwoWayBinding;
}

export default class FormItem extends React.Component<FormItemProps, {}> {
  private readonly SUFFIX_MARGIN_PIXELS = 5;

  componentWillUnmount() {
    let childWithBinding = this.getChildWithValidationStatus();
    if (childWithBinding.binding) {
      childWithBinding.binding.clearValidation();
    }
  }

  render() {
    if (this.props.isHidden) {
      return null;
    }

    const childWithValidation = this.getChildWithValidationStatus();
    let validationStatus: 'success' | 'warning' | 'error' | 'validating' | undefined = undefined;
    if (this.props.hideLoadingAnimations) {
      validationStatus = undefined;
    } else {
      if (childWithValidation.validationStatus === 'validating') {
        validationStatus = 'validating';
      } else if (childWithValidation.validationStatus === 'error') {
        validationStatus = 'error';
      }
    }

    let suffixWidth = this.props.suffix ? this.props.suffixWidth || 32 : 0;

    let controlWidth = this.props.controlWidth || this.props.formControlWidth || undefined;
    if (controlWidth && this.props.suffix) {
      controlWidth -= suffixWidth + this.SUFFIX_MARGIN_PIXELS;
    }

    return (
      <Form.Item
        className={
          RenderHelpers.classes(
            styles.component,
            this.props.className,
            this.props.formLayout === 'toolbox' ? styles.toolbox : null,
            isEmptyOrWhiteSpace(this.props.label as string) ? styles.noLabel : null
          )}
        style={this.props.style}
        label={this.props.label}
        colon={false}
        validateStatus={validationStatus}
        required={this.props.required}
      >
        {
          React.isValidElement(childWithValidation.child)
            ? React.cloneElement(childWithValidation.child, {style: {width: controlWidth}} as any)
            : <div style={{width: controlWidth}}>
              {childWithValidation.child}
            </div>
        }
        <If condition={!isNil(this.props.suffix)}>
          <span
            className={styles.suffix}
            style={{width: suffixWidth}}
          >
              {this.props.suffix}
          </span>
        </If>
      </Form.Item>
    );
  }

  private getChildWithValidationStatus = (): ChildWithValidationStatus => {
    const child = React.Children.only(this.props.children as ReactElement);

    let status: ValidationStatus = 'none';
    let binding: TwoWayBinding | undefined = undefined;

    if (child.props) {
      binding = child.props.binding;

      if (binding) {
        if (binding.isValidating) {
          status = 'validating';
        } else if (!binding.isValid) {
          status = 'error';
        }
      } else {
        switch (child.props['data-validation']) {
          case 'error':
            status = 'error';
            break;
          case 'validating':
            status = 'validating';
            break;
          default:
            break;
        }
      }
    }

    return {
      child: child,
      binding: binding,
      validationStatus: status
    };
  }
}
