import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Window from '../Window/Window';
import {Button, Icon} from 'antd';
import styles from './ConfirmationDialog.module.css';
import {classes} from '../../helpers/RenderHelpers';
import EqualSizeGroup from '../EqualSizeGroup/EqualSizeGroup';
import {ReactElement} from 'react';
import SingletonDialogService from '../../services/SingletonDialogService';
import {isEmptyOrWhiteSpace} from '../../helpers/StateHelpers';

export enum ConfirmationType {
  Success,
  Question,
  Exclamation
}

type ConfirmationDialogContentFunc = (closeCallback: () => void) => ReactElement<any>;

export interface ConfirmationDialogOptions {
  title: string;
  content: string | React.ReactNode | ConfirmationDialogContentFunc;
  maxWidth?: number;
  type?: ConfirmationType;
  className?: string;
  okText?: string | React.ReactNode;
  okIcon?: string | React.ReactNode;
  showCancel?: boolean;
  cancelText?: string |  React.ReactNode;
  singletonDialogGroupCode?: string;
}

export default class ConfirmationDialog {
  private static DEFAULT_MAX_WIDTH_PIXELS = 520;

  public static show = async (options: ConfirmationDialogOptions): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {

      const deregister = () => {
        if (options.singletonDialogGroupCode) {
          SingletonDialogService.deregisterAnonymousDialogs(options.singletonDialogGroupCode);
        }
      };

      const onOk = () => {
        ConfirmationDialog.render(mountingDiv, onOk, onCancel, options, true);
        deregister();
        resolve(true);
      };

      const onCancel = () => {
        ConfirmationDialog.render(mountingDiv, onOk, onCancel, options, true);
        deregister();
        resolve(false);
      };

      if (options.singletonDialogGroupCode) {
        SingletonDialogService.registerAnonymousDialog(options.singletonDialogGroupCode, onCancel);
      }

      const mountingDiv = document.createElement('div');
      document.body.appendChild(mountingDiv);
      ConfirmationDialog.render(mountingDiv, onOk, onCancel, options);
    });
  };

  private static render = (mountingDiv: HTMLDivElement,
                           onOk: () => void,
                           onCancel: () => void,
                           options: ConfirmationDialogOptions,
                           isClosing?: boolean) => {
    ReactDOM.render(
      ConfirmationDialog.createWindow(mountingDiv, onOk, onCancel, options, isClosing),
      mountingDiv
    );
  };

  private static createWindow = (mountingDiv: HTMLDivElement,
                                 onOk: () => void,
                                 onCancel: () => void,
                                 options: ConfirmationDialogOptions,
                                 isClosing?: boolean) => {

    let buttons = [
      <Button
        key="1"
        onClick={onOk}
        type={options.type === ConfirmationType.Exclamation ? 'danger' : 'primary'}
        icon={
          (!options.okIcon || typeof options.okIcon === 'string')
            ? options.okIcon as string || 'check-circle-o'
            : undefined
        }
      >
        {typeof options.okIcon !== 'string' ? options.okIcon : null}
        {options.okText || 'OK'}
      </Button>
    ];

    if (options.showCancel !== false && options.type !== ConfirmationType.Success) {
      buttons.unshift(<Button
        key="0"
        onClick={onCancel}
        icon={'close-circle-o'}
      >
        {options.cancelText && !isEmptyOrWhiteSpace(options.cancelText) ? options.cancelText : 'Cancel'}
      </Button>);
    }

    return (<Window
      title={null}
      footer={<EqualSizeGroup gapWidth={10}>{buttons}</EqualSizeGroup>}
      onOk={onOk}
      onCancel={onCancel}
      visible={!isClosing}
      width={options.maxWidth || ConfirmationDialog.DEFAULT_MAX_WIDTH_PIXELS}
      className={classes(styles.window, options.className)}
      afterClosed={() => {
        const wasUnmounted = ReactDOM.unmountComponentAtNode(mountingDiv);
        if (wasUnmounted && mountingDiv.parentNode) {
          mountingDiv.parentNode.removeChild(mountingDiv);
        }
      }}
    >
      <div className={styles.heading}>
        <Icon
          className={classes(
            styles.headingIcon,
            options.type === ConfirmationType.Exclamation ? styles.exclamation : null,
            options.type === ConfirmationType.Success ? styles.success : null
          )}
          type={
            options.type === ConfirmationType.Exclamation
              ? 'exclamation-circle-o'
              : options.type === ConfirmationType.Success ? 'check-circle-o' : 'question-circle-o'
          }
        />
        <span className={styles.headingText}>{options.title}</span>
      </div>
      <div className={styles.content}>
        {ConfirmationDialog.getContent(options, onOk)}
      </div>
    </Window>);
  };

  private static getContent = (options: ConfirmationDialogOptions, closeCallback: () => void): React.ReactNode => {
    if (typeof options.content === 'function') {
      return (options.content as ConfirmationDialogContentFunc)(closeCallback);
    } else {
      return options.content;
    }
  };
}
