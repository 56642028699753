import * as React from 'react';
import {PropsWithChildren, useState} from 'react';
import DataService from '../../shared/services/DataService';
import UserService from '../../shared/services/UserService';
import CodeHelpers from '../helpers/CodeHelpers';
import {ProductLine} from '../types/ProductLine';
import {DatasetCache} from '../context/DatasetCache';
import {useDatasetStore} from '../hooks/useDatasetStore';
import {DatasetData} from '../types/DatasetData';

export interface CampaignStoreProps {
  productLine: ProductLine;
}

export const CampaignStore = (props: PropsWithChildren<CampaignStoreProps>) => {
  const [isProcessing, setIsProcessing] = useState<boolean>();

  const {
    getDatasets,
    clearCache,
    isLoaded
  } = useDatasetStore(
    async (surveyModelId: string) => {
      setIsProcessing(true);
      try {
        return await DataService.tryLoad<DatasetData>(
          '/master-data/get-datasets',
          {
            organisationId: UserService.instance.organisationId,
            productLineCode: CodeHelpers.codeForProductLine(props.productLine),
            surveyModelId: surveyModelId
          }
        );
      } finally {
        setIsProcessing(false);
      }
    },
    [UserService.instance.organisationId, props.productLine]
  );
  return (
    <DatasetCache.Provider
      value={{
        getDatasets: getDatasets,
        clearCache,
        isLoaded,
        isDatasetCacheProcessing: isProcessing
      }}
    >
      {props.children}
    </DatasetCache.Provider>
  );
};
