import {useCache} from './useCache';
import {filter, first, isNil} from 'lodash-es';
import {DependencyList, useEffect} from 'react';
import {DemographicData} from '../types/DemographicData';
import {DemographicAttribute} from '../types/DemographicAttribute';
import {Demographic} from '../types/Demograhic';
import {DemographicCategory} from '../types/DemograhicCategory';

export function useDemographicsStore(loadDemographicsCallback: (languageCode: string) => Promise<DemographicData>,
                                     deps: DependencyList = []) {
  // Cache the data for all accessible survey models, indexed by language code
  const {getValue: getDemographicData, clearCache} = useCache<string, DemographicData>(loadDemographicsCallback);

  // Reload the cache if any dependencies have changed
  useEffect(
    () => {
      clearCache();
    },
    deps
  );

  const getDemographics = (languageCode: string): Demographic[] => {
    const data = getDemographicData(languageCode);
    return isNil(data) ? [] : data.demographics;
  };

  const getDemographicCategories = (languageCode: string): DemographicCategory[] => {
    const data = getDemographicData(languageCode);
    return isNil(data) ? [] : filter(data.demographics, d => !d.isField).map (category => {
    return {clientId : category.clientId,
      id: category.id,
      code: category.code,
      displayText: category.displayText,
      questionText: category.questionText,
      isMandatory: category.isMandatory,
      displayOrder: category.displayOrder,
      languageCode: category.languageCode} as DemographicCategory; });
  };

  const getDemographicAttributes = (languageCode: string): DemographicAttribute[] => {
    const data = getDemographicData(languageCode);
    return isNil(data) ? [] : data.demographicAttributes;
  };

  const findDemographicByCode = (categoryCode: string,
                                 languageCode: string):  Demographic => {
    const data = getDemographicData(languageCode);
    if (isNil(data)) {
      return undefined;
    }

    return first(filter(data.demographics, c => c.code === categoryCode));
  };

  const findCategoryByCode = (categoryCode: string,
                              languageCode: string):  DemographicCategory => {
    const data = getDemographicData(languageCode);
    if (isNil(data)) {
      return undefined;
    }

    let demographicCategories = data.demographics.map (category => {
      return {clientId : category.clientId,
        id: category.id,
        code: category.code,
        displayText: category.displayText,
        questionText: category.questionText,
        isMandatory: category.isMandatory,
        displayOrder: category.displayOrder,
        languageCode: category.languageCode} as DemographicCategory; });

    return first(filter(demographicCategories, c => c.code === categoryCode));
  };

  const findAttributeByCode = (attributeCode: string,
                               languageCode: string):  DemographicAttribute => {
    const data = getDemographicData(languageCode);
    if (isNil(data)) {
      return undefined;
    }

    return first(filter(data.demographicAttributes, a => a.code === attributeCode));
  };

  const findAttributesCategoryId = (categoryId: string,
                                    languageCode: string):  DemographicAttribute[] => {
    const data = getDemographicData(languageCode);
    if (isNil(data)) {
      return undefined;
    }

    return filter(data.demographicAttributes, a => a.categoryId === categoryId);
  };

  return {
    getDemographics,
    getDemographicCategories,
    getDemographicAttributes,
    findDemographicByCode,
    findCategoryByCode,
    findAttributeByCode,
    findAttributesCategoryId,
    clearCache
  };
}
