import moment, {Moment, MomentInput} from 'moment';
import momentTimezone from 'moment-timezone';
import {isNil} from 'lodash-es';
import {isEmptyOrWhiteSpace} from './StateHelpers';

export function asMoment(date: MomentInput, format?: string): Moment | undefined {
  if (!date) {
    return undefined;
  }

  if (isEmptyOrWhiteSpace(format)) {
    return moment(date);
  } else {
    return moment(date, format);
  }
}

export function unixAsMoment(unixTimestamp: number): Moment {
  return moment.unix(unixTimestamp);
}

export function localTime(hour: number, minute: number = 0, seconds: number = 0) {
  return moment().hours(hour).minutes(minute).seconds(seconds).milliseconds(0);
}

export function cloneMoment(value: Moment): Moment {
  return moment(value);
}

export function cloneMomentToTheDay(value: Moment): Moment {
  return moment(value).hours(0).minutes(0).seconds(0).milliseconds(0);
}

export function cloneMomentToTheHour(value: Moment): Moment {
  return moment(value).minutes(0).seconds(0).milliseconds(0);
}

export function cloneMomentToTheMinute(value: Moment): Moment {
  return moment(value).seconds(0).milliseconds(0);
}

export function cloneMomentToTheSecond(value: Moment): Moment {
  return moment(value).milliseconds(0);
}

export function formatMoment(value: Moment | undefined | null, format: string, defaultValue: string = '') {
  if (!value) {
    return defaultValue;
  }

  return moment(value).format(format);
}

export function guessTimeZone() {
  return momentTimezone.tz.guess();
}

export function areMomentsOnSameDay(a: Moment | undefined | null, b: Moment | undefined | null): boolean {
  if (isNil(a) && isNil(b)) {
    return true;
  }

  if (isNil(a) || isNil(b)) {
    return false;
  }

  return a.isSame(b);
}