import * as React from 'react';
import {Modal, Button} from 'antd';
import {DraggableCore, DraggableEvent} from 'react-draggable';
import styles from './Window.module.css';
import {ReactElement, ReactNode} from 'react';
import Icon from 'antd/es/icon';
import ReactResizeDetector from 'react-resize-detector';
import {isEmptyOrWhiteSpace, safeNav} from '../../helpers/StateHelpers';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import {isNil} from 'lodash-es';
import CircleIcon from '../CircleIcon/CircleIcon';
import FormErrorMessage from '../FormErrorMessage/FormErrorMessage';
import {classes, renderIf} from '../../helpers/RenderHelpers';
import SingletonDialogService from '../../services/SingletonDialogService';

class AbsoluteWindowPosition {
  private readonly _top: number | undefined;
  private readonly _left: number | undefined;
  private readonly _right: number | undefined;

  constructor(top?: number, left?: number, right?: number) {
    this._top = top;
    this._left = left;
    this._right = right;
  }

  public get top(): number | undefined {
    return this._top;
  }

  public get left(): number | undefined {
    return this._left;
  }

  public get right(): number | undefined {
    return this._right;
  }

  public isDetermined() {
    return !isNil(this._top) || !isNil(this._left) || !isNil(this._right);
  }
}

interface WindowSizeInternal {
  width?: number;
  height?: number;
}

export enum WindowButtons {
  Ok,
  Close,
  Cancel,
  OkCancel,
  CancelNext,
  SaveCancel,
  DeleteCancel,
  ArchiveCancel,
  AcceptCancel,
  ImportClose,
  YesCancel
}

export enum WindowMask {
  NoMask,
  Visible,
  Invisible
}

export enum WindowPosition {
  Center,
  BottomLeftOfParent,
  RightOfParent
}

export interface WindowProps {
  className?: string;
  bodyClassName?: string;
  visible: boolean;
  mask?: WindowMask;
  onOk: () => void;
  disableOk?: boolean;
  title: string | ReactElement<any> | undefined | null;
  logo?: ReactElement<any>;
  iconType?: string | ReactElement<any>;
  onCancel?: () => void;
  footer: WindowButtons | ReactElement<any>[] | ReactNode | undefined | null;
  relativeParent?: HTMLElement;
  initialPosition?: WindowPosition;
  allowMoving?: boolean;
  width: number;
  isLoading?: boolean;
  zIndex?: number;
  onOpen?: () => void;
  closeOnMaskClick?: boolean;
  showLoadingOverlay?: boolean;
  afterClosed?: () => void;
  errorMessage?: string;
  hideContentPadding?: boolean;
  singletonDialogGroupCode?: string;
  hideErrorArea?: boolean;
}

interface WindowState {
  top?: number;
  left?: number;
  right?: number;
  dragStartY: number;
  dragStartX: number;
}

export default class Window extends React.Component<WindowProps, WindowState> {
  private readonly MARGIN_FROM_SCREEN_EDGE_PIXELS = 10;

  private _contentContainerRef: HTMLDivElement | null = null;

  constructor(props: WindowProps) {
    super(props);

    this.state = {
      top: undefined,
      left: undefined,
      right: undefined,
      dragStartY: 0,
      dragStartX: 0
    };
  }

  componentWillReceiveProps(props: WindowProps) {
    if (!this.props.visible && props.visible) {
      this.openWindow(props);
    } else if (this.props.visible && !props.visible) {
      // If this window is a singleton, register that this instance is closed
      if (this.props.singletonDialogGroupCode) {
        SingletonDialogService.deregisterDialog(this);
      }
    }
  }

  componentWillMount() {
    window.addEventListener('resize', this.resizeHandler);
  }

  componentDidMount() {
    if (this.props.visible && this.props.onOpen) {
      this.openWindow(this.props);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  render() {
    let titleContents: string | JSX.Element | undefined | null = null;

    if (this.props.logo && typeof this.props.title === 'string') {
      titleContents = <div className={styles.title}>
        <div className={styles.logoWrapper}>{this.props.logo}</div>
        <span>{this.props.title}</span>
      </div>;
    } else if (this.props.iconType) {
      if (typeof this.props.iconType === 'string') {
        titleContents = <div className={styles.title}>
          <CircleIcon className={styles.iconWrapper}>
            <Icon className={styles.icon} type={this.props.iconType as string}/>
          </CircleIcon>
          <span>{this.props.title}</span>
        </div>;
      } else {
        titleContents = <div className={styles.title}>
          <CircleIcon className={styles.iconWrapper}>
            {this.props.iconType}
          </CircleIcon>
          <span>{this.props.title}</span>
        </div>;
      }
    } else if (this.props.title && typeof this.props.title === 'string') {
      titleContents = <span>{this.props.title}</span>;
    } else if (this.props.title) {
      titleContents = this.props.title;
    }

    let footerElement: ReactElement<any>[] | ReactNode | null = null;

    if (this.props.footer === WindowButtons.Ok) {
      footerElement = [
        <Button
          key="0"
          type="primary"
          disabled={this.props.disableOk || this.props.showLoadingOverlay}
          onClick={this.props.onOk}
          icon={'check-circle-o'}
        >
          {'OK'}
        </Button>
      ];
    } else if (this.props.footer === WindowButtons.Close) {
      footerElement = [
        <Button key="0" type="primary" onClick={this.props.onOk} icon="close-circle-o">{'Close'}</Button>
      ];
    } else if (this.props.footer === WindowButtons.OkCancel) {
      footerElement = [
        <Button key="0" onClick={this.props.onCancel} icon="close-circle-o">{'Cancel'}</Button>,
        <Button
          key="1"
          loading={this.props.isLoading}
          type="primary"
          disabled={this.props.disableOk || this.props.showLoadingOverlay}
          onClick={this.props.onOk}
          icon={'check-circle-o'}
        >
          {'OK'}
        </Button>
      ];
    } else if (this.props.footer === WindowButtons.AcceptCancel) {
      footerElement = [
        <Button key="0" onClick={this.props.onCancel} icon="close-circle-o">{'Cancel'}</Button>,
        <Button
          key="1"
          loading={this.props.isLoading}
          type="primary"
          disabled={this.props.disableOk || this.props.showLoadingOverlay}
          onClick={this.props.onOk}
          icon={'check-circle-o'}
        >
          {'Accept'}
        </Button>
      ];
    } else if (this.props.footer === WindowButtons.YesCancel) {
      footerElement = [
        <Button key="0" onClick={this.props.onCancel} icon="close-circle-o">{'Cancel'}</Button>,
        <Button
          key="1"
          loading={this.props.isLoading}
          type="primary"
          disabled={this.props.disableOk || this.props.showLoadingOverlay}
          onClick={this.props.onOk}
          icon={'check-circle-o'}
        >
          {'Yes'}
        </Button>
      ];
    } else if (this.props.footer === WindowButtons.ImportClose) {
      footerElement = [
        <Button key="0" onClick={this.props.onCancel} icon="close-circle-o">{'Close'}</Button>,
        <Button
          key="1"
          loading={this.props.isLoading}
          type="primary"
          disabled={this.props.disableOk || this.props.showLoadingOverlay}
          onClick={this.props.onOk}
          icon={'check-circle-o'}
        >
          {'Import'}
        </Button>
      ];
    } else if (this.props.footer === WindowButtons.CancelNext) {
      footerElement = [
        <Button key="0" onClick={this.props.onCancel} icon="close-circle-o">{'Cancel'}</Button>,
        <Button
          key="1"
          loading={this.props.isLoading}
          type="primary"
          disabled={this.props.disableOk || this.props.showLoadingOverlay}
          onClick={this.props.onOk}
          icon={'check-circle-o'}
        >
          {'Next'}
        </Button>
      ];
    } else if (this.props.footer === WindowButtons.SaveCancel) {
      footerElement = [
        <Button key="0" onClick={this.props.onCancel} icon="close-circle-o">{'Cancel'}</Button>,
        <Button
          key="1"
          loading={this.props.isLoading}
          type="primary"
          disabled={this.props.disableOk || this.props.showLoadingOverlay}
          onClick={this.props.onOk}
          icon={'save'}
        >
          {'Save'}
        </Button>
      ];
    } else if (this.props.footer === WindowButtons.DeleteCancel) {
      footerElement = [
        <Button key="0" onClick={this.props.onCancel} icon="close-circle-o">{'Cancel'}</Button>,
        <Button
          key="1"
          loading={this.props.isLoading}
          type="danger"
          disabled={this.props.disableOk || this.props.showLoadingOverlay}
          onClick={this.props.onOk}
          icon={'delete'}
        >
          {'Delete'}
        </Button>
      ];
    } else if (this.props.footer === WindowButtons.ArchiveCancel) {
      footerElement = [
        <Button key="0" onClick={this.props.onCancel} icon="close-circle-o">{'Cancel'}</Button>,
        <Button
          key="1"
          loading={this.props.isLoading}
          type="danger"
          disabled={this.props.disableOk || this.props.showLoadingOverlay}
          onClick={this.props.onOk}
          icon={'delete'}
        >
          {'Archive'}
        </Button>
      ];
    } else if (this.props.footer === WindowButtons.Cancel) {
      footerElement = [
        <Button key="0" onClick={this.props.onCancel} icon="close-circle-o">{'Cancel'}</Button>
      ];
    } else if (this.props.footer) {
      footerElement = this.props.footer;
    }

    let maskStyle: React.CSSProperties = {pointerEvents: 'auto', background: 'rgba(55, 55, 55, 0.6)'};

    if (this.props.mask === WindowMask.Invisible) {
      maskStyle = {background: 'transparent'};
    }

    // The padded window cannot be wider than the screen
    let windowWidth = this.props.width
      ? Math.min(this.props.width, window.innerWidth - (this.MARGIN_FROM_SCREEN_EDGE_PIXELS * 2))
      : window.innerWidth - (this.MARGIN_FROM_SCREEN_EDGE_PIXELS * 2);

    return (
      <Modal
        className={[
          styles.modal,
          this.props.className
        ].join(' ')}
        style={{
          top: this.state.top,
          left: this.state.left,
          right: this.state.right,
          zIndex: this.props.zIndex || 'auto',
          maxWidth: this.props.width
        }}
        title={titleContents ?
          (<DraggableCore
            disabled={!this.props.allowMoving}
            onStart={(e: DraggableEvent) => this.onDragStart(e as MouseEvent)}
            onDrag={(e: DraggableEvent) => this.onDrag(e as MouseEvent)}
          >
            <div
              className={[
                'draggable-title',
                this.props.allowMoving ? styles.dragEnabled : null
              ].join(' ')}
            >
              {titleContents}
            </div>
          </DraggableCore>) : null}
        visible={this.props.visible}
        maskClosable={this.props.closeOnMaskClick === true}
        destroyOnClose={true}
        onOk={this.okayWindow}
        onCancel={this.cancelWindow}
        closable={false}
        wrapClassName={this.props.allowMoving ? styles.draggableWrap : undefined}
        mask={this.props.mask !== WindowMask.NoMask}
        maskStyle={maskStyle}
        footer={footerElement}
        width={windowWidth}
        afterClose={this.props.afterClosed}
      >
        {this.props.showLoadingOverlay ? <div className={styles.loadingOverlay}><LoadingAnimation/></div> : null}
        <div
          className={classes(
            this.props.bodyClassName,
            styles.contentWrapper,
            this.props.hideContentPadding ? styles.hideContentPadding : null
          )}
          ref={(me: HTMLDivElement) => {
            if (me && !this._contentContainerRef) {
              this._contentContainerRef = me;
              this.updatePosition(this.props);
            } else {
              this._contentContainerRef = me;
            }
          }}
        >
          {this.props.children}
          <ReactResizeDetector
            onResize={() => this.updatePosition(this.props)}
            handleHeight={true}
            handleWidth={true}
          />
        </div>
        {renderIf(
          <div className={styles.errorMessage}>
            {renderIf(
              <FormErrorMessage errorMessage={this.props.errorMessage}/>,
              !isEmptyOrWhiteSpace(this.props.errorMessage))
            }
          </div>,
          !this.props.hideContentPadding && !this.props.hideErrorArea)}
      </Modal>
    );
  }

  private openWindow = (props: WindowProps) => {
    // If this window is a singleton, close any other instances
    if (this.props.singletonDialogGroupCode) {
      SingletonDialogService.registerDialog(
        this,
        this.props.singletonDialogGroupCode,
        this.props.onCancel || this.props.onOk
      );
    }

    this.updatePosition(this.props);
    if (props.onOpen) {
      props.onOpen();
    }
  };

  private okayWindow = () => {
    // If this window is a singleton, register that this instance is closed
    if (this.props.singletonDialogGroupCode) {
      SingletonDialogService.deregisterDialog(this);
    }

    this.props.onOk();
  };

  private cancelWindow = () => {
    // If this window is a singleton, register that this instance is closed
    if (this.props.singletonDialogGroupCode) {
      SingletonDialogService.deregisterDialog(this);
    }

    if (this.props.onCancel) {
      this.props.onCancel();
    } else {
      this.props.onOk();
    }
  };

  private resizeHandler = () => {
    this.updatePosition(this.props);
  };

  private calculateDraggedToPosition = (props: WindowProps): AbsoluteWindowPosition => {
    if (!props.allowMoving || (!this.state.top && !this.state.left)) {
      return new AbsoluteWindowPosition();
    }

    return new AbsoluteWindowPosition(this.state.top, this.state.left);
  };

  private calculatePositionRelativeToParent = (props: WindowProps): AbsoluteWindowPosition => {
    if (!props.relativeParent || (props.initialPosition !== WindowPosition.BottomLeftOfParent
      && props.initialPosition !== WindowPosition.RightOfParent)) {
      return new AbsoluteWindowPosition();
    }

    let parentBounds = safeNav(props.relativeParent, x => x.getBoundingClientRect());

    if (!parentBounds) {
      return new AbsoluteWindowPosition();
    }

    switch (props.initialPosition) {
      case WindowPosition.BottomLeftOfParent:
        return new AbsoluteWindowPosition(parentBounds.bottom + 5, parentBounds.left);
      case WindowPosition.RightOfParent:
        return new AbsoluteWindowPosition(parentBounds.top, parentBounds.right + 5);
      default:
        return new AbsoluteWindowPosition();
    }
  };

  private calculateCenteredPosition = (props: WindowProps,
                                       windowSize: WindowSizeInternal): AbsoluteWindowPosition => {

    if (!isNil(this.props.initialPosition) && this.props.initialPosition !== WindowPosition.Center) {
      return new AbsoluteWindowPosition();
    }

    if (!windowSize.width || !windowSize.height) {
      return new AbsoluteWindowPosition();
    }

    let left: number | undefined;
    let right: number | undefined;
    let top: number | undefined;

    if (window.innerWidth > windowSize.width + (this.MARGIN_FROM_SCREEN_EDGE_PIXELS * 2)) {
      left = (window.innerWidth - windowSize.width) / 2;
      right = left;
    } else {
      left = this.MARGIN_FROM_SCREEN_EDGE_PIXELS;
      right = this.MARGIN_FROM_SCREEN_EDGE_PIXELS;
    }

    if (window.innerHeight > windowSize.height) {
      top = (window.innerHeight - windowSize.height) / 2;
    }

    return new AbsoluteWindowPosition(top, left, right);
  };

  private ensureWindowWithinScreenBounds = (position: AbsoluteWindowPosition,
                                            windowSize: WindowSizeInternal): AbsoluteWindowPosition => {
    if (!windowSize.width || !windowSize.height) {
      return position;
    }

    const minimumX = this.MARGIN_FROM_SCREEN_EDGE_PIXELS;
    const maximumX = window.innerWidth - windowSize.width - this.MARGIN_FROM_SCREEN_EDGE_PIXELS;

    const minimumY = this.MARGIN_FROM_SCREEN_EDGE_PIXELS;
    const maximumY = Math.max(
      this.MARGIN_FROM_SCREEN_EDGE_PIXELS,
      window.innerHeight - windowSize.height - this.MARGIN_FROM_SCREEN_EDGE_PIXELS
    );

    let top: number | undefined = position.top;
    let left: number | undefined = position.left;

    if (!left || left < minimumX) {
      left = minimumX;
    }

    if (left >= maximumX) {
      left = maximumX;
    }

    if (!top || top < minimumY) {
      top = minimumY;
    }

    if (top > maximumY) {
      top = maximumY;
    }

    return new AbsoluteWindowPosition(top, left, position.right);
  };

  private calculateWindowSize = (props: WindowProps): WindowSizeInternal => {
    if (!this._contentContainerRef) {
      return {};
    }

    let modalElement = this._contentContainerRef.closest('.ant-modal');

    if (!modalElement) {
      return {};
    }

    let allowedWidth = Math.min(
      props.width || modalElement.clientWidth,
      window.innerWidth - (this.MARGIN_FROM_SCREEN_EDGE_PIXELS * 2)
    );

    return {
      width: allowedWidth,
      height: modalElement.clientHeight
    };
  };

  private calculatePosition = (props: WindowProps): AbsoluteWindowPosition => {
    let windowSize = this.calculateWindowSize(props);

    let newPosition = this.calculateDraggedToPosition(props);

    if (!newPosition.isDetermined()) {
      newPosition = this.calculatePositionRelativeToParent(props);
    }

    if (!newPosition.isDetermined()) {
      newPosition = this.calculateCenteredPosition(props, windowSize);
    }

    newPosition = this.ensureWindowWithinScreenBounds(newPosition, windowSize);
    return newPosition;
  };

  private updatePosition = (props: WindowProps) => {
    let position = this.calculatePosition(props);

    const deltaTop = Math.abs((this.state.top || 0) - (position.top || 0));
    const deltaLeft = Math.abs((this.state.left || 0) - (position.left || 0));
    const deltaRight = Math.abs((this.state.right || 0) - (position.right || 0));

    if (deltaLeft > 1 || deltaTop > 1 || deltaRight > 1) {
      this.setState({
        top: position.top,
        left: position.left,
        right: position.right
      });
    }
  };

  private onDragStart = (e: MouseEvent) => {
    this.setState({
      dragStartY: e.clientY - (this.state.top || 0),
      dragStartX: e.clientX - (this.state.left || 0)
    });
  };

  private onDrag = (e: MouseEvent) => {
    this.setState(
      {
        top: e.clientY - this.state.dragStartY,
        left: e.clientX - this.state.dragStartX
      },
      () => this.updatePosition(this.props));
  };
}
