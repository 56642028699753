import {ProductLine} from '../types/ProductLine';

export default class ProductLineHelpers {

  public static getUnitAnalyticsPermission(productLine: ProductLine): string | undefined {
    switch (productLine) {
      case ProductLine.Workplace:
        return 'WORKPLACE_ANALYTICS_ACCESS';
      case ProductLine.Assess:
        return 'WORKPLACE_ANALYTICS_ACCESS';
      case ProductLine.Lifecycle:
        return 'LIFECYCLE_ANALYTICS_ACCESS';
      default:
        return undefined;
    }
  }

  public static getOrgAnalyticsPermission(productLine: ProductLine): string | undefined {
    switch (productLine) {
      case ProductLine.Workplace:
        return 'WORKPLACE_ANALYTICS_ACCESS_ORG';
      case ProductLine.Assess:
        return 'WORKPLACE_ANALYTICS_ACCESS_ORG';
      case ProductLine.Lifecycle:
        return 'LIFECYCLE_ANALYTICS_ACCESS_ORG';
      default:
        return undefined;
    }
  }
}
