import { some, compact, isNil } from 'lodash';

export default class RenderHelpers {
  // Builds a composite class name
  public static classes(...classNames: (string | null | undefined)[]): string | undefined {
    const appliedClassNames = compact(classNames);

    if (!some(appliedClassNames)) {
      return undefined;
    }

    return appliedClassNames.join(' ');
  }

  // Inline switch expression
  public static switch<TKey, TValue>(value: TKey, ...cases: { when?: TKey, then: TValue }[]): TValue | undefined {
    for (const c of cases) {
      if (isNil(c.when) || value === c.when) {
        return c.then;
      }
    }

    return undefined;
  }

  // Inline case expression
  public static case<TValue>(...cases: { when?: boolean, then: TValue }[]): TValue | undefined {
    for (const c of cases) {
      if (isNil(c.when) || c.when) {
        return c.then;
      }
    }

    return undefined;
  }
}
