import * as React from 'react';
import {ReactChild} from 'react';

export interface IfProps {
  condition: boolean;
}

export default class If extends React.PureComponent<IfProps> {
  render() {
    if (this.props.condition) {
      const {children, condition, ...otherProps} = this.props;
      return (<>
        {React.Children.map(this.props.children as ReactChild[], (child: ReactChild) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, otherProps);
          } else {
            return child;
          }
        })}
      </>);
    } else {
      return null;
    }
  }
}
