import * as React from 'react';
import {ProductLineFilter} from '../../shell/context/ProductLineFilter';
import {PropsWithChildren, useContext, useEffect, useRef, useState} from 'react';
import {ReportFilters} from '../context/ReportFilters';
import {AnalyticsFilter} from '../types/AnalyticsFilter';
import DemographicCategory from '../../shared/models/DemographicCategory';
import CodeHelpers from '../../shared-common/helpers/CodeHelpers';
import {useLoadedData} from '../../shared-common/hooks/useLoadedData';
import UserService from '../../shared/services/UserService';
import {AnalyticsFilterType} from '../types/AnalyticsFilterType';
import {find, isNil, some} from 'lodash-es';
import {useMessageHandler} from '../../shared-common/hooks/useMessageHandler';
import {CommonMessages} from '../../shared-common/messaging/CommonMessages';
import {DatasetEntity} from '../types/DatasetEntity';
import {SurveyModelEntity} from '../types/SurveyModelEntity';
import {VirtualStructureEntity} from '../types/VirtualStructureEntity';
import CallbackHelpers from '../../shared-common/helpers/CallbackHelpers';
import {useForceUpdate} from '../../shared-common/hooks/useForceUpdate';

interface FilterData {
  organisationId: string;
  cacheDependencyTag: string;
  datasets: DatasetEntity[];
  surveyModels: SurveyModelEntity[];
  virtualStructures: VirtualStructureEntity[];
  demographics: DemographicCategory[];
}

export const ReportFilterStore = (props: PropsWithChildren<{}>) => {
  const {currentProductLine} = useContext(ProductLineFilter);
  const registeredComponentsRef = useRef<React.Component<any, any, any>[]>([]);
  const [
    organisationCacheTag,
    setOrganisationCacheTag
  ] = useState<string>(UserService.instance.currentClientOrPartnerId);
  const [filters, setFilters] = useState<AnalyticsFilter[]>([]);
  const forceUpdate = useForceUpdate();

  const [filterData, isLoading, reloadFilterData] = useLoadedData<FilterData>(
    '/engage-analytics/load-filter-data',
    {
      organisationId: UserService.instance.currentClientOrPartnerId,
      productLineCode: CodeHelpers.codeForProductLine(currentProductLine)
    }
  );

  useMessageHandler(CommonMessages.ResetReportFilters, async () => reloadFilterData());

  useEffect(
    () => {
      if (isLoading) {
        if (some(filters)) {
          setFilters([]);
        }
        updateRegisteredComponents();
        return;
      }

      if (isNil(filterData)) {
        if (some(filters)) {
          setFilters([]);
        }
        return;
      }

      setOrganisationCacheTag(filterData.cacheDependencyTag);

      const createNewFilter = (filterType: AnalyticsFilterType) => {
        const newFilter = new AnalyticsFilter(
          filterType,
          filterData.virtualStructures,
          filterData.surveyModels,
          filterData.datasets,
          () => {
            forceUpdate();
          }
        );

        newFilter.reset(currentProductLine);
        return newFilter;
      };

      setFilters([
        createNewFilter('Analytics'),
        createNewFilter('Pulse'),
        createNewFilter('ComparisonColumn1'),
        createNewFilter('ComparisonColumn2'),
        createNewFilter('ComparisonColumn3'),
        createNewFilter('Covid19'),
        createNewFilter('Indicator'),
        createNewFilter('Insights')
      ]);
    },
    [isLoading, filterData]
  );

  const getFilter = (filterType: AnalyticsFilterType): AnalyticsFilter => {
    const filter = find(filters, f => f.type === filterType);

    if (isNil(filter)) {
      const tempFilter = new AnalyticsFilter(
        filterType,
        [],
        [],
        [],
        CallbackHelpers.NoOp
      );

      tempFilter.reset(currentProductLine);

      return tempFilter;
    }

    return filter;
  };

  const updateRegisteredComponents = () => {
    for (let component of registeredComponentsRef.current) {
      component.forceUpdate();
    }
  };

  return (
    <ReportFilters.Provider
      value={{
        getFilter: getFilter,
        reloadFilterData: reloadFilterData,
        isLoadingFilterData: isLoading,
        organisationCacheDependencyTag: organisationCacheTag
      }}
    >
      {props.children}
    </ReportFilters.Provider>
  );
};
