export default class MobileMenuService {
  public static isOpen: boolean = false;
  public static isMobile: boolean = false;
  public static showAllPages: boolean = false;
  
  public static setIsOpen(open: boolean): void  {
    MobileMenuService.isOpen = open;
  }

  public static isMobileMenuOpen(): boolean  {
    return  MobileMenuService.isOpen;
  }

  public static setMobile(mobile: boolean): void  {
    MobileMenuService.isMobile = mobile;
  }

  public static mobile(open: boolean): boolean  {
    return  MobileMenuService.isMobile;
  }
}