import * as React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import {Children, CSSProperties, ReactElement, ReactNode} from 'react';
import {classes} from '../../helpers/RenderHelpers';
import styles from './EqualSizeGroup.module.css';

export interface EqualSizeGroupProps {
  className?: string;
  style?: CSSProperties;
  direction?: 'horizontal' | 'vertical';
  gapWidth?: number;
}

export default class EqualSizeGroup extends React.Component<EqualSizeGroupProps, {}> {

  private readonly _childSizes: number[] = [];

  constructor(props: EqualSizeGroupProps) {
    super(props);

    this._childSizes = [];
  }

  render() {
    let maxChildSize: number | undefined = Math.max(...this._childSizes);
    if (!maxChildSize || !isFinite(maxChildSize)) {
      maxChildSize = undefined;
    }

    return <div
      className={
        classes(
          styles.component,
          this.props.className,
          this.isHorizontal ? undefined : styles.isVertical
        )
      }
    >
      {Children.map(this.props.children, (child: ReactNode, index: number) =>
        <div
          style={{
            paddingLeft: index > 0 ? (this.props.gapWidth || 0) / 2 : undefined,
            paddingRight: index < Children.count(this.props.children) - 1 ? (this.props.gapWidth || 0) / 2 : undefined
          }}
        >
          {React.cloneElement(child as ReactElement<any>, {
            style: {
              minWidth: this.isHorizontal ? maxChildSize : undefined,
              minHeight: !this.isHorizontal ? maxChildSize : undefined
            }
          })}
          <ReactResizeDetector
            handleWidth={this.isHorizontal}
            handleHeight={!this.isHorizontal}
            onResize={
              (width: number, height: number) => {
                this._childSizes[index] = this.isHorizontal ? width : height;
                this.forceUpdate();
              }
            }
          />
        </div>
      )}
    </div>;
  }

  private get isHorizontal() {
    return this.props.direction !== 'vertical'; // Default is horizontal
  }
}