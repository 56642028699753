import * as React from 'react';
import styles from './PageMenu.module.css';
import {Page} from '../../models/Page';
import {find, trimEnd} from 'lodash-es';
import {History} from 'history';
import RouteInformation from '../../models/RouteInformation';
import {StaticContext} from 'react-router';
import {stripRouteParameters} from '../../helpers/RouteHelpers';
import {ThemeContext, ThemeContextProps} from '../../../app/context/ThemeContext';
import {useContext, useEffect} from 'react';
import {Navigator} from '../../../shell/context/Navigator';

export interface PageMenuProps {
  moduleUrlSlug?: string;
  pages: Page<any>[];
  history?: History;
  staticContext?: StaticContext;
}

export const PageMenu = (props: RouteInformation<{}> & PageMenuProps) => {
  const {navigateTo} = useContext(Navigator);

  useEffect(
    () => {
      const pageUrlSlug: string = stripRouteParameters(props.pages[0].urlSlug);
      let splitURL = props.match.path.split('/');

      // 2 means is just the base url and the top menu selected
      if (splitURL.length === 2) {
        if (splitURL[2] !== pageUrlSlug && props.match.path !== '/') {
          // we then force the first subMenu item to be selected
          props.history.push(props.match.path + '/' + pageUrlSlug);
        }
      }
    },
    []
  );

  const navigate = (activeKey: string) => {
    let page = find(props.pages, (p) => p.urlSlug === activeKey);
    if (page) {
      navigateTo('/' + props.moduleUrlSlug + '/' + stripRouteParameters(page.urlSlug) + '/');
    }
  };

  const renderPageTab = (page: Page<any>, isCurrentPage: boolean, themeContext: ThemeContextProps) => {
    return (
      <div
        key={page.urlSlug}
        className={['pageMenuTab',
          isCurrentPage ? 'active' : ''].join(' ')}
        onClick={() => navigate(page.urlSlug)}
      >
        <div className={styles.icon}> {page.icon}</div>
        <span>
          {page.name.toUpperCase()}
        </span>
      </div>
    );
  };

  // If there are no pages to show, or there is only one default page, don't draw the page menu
  if (!props.pages || props.pages.length === 0 ||
    (props.pages.length === 1 && props.pages[0].isDefault)) {
    return (<div/>);
  }

  let currentPageUrlSlug = stripRouteParameters(props.pages[0].urlSlug); // Setting to default
  if (props.history) {
    if (props.history.location.pathname.split('/').length >= 2) {
      let split = props.history.location.pathname.split('/');
      split.shift();
      split.shift();
      currentPageUrlSlug = split.join('/');
    }
  }

  currentPageUrlSlug = trimEnd(currentPageUrlSlug, '/'); // Remove last slash
  let currentPage = find(props.pages, (page: Page<any>) =>
      currentPageUrlSlug.startsWith(stripRouteParameters(page.urlSlug)))
    || props.pages[0];

  return (
    <div className={styles.pane}>
      <ThemeContext.Consumer>
        {themeContext => (
          <div
            className={styles.tabPane}
            style={{background: themeContext.theme.subMenu.primaryBackgroundColor}}
          >
            {props.pages.map((page: Page<any>) => renderPageTab(page, page === currentPage, themeContext))}
          </div>
        )}
      </ThemeContext.Consumer>
    </div>
  );
};
