import * as React from 'react';
import {Module} from '../shared/models/Module';
import loadAsyncComponent from '../shared/helpers/DynamicLoadingHelpers';
import LicenceExpiredPage from './pages/LicenceExpiredPage/LicenceExpiredPage';
import {ReactComponent as InsightsIcons} from './images/svg/Insight.svg';
import {ReactComponent as TempIcon} from './images/svg/temp-icon.svg';
import {ReactComponent as OrgStructure} from './images/svg/Org-Structure.svg';
import {ReactComponent as OrgStructureVirtual} from './images/svg/Org-Structure-Virtual.svg';
import {ReactComponent as SurveyModelLogo} from './images/svg/Menu-surveymodel.svg';
import {ReactComponent as DefineSurvey} from './images/svg/Menu-definesurvey.svg';
import {ReactComponent as RunSurveyTopMenu} from './images/svg/Menu-runsurvey.svg';
import {ReactComponent as ManageData} from './images/svg/Menu-managedata.svg';
import {ReactComponent as InsightsImprove} from './images/svg/Insight-submenu-do.svg';
import {ReactComponent as InsightsSummary} from './images/svg/Insight-submenu-summary.svg';
import {ReactComponent as InsightsContinue} from './images/svg/Insight-submenu-keepup.svg';
import {ReactComponent as InsightsEdit} from './images/svg/Insight-submenu-editrules.svg';
import {ReactComponent as SurveyModelComms} from './images/svg/Survey-Model-Tab-Comms.svg';
import {ReactComponent as SurveyModelConstructs} from './images/svg/Survey-Model-Tab-Constructs.svg';
import {ReactComponent as SurveyModelDiagnostic} from './images/svg/Survey-Model-Tab-Diagnostic.svg';
import {ReactComponent as SurveyModelPulse} from './images/svg/Survey-Model-Tab-Pulse.svg';
import {ReactComponent as AnalyticsComparison} from './images/svg/analytics/menu-comparison.svg';
import {ReactComponent as AnalyticsComparisonIcon} from './images/svg/analytics/menu-comparison-icon.svg';
import {ReactComponent as AnalyticsHeatmap} from './images/svg/analytics/menu-heatmap.svg';
import {ReactComponent as AnalyticsHeatmapIcon} from './images/svg/analytics/menu-heatmap-icon.svg';
import {ReactComponent as AnalyticsParticipation} from './images/svg/analytics/menu-participation.svg';
import {ReactComponent as AnalyticsParticipationIcon} from './images/svg/analytics/menu-participation-icon.svg';
import {ReactComponent as AnalyticsPulse} from './images/svg/analytics/menu-pulse.svg';
import {ReactComponent as AnalyticsPulseQuestions} from './images/svg/analytics/menu-pulse-questions.svg';
import {ReactComponent as AnalyticsPulseParticipation} from './images/svg/analytics/menu-pulse-participation.svg';
import {ReactComponent as AnalyticsPulseTrends} from './images/svg/analytics/menu-pulse-trend.svg';
import {ReactComponent as AnalyticsQuestions} from './images/svg/analytics/menu-questions-ta.svg';
import {ReactComponent as TextAnalytics} from './images/svg/analytics/Menu-text-analytics.svg';
import {ReactComponent as AnalyticsQuestionsIcon} from './images/svg/analytics/menu-questions-icon.svg';
import {ReactComponent as AnalyticsTextIcon} from './images/svg/analytics/menu-text-icon.svg';
import {ReactComponent as AnalyticsSummary} from './images/svg/analytics/menu-summary.svg';
import {ReactComponent as AnalyticsSummaryIcon} from './images/svg/analytics/menu-summary-icon.svg';
import {ReactComponent as SurveyModelModels} from './images/svg/Survey-Model-Tab-Models.svg';
import {ReactComponent as SurveyModelSettings} from './images/svg/Survey-Model-Tab-Settings.svg';
import {ReactComponent as SurveyModelPublish} from './images/svg/Survey-Model-Tab-Publish.svg';
import {ReactComponent as SurveyModelTemplates} from './images/svg/Survey-Model-Tab-Templates.svg';
import {ReactComponent as SurveyModelBenchmarks} from './images/svg/Survey-Model-Tab-Benchmark.svg';
import {ReactComponent as SurveyModelLanguage} from './images/svg/Survey-Model-Tab-Language.svg';
import {ReactComponent as DefineSurveyDefineEdit} from './images/svg/Define-Survey-Tab-DefineEdit.svg';
import {ReactComponent as DefineSurveyComms} from './images/svg/Define-Survey-Tab-Comms.svg';
import {ReactComponent as DefineSurveyConfigure} from './images/svg/Define-Survey-Tab-Configure.svg';
import {ReactComponent as DefineSurveyParticipants} from './images/svg/Define-Survey-Tab-Participants.svg';
import {ReactComponent as DefineSurveyTestEmails} from './images/svg/Define-Survey-Tab-TestEmails.svg';
import {ReactComponent as SystemAdminLicense} from './images/svg/Menu-Sysadmin-License.svg';
import {ReactComponent as SystemAdminProfile} from './images/svg/Menu-Sysadmin-Profile.svg';
import {ReactComponent as OrgDemographics} from './images/svg/Org-Tab-Demographics.svg';
import {ReactComponent as RunSurvey} from './images/svg/Run-Survey-Tab.svg';
import {ReactComponent as ClientInvoice} from './images/svg/Menu-Client-Invoice.svg';
import {ReactComponent as ClientNew} from './images/svg/Menu-Client-New.svg';
import {ReactComponent as ClientQuote} from './images/svg/Menu-Client-Quote.svg';
import {ReactComponent as PartnerEmployee} from './images/svg/Menu-Partner-Employee.svg';
import {ReactComponent as PartnerNew} from './images/svg/Menu-Partner-New.svg';
import {ReactComponent as PatnerProfile} from './images/svg/Menu-Partner-Profile.svg';
// import {ReactComponent as MenuIndicator} from './images/svg/analytics/menu-indicator.svg';
// import {ReactComponent as MenuIndicatorSummary} from './images/svg/analytics/menu-indicator-icon.svg';
import {ReactComponent as MenuLifecycle} from './images/svg/menu-lifecycle.svg';
import {ReactComponent as LifecycleSettings} from './images/svg/sidepanel-settings.svg';
import {ReactComponent as LifecycleEmployee} from './images/svg/sidepanel-employee2.svg';
import {ReactComponent as LifecycleCandidate} from './images/svg/sidepanel-candidate2.svg';
import {ReactComponent as MenuDataTriggers} from './images/svg/menu-data-triggers.svg';
import {ReactComponent as DataTriggersList} from './images/svg/sidepanel-data-triggers.svg';
import {ReactComponent as DataTriggerRules} from './images/svg/sidepanel-data-rules.svg';
import {ReactComponent as DataTriggerActions} from './images/svg/sidepanel-data-actions.svg';
import {ReactComponent as ReportSurvey} from './images/svg/report-survey.svg';
import {ReactComponent as ReportAssessee} from './images/svg/report-assessee.svg';
import {ReactComponent as ReportAssessor} from './images/svg/report-assessor.svg';

import UserService from '../shared/services/UserService';

// import {getIndicatorModelFingerprint} from '../shared/helpers/SurveyModelFingerprintHelpers';
import {ProductLine} from '../shared-common/types/ProductLine';

function hasPermissions(...permissions: string[]): boolean {
  return UserService.instance.checkPermissionsGrantedForClientOrPartner(permissions);
}

// function hasAnyBUPermission (permission: string): boolean {
//   return UserService.instance.checkPermissionGrantedForAnyUnit(permission);
// }

function isClient(): boolean {
  return !UserService.instance.currentClientOrPartner.isPartner;
}

function isPartner(): boolean {
  return UserService.instance.currentClientOrPartner.isPartner;
}

function hasSurveyReports(): boolean {
  if (UserService.instance.currentClientOrPartner.isPartner) {
    return false;
  }
  return UserService.instance.doesCurrentUserHaveSurveyReports();
}

function hasAssessReports(): boolean {
  if (UserService.instance.currentClientOrPartner.isPartner) {
    return false;
  }
  return UserService.instance.doesCurrentUserHaveAssessReports();
}

function hasManagerReports(): boolean {
  if (UserService.instance.currentClientOrPartner.isPartner) {
    return false;
  }
  return UserService.instance.doesCurrentUserHaveManagerReports();
}

// function doPublishedDatasetsMatchingFingerprintExist(fingerprint: { constructCodes: string[] }) {
//   return UserService.instance.doesCurrentClientHavePublishedDatasetsMatchingFingerprint(fingerprint);
// }

export default function getModulesForProduct(productLine: ProductLine): Module[] {
  return [
    {
      name: 'Engage Personal',
      urlSlug: 'engage-personal',
      showOnMobile: true,
      pages: [
        {
          name: 'Reports',
          urlSlug: 'reports',
          isDefault: true,
          subURL: true,
          content: loadAsyncComponent(() =>
            import('../engage-personal/pages/EngagePersonalLandingPage/EngagePersonalLandingPage')),
          isVisible: () =>
            isClient()
            && hasPermissions('PERSONAL_ACCESS')
            && (hasAssessReports()
              || hasSurveyReports()
              || hasManagerReports())
           && (!hasPermissions('ANALYTICS_INDICATORS_LICENCE')
              || hasPermissions('MANAGE_CLIENTS')),
          licenceRequiredContent: LicenceExpiredPage,
          showLicenceRequired: () => false,
          showOnMobile: true,
          pages: [
            {
              icon: <ReportSurvey/>,
              name: 'Survey reports',
              urlSlug: 'survey-reports',
              content: loadAsyncComponent(() =>
                import('../engage-personal/pages/EngagePersonalReportPage/EngagePersonalReportPage')),
              isVisible: () =>
                isClient()
                && hasPermissions('PERSONAL_ACCESS')
                && hasSurveyReports()
                && (!hasPermissions('ANALYTICS_INDICATORS_LICENCE')
                    || hasPermissions('MANAGE_CLIENTS')),
              licenceRequiredContent: LicenceExpiredPage,
              showLicenceRequired: () => false,
              showOnMobile: true
            },
            {
              icon: <ReportAssessee/>,
              name: 'Self-assessments',
              urlSlug: 'assessments',
              content: loadAsyncComponent(() =>
                import('../engage-personal/pages/EngagePersonalAssessmentPage/EngagePersonalAssessmentPage')),
              isVisible: () =>
                isClient()
                && hasPermissions('PERSONAL_ACCESS')
                && hasAssessReports()
                && (!hasPermissions('ANALYTICS_INDICATORS_LICENCE')
                  || hasPermissions('MANAGE_CLIENTS')),
              licenceRequiredContent: LicenceExpiredPage,
              showLicenceRequired: () => false,
              showOnMobile: true
            },
            {
              icon: <ReportAssessor/>,
              name: 'Assessee reports',
              urlSlug: 'practitioner-manager',
              content: loadAsyncComponent(() =>
                import('../engage-personal/pages/EngagePersonalManagerPage/EngagePersonalManagerPage')),
              isVisible: () =>
                isClient()
                && hasPermissions('PERSONAL_ACCESS')
                && hasManagerReports()
                && (!hasPermissions('ANALYTICS_INDICATORS_LICENCE')
                  || hasPermissions('MANAGE_CLIENTS')),
              licenceRequiredContent: LicenceExpiredPage,
              showLicenceRequired: () => false,
              showOnMobile: true
            }
          ]
        },
      ]
    },
    {
      name: 'Engage Analytics',
      urlSlug: 'engage-analytics',
      showOnMobile: true,
      pages: [
        // {
        //   name: 'INDICATOR',
        //   urlSlug: 'indicator-results',
        //   subURL: true,
        //   icon: <MenuIndicator/>,
        //   content: loadAsyncComponent(() =>
        //     import('../engage-analytics/pages/AnalyticsReportLandingPage/AnalyticsReportLandingPage')),
        //   isVisible: () =>
        //     hasPermissions('INDICATOR_ACCESS', 'ANALYTICS_INDICATORS_LICENCE')
        //     && !hasPermissions('ENGAGEMENT_SURVEY_LICENCE')
        //     && isClient()
        //     && doPublishedDatasetsMatchingFingerprintExist({constructCodes: getIndicatorModelFingerprint()})
        //   ,
        //   showOnMobile: true,
        //   pages: [
        //     {
        //       showOnMobile: true,
        //       icon: <MenuIndicatorSummary/>,
        //       name: 'Summary',
        //       urlSlug: 'summary',
        //       content: loadAsyncComponent(() =>
        //         import('../engage-analytics/pages/AnalyticsIndicatorSummaryPage/AnalyticsIndicatorSummaryPage')),
        //       isVisible: () =>
        //         hasPermissions('INDICATOR_ACCESS', 'ANALYTICS_INDICATORS_LICENCE')
        //         && isClient()
        //         && doPublishedDatasetsMatchingFingerprintExist({constructCodes: getIndicatorModelFingerprint()})
        //     }
        //   ]
        // },
        {
          name: 'Summary',
          urlSlug: 'summary',
          subURL: true,
          icon: <AnalyticsSummary/>,
          content: loadAsyncComponent(() =>
            import('../engage-analytics/pages/AnalyticsReportLandingPage/AnalyticsReportLandingPage')),
          isVisible: () =>
            (((productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                && (hasPermissions('WORKPLACE_ANALYTICS_ACCESS')
                  || hasPermissions('VIRTUAL_STRUCTURE_MANAGER_ANALYTICS_ACCESS')
                  || hasPermissions('ENGAGEMENT_SURVEY_LICENCE')
                ))
              || (productLine === ProductLine.Lifecycle && hasPermissions('LIFECYCLE_ANALYTICS_ACCESS')))
            && (
              (hasPermissions('ANALYTICS_LICENCE') || hasPermissions('ENGAGEMENT_SURVEY_LICENCE'))
              || ((hasPermissions('ANALYTICS_COVID_19_LICENCE')
                || hasPermissions('ANALYTICS_INDICATORS_LICENCE')) === false)
            )
            && isClient(),
          licenceRequiredContent: LicenceExpiredPage,
          showLicenceRequired: () =>
            !hasPermissions('ANALYTICS_LICENCE') && !hasPermissions('ENGAGEMENT_SURVEY_LICENCE'),
          showOnMobile: false,
          pages: [
            {
              isDefault: true,
              icon: <AnalyticsSummaryIcon/>,
              name: 'Summary',
              urlSlug: 'summary',
              content: loadAsyncComponent(() =>
                import('../engage-analytics/pages/AnalyticsSummaryPage/AnalyticsSummaryPage')),
              isVisible: () =>
                (((productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                    && (hasPermissions('WORKPLACE_ANALYTICS_ACCESS')
                      || hasPermissions('VIRTUAL_STRUCTURE_MANAGER_ANALYTICS_ACCESS')
                      || hasPermissions('ENGAGEMENT_SURVEY_LICENCE')
                    ))
                  || (productLine === ProductLine.Lifecycle && hasPermissions('LIFECYCLE_ANALYTICS_ACCESS')))
                && (
                  (hasPermissions('ANALYTICS_LICENCE') || hasPermissions('ENGAGEMENT_SURVEY_LICENCE'))
                  || ((hasPermissions('ANALYTICS_COVID_19_LICENCE')
                    || hasPermissions('ANALYTICS_INDICATORS_LICENCE')) === false)
                )
                && isClient(),
              licenceRequiredContent: LicenceExpiredPage,
              showLicenceRequired: () =>
                !hasPermissions('ANALYTICS_LICENCE') && !hasPermissions('ENGAGEMENT_SURVEY_LICENCE'),
              showOnMobile: false
            }
          ]
        },
        {
          name: 'Heatmap',
          urlSlug: 'heatmap',
          subURL: true,
          icon: <AnalyticsHeatmap/>,
          content: loadAsyncComponent(() =>
            import('../engage-analytics/pages/AnalyticsReportLandingPage/AnalyticsReportLandingPage')),
          isVisible: () =>
            (((productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                && (hasPermissions('WORKPLACE_ANALYTICS_ACCESS') || hasPermissions(
                  'VIRTUAL_STRUCTURE_MANAGER_ANALYTICS_ACCESS')))
              || (productLine === ProductLine.Lifecycle && hasPermissions('LIFECYCLE_ANALYTICS_ACCESS')))
            && (
              hasPermissions('ANALYTICS_LICENCE')
              || ((hasPermissions('ANALYTICS_COVID_19_LICENCE')
                || hasPermissions('ANALYTICS_INDICATORS_LICENCE')
                || hasPermissions('ENGAGEMENT_SURVEY_LICENCE')) === false)
            )
            && isClient(),
          licenceRequiredContent: LicenceExpiredPage,
          showLicenceRequired: () => !hasPermissions('ANALYTICS_LICENCE'),
          showOnMobile: false,
          isDefault: true,
          pages: [
            {
              isDefault: true,
              icon: <AnalyticsHeatmapIcon/>,
              name: 'Heatmap',
              urlSlug: 'heatmap',
              content: loadAsyncComponent(() =>
                import('../engage-analytics/pages/AnalyticsHeatmapPage/AnalyticsHeatmapPage')),
              isVisible: () =>
                (((productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                    && (hasPermissions('WORKPLACE_ANALYTICS_ACCESS') || hasPermissions(
                      'VIRTUAL_STRUCTURE_MANAGER_ANALYTICS_ACCESS')))
                  || (productLine === ProductLine.Lifecycle && hasPermissions('LIFECYCLE_ANALYTICS_ACCESS')))
                && (
                  hasPermissions('ANALYTICS_LICENCE')
                  || ((hasPermissions('ANALYTICS_COVID_19_LICENCE')
                    || hasPermissions('ANALYTICS_INDICATORS_LICENCE')
                    || hasPermissions('ENGAGEMENT_SURVEY_LICENCE')) === false)
                )
                && isClient(),
              licenceRequiredContent: LicenceExpiredPage,
              showLicenceRequired: () => !hasPermissions('ANALYTICS_LICENCE'),
              showOnMobile: false
            }
          ]
        },
        {
          name: productLine === ProductLine.Assess ? 'Questions' : 'Survey Questions',
          urlSlug: 'survey-questions/:constructOrGroup?/:question?',
          subURL: true,
          icon: <AnalyticsQuestions/>,
          content: loadAsyncComponent(() =>
            import('../engage-analytics/pages/AnalyticsReportLandingPage/AnalyticsReportLandingPage')),
          isVisible: () =>
            (((productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                && (hasPermissions('WORKPLACE_ANALYTICS_ACCESS') || hasPermissions(
                  'VIRTUAL_STRUCTURE_MANAGER_ANALYTICS_ACCESS')))
              || (productLine === ProductLine.Lifecycle && hasPermissions('LIFECYCLE_ANALYTICS_ACCESS')))
            && (
              hasPermissions('ANALYTICS_LICENCE')
              || ((hasPermissions('ANALYTICS_COVID_19_LICENCE')
                || hasPermissions('ANALYTICS_INDICATORS_LICENCE')
                || hasPermissions('ENGAGEMENT_SURVEY_LICENCE')) === false)
            )
            && isClient(),
          licenceRequiredContent: LicenceExpiredPage,
          showLicenceRequired: () => !hasPermissions('ANALYTICS_LICENCE'),
          showOnMobile: false,
          pages: [
            {
              isDefault: true,
              icon: <AnalyticsQuestionsIcon/>,
              name: 'Survey questions',
              urlSlug: 'survey-questions',
              content: loadAsyncComponent(() =>
                import('../engage-analytics/pages/AnalyticsSurveyQuestionsPage/AnalyticsSurveyQuestionsPage')),
              isVisible: () =>
                (((productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                    && (hasPermissions('WORKPLACE_ANALYTICS_ACCESS') || hasPermissions(
                      'VIRTUAL_STRUCTURE_MANAGER_ANALYTICS_ACCESS')))
                  || (productLine === ProductLine.Lifecycle && hasPermissions('LIFECYCLE_ANALYTICS_ACCESS')))
                && (
                  hasPermissions('ANALYTICS_LICENCE')
                  || ((hasPermissions('ANALYTICS_COVID_19_LICENCE')
                    || hasPermissions('ANALYTICS_INDICATORS_LICENCE')
                    || hasPermissions('ENGAGEMENT_SURVEY_LICENCE')) === false)
                )
                && isClient(),
              licenceRequiredContent: LicenceExpiredPage,
              showLicenceRequired: () => !hasPermissions('ANALYTICS_LICENCE'),
              showOnMobile: false
            }
          ]
        },
        {
          name: 'Text Analytics',
          urlSlug: 'text-analytics/:constructOrGroup?/:question?',
          subURL: true,
          icon: <TextAnalytics/>,
          content: loadAsyncComponent(() =>
            import('../engage-analytics/pages/AnalyticsReportLandingPage/AnalyticsReportLandingPage')),
          isVisible: () =>
            (((productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                && (hasPermissions('WORKPLACE_ANALYTICS_ACCESS') || hasPermissions(
                  'VIRTUAL_STRUCTURE_MANAGER_ANALYTICS_ACCESS')))
              || (productLine === ProductLine.Lifecycle && hasPermissions('LIFECYCLE_ANALYTICS_ACCESS')))
            && (
              hasPermissions('ANALYTICS_LICENCE')
              || ((hasPermissions('ANALYTICS_COVID_19_LICENCE')
                || hasPermissions('ANALYTICS_INDICATORS_LICENCE')
                || hasPermissions('ENGAGEMENT_SURVEY_LICENCE')) === false)
            )
            && isClient(),
          licenceRequiredContent: LicenceExpiredPage,
          showLicenceRequired: () => !hasPermissions('ANALYTICS_LICENCE'),
          showOnMobile: false,
          pages: [
            {
              isDefault: true,
              icon: <AnalyticsTextIcon/>,
              name: 'Text analytics',
              urlSlug: 'text-analytics',
              content: loadAsyncComponent(() =>
                import('../engage-analytics/pages/AnalyticsTextPage/AnalyticsTextPage')),
              isVisible: () =>
                (((productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                    && (hasPermissions('WORKPLACE_ANALYTICS_ACCESS') || hasPermissions(
              'VIRTUAL_STRUCTURE_MANAGER_ANALYTICS_ACCESS')))
                  || (productLine === ProductLine.Lifecycle && hasPermissions('LIFECYCLE_ANALYTICS_ACCESS')))
                && (
                  hasPermissions('ANALYTICS_LICENCE')
                  || ((hasPermissions('ANALYTICS_COVID_19_LICENCE')
                    || hasPermissions('ANALYTICS_INDICATORS_LICENCE')
                    || hasPermissions('ENGAGEMENT_SURVEY_LICENCE')) === false)
                )
                && isClient(),
              licenceRequiredContent: LicenceExpiredPage,
              showLicenceRequired: () => !hasPermissions('ANALYTICS_LICENCE'),
              showOnMobile: false
            }
          ]
        },
        {
          name: 'Comparisons',
          urlSlug: 'comparisons',
          subURL: true,
          icon: <AnalyticsComparison/>,
          content: loadAsyncComponent(() =>
            import('../engage-analytics/pages/AnalyticsReportLandingPage/AnalyticsReportLandingPage')),
          isVisible: () =>
            (((productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                && (hasPermissions('WORKPLACE_ANALYTICS_ACCESS') || hasPermissions(
                  'VIRTUAL_STRUCTURE_MANAGER_ANALYTICS_ACCESS')))
              || (productLine === ProductLine.Lifecycle && hasPermissions('LIFECYCLE_ANALYTICS_ACCESS')))
            && (
              hasPermissions('ANALYTICS_LICENCE')
              || ((hasPermissions('ANALYTICS_COVID_19_LICENCE')
                || hasPermissions('ANALYTICS_INDICATORS_LICENCE')
                || hasPermissions('ENGAGEMENT_SURVEY_LICENCE')) === false)
            )
            && isClient(),
          licenceRequiredContent: LicenceExpiredPage,
          showLicenceRequired: () => !hasPermissions('ANALYTICS_LICENCE'),
          showOnMobile: false,
          pages: [
            {
              isDefault: true,
              icon: <AnalyticsComparisonIcon/>,
              name: 'Comparisons',
              urlSlug: 'comparisons',
              content: loadAsyncComponent(() =>
                import('../engage-analytics/pages/AnalyticsComparisonsPage/AnalyticsComparisonsPage')),
              isVisible: () =>
                (((productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                    && (hasPermissions('WORKPLACE_ANALYTICS_ACCESS') || hasPermissions(
                      'VIRTUAL_STRUCTURE_MANAGER_ANALYTICS_ACCESS')))
                  || (productLine === ProductLine.Lifecycle && hasPermissions('LIFECYCLE_ANALYTICS_ACCESS')))
                && (
                  hasPermissions('ANALYTICS_LICENCE')
                  || ((hasPermissions('ANALYTICS_COVID_19_LICENCE')
                    || hasPermissions('ANALYTICS_INDICATORS_LICENCE')
                    || hasPermissions('ENGAGEMENT_SURVEY_LICENCE')) === false)
                )
                && isClient(),
              licenceRequiredContent: LicenceExpiredPage,
              showLicenceRequired: () => !hasPermissions('ANALYTICS_LICENCE'),
              showOnMobile: false
            }
          ]
        },
        {
          name: 'Participation',
          urlSlug: 'participation',
          subURL: true,
          icon: <AnalyticsParticipation/>,
          content: loadAsyncComponent(() =>
            import('../engage-analytics/pages/AnalyticsReportLandingPage/AnalyticsReportLandingPage')),
          isVisible: () =>
            (((productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                && (hasPermissions('WORKPLACE_ANALYTICS_ACCESS') || hasPermissions(
                  'VIRTUAL_STRUCTURE_MANAGER_ANALYTICS_ACCESS')))
              || (productLine === ProductLine.Lifecycle && hasPermissions('LIFECYCLE_ANALYTICS_ACCESS')))
            && (
              hasPermissions('ANALYTICS_LICENCE')
              || ((hasPermissions('ANALYTICS_COVID_19_LICENCE')
                || hasPermissions('ANALYTICS_INDICATORS_LICENCE')
                || hasPermissions('ENGAGEMENT_SURVEY_LICENCE')) === false)
            )
            && isClient(),
          licenceRequiredContent: LicenceExpiredPage,
          showLicenceRequired: () => !hasPermissions('ANALYTICS_LICENCE'),
          showOnMobile: false,
          pages: [
            {
              isDefault: true,
              icon: <AnalyticsParticipationIcon/>,
              name: 'Participation',
              urlSlug: 'participation',
              content: loadAsyncComponent(() =>
                import('../engage-analytics/pages/AnalyticsParticipationPage/AnalyticsParticipationPage')),
              isVisible: () =>
                (((productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                    && (hasPermissions('WORKPLACE_ANALYTICS_ACCESS') || hasPermissions(
                      'VIRTUAL_STRUCTURE_MANAGER_ANALYTICS_ACCESS')))
                  || (productLine === ProductLine.Lifecycle && hasPermissions('LIFECYCLE_ANALYTICS_ACCESS')))
                && (
                  hasPermissions('ANALYTICS_LICENCE')
                  || ((hasPermissions('ANALYTICS_COVID_19_LICENCE')
                    || hasPermissions('ANALYTICS_INDICATORS_LICENCE')
                    || hasPermissions('ENGAGEMENT_SURVEY_LICENCE')) === false)
                )
                && isClient(),
              licenceRequiredContent: LicenceExpiredPage,
              showLicenceRequired: () => !hasPermissions('ANALYTICS_LICENCE'),
              showOnMobile: false
            }
          ]
        },
        {
          name: 'Pulses & Trends',
          urlSlug: 'pulses-trends',
          subURL: true,
          icon: <AnalyticsPulse/>,
          content: loadAsyncComponent(() =>
            import('../engage-analytics/pages/AnalyticsReportLandingPage/AnalyticsReportLandingPage')),
          isVisible: () =>
            (((productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                && (hasPermissions('WORKPLACE_ANALYTICS_ACCESS') || hasPermissions(
                  'VIRTUAL_STRUCTURE_MANAGER_ANALYTICS_ACCESS')))
              || (productLine === ProductLine.Lifecycle && hasPermissions('LIFECYCLE_ANALYTICS_ACCESS')))
            && (
              hasPermissions('ANALYTICS_LICENCE')
              || ((hasPermissions('ANALYTICS_COVID_19_LICENCE')
                || hasPermissions('ANALYTICS_INDICATORS_LICENCE')
                || hasPermissions('ENGAGEMENT_SURVEY_LICENCE')) === false)
            )
            && isClient(),
          licenceRequiredContent: LicenceExpiredPage,
          showLicenceRequired: () => !hasPermissions('ANALYTICS_LICENCE'),
          showOnMobile: false,
          pages: [
            {
              icon: <AnalyticsPulseTrends/>,
              name: 'Trends',
              urlSlug: 'trends',
              content: loadAsyncComponent(() =>
                import('../engage-analytics/pages/AnalyticsPulseTrendsPage/AnalyticsPulseTrendsPage')),
              isVisible: () =>
                (((productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                    && (hasPermissions('WORKPLACE_ANALYTICS_ACCESS') || hasPermissions(
                      'VIRTUAL_STRUCTURE_MANAGER_ANALYTICS_ACCESS')))
                  || (productLine === ProductLine.Lifecycle && hasPermissions('LIFECYCLE_ANALYTICS_ACCESS')))
                && (
                  hasPermissions('ANALYTICS_LICENCE')
                  || ((hasPermissions('ANALYTICS_COVID_19_LICENCE')
                    || hasPermissions('ANALYTICS_INDICATORS_LICENCE')
                    || hasPermissions('ENGAGEMENT_SURVEY_LICENCE')) === false)
                )
                && isClient(),
              licenceRequiredContent: LicenceExpiredPage,
              showLicenceRequired: () => !hasPermissions('ANALYTICS_LICENCE'),
              showOnMobile: false
            },
            {
              icon: <AnalyticsPulseQuestions/>,
              name: 'Pulse questions',
              urlSlug: 'questions',
              content: loadAsyncComponent(() =>
                import('../engage-analytics/pages/AnalyticsPulseQuestionsPage/AnalyticsPulseQuestionsPage')),
              isVisible: () =>
                (((productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                    && (hasPermissions('WORKPLACE_ANALYTICS_ACCESS') || hasPermissions(
                      'VIRTUAL_STRUCTURE_MANAGER_ANALYTICS_ACCESS')))
                  || (productLine === ProductLine.Lifecycle && hasPermissions('LIFECYCLE_ANALYTICS_ACCESS')))
                && (
                  hasPermissions('ANALYTICS_LICENCE')
                  || ((hasPermissions('ANALYTICS_COVID_19_LICENCE')
                    || hasPermissions('ANALYTICS_INDICATORS_LICENCE')
                    || hasPermissions('ENGAGEMENT_SURVEY_LICENCE')) === false)
                )
                && isClient(),
              licenceRequiredContent: LicenceExpiredPage,
              showLicenceRequired: () => !hasPermissions('ANALYTICS_LICENCE'),
              showOnMobile: false
            },
            {
              icon: <AnalyticsPulseParticipation/>,
              name: 'Pulse participation',
              urlSlug: 'participation',
              content: loadAsyncComponent(() =>
                import('../engage-analytics/pages/AnalyticsPulseParticipationPage/AnalyticsPulseParticipationPage')),
              isVisible: () =>
                (((productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                    && (hasPermissions('WORKPLACE_ANALYTICS_ACCESS') || hasPermissions(
                      'VIRTUAL_STRUCTURE_MANAGER_ANALYTICS_ACCESS')))
                  || (productLine === ProductLine.Lifecycle && hasPermissions('LIFECYCLE_ANALYTICS_ACCESS')))
                && (
                  hasPermissions('ANALYTICS_LICENCE')
                  || ((hasPermissions('ANALYTICS_COVID_19_LICENCE')
                    || hasPermissions('ANALYTICS_INDICATORS_LICENCE')
                    || hasPermissions('ENGAGEMENT_SURVEY_LICENCE')) === false)
                )
                && isClient(),
              licenceRequiredContent: LicenceExpiredPage,
              showLicenceRequired: () => !hasPermissions('ANALYTICS_LICENCE'),
              showOnMobile: false
            }
          ]
        }
      ]
    },
    {
      name: 'Engage Insight',
      urlSlug: 'engage-insight',
      showOnMobile: true,
      pages: [
        {
          isDefault: true,
          name: 'insight',
          urlSlug: 'insight',
          icon: <InsightsIcons/>,
          subURL: true,
          content: loadAsyncComponent(() =>
            import('../engage-insights2/pages/engage-insights2')),
          isVisible: () =>
            hasPermissions('INSIGHTS_LICENCE')
            && hasPermissions('INSIGHTS_ACCESS')
            && isClient()
          ,
          licenceRequiredContent: LicenceExpiredPage,
          showOnMobile: true,
          showLicenceRequired: () => !hasPermissions('INSIGHTS_LICENCE'),
          pages: [
            {
              icon: <InsightsSummary/>,
              name: 'Summary',
              urlSlug: 'summary',
              content: loadAsyncComponent(() => import('../engage-insights2/pages/Summary/SummaryPage')),
              isVisible: () =>
                isClient()
                && hasPermissions('INSIGHTS_ACCESS') && hasPermissions('INSIGHTS_LICENCE'),
              showOnMobile: true,
              licenceRequiredContent: LicenceExpiredPage,
              showLicenceRequired: () => !hasPermissions('INSIGHTS_LICENCE'),
            },
            {
              icon: <InsightsImprove/>,
              name: 'Improve',
              urlSlug: 'improve',
              content: loadAsyncComponent(() => import('../engage-insights2/pages/Improve/ImprovePage')),
              isVisible: () =>
                isClient()
                && hasPermissions('INSIGHTS_ACCESS') && hasPermissions('INSIGHTS_LICENCE')
              ,
              showOnMobile: true,
              licenceRequiredContent: LicenceExpiredPage,
              showLicenceRequired: () => !hasPermissions('INSIGHTS_LICENCE'),
            },
            {
              icon: <InsightsContinue/>,
              name: 'Continue',
              urlSlug: 'continue',
              content: loadAsyncComponent(() => import('../engage-insights2/pages/Continue/ContinuePage')),
              isVisible: () =>
                isClient()
                && hasPermissions('INSIGHTS_ACCESS') && hasPermissions('INSIGHTS_LICENCE'),
              showOnMobile: true,
              licenceRequiredContent: LicenceExpiredPage,
              showLicenceRequired: () => !hasPermissions('INSIGHTS_LICENCE'),
            },
            // {
            //   icon: <InsightsEdit/>,
            //   name: 'Cohort report',
            //   urlSlug: 'cohort-report',
            //   content: loadAsyncComponent(() => import('../engage-insights2/pages/CohortReport/CohortReportPage')),
            //   isVisible: () =>
            //     isClient()
            //     && hasPermissions('INSIGHTS_ACCESS', 'INSIGHTS_LICENCE')
            //     && (hasPermissions('COHORT_REPORT_VIEW_ACCESS_ORG')
            //       || hasAnyBUPermission('BUSINESS_UNIT_COHORT_REPORT_VIEW_ACCESS')),
            //   showOnMobile: true,
            //   licenceRequiredContent: LicenceExpiredPage,
            //   showLicenceRequired: () => !hasPermissions('INSIGHTS_LICENCE'),
            // },
            {
              icon: <InsightsEdit/>,
              name: 'Assign reports',
              urlSlug: 'assign-reports',
              content: loadAsyncComponent(() => import('../engage-insights2/pages/AssignReports/AssignReportsPage')),
              isVisible: () =>
                isClient()
                && hasPermissions('INSIGHTS_ACCESS', 'INSIGHTS_EDIT_RULES') && hasPermissions('INSIGHTS_LICENCE'),
              showOnMobile: true,
              licenceRequiredContent: LicenceExpiredPage,
              showLicenceRequired: () => !hasPermissions('INSIGHTS_LICENCE'),
            }
          ]
        }
      ]
    },
    // {
    //   name: 'Engage Insight',
    //   urlSlug: 'engage-insight',
    //   showOnMobile: true,
    //   pages: [
    //     {
    //       isDefault: true,
    //       name: 'insight',
    //       urlSlug: 'insight',
    //       icon: <InsightsIcons/>,
    //       subURL: true,
    //       content: loadAsyncComponent(() =>
    //         import('../engage-insights/pages/engage-insights')),
    //       isVisible: () =>
    //         (productLine === ProductLine.Workplace)
    //         && hasPermissions('INSIGHTS_LICENCE')
    //         && hasPermissions('INSIGHTS_ACCESS')
    //         && isClient()
    //       ,
    //       licenceRequiredContent: LicenceExpiredPage,
    //       showOnMobile: true,
    //       showLicenceRequired: () => !hasPermissions('INSIGHTS_LICENCE'),
    //       pages: [
    //         {
    //           icon: <InsightsSummary/>,
    //           name: 'Summary',
    //           urlSlug: 'summary',
    //           content: loadAsyncComponent(() => import('../engage-insights/pages/summary/summary')),
    //           isVisible: () =>
    //             (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
    //             && isClient()
    //             && hasPermissions('INSIGHTS_ACCESS') && hasPermissions('INSIGHTS_LICENCE'),
    //           showOnMobile: true,
    //           licenceRequiredContent: LicenceExpiredPage,
    //           showLicenceRequired: () => !hasPermissions('INSIGHTS_LICENCE'),
    //         },
    //         {
    //           icon: <InsightsImprove/>,
    //           name: 'Improve',
    //           urlSlug: 'improve',
    //           content: loadAsyncComponent(() => import('../engage-insights/pages/improve/Remedial')),
    //           isVisible: () =>
    //             (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
    //             && isClient()
    //             && hasPermissions('INSIGHTS_ACCESS') && hasPermissions('INSIGHTS_LICENCE')
    //           ,
    //           showOnMobile: true,
    //           licenceRequiredContent: LicenceExpiredPage,
    //           showLicenceRequired: () => !hasPermissions('INSIGHTS_LICENCE'),
    //         },
    //         {
    //           icon: <InsightsContinue/>,
    //           name: 'Continue',
    //           urlSlug: 'continue',
    //           content: loadAsyncComponent(() => import('../engage-insights/pages/continue/Continue')),
    //           isVisible: () =>
    //             (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
    //             && isClient()
    //             && hasPermissions('INSIGHTS_ACCESS') && hasPermissions('INSIGHTS_LICENCE'),
    //           showOnMobile: true,
    //           licenceRequiredContent: LicenceExpiredPage,
    //           showLicenceRequired: () => !hasPermissions('INSIGHTS_LICENCE'),
    //         },
    //         {
    //           icon: <InsightsEdit/>,
    //           name: 'Edit rulesets',
    //           urlSlug: 'edit-rulesets',
    //           content: loadAsyncComponent(() => import('../engage-insights/pages/EditRuleSets/EditRuleSets')),
    //           isVisible: () =>
    //             (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
    //             && isClient()
    //             && hasPermissions('INSIGHTS_ACCESS', 'INSIGHTS_EDIT_RULES') && hasPermissions('INSIGHTS_LICENCE')
    //           ,
    //           showOnMobile: false,
    //           licenceRequiredContent: LicenceExpiredPage,
    //           showLicenceRequired: () => !hasPermissions('INSIGHTS_LICENCE'),
    //         }
    //       ]
    //     }
    //   ]
    // },
    {
      name: productLine === ProductLine.Assess ? 'Engage Assess' : 'Engage Survey',
      urlSlug: 'engage-survey',
      showOnMobile: true,
      pages: [
        {
          name: 'Lifecycle',
          urlSlug: 'lifecycle',
          subURL: true,
          icon: <MenuLifecycle/>,
          content: loadAsyncComponent(() =>
            import('../lifecycle/pages/LifecyclePage/LifecyclePage')),
          isVisible: () =>
            productLine === ProductLine.Lifecycle
            && (
              hasPermissions('LIFECYCLE_SETTINGS')
              || hasPermissions('LIFECYCLE_MANAGE_EMPLOYEE_TRIGGERS')
              || hasPermissions('LIFECYCLE_MANAGE_CANDIDATE_TRIGGERS')
            )
            && hasPermissions('LIFECYCLE_LICENCE')
            && isClient(),
          showOnMobile: false,
          pages: [
            {
              icon: <LifecycleSettings/>,
              name: 'Lifecycle settings',
              urlSlug: 'settings',
              content: loadAsyncComponent(() =>
                import('../lifecycle/pages/LifecycleSettingsPage/LifecycleSettingsPage')),
              isVisible: () =>
                productLine === ProductLine.Lifecycle
                && hasPermissions('LIFECYCLE_SETTINGS')
                && hasPermissions('LIFECYCLE_LICENCE')
                && isClient(),
              showOnMobile: false
            },
            {
              icon: <LifecycleEmployee/>,
              name: 'Employee events',
              urlSlug: 'employee-events',
              content: loadAsyncComponent(
                () =>
                  import('../lifecycle/pages/LifecycleEmployeeEventsPage/LifecycleEmployeeEventsPage')),
              isVisible: () =>
                productLine === ProductLine.Lifecycle
                && hasPermissions('LIFECYCLE_MANAGE_EMPLOYEE_TRIGGERS')
                && hasPermissions('LIFECYCLE_LICENCE')
                && isClient(),
              showOnMobile: false
            },
            {
              icon: <LifecycleCandidate/>,
              name: 'Candidate events',
              urlSlug: 'candidate-events',
              content: loadAsyncComponent(
                () =>
                  import('../lifecycle/pages/LifecycleCandidateEventsPage/LifecycleCandidateEventsPage')),
              isVisible: () =>
                productLine === ProductLine.Lifecycle
                && hasPermissions('LIFECYCLE_MANAGE_CANDIDATE_TRIGGERS')
                && hasPermissions('LIFECYCLE_LICENCE')
                && isClient(),
              showOnMobile: false
            }
          ]
        },
        {
          name: productLine === ProductLine.Assess ? 'Assessments' : 'Survey Model',
          urlSlug: 'survey-model/:pageOrId?/:tab?/:questionnaireId?',
          subURL: true,
          icon: <SurveyModelLogo/>,
          content: loadAsyncComponent(() =>
            import('../engage-survey/pages/SurveyModelPage/SurveyModelPage')),
          isVisible: () =>
            (
              (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
              && hasPermissions('SURVEY_SURVEY_MODEL')
              && hasPermissions('SURVEY_LICENCE')
            )
            || (
              productLine === ProductLine.Lifecycle
              && hasPermissions('LIFECYCLE_VIEW_SURVEY')
              && hasPermissions('LIFECYCLE_LICENCE')
            ),
          showOnMobile: false,
          pages: [
            {
              icon: <SurveyModelTemplates/>,
              name: productLine === ProductLine.Assess ? 'Templates' : 'Survey templates',
              urlSlug: 'templates',
              content: loadAsyncComponent(() =>
                import('../engage-survey/pages/SurveyModelTemplatesPage/SurveyModelTemplatesPage')),
              isVisible: () =>
                (
                  (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                  && hasPermissions('SURVEY_SURVEY_MODEL')
                  && hasPermissions('SURVEY_LICENCE')
                )
                || (
                  productLine === ProductLine.Lifecycle
                  && hasPermissions('LIFECYCLE_VIEW_SURVEY')
                  && hasPermissions('LIFECYCLE_LICENCE')
                ),
              showOnMobile: false
            },
            {
              icon: <SurveyModelModels/>,
              name: productLine === ProductLine.Assess ? 'Assessments' : 'Survey models',
              urlSlug: 'models',
              content: loadAsyncComponent(
                () =>
                  import('../engage-survey/pages/SurveyModelModelsPage/SurveyModelModelsPage')),
              isVisible: () =>
                (
                  (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                  && hasPermissions('SURVEY_SURVEY_MODEL')
                  && hasPermissions('SURVEY_LICENCE')
                )
                || (
                  productLine === ProductLine.Lifecycle
                  && hasPermissions('LIFECYCLE_VIEW_SURVEY')
                  && hasPermissions('LIFECYCLE_LICENCE')
                ),
              showOnMobile: false
            },
            {
              icon: <SurveyModelConstructs/>,
              name: 'Constructs/questions',
              urlSlug: 'constructs',
              content: loadAsyncComponent(() =>
                import('../engage-survey/pages/SurveyModelConstructsPage/SurveyModelConstructsPage')),
              isVisible: () =>
                (
                  (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                  && hasPermissions('SURVEY_SURVEY_MODEL')
                  && hasPermissions('SURVEY_LICENCE')
                )
                || (
                  productLine === ProductLine.Lifecycle
                  && hasPermissions('LIFECYCLE_VIEW_SURVEY')
                  && hasPermissions('LIFECYCLE_LICENCE')
                ),
              showOnMobile: false
            },
            {
              icon: <SurveyModelDiagnostic/>,
              name: 'Diagnostic survey',
              urlSlug: 'diagnostic-survey',
              content: loadAsyncComponent(() =>
                import('../engage-survey/pages/SurveyModelQuestionnairePage/SurveyModelQuestionnairePage')),
              isVisible: () =>
                (
                  (productLine === ProductLine.Workplace)
                  && hasPermissions('SURVEY_SURVEY_MODEL')
                  && hasPermissions('SURVEY_LICENCE')
                )
                || (
                  productLine === ProductLine.Lifecycle
                  && hasPermissions('LIFECYCLE_VIEW_SURVEY')
                  && hasPermissions('LIFECYCLE_LICENCE')
                ),
              showOnMobile: false
            },
            {
              icon: <SurveyModelPulse/>,
              name: productLine === ProductLine.Assess ? 'Questionnaires' : 'Pulse surveys',
              urlSlug: 'pulse-surveys',
              content: loadAsyncComponent(
                () =>
                  import('../engage-survey/pages/SurveyModelQuestionnaireDisplayPage/' +
                  'SurveyModelQuestionnaireDisplayPage')),
              isVisible: () =>
                (
                  (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                  && hasPermissions('SURVEY_SURVEY_MODEL')
                  && hasPermissions('SURVEY_LICENCE')
                )
                || (
                  productLine === ProductLine.Lifecycle
                  && hasPermissions('LIFECYCLE_VIEW_SURVEY')
                  && hasPermissions('LIFECYCLE_LICENCE')
                ),
              showOnMobile: false
            },
            {
              icon: <SurveyModelComms/>,
              name: 'Communications',
              urlSlug: 'communications',
              content: loadAsyncComponent(
                () =>
                  import('../engage-survey/pages/SurveyModelCommunicationsPage/SurveyModelCommunicationsPage')),
              isVisible: () =>
                hasPermissions('SURVEY_SURVEY_MODEL')
                && isPartner(),
              showOnMobile: false
            },
            {
              icon: <SurveyModelSettings/>,
              name: productLine === ProductLine.Assess ? 'Settings' : 'Survey settings',
              urlSlug: 'settings',
              content: loadAsyncComponent(
                () =>
                  import('../engage-survey/pages/SurveyModelSettingsPage/SurveyModelSettingsPage')),
              isVisible: () =>
                (
                  (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                  && hasPermissions('SURVEY_SURVEY_MODEL')
                  && hasPermissions('SURVEY_LICENCE')
                )
                || (
                  productLine === ProductLine.Lifecycle
                  && hasPermissions('LIFECYCLE_VIEW_SURVEY')
                  && hasPermissions('LIFECYCLE_LICENCE')
                ),
              showOnMobile: false
            },
            {
              icon: <SurveyModelBenchmarks/>,
              name: 'Benchmarks',
              urlSlug: 'benchmarks',
              content: loadAsyncComponent(
                () =>
                  import('../engage-survey/pages/SurveyModelBenchmarksPage/SurveyModelBenchmarksPage')),
              isVisible: () =>
                (
                  (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                  && hasPermissions('SURVEY_SURVEY_MODEL')
                  && hasPermissions('SURVEY_LICENCE')
                )
                || (
                  productLine === ProductLine.Lifecycle
                  && hasPermissions('LIFECYCLE_VIEW_SURVEY')
                  && hasPermissions('LIFECYCLE_LICENCE')
                ),
              showOnMobile: false
            },
            {
              icon: <SurveyModelLanguage/>,
              name: 'Language settings',
              urlSlug: 'languageSettings',
              content: loadAsyncComponent(
                () =>
                  import('../engage-survey/pages/SurveyModelLanguageSettingsPage/SurveyModelLanguageSettingsPage')),
              isVisible: () =>
                (
                  (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                  && hasPermissions('SURVEY_SURVEY_MODEL')
                  && hasPermissions('SURVEY_LICENCE')
                )
                || (
                  productLine === ProductLine.Lifecycle
                  && hasPermissions('LIFECYCLE_VIEW_SURVEY')
                  && hasPermissions('LIFECYCLE_LICENCE')
                ),
              showOnMobile: false
            },
            {
              icon: <SurveyModelPublish/>,
              name: 'Publish template',
              urlSlug: 'publish',
              content: loadAsyncComponent(
                () =>
                  import('../engage-survey/pages/SurveyModelPublishPage/SurveyModelPublishPage')),
              isVisible: () =>
                hasPermissions('SURVEY_SURVEY_MODEL')
                && isPartner(),
              showOnMobile: false
            }
          ]
        },
        {
          name: productLine === ProductLine.Assess ? 'Manage Project' : 'Define Survey',
          urlSlug: 'define-survey/:pageOrId?/:tab?',
          icon: <DefineSurvey/>,
          subURL: true,
          content: loadAsyncComponent(() =>
            import('../engage-survey/pages/DefineSurveyPage/DefineSurveyPage')),
          isVisible: () =>
            ((
              (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
              && hasPermissions('SURVEY_DEFINE_SURVEY')
              && hasPermissions('SURVEY_LICENCE')
            )
            || (
              productLine === ProductLine.Lifecycle
              && hasPermissions('LIFECYCLE_VIEW_SURVEY')
              && hasPermissions('LIFECYCLE_LICENCE')
            ))
            && isClient(),
          showOnMobile: false,
          pages: [
            {
              icon: <DefineSurveyDefineEdit/>,
              name: productLine === ProductLine.Assess ? 'Define/edit project' : 'Define/edit survey',
              urlSlug: 'surveys',
              content: loadAsyncComponent(() =>
                import('../engage-survey/pages/DefineSurveySurveysPage/DefineSurveySurveysPage')),
              isVisible: () =>
                ((
                  (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                  && hasPermissions('SURVEY_DEFINE_SURVEY')
                  && hasPermissions('SURVEY_LICENCE')
                )
                || (
                  productLine === ProductLine.Lifecycle
                  && hasPermissions('LIFECYCLE_VIEW_SURVEY')
                  && hasPermissions('LIFECYCLE_LICENCE')
                ))
                && isClient(),
              showOnMobile: false
            },
            {
              icon: <DefineSurveyParticipants/>,
              name: productLine === ProductLine.Assess ? 'Assessees' : 'Participants',
              urlSlug: 'participants',
              content: loadAsyncComponent(() =>
                import('../engage-survey/pages/DefineSurveyParticipantsPage/DefineSurveyParticipantsPage')),
              isVisible: () =>
                (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                && hasPermissions('SURVEY_DEFINE_SURVEY') && hasPermissions('SURVEY_LICENCE')
                && isClient(),
              showOnMobile: false
            },
            {
              icon: <DefineSurveyComms/>,
              name: 'Communications',
              urlSlug: 'communications',
              content: loadAsyncComponent(
                () =>
                  import('../engage-survey/pages/DefineSurveyCommunicationsPage/DefineSurveyCommunicationsPage')),
              isVisible: () =>
                ((
                  (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                  && hasPermissions('SURVEY_DEFINE_SURVEY')
                  && hasPermissions('SURVEY_LICENCE')
                )
                || (
                  productLine === ProductLine.Lifecycle
                  && hasPermissions('LIFECYCLE_VIEW_SURVEY')
                  && hasPermissions('LIFECYCLE_LICENCE')
                ))
                && isClient(),
              showOnMobile: false
            },
            {
              icon: <DefineSurveyTestEmails/>,
              name: 'Test emails',
              urlSlug: 'test-emails',
              content: loadAsyncComponent(() =>
                import('../engage-survey/pages/DefineSurveyTestEmailsPage/DefineSurveyTestEmailsPage')),
              isVisible: () =>
                ((
                  (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                  && hasPermissions('SURVEY_DEFINE_SURVEY')
                  && hasPermissions('SURVEY_LICENCE')
                )
                || (
                  productLine === ProductLine.Lifecycle
                  && hasPermissions('LIFECYCLE_VIEW_SURVEY')
                  && hasPermissions('LIFECYCLE_LICENCE')
                ))
                && isClient(),
              showOnMobile: false
            },
            {
              icon: <DefineSurveyConfigure/>,
              name: 'Configure',
              urlSlug: 'configure',
              content: loadAsyncComponent(() =>
                import('../engage-survey/pages/DefineSurveyConfigurePage/DefineSurveyConfigurePage')),
              isVisible: () =>
                ((
                    (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                    && hasPermissions('SURVEY_DEFINE_SURVEY')
                    && hasPermissions('SURVEY_LICENCE')
                  )
                  || (
                    productLine === ProductLine.Lifecycle
                    && hasPermissions('LIFECYCLE_VIEW_SURVEY')
                    && hasPermissions('LIFECYCLE_LICENCE')
                  ))
                && isClient(),
              showOnMobile: false
            }
          ]
        },
        {
          icon: <RunSurveyTopMenu/>,
          isDefault: true,
          name: productLine === ProductLine.Assess ? 'Track Project' : 'Run Survey',
          urlSlug: 'run-survey/:surveyId?',
          content: loadAsyncComponent(() =>
            import('../engage-survey/pages/RunSurveyPage/RunSurveyPageSiderMenu')),
          isVisible: () =>
            ((
              (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
              && hasPermissions('SURVEY_DEFINE_SURVEY')
              && hasPermissions('SURVEY_LICENCE')
            )
            || (
              productLine === ProductLine.Lifecycle
              && hasPermissions('LIFECYCLE_VIEW_SURVEY')
              && hasPermissions('LIFECYCLE_LICENCE')
            ))
            && isClient(),
          showOnMobile: true,
          pages: [
            {
              name: productLine === ProductLine.Assess ? 'Run Project' : 'Run Survey',
              urlSlug: '/',
              icon: <RunSurvey/>,
              content: loadAsyncComponent(() =>
                import('../engage-survey/pages/RunSurveyPage/RunSurveyPage')),
              isDefault: true,
              isVisible: () =>
                ((
                  (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                  && hasPermissions('SURVEY_DEFINE_SURVEY')
                  && hasPermissions('SURVEY_LICENCE')
                )
                || (
                  productLine === ProductLine.Lifecycle
                  && hasPermissions('LIFECYCLE_VIEW_SURVEY')
                  && hasPermissions('LIFECYCLE_LICENCE')
                ))
                && isClient(),
              showOnMobile: true
            }
          ]
        },
        {
          name: 'Manage Data',
          urlSlug: 'manage-data',
          icon: <ManageData/>,
          content: loadAsyncComponent(() =>
            import('../engage-survey/pages/ManageDataPage/ManageDataPage')),
          isVisible: () =>
            (hasPermissions('SURVEY_MANAGE_DATA') || hasPermissions('SURVEY_VIEW_MANAGE_DATA'))
            && hasPermissions('SURVEY_LICENCE')
            && isClient(),
          showOnMobile: false
        },
        {
          name: 'Data Triggers',
          urlSlug: 'data-triggers/:pageOrId?',
          subURL: true,
          icon: <MenuDataTriggers/>,
          content: loadAsyncComponent(() =>
            import('../data-triggers/pages/DataTriggersPage/DataTriggersPage')),
          isVisible: () =>
            productLine === ProductLine.Lifecycle
            && hasPermissions('MANAGE_DATA_TRIGGERS_ORG') && hasPermissions('SURVEY_LICENCE')
            && isClient(),
          showOnMobile: false,
          pages: [
            {
              icon: <DataTriggersList/>,
              name: 'Data triggers',
              urlSlug: 'triggers',
              content: loadAsyncComponent(() =>
                import('../data-triggers/pages/DataTriggersListPage/DataTriggersListPage')),
              isVisible: () =>
                productLine === ProductLine.Lifecycle
                && hasPermissions('MANAGE_DATA_TRIGGERS_ORG') && hasPermissions('SURVEY_LICENCE')
                && isClient(),
              showOnMobile: false
            },
            {
              icon: <DataTriggerRules/>,
              name: 'Rules',
              urlSlug: 'rules',
              content: loadAsyncComponent(
                () =>
                  import('../data-triggers/pages/DataTriggersRulePage/DataTriggersRulePage')),
              isVisible: () =>
                productLine === ProductLine.Lifecycle
                && hasPermissions('MANAGE_DATA_TRIGGERS_ORG') && hasPermissions('SURVEY_LICENCE')
                && isClient(),
              showOnMobile: false
            },
            {
              icon: <DataTriggerActions/>,
              name: 'Actions',
              urlSlug: 'actions',
              content: loadAsyncComponent(
                () =>
                  import('../data-triggers/pages/DataTriggersActionsPage/DataTriggersActionsPage')),
              isVisible: () =>
                productLine === ProductLine.Lifecycle
                && hasPermissions('MANAGE_DATA_TRIGGERS_ORG') && hasPermissions('SURVEY_LICENCE')
                && isClient(),
              showOnMobile: false
            }
          ]
        }
      ]
    },
    {
      name: 'manage PARTICIPANTS',
      urlSlug: 'manage-participants',
      showOnMobile: false,
      pages: [
        {
          name: 'Organization',
          urlSlug: 'participants/:tab?/:virtualStructureId?/:mode?',
          subURL: true,
          content: loadAsyncComponent(() =>
            import('../engage-employees/EngagedOrganisation')),
          isDefault: true,
          isVisible: () =>
            ((
                (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                && (hasPermissions('MANAGE_EMPLOYEE_LIST')
                  || hasPermissions('EDIT_DEMOGRAPHICS')
                  || hasPermissions('WORKPLACE_VIEW_EMPLOYEE_LIST'))
              )
              || (
                productLine === ProductLine.Lifecycle
                && hasPermissions('LIFECYCLE_VIEW_EMPLOYEE_LIST')
              ))
            && hasPermissions('MANAGE_PARTICIPANTS_LICENCE')
            && isClient(),
          pages: [
            {
              name: 'Actual structure',
              urlSlug: 'organization-structure',
              icon: <OrgStructure/>,
              content: loadAsyncComponent(() => import('../engage-employees/pages/EmployeesPage/EmployeesPage')),
              isVisible: () =>
                ((
                    (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                    && (hasPermissions('MANAGE_EMPLOYEE_LIST')
                      || hasPermissions('WORKPLACE_VIEW_EMPLOYEE_LIST'))
                  )
                  || (
                    productLine === ProductLine.Lifecycle
                    && hasPermissions('LIFECYCLE_VIEW_EMPLOYEE_LIST')
                  ))
                && hasPermissions('MANAGE_PARTICIPANTS_LICENCE')
                && isClient(),
              showOnMobile: false
            },
            {
              name: 'Virtual structures',
              urlSlug: 'virtual-structures',
              icon: <OrgStructureVirtual/>,
              content: loadAsyncComponent(() =>
                import('../engage-employees/pages/VirtualStructures/VirtualStructuresLandingPage')),
              isVisible: () =>
                (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                && (
                  hasPermissions('MANAGE_EMPLOYEE_LIST')
                  || hasPermissions('WORKPLACE_VIEW_EMPLOYEE_LIST')
                )
                && hasPermissions('MANAGE_PARTICIPANTS_LICENCE')
                && hasPermissions('VIRTUAL_STRUCTURES_LICENCE')
                && (
                  hasPermissions('MANAGE_VIRTUAL_STRUCTURE')
                )
                && isClient(),
              showOnMobile: false

            },
            {
              name: 'Demographics',
              urlSlug: 'demographics',
              icon: <OrgDemographics/>,
              content: loadAsyncComponent(() => import('../engage-employees/pages/DemographicsPage/DemographicsPage')),
              isVisible: () =>
                (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
                && hasPermissions('EDIT_DEMOGRAPHICS')
                && hasPermissions('MANAGE_PARTICIPANTS_LICENCE')
                && isClient(),
              showOnMobile: false
            },
          ]
        }
      ]
    },
    {
      name: 'System Admin',
      urlSlug: 'system-admin',
      showOnMobile: false,
      pages: [
        {
          name: 'partner users',
          urlSlug: 'partner-employees',
          icon: <PartnerEmployee/>,
          content: loadAsyncComponent(() =>
            import('../partner-management/pages/PartnerEmployees/PartnerEmployees')),
          isVisible: () =>
            (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
            && hasPermissions('MANAGE_PARTNERS_EMPLOYEE_LIST')
            && isPartner()
        },
        {
          name: 'partner profile',
          urlSlug: 'partner-profile',
          icon: <PatnerProfile/>,
          content: loadAsyncComponent(() =>
            import('../partner-management/pages/PartnerProfile/PartnerProfile')),
          isVisible: () =>
            (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
            && hasPermissions('MANAGE_PARTNERS')
            && isPartner()
        },
        {
          name: 'New Partner',
          urlSlug: 'new-partner',
          icon: <PartnerNew/>,
          content: loadAsyncComponent(() =>
            import('../partner-management/pages/NewPartner/NewPartner')),
          isVisible: () =>
            (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
            && hasPermissions('ADD_NEW_PARTNER')
            && isPartner()
        },
        {
          name: 'Client Profile',
          urlSlug: 'client-profile',
          icon: <SystemAdminProfile/>,
          content: loadAsyncComponent(() =>
            import('../client-management/pages/ClientProfile')),
          isVisible: () =>
            (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
            && (
              hasPermissions('VIEW_CLIENT_PROFILE')
              || hasPermissions('MANAGE_CLIENT_PROFILE')
            )
            && (
              hasPermissions('ANALYTICS_LICENCE')
              || ((hasPermissions('ANALYTICS_COVID_19_LICENCE')
                || hasPermissions('ANALYTICS_INDICATORS_LICENCE')
                || hasPermissions('ENGAGEMENT_SURVEY_LICENCE')) === false)
            )
            && isClient()
        },
        {
          name: 'Licence',
          urlSlug: 'client-licence',
          icon: <SystemAdminLicense/>,
          content: loadAsyncComponent(() =>
            import('../partner-management/pages/ClientLicence/ClientLicence')),
          isVisible: () =>
            (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
            && (
              hasPermissions('VIEW_CLIENT_PROFILE')
              || hasPermissions('MANAGE_CLIENT_PROFILE')
            )
            && (
              hasPermissions('ANALYTICS_LICENCE')
              || ((hasPermissions('ANALYTICS_COVID_19_LICENCE')
                || hasPermissions('ANALYTICS_INDICATORS_LICENCE')
                || hasPermissions('ENGAGEMENT_SURVEY_LICENCE')) === false)
            )
            && isClient()
        },
        {
          name: 'Quotes',
          urlSlug: 'quote-requests',
          icon: <ClientQuote/>,
          content: loadAsyncComponent(() =>
            import('../partner-management/pages/QuoteRequests/QuoteRequests')),
          isVisible: () =>
            (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
            && hasPermissions('MANAGE_SUBSCRIPTIONS')
            && isPartner()
        },
        {
          name: 'Invoices',
          urlSlug: 'invoices',
          icon: <ClientInvoice/>,
          content: loadAsyncComponent(() =>
            import('../partner-management/pages/Invoices/Invoices')),
          isVisible: () =>
            (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
            && hasPermissions('MANAGE_SUBSCRIPTIONS')
            && isPartner()
        },
        {
          name: 'Quote Details',
          urlSlug: 'quote-details/:quoteType?/:id?',
          icon: <ClientQuote/>,
          content: loadAsyncComponent(() =>
            import('../partner-management/pages/QuoteDetails/QuoteDetailsViewer')),
          showInMenu: false,
          isVisible: () =>
            (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
            && hasPermissions('MANAGE_SUBSCRIPTIONS')
            && isPartner()
        },
        {
          name: 'invoice details',
          urlSlug: 'invoice-details/:id?',
          icon: <ClientInvoice/>,
          content: loadAsyncComponent(() =>
            import('../partner-management/pages/Invoices/InvoiceViewer/InvoiceViewer')),
          showInMenu: false,
          isVisible: () =>
            (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
            && hasPermissions('MANAGE_PARTNERS')
            && isPartner()
        },
        {
          name: 'Products',
          urlSlug: 'select-product/:action?/:clientName?/:clientId?/:partnerRequest?',
          icon: <TempIcon/>,
          content: loadAsyncComponent(() =>
            import('../partner-management/pages/ProtectedProduct/ProtectedProduct')),
          showInMenu: false,
          isVisible: () =>
            (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
            && hasPermissions('MANAGE_PARTNERS')
            && isPartner()
        },
        {
          name: 'New Client',
          urlSlug: 'new-client',
          icon: <ClientNew/>,
          content: loadAsyncComponent(() =>
            import('../partner-management/pages/PartnerCreateNewClient/PartnerCreateNewClient')),
          isVisible: () =>
            productLine === ProductLine.Workplace
            && hasPermissions('MANAGE_CLIENTS')
            && isPartner()
        },
        {
          name: 'Client Admin',
          urlSlug: 'client-management',
          icon: <ClientNew/>,
          content: loadAsyncComponent(() =>
            import('../partner-management/pages/PartnerClientManagement/PartnerClientManagement')),
          isVisible: () =>
            (productLine === ProductLine.Workplace || productLine === ProductLine.Assess)
            && hasPermissions('MANAGE_CLIENT_PARTNER_HIERARCHIES')
            && isPartner()
        },
      ]
    },
    {
      name: 'Engage Reports',
      urlSlug: 'engage-reports',
      showOnMobile: true,
      pages: [
        {
          name: 'Reports',
          urlSlug: 'reports/:pageOrId?',
          subURL: true,
          icon: null,
          content: loadAsyncComponent(() =>
            import('../engage-reports/pages/EngageReportsPage/EngageReportsPage')),
          isVisible: () =>
            hasPermissions('REPORT_EDITOR_ACCESS', 'REPORT_EDITOR_LICENCE'),
          showOnMobile: false,
          isDefault: true,
          pages: [
            {
              icon: null,
              name: 'Reports',
              urlSlug: 'reports',
              content: loadAsyncComponent(() =>
                import('../engage-reports/pages/EngageReportsListPage/EngageReportsListPage')),
              isVisible: () =>
                hasPermissions('REPORT_EDITOR_ACCESS', 'REPORT_EDITOR_LICENCE'),
              showOnMobile: false
            },
            {
              icon: null,
              name: 'Design',
              urlSlug: 'design',
              content: loadAsyncComponent(
                () =>
                  import('../engage-reports/pages/EngageReportsDesignPage/EngageReportsDesignPage')),
              isVisible: () =>
                hasPermissions('REPORT_EDITOR_ACCESS', 'REPORT_EDITOR_LICENCE'),
              showOnMobile: false
            },
          ]
        }
      ]
    }
  ];
}