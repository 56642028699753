import * as React from 'react';
import UserService, {OrganisationDetails} from '../../../shared/services/UserService';
import {concat, filter, some} from 'lodash-es';
import Caret, {CaretTheme} from '../../../shared/components/Caret/Caret';
import {safeNav} from '../../../shared/helpers/StateHelpers';
import Dropdown from 'antd/es/dropdown/dropdown';
import SimpleTree, {SimpleTreeClickBehaviour} from '../../../shared/components/SimpleTree/SimpleTree';
import styles from './OrganisationSelector.module.css';
import Input from 'antd/es/input/Input';
import {findAllNodesInPrunedTree, iterateOverTree, TreeNode} from '../../../shared/helpers/TreeHelpers';
import {ChangeEvent} from 'react';
import MobileMenuService from '../../../shared/services/MobileMenuService';
import {classes} from '../../../shared/helpers/RenderHelpers';
import RenderHelpers from '../../../shared-ui/helpers/RenderHelpers';
import {ProductLineFilter} from '../../context/ProductLineFilter';

interface OrganisationSelectorState {
  isOpen: boolean;
  expandedIds: string[];
  searchText: string;
}

interface OrganisationSelectorProps {
  className?: string;
  mobile?: boolean;
}

// The Ant Design Dropdown assumes that the overlay is a Menu, and tries to set expected props that are not
// available on an ordinary <div>. This component exists purely to ignore these unexpected props.
class IgnoreExternalProps extends React.Component<{}, {}> {
  render() {
    return this.props.children;
  }
}

export default class OrganisationSelector
  extends React.Component<OrganisationSelectorProps, OrganisationSelectorState> {

  static contextType = ProductLineFilter;
  public context!: React.ContextType<typeof ProductLineFilter>;

  constructor(props: OrganisationSelectorProps) {
    super(props);

    this.state = {
      isOpen: false,
      expandedIds: [],
      searchText: ''
    };
  }

  onNodeSelected = async (node: TreeNode<OrganisationDetails>) => {
    await UserService.instance.setCurrentClientOrPartnerId(node.id, this.context.currentProductLine);
    this.setState({isOpen: false});
  };

  onVisibleChanged = () => {
    this.setState({isOpen: !this.state.isOpen});
  };

  onSearchTextChanged = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({searchText: event.target.value});
  };

  render() {
    if (!UserService.instance.userProfile) {
      return <div/>;
    }

    let clientsAndPartners = concat(
      safeNav(UserService.instance.userProfile, x => x.partnerDetails) || [],
      safeNav(UserService.instance.userProfile, x => x.clientDetails) || []);

    let rootOrganisations: OrganisationDetails[]
      = filter(clientsAndPartners, (organisation: OrganisationDetails) => !organisation.parent);

    let canDropDown = true;
    if (rootOrganisations.length === 0) {
      canDropDown = false;
    } else if (rootOrganisations.length === 1) {
      let onlyRootOrganisation = rootOrganisations[0];
      if (onlyRootOrganisation && (!onlyRootOrganisation.children || onlyRootOrganisation.children.length === 0)) {
        canDropDown = false;
      }
    }

    let hasSearchText = this.state.searchText && this.state.searchText.length > 0;

    let highlightedNodes: OrganisationDetails[] = [];
    if (hasSearchText) {
      iterateOverTree<OrganisationDetails>(rootOrganisations, organisation => {
        if (this.createNodeLabel(organisation).toLowerCase().includes(this.state.searchText.toLowerCase())) {
          highlightedNodes.push(organisation);
        }
      });
    }

    let visibleNodes: OrganisationDetails[] | undefined = undefined;
    if (hasSearchText) {
      visibleNodes = findAllNodesInPrunedTree(
        clientsAndPartners,
        (organisation: OrganisationDetails) => {
          return some(highlightedNodes, (searchResult: OrganisationDetails) => {
            return searchResult.id === organisation.id;
          });
        },
        false);
    }

    let overlay = (
      <IgnoreExternalProps>
        <div
          className={classes(
            styles.treeWrapper,
            this.props.className,
            this.props.mobile ? styles.mobileTreeWrapper : null
          )}
        >
          <Input
            className={styles.search}
            placeholder={'Type to search'}
            onChange={this.onSearchTextChanged}
            onClick={(event: React.MouseEvent<any>) => event.stopPropagation()}
          />
          <SimpleTree
            className={[styles.tree, MobileMenuService.isMobile ? styles.mobileTree : ''].join(' ')}
            nodes={rootOrganisations}
            onRenderNode={SimpleTree.createSimpleNodeRenderer(x => {
              const organisation = x as OrganisationDetails;
              return this.createNodeLabel(organisation);
            })}
            onNodeSelectedChanged={this.onNodeSelected}
            selectedNodes={
              UserService.instance.currentClientOrPartner
                ? [UserService.instance.currentClientOrPartner]
                : []
            }
            expandedNodes={[rootOrganisations[0]]}
            doubleClickBehaviour={SimpleTreeClickBehaviour.ExpandNode}
            highlightedNodes={highlightedNodes}
            visibleNodes={visibleNodes}
            notExpandableNodes={highlightedNodes}
          />
        </div>
      </IgnoreExternalProps>);

    return (
      <Dropdown
        overlay={overlay}
        trigger={['click']}
        onVisibleChange={this.onVisibleChanged}
        disabled={!canDropDown}
        visible={this.state.isOpen}
      >
        <div
          className={[
            this.props.mobile ? styles.mobile : '',
            this.props.className,
            styles.component,
            canDropDown ? styles.enabled : '',
            this.state.isOpen ? styles.open : ''
          ].join(' ')}
        >
          <div
            className={
              RenderHelpers.classes(
                styles.accountContainer,
                this.props.mobile ? styles.mobile : undefined
              )}
          >
            <div
              className={styles.activeArea}
            >
            <span className={styles.selectedName}>
              {safeNav(UserService.instance.currentClientOrPartner, x => x.name)}
            </span>
              {
                canDropDown
                  ? <Caret
                    style={{
                      marginRight: 5
                    }}
                    size={20}
                    theme={CaretTheme.None}
                    isOpen={this.state.isOpen}
                  />
                  : null
              }
            </div>
          </div>
        </div>
      </Dropdown>);
  }

  private createNodeLabel = (organisation: OrganisationDetails) => {
    if (!organisation.isWizardClient) {
      return organisation.name;
    }

    return organisation.name + ': ' +
      (organisation.indicatorSystemAdmin ? organisation.indicatorSystemAdmin : '(no admin)');
  };
}
