import {DependencyList, useEffect, useRef} from 'react';
import {Func} from '../types/Func';

export function useTimer(frequencyMilliseconds: number, callback: Func, deps?: DependencyList) {
  const savedCallback = useRef<Func>();
  const timeoutHandleRef = useRef<number>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  const tick = () => {
    clearTimeout(timeoutHandleRef.current);
    savedCallback.current();
    timeoutHandleRef.current = window.setTimeout(tick, frequencyMilliseconds);
  };

  useEffect(
    () => {
      clearTimeout(timeoutHandleRef.current);
      timeoutHandleRef.current = window.setTimeout(tick, frequencyMilliseconds);
      return () => clearTimeout(timeoutHandleRef.current);
    },
    [frequencyMilliseconds, ...deps || []]
  );
}
