import {ProductLine} from '../types/ProductLine';

export default class CodeHelpers {
  public static codeForProductLine(productLine: ProductLine): string | undefined {
    switch (productLine) {
      default:
        return undefined;
      case ProductLine.Workplace:
        return 'WORKPLACE';
      case ProductLine.Lifecycle:
        return 'LIFECYCLE';
      case ProductLine.Assess:
        return 'ASSESS';
    }
  }
}
