import * as React from 'react';
import {DataResponse, StandardResponseData} from '../models/DataResponse';
import DataService from '../services/DataService';
import ConfirmationDialog, {
  ConfirmationDialogOptions,
  ConfirmationType
} from '../components/ConfirmationDialog/ConfirmationDialog';

export function confirmServerAction<T = StandardResponseData>(
  dialogOptions: ConfirmationDialogOptions,
  url: string,
  requestBody: any): Promise<DataResponse<T> | undefined> {

  return new Promise<DataResponse<T> | undefined>(
    async (resolve: (result: DataResponse<T> | undefined) => void,
           reject: (reason?: any) => void) => {
      let okPressed = await ConfirmationDialog.show(dialogOptions);

      if (okPressed) {
        try {
          let result = await DataService.fetch<T>(url, requestBody);
          resolve(result);
        } catch (ex) {
          showErrorIfRequestFailed(ex);
          resolve(ex);
        }
      } else {
        reject();
      }
    });
}

export function showErrorIfRequestFailed(response: DataResponse<any>) {
  if (response.statusCode < 400) {
    return;
  }

  if (response.hasNetworkFailure) {
    showErrorMessage(true);
  } else {
    showErrorMessage();
  }
}

export async function showErrorMessage(showAsOffline: boolean = false) {
  const hasInternetConnection = showAsOffline || await DataService.checkInternetAccess();

  if (hasInternetConnection) {
    ConfirmationDialog.show({
      type: ConfirmationType.Exclamation,
      title: 'Oops, something went wrong',
      maxWidth: 400,
      showCancel: false,
      okText: 'Close',
      okIcon: 'cross-circle-o',
      singletonDialogGroupCode: 'ERROR_MESSAGE',
      content: <div>
        <span>{'An unexpected error has occurred'}</span>
        <br/>
        <span>{'If the issue persists, please contact'}</span>
        <br/>
        <a href={'mailto:support@mindsetmanage.com'}>{'support@mindsetmanage.com'}</a>
      </div>
    });
  } else {
    ConfirmationDialog.show({
      type: ConfirmationType.Exclamation,
      title: 'Oops, connection interrupted',
      maxWidth: 400,
      showCancel: false,
      okText: 'Close',
      okIcon: 'cross-circle-o',
      singletonDialogGroupCode: 'ERROR_MESSAGE',
      content: <div>
        <span>{'Your internet connection seems to be interrupted. Please try again later.'}</span>
      </div>
    });
  }
}

export async function showCustomErrorMessage(title: string, content: string | React.ReactNode) {
  await ConfirmationDialog.show({
    type: ConfirmationType.Exclamation,
    title: title,
    content: content,
    okText: 'Close',
    okIcon: 'cross-circle-o',
    showCancel: false
  });
}

export async function showCustomErrorMessageAdvanced(
  title: string,
  content: string | React.ReactNode,
  onOkayText: string | React.ReactNode,
  onOkay: () => void,
  cancelText: string | React.ReactNode) {

  let okPressed = await ConfirmationDialog.show({
    type: ConfirmationType.Exclamation,
    title: title,
    content: content,
    okText: onOkayText,
    okIcon: <span/>,
    showCancel: true,
    cancelText: cancelText
  });

  if (okPressed) {
    onOkay();
  }
}

export async function showSuccessMessage(title: string, content: string | React.ReactNode) {
  await ConfirmationDialog.show({
    type: ConfirmationType.Success,
    title: title,
    content: content,
    okText: 'Close',
    okIcon: 'check-circle-o',
    showCancel: false
  });
}

export async function confirmAbandonChanges(onChangesAbandoned: () => void) {
  let okPressed = await ConfirmationDialog.show({
    title: 'Abandon changes',
    content: 'You are about to lose your changes. Continue?'
  });

  if (okPressed) {
    onChangesAbandoned();
  }
}
