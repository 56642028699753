import * as React from 'react';
import {MercuryQuery} from '../types/MercuryQuery';
import {MercuryResult} from '../types/MercuryResult';
import CallbackHelpers from '../../shared-common/helpers/CallbackHelpers';

export interface MercuryContextProps {
  executeQuery(query: MercuryQuery): MercuryResult;
}

export const Mercury = React.createContext<MercuryContextProps>({
  executeQuery: CallbackHelpers.NotImplemented
});
