export type TreeNode<T extends TreeNode<T>> = {
  id: string;
  children?: T[];
};

export function findAllNodesInPrunedTree<T extends TreeNode<T>>
(nodes: T[],
 shouldKeepNode: (node: T) => boolean,
 keepEntireBranch: boolean = false): T[] {

  let keptNodes: Set<T> = new Set();
  findAllNodesInPrunedTreeInternal(nodes, shouldKeepNode, keptNodes, keepEntireBranch);
  return [...keptNodes.values()];
}

function findAllNodesInPrunedTreeInternal<T extends TreeNode<T>>
(nodes: T[],
 shouldKeepNode: (node: T) => boolean,
 keptNodes: Set<T>,
 keepEntireBranch: boolean): boolean {

  let wasNodeKept: boolean = false;

  for (let node of nodes) {
    if (shouldKeepNode(node)) {
      if (!keptNodes.has(node)) {
        keptNodes.add(node);
      }
      wasNodeKept = true;
    }

    if (!node.children || node.children.length === 0) {
      continue;
    }

    if (keepEntireBranch && wasNodeKept) {
      iterateOverTree(node.children, (n: T) => {
        if (!keptNodes.has(n)) {
          keptNodes.add(n);
        }
      });
    } else {
      if (findAllNodesInPrunedTreeInternal(node.children, shouldKeepNode, keptNodes, keepEntireBranch)) {
        if (!keptNodes.has(node)) {
          keptNodes.add(node);
        }
        wasNodeKept = true;
      }
    }
  }

  return wasNodeKept;
}

export function shakeTree<T extends TreeNode<T>>(rootNodes: T[],
                                                 isNodeAlive: (node: T) => boolean): T[] {
  let nodesOnPath: Set<T> = new Set();
  shakeTreeInternal(rootNodes, isNodeAlive, nodesOnPath);
  let result: T[] = [];
  nodesOnPath.forEach(value => result.push(value));
  return result;
}

export function shakeTreeInternal<T extends TreeNode<T>>(nodes: T[],
                                                         isNodeAlive: (node: T) => boolean,
                                                         nodesOnPath: Set<T>
): boolean {
  let wasNodeKept: boolean = false;

  for (let node of nodes) {
    if (isNodeAlive(node)) {
      if (!nodesOnPath.has(node)) {
        nodesOnPath.add(node);
      }
      wasNodeKept = true;
    }

    if (!node.children || node.children.length === 0) {
      continue;
    }

    if (shakeTreeInternal(node.children, isNodeAlive, nodesOnPath)) {
      if (!nodesOnPath.has(node)) {
        nodesOnPath.add(node);
      }
      wasNodeKept = true;
    }
  }

  return wasNodeKept;
}

export interface TreeNodeInfo<T extends TreeNode<T>> {
  depth: number;
  parent?: T;
}

export function iterateOverTree<T extends TreeNode<T>>(nodes: T[],
                                                       forEachNode: (node: T, info: TreeNodeInfo<T>) => void) {
  iterateOverTreeInternal(nodes, forEachNode, 0, undefined);
}

function iterateOverTreeInternal<T extends TreeNode<T>>(nodes: T[],
                                                        forEachNode: (node: T, info: TreeNodeInfo<T>) => void,
                                                        depth: number,
                                                        parent: TreeNode<T> | undefined) {
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    forEachNode(
      node,
      {
        depth: depth,
        parent: parent
      } as TreeNodeInfo<T>
    );

    if (node.children && node.children.length > 0) {
      iterateOverTreeInternal(
        node.children,
        forEachNode,
        depth + 1,
        node
      );
    }
  }
}

export function findTreeDepth<T extends TreeNode<T>>(nodes: T[]): number {
  return findTreeDepthInternal<T>(nodes, 1);
}

function findTreeDepthInternal<T extends TreeNode<T>>(nodes: T[], depth: number): number {
  let maxDepth: number = depth;

  for (let node of nodes) {
    if (node.children && node.children.length > 0) {
      const maxNodeDepth = findTreeDepthInternal(node.children, depth + 1);

      if (maxNodeDepth > maxDepth) {
        maxDepth = maxNodeDepth;
      }
    }
  }

  return maxDepth;
}

export function flattenTree<T extends TreeNode<T>>(nodes: T[]): T[] {
  let flattenedNodes: T[] = [];
  flattenTreeInternal(nodes, flattenedNodes);
  return flattenedNodes;
}

function flattenTreeInternal<T extends TreeNode<T>>(nodes: T[], flattenedNodes: T[]) {
  for (let node of nodes) {
    flattenedNodes.push(node);
    if (node.children && node.children.length > 0) {
      flattenTreeInternal(node.children, flattenedNodes);
    }
  }
}

// Performs a breadth-first search of the tree
export function findNodeInTree<T extends TreeNode<T>>(nodes: T[], predicate: (node: T) => boolean): T | undefined {
  for (let node of nodes) {
    if (predicate(node)) {
      return node;
    }
  }

  for (let node of nodes) {
    if (node.children) {
      let foundNode = findNodeInTree<T>(node.children as T[], predicate);
      if (foundNode) {
        return foundNode;
      }
    }
  }

  return undefined;
}

// Find the depth of a node in the tree, using a depth first search
export function findNodeDepthInTree<T extends TreeNode<T>>(
  nodes: T[],
  predicate: (node: T) => boolean): number | undefined {
  return findNodeDepthInTreeInternal(nodes, predicate, 0);
}

function findNodeDepthInTreeInternal<T extends TreeNode<T>>(
  nodes: T[],
  predicate: (node: T) => boolean,
  depth: number): number | undefined {

  for (let node of nodes) {
    if (predicate(node)) {
      return depth;
    }
  }

  for (let node of nodes) {
    if (node.children) {
      let foundDepth = findNodeDepthInTreeInternal<T>(node.children as T[], predicate, depth + 1);
      if (foundDepth) {
        return foundDepth;
      }
    }
  }

  return undefined;
}

export function buildTree<T extends TreeNode<T>>
(nodes: T[],
 parentIdAccessor: (node: T) => string | undefined): T[] {
  let nodeMap = new Map<string, T>();

  for (let node of nodes) {
    nodeMap.set(node.id, node);
  }

  let roots: T[] = [];

  for (let node of nodes) {
    let parentId = parentIdAccessor(node);
    if (!parentId) {
      roots.push(node);
    } else {
      let parent = nodeMap.get(parentId);

      if (parent) {
        if (!parent.children) {
          parent.children = [];
        }
        parent.children.push(node);
      } else {
        roots.push(node);
      }
    }
  }

  return roots;
}