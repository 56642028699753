import * as React from 'react';
import Form from 'antd/es/form/Form';
import styles from './FormSection.module.css';
import {CSSProperties, ReactChild} from 'react';
import FormErrorMessage from '../FormErrorMessage/FormErrorMessage';

interface FormSectionProps {
  className?: string;
  style?: CSSProperties;
  controlWidth?: number;
  formWidth?: number;
  errorMessage?: string;
  layout?: 'horizontal' | 'inline' | 'vertical' | 'toolbox';
  hideErrors?: boolean;
}

export default class FormSection extends React.Component<FormSectionProps, {}> {
  render() {
    return (
      <Form
        className={[styles.form, this.props.className].join(' ')}
        hideRequiredMark={true}
        layout={this.props.layout === 'toolbox' ? 'horizontal' : this.props.layout}
        style={{width: this.props.formWidth || undefined, ...this.props.style}}
      >
        {React.Children.map(this.props.children as ReactChild[], (child: ReactChild) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              formControlWidth: this.props.controlWidth,
              formLayout: this.props.layout
            } as any);
          } else {
            return child;
          }
        })}
        {this.props.hideErrors !== true
          ? <FormErrorMessage errorMessage={this.props.errorMessage}/>
          : null}
      </Form>);
  }
}