import * as React from 'react';
import {CSSProperties} from 'react';
import {classes} from '../../helpers/RenderHelpers';
import styles from './StackPanel.module.css';
import {ResponsiveLength} from '../../models/ResponsiveLength';
import {calculateResponsiveLength} from '../../helpers/ScreenSizeHelpers';
import {isNil} from 'lodash-es';
import {MouseEventHandler} from 'react';
import {HorizontalAlignment} from '../../../shared-ui/types/HorizontalAlignment';
import {VerticalAlignment} from '../../../shared-ui/types/VerticalAlignment';

export interface StackPanelProps {
  className?: string;
  style?: CSSProperties;
  orientation: 'horizontal' | 'vertical';
  horizontalAlignment?: Exclude<HorizontalAlignment, 'stretch'>;
  verticalAlignment?: Exclude<VerticalAlignment, 'stretch'>;
  width?: number | ResponsiveLength | 'fill';
  height?: number | ResponsiveLength | 'fill';
  onClick?: MouseEventHandler<HTMLDivElement>;
  id?: string;
}

export default class StackPanel extends React.Component<StackPanelProps, {}> {

  render() {
    return (
      <div
        id={this.props.id}
        className={
          classes(
            styles.component,
            this.props.className,
            this.props.width === 'fill' ? styles.fillWidth : null,
            this.props.height === 'fill' ? styles.fillHeight : null,
            this.props.orientation === 'horizontal' ? styles.orientHorizontal : styles.orientVertical,
            this.props.horizontalAlignment === 'left' ? styles.horizontalAlignmentLeft : null,
            isNil(this.props.horizontalAlignment) || this.props.horizontalAlignment === 'center'
              ? styles.horizontalAlignmentCenter
              : null,
            this.props.horizontalAlignment === 'right' ? styles.horizontalAlignmentRight : null,
            this.props.verticalAlignment === 'top' ? styles.verticalAlignmentTop : null,
            isNil(this.props.verticalAlignment) || this.props.verticalAlignment === 'center'
              ? styles.verticalAlignmentCenter
              : null,
            this.props.verticalAlignment === 'bottom' ? styles.verticalAlignmentBottom : null
          )}
        style={{
          width: this.width,
          height: this.height,
          ...this.props.style
        }}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </div>);
  }

  private get height(): number | undefined {
    if (this.props.height === 'fill') {
      return undefined;
    }

    return calculateResponsiveLength(this.props.height);
  }

  private get width(): number | undefined {
    if (this.props.width === 'fill') {
      return undefined;
    }

    return calculateResponsiveLength(this.props.width);
  }
}
