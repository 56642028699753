import AirbrakeClient from 'airbrake-js';
import {FetchOperation} from '../services/DataService';
import ConfigService from '../services/ConfigService';

export function handleError(ex: Error, context?: any, environment?: any) {
  let client = new AirbrakeClient(
    {
      projectId: ConfigService.config.airbrakeProjectId,
      projectKey: ConfigService.config.airbrakeProjectKey
    });
  client.notify({
      error: JSON.stringify(ex),
      context: context,
      environment: environment || {env: ConfigService.config.environment}
    }
  );
}

export function handleFetchError(operation: FetchOperation, url: string,
                                 requestBody: any, statusCode: number,
                                 errorReason?: string) {

  if (statusCode < 500 || statusCode >= 599) {
    return;
  }

  handleError(
    new Error(
      `Fetch failed for ${operation} ${url} ${JSON.stringify(requestBody)} - [${statusCode}] ${errorReason || ''}`
    ),
    {
      url: url,
      requestBody: requestBody,
      statusCode: statusCode,
      errorReason: errorReason
    }
  );
}