import * as React from 'react';
import {PropsWithChildren, useState} from 'react';
import {useCache} from '../../shared-common/hooks/useCache';
import DataService from '../../shared/services/DataService';
import {Rater} from '../../shared-common/types/Rater';
import {RaterCache} from '../../shared-common/context/RaterCache';
import {RaterFilter} from '../../shared-common/types/RaterFilter';

export const RaterStore = (props: PropsWithChildren<{}>) => {
  const [isProcessing, setIsProcessing] = useState<boolean>();

  const {clearCache, getValue} = useCache<RaterFilter, Rater[]>(
    async (key: RaterFilter) => {
      setIsProcessing(true);
      try {
        return await DataService.tryLoad<Rater[]>(
          '/master-data/get-raters', {
            datasetIds: key.datasetIds,
            assesseeIds: key.assesseeIds,
            onlyWithResponses: key.onlyWithResponses
          });
      } finally {
        setIsProcessing(false);
      }
    }
  );

  const getRaters = (filter: RaterFilter) => {
    return getValue(filter);
  };

  const clearRaterCache = () => {
    clearCache();
  };

  return (
    <RaterCache.Provider
      value={{
        getRaters: getRaters,
        clearRaterCache: clearRaterCache,
        isRaterCacheProcessing: isProcessing
      }}
    >
      {props.children}
    </RaterCache.Provider>
  );
};
