import * as React from 'react';
import {ContextTheme} from '../../../app/context/ThemeContext';
import tinycolor from 'tinycolor2';

export interface Theme {
  baseFontSize: string;
  inputBorderRadius: number;
  buttonBorderRadius: number;
  popupBorderRadius: number;
  dialogBorderRadius: number;
  tabsBorderRadius: number;
  moduleMenuBackgroundColour1: string;
  moduleMenuBackgroundColour2: string;
  moduleMenuTextColour1: string;
  moduleMenuTextColour2: string;
  siderNavigationItem: MenuStates;
  toolBoxNavigation: MenuStates;
  subMenuNavigation: MenuStates;
  defaultSiderToolboxIcons: ColorRange;
  defaultSiderToolboxIconsHover: ColorRange;
  tabs: MenuStates;
  dropShadowColor: string;
  siderMenuSize: number;
  siderMenuCollapseSize: number;
  pageContentPadding: number;
  questionnairePrimaryColour: string;
  questionnairePrimaryTextColour: string;
  questionnaireSecondaryColour: string;
  questionnaireSecondaryTextColour: string;
  questionnaireTertiaryColour: string;
  questionnaireTertiaryTextColour: string;
  dropdownHighlightColour: string;
  implication: ImplicationIconStyle;
  InsightIcon1: MenuStates;
  InsightIcon2: MenuStates;
}

export interface ImplicationIconStyle {
  High: ColorRange;
  Middle: ColorRange;
  Low: ColorRange;
  Lowest: ColorRange;
}

export interface MenuStates {
  normal: MenuNode;
  selected: MenuNode;
  hover: MenuNode;
}

export interface MenuNode {
  icon: ColorRange;
  textColor: string;
  background: string;
  iconStroke?: string;
  iconBackground?: string;
}

export interface ButtonStyle {
  color: string;
  textColor: string;
  highlightColor: string;
}

export interface ColorRange {
  primary: string;
  secondary: string;
  tertiary: string;
  quaternary?: string;
}

interface ThemeProviderProps {
  theme: Theme;
  themeContext: ContextTheme;
}

export default class ThemeProvider extends React.Component<ThemeProviderProps, {}> {

  private static addOpacityToRgbColour(colour: string, opacity: number) {
    colour = colour.replace('rgb(', 'rgba(');
    colour = colour.replace(')', ',' + opacity.toString() + ')');
    return colour;
  }

  componentWillReceiveProps(nextProps: Readonly<ThemeProviderProps>, nextContext: any): void {

    if (this.props.themeContext !== nextProps.themeContext) {
      this.removeStyleElement();
      this.createStyleElement(nextProps);
    }
  }

  componentDidMount() {
    this.createStyleElement(this.props);
  }

  componentWillUnmount() {
    this.removeStyleElement();
  }

  private createStyleElement = (themeProps: Readonly<ThemeProviderProps>) => {
    const dynamicStyle = document.createElement('style');

    dynamicStyle.id = 'theme-style';

    let css: string = '';

    css += this.common(themeProps.theme);
    css += this.sideNavigation();
    css += this.toolbox();
    css += this.responsiveStyling();
    css += this.pageMenu(themeProps);
    css += this.onlineQuestionnaire();
    css += this.interactive(themeProps);

    // Append style element to head
    dynamicStyle.innerHTML = css;
    document.head.appendChild(dynamicStyle);
  };

  private removeStyleElement = () => {
    const dynamicStyle = document.getElementById('theme-style');
    if (dynamicStyle && dynamicStyle.parentElement) {
      dynamicStyle.parentElement.removeChild(dynamicStyle);
    }
  };

  common(theme: Theme) {
    // language=CSS
    return `
      .shadowAndCurve {
        box-shadow: 1px 2px 6px rgba(88, 88, 88, 0.278);
        border: 1px solid #58585829;
        border-radius: 3px;
      }
      
      .ant-layout-sider.ant-layout-sider-collapsed {
        min-width: ${theme.siderMenuSize}px!important;
        width:  ${theme.siderMenuSize}px!important;
        flex: 0 1  ${theme.siderMenuSize}px!important;
      }
      
      .ant-layout-sider   {
        max-width:  ${theme.siderMenuSize}px!important;
        min-width: ${theme.siderMenuSize}px!important;
        width:  ${theme.siderMenuSize}px!important;
        flex: 1 1  ${theme.siderMenuSize}px!important;
        height: 100%;
      }        
      
        .ant-select-dropdown-menu-item:hover {
          background-color: ${ThemeProvider.addOpacityToRgbColour(theme.dropdownHighlightColour, 0.2)};
        }
        .ant-select-dropdown-menu-item-active {
          background-color: ${ThemeProvider.addOpacityToRgbColour(theme.dropdownHighlightColour, 0.2)};
        }  
        .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
          background-color: ${ThemeProvider.addOpacityToRgbColour(theme.dropdownHighlightColour, 0.2)};
        }
    `;
  }

  responsiveStyling() {
    // language=CSS
    return `
        @media (max-width: 575px) {
            .ant-col-xs-8 {
                width: 33.33333333%;
            }

            .ant-col-xs-10 {
                width: 41.66666667%;
            }
        }
    `;
  }

  toolbox() {
    // language=CSS
    return `
      .icon-toolbox-menu-default-primary  {
        fill: #788da8;
      }
      
      .icon-toolbox-menu-default-tertiary {
        fill: #fff;
      }
      
      .toolbox
      {
        background-color:${this.props.theme.toolBoxNavigation.normal.background}!important ;
      }
      
      .toolbox >.ant-menu-submenu ,  .ant-menu-submenu-inline
      {
        background-color: ${this.props.theme.toolBoxNavigation.normal.background};
      }
      
      .toolbox > .ant-menu-submenu, .ant-menu-submenu-title
      {
        color:${this.props.theme.toolBoxNavigation.normal.textColor};!important;
      }
      
     /*.toolbox > .ant-menu-submenu-open > 
     .ant-menu-submenu-title > 
     .ant-menu-submenu-arrow:before {
          background-color:${this.props.theme.toolBoxNavigation.selected.icon.primary};
          background:${this.props.theme.toolBoxNavigation.selected.icon.primary};
      }*/
      
      .toolbox > .ant-menu-submenu-open > 
     .ant-menu-submenu-title > 
     .ant-menu-submenu-arrow:after {
          background-color:${this.props.theme.toolBoxNavigation.selected.icon.primary};
          background:${this.props.theme.toolBoxNavigation.selected.icon.primary};
      } 
      
      /* normal */
      .toolbox > .ant-menu-submenu > .ant-menu-submenu-title > .toolboxTitle > .toolBoxIcon > svg >
      .icon-primary {
        fill:${this.props.theme.toolBoxNavigation.normal.icon.primary};
      }
      
      .toolbox > .ant-menu-submenu > .ant-menu-submenu-title > .toolboxTitle > .toolBoxIcon > svg 
      .icon-secondary {
        fill:${this.props.theme.toolBoxNavigation.normal.icon.secondary};
      }
      
      .toolbox > .ant-menu-submenu > .ant-menu-submenu-title > .toolboxTitle > .toolBoxIcon > svg 
      .icon-tertiary {
        stroke:${this.props.theme.toolBoxNavigation.normal.icon.tertiary};
      }
      
      /* selected */  
      .toolbox > .ant-menu-submenu-open > .ant-menu-submenu-title > .toolboxTitle > .toolBoxIcon > svg 
      .icon-primary {
        fill:${this.props.theme.toolBoxNavigation.selected.icon.primary};
      }
      
      .toolbox > .ant-menu-submenu-open > .ant-menu-submenu-title > .toolboxTitle > .toolBoxIcon > svg  
      .icon-secondary {
        fill:${this.props.theme.toolBoxNavigation.selected.icon.secondary};
      }
      
      .toolbox > .ant-menu-submenu-open > .ant-menu-submenu-title > .toolboxTitle > .toolBoxIcon > svg  
      .icon-tertiary {
        stroke:${this.props.theme.toolBoxNavigation.selected.icon.tertiary};
      }
      
      /* hover */ 
      .toolbox > .ant-menu-submenu> .ant-menu-submenu-title:hover  > .toolboxTitle > .toolBoxIcon > svg  
      .icon-primary {
        fill:${this.props.theme.toolBoxNavigation.hover.icon.primary};
      }
      
      .toolbox > .ant-menu-submenu > .ant-menu-submenu-title:hover > .toolboxTitle > .toolBoxIcon > svg  
      .icon-secondary {
        fill:${this.props.theme.toolBoxNavigation.hover.icon.secondary};
      }
      
      .toolbox > .ant-menu-submenu > .ant-menu-submenu-title:hover > .toolboxTitle > .toolBoxIcon > svg  
      .icon-tertiary {
        stroke:${this.props.theme.toolBoxNavigation.hover.icon.tertiary};
      }
      
      .ant-menu-submenu-vertical > 
      .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu-submenu-vertical-left > 
      .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu-submenu-vertical-right > 
      .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu-submenu-inline > 
      .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu-submenu-vertical > 
      .ant-menu-submenu-title .ant-menu-submenu-arrow:before, .ant-menu-submenu-vertical-left > 
      .ant-menu-submenu-title .ant-menu-submenu-arrow:before, .ant-menu-submenu-vertical-right > 
      .ant-menu-submenu-title .ant-menu-submenu-arrow:before, .ant-menu-submenu-inline > 
      .ant-menu-submenu-title .ant-menu-submenu-arrow:before
      {
        background: ${this.props.theme.toolBoxNavigation.normal.icon.primary};
        background-image: linear-gradient(to right, ${this.props.theme.toolBoxNavigation.normal.icon.primary}, 
        ${this.props.theme.toolBoxNavigation.normal.icon.primary});
      }
      
      /* Hover over ToolBox */
      .ant-menu-submenu-vertical > 
      .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after, .ant-menu-submenu-vertical-left > 
      .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after, .ant-menu-submenu-vertical-right > 
      .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after, .ant-menu-submenu-inline > 
      .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after, .ant-menu-submenu-vertical > 
      .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before, .ant-menu-submenu-vertical-left > 
      .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before, .ant-menu-submenu-vertical-right > 
      .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before, .ant-menu-submenu-inline > 
      .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before
      {
        background: ${this.props.theme.toolBoxNavigation.hover.icon.primary};
        background-image: linear-gradient(to right, ${this.props.theme.toolBoxNavigation.hover.icon.primary} , 
        ${this.props.theme.toolBoxNavigation.hover.icon.primary});
      }
      
      .toolbox > .ant-menu-submenu > .ant-menu-submenu-title:hover
      {
        color:${this.props.theme.toolBoxNavigation.hover.textColor}!important;
      }
        
      .toolboxDefaultIcons > svg > .icon-tertiary {
          stroke: ${this.props.theme.defaultSiderToolboxIcons.tertiary}!important;
      }
      
      .toolboxDefaultIcons:hover > svg > .icon-primary {
        fill:  ${this.props.theme.defaultSiderToolboxIconsHover.primary}!important;
      }
      
      .toolboxDefaultIcons:hover > svg > .icon-secondary {
        fill:  ${this.props.theme.defaultSiderToolboxIconsHover.secondary}!important;
      }
      
      .toolboxDefaultIcons:hover > svg > .icon-tertiary {
        stroke: ${this.props.theme.defaultSiderToolboxIconsHover.tertiary}!important;
      }
      
      
      .toolbox .ant-select{
          height: 32px;
          font-family: "Avant Garde Bk BT";
      }
      
      .toolbox .ant-select-selection:hover{
          border:1px solid #b8c3d2;
      }
      
      .toolbox .ant-select-selection:active
      {
          border:1px solid #b8c3d2;
      }
      
      .toolbox .ant-select {
          height: 25px;
      }
      
      .toolbox .ant-select-selection {
          height: 25px;
      }
    `;
  }

  sideNavigation() {
    // language=CSS
    return ` 
    /* Standard None Selected Menu Items */
      
    .sideNavigation .icon-primary {
      fill:${this.props.theme.siderNavigationItem.normal.icon.primary};
    }
    
    .sideNavigation .icon-secondary{
      fill:${this.props.theme.siderNavigationItem.normal.icon.secondary};
    }
    
    .sideNavigation .icon-tertiary {
      stroke:${this.props.theme.siderNavigationItem.normal.icon.tertiary};
    }
  
    .sideNavigation .ant-menu-item {
      border-radius: ${this.props.theme.tabsBorderRadius}px 0px 0px ${this.props.theme.tabsBorderRadius}px;
      background-color: ${this.props.theme.siderNavigationItem.normal.background};
      color: ${this.props.theme.siderNavigationItem.normal.textColor};
    }
  
  
    
    .sideNavigation.ant-menu-inline-collapsed > .ant-menu-item
    {
      background-color:${this.props.theme.siderNavigationItem.normal.background};
    }

     
    
     .sideNavigation .ant-menu-item-selected {
        background-color: ${this.props.theme.siderNavigationItem.selected.background};
        color: ${this.props.theme.siderNavigationItem.selected.textColor};
     }
    
    .sideNavigation > .ant-menu-item-selected:after {
    background-color:${this.props.theme.siderNavigationItem.selected.icon.secondary};
  }

    .sideNavigation  .ant-menu-item-selected {
    background-color: ${this.props.theme.siderNavigationItem.selected.background}!important;
    color: ${this.props.theme.siderNavigationItem.selected.textColor};
  }

    .sideNavigation .ant-menu-item-selected > .iconHolder > svg  .icon-primary{
    fill:${this.props.theme.siderNavigationItem.selected.icon.primary};
  }
  
    .sideNavigation .ant-menu-item-selected > .iconHolder > svg  .icon-secondary{
    fill:${this.props.theme.siderNavigationItem.selected.icon.secondary};
  }

    .sideNavigation .ant-menu-item-selected > .iconHolder > svg  .icon-tertiary{
    stroke:${this.props.theme.siderNavigationItem.selected.icon.tertiary};
  }




    /* Standard Selected Menu: Hover Items */
   
    
    .sideNavigation  .ant-menu-item:hover:not(.ant-menu-item-selected) {
    background-color: ${this.props.theme.siderNavigationItem.hover.background};
    color: ${this.props.theme.siderNavigationItem.hover.textColor};
  }

    .sideNavigation .ant-menu-item:hover:not(.ant-menu-item-selected) > 
    .side-menu-icon > svg  .icon-primary{
    fill:${this.props.theme.siderNavigationItem.hover.icon.primary};
  }
  
    .sideNavigation .ant-menu-item:hover:not(.ant-menu-item-selected) > 
    .side-menu-icon > svg  .icon-secondary{
    fill:${this.props.theme.siderNavigationItem.hover.icon.secondary};
  }

    .sideNavigation .ant-menu-item:hover:not(.ant-menu-item-selected) > 
    .side-menu-icon > svg  .icon-tertiary{
    stroke:${this.props.theme.siderNavigationItem.hover.icon.tertiary};
  }
  `;
  }

  onlineQuestionnaire() {
    // language=CSS
    return `
  .onlineQuestionnaire .ant-select-selection:hover {
    border-color: ${this.props.theme.questionnaireTertiaryColour};
  }
  .onlineQuestionnaire .ant-select-focused .ant-select-selection,
  .onlineQuestionnaire .ant-select-selection:focus,
  .onlineQuestionnaire .ant-select-selection:active {
    border-color: ${this.props.theme.questionnaireTertiaryColour};
    box-shadow: 0 0 0 2px ${ThemeProvider.addOpacityToRgbColour(this.props.theme.questionnaireTertiaryColour, 0.2)};
  }
  .onlineQuestionnaire  .ant-select-open .ant-select-selection {
    border-color: ${this.props.theme.questionnaireTertiaryColour};
    box-shadow: 0 0 0 2px ${ThemeProvider.addOpacityToRgbColour(this.props.theme.questionnaireTertiaryColour, 0.2)};
  }
    
  .onlineQuestionnaire .commentQuestion textarea:focus {
    outline: none;
    border:1px solid ${this.props.theme.questionnaireTertiaryColour};
    box-shadow: 0 0 0 2px ${ThemeProvider.addOpacityToRgbColour(this.props.theme.questionnaireTertiaryColour, 0.2)};
  }

  .onlineQuestionnaire .commentQuestion textarea:hover:not(:focus) {
    outline: none;
    border:1px solid ${this.props.theme.questionnaireTertiaryColour};
  }
  
  @media all and (min-width: 577px) {
    .onlineQuestionnaire div:hover > .multipleChoiceOption {
      border: 1px solid ${this.props.theme.questionnaireTertiaryColour};
      box-shadow: 0 0 0 1px ${ThemeProvider.addOpacityToRgbColour(this.props.theme.questionnaireTertiaryColour, 0.75)};
      background-color: ${ThemeProvider.addOpacityToRgbColour(this.props.theme.questionnaireTertiaryColour, 0.40)};
    }
  }

  .onlineQuestionnaire .multipleChoiceOption.selected {
    color: ${this.props.theme.questionnaireTertiaryTextColour};
    background: ${this.props.theme.questionnaireTertiaryColour};
  }
  
  .onlineQuestionnaire .multipleChoiceOption.selected .tick{
    border-color: ${this.props.theme.questionnaireTertiaryTextColour};
  }
    
  .onlineQuestionnaire .questionnairePageFooter .ant-progress-bg {
    background: ${this.props.theme.questionnairePrimaryColour};
  }
  
  .onlineQuestionnaire .numberedItem.numberedItemHighlighted {
    color: ${this.props.theme.questionnairePrimaryTextColour};
  }
  
  .onlineQuestionnaire .numberedItemHighlighted .numberedItemPanel {
    background: ${this.props.theme.questionnairePrimaryColour};
    color: ${this.props.theme.questionnairePrimaryTextColour};
  }
  
  .onlineQuestionnaire .numberedItemHighlighted .numberedItemItemNumber {
    background: ${this.props.theme.questionnairePrimaryColour};
    color: ${this.props.theme.questionnairePrimaryTextColour};
  }
  
  .onlineQuestionnaire .progressHeader {
    background: ${this.props.theme.questionnaireSecondaryColour};
    color: ${this.props.theme.questionnaireSecondaryTextColour};
  }
  
  .onlineQuestionnaire .progressHeader .pending {
    background: ${this.props.theme.questionnairePrimaryColour};
    color: ${this.props.theme.questionnairePrimaryTextColour};
  }
    `;
  }

  pageMenu(theme: Readonly<ThemeProviderProps>) {

    let applyHoverColorEffect = (color: string) => tinycolor(color).desaturate(25).brighten(10).toString();

    let applyDisabledColorEffect = (color: string) => tinycolor(color).desaturate(100).lighten(20).toString();

    // language=CSS
    return `      
        .pageMenuTab svg .icon-primary {
          fill: ${applyDisabledColorEffect(theme.themeContext.icons.primaryColor)};
        }
        
        .pageMenuTab svg .icon-secondary {
          fill: ${applyDisabledColorEffect(theme.themeContext.icons.secondaryColor)};
        }
      
        .pageMenuTab svg .icon-tertiary {
          stroke: ${applyDisabledColorEffect(theme.themeContext.icons.tertiaryColor)};
        }
        
        .pageMenuTab:hover svg .icon-primary {
          fill: ${applyHoverColorEffect(theme.themeContext.icons.primaryColor)};
        }
        
        .pageMenuTab:hover svg.icon-secondary {
          fill: ${applyHoverColorEffect(theme.themeContext.icons.secondaryColor)}
        }
        
        .pageMenuTab:hover svg .icon-tertiary {
          stroke: ${applyHoverColorEffect(theme.themeContext.icons.tertiaryColor)}
        }
                
        .pageMenuTab.active svg .icon-primary {
          fill: ${theme.themeContext.icons.primaryColor}
        }
        
        .pageMenuTab.active svg .icon-secondary {
          fill: ${theme.themeContext.icons.secondaryColor}
        }
        
        .pageMenuTab.active svg .icon-tertiary {
          stroke: ${theme.themeContext.icons.tertiaryColor}
        }
        
        .pageMenuTab span {
          color: ${theme.theme.tabs.normal.textColor}
        }
        
        .pageMenuTab:hover > span {
          color: ${theme.theme.tabs.hover.textColor}
        }
        
        .pageMenuTab.active > span {
          color: ${theme.theme.tabs.selected.textColor};
          border-bottom: 3px solid ${theme.theme.siderNavigationItem.selected.background};
        }
    `;
  }

  interactive(theme: Readonly<ThemeProviderProps>) {

    let applyHoverColorEffect = (color: string) => tinycolor(color).desaturate(25).brighten(10).toString();

    // language=CSS
    return `      
        .interactive svg .icon-primary {
          fill: ${theme.themeContext.icons.primaryColor};
        }
        
        .interactive svg .icon-secondary {
          fill: ${theme.themeContext.icons.secondaryColor};
        }
      
        .interactive svg .icon-tertiary {
          stroke: ${theme.themeContext.icons.tertiaryColor};
        }
        
        .interactive:hover svg .icon-primary {
          fill: ${applyHoverColorEffect(theme.themeContext.icons.primaryColor)};
        }
        
        .interactive:hover svg .icon-secondary {
          fill: ${applyHoverColorEffect(theme.themeContext.icons.secondaryColor)}
        }
        
        .interactive:hover svg .icon-tertiary {
          stroke: ${applyHoverColorEffect(theme.themeContext.icons.tertiaryColor)}
        }
    `;
  }

  render() {
    return this.props.children;
  }
}
