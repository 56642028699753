import * as React from 'react';
import CallbackHelpers from '../helpers/CallbackHelpers';

export type AppClipboardDataType = 'RuleElement' | 'Element' | 'Formula';

export interface AppClipboardProps {
  copyToClipboard: (type: AppClipboardDataType, data: unknown) => void;
  pasteFromClipboard: (type: AppClipboardDataType) => unknown | undefined;
  clearClipboard: () => void;
  hasCopiedData: (type: AppClipboardDataType) => boolean;
}

export const AppClipboard = React.createContext<AppClipboardProps>({
  copyToClipboard: CallbackHelpers.NoOp,
  pasteFromClipboard: CallbackHelpers.Nil,
  clearClipboard: CallbackHelpers.NoOp,
  hasCopiedData: CallbackHelpers.Never
});
