import {filter} from 'lodash-es';
import {Page} from '../models/Page';
import {ContentWithToolboxProps, NavigationItem} from '../components/PageSiderMenu/PageSiderMenu';
import React from 'react';

export function createSidePanelMenu(navPages: Page<ContentWithToolboxProps>[] | undefined): NavigationItem[] {
  let tabs: NavigationItem[] = [];

  let activePages = filter(
    navPages || [],
    (page: Page<ContentWithToolboxProps>) => page.isVisible() && (page.showInMenu !== false
    )
  );

  for (let activePage of activePages) {
    if (activePage) {
      tabs.push({
        name: activePage.name,
        content: activePage.content,
        urlSlug: activePage.urlSlug,
        icon: activePage.icon || React.createElement('div', {}, '')
      });
    }
  }

  return tabs;
}

export function navigateToExternalLink(navigationURL: string) {
  let link = document.createElement('a');
  link.href = navigationURL;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
