import * as React from 'react';
import styles from './CircleIcon.module.css';
import {CSSProperties} from 'react';
import {classes} from '../../helpers/RenderHelpers';

interface CircleIconProps {
  className?: string;
  style?: CSSProperties;
}

export default class CircleIcon extends React.Component<CircleIconProps, {}> {
  render() {
    return  (
      <div
        className={classes(styles.circle, this.props.className)}
        style={this.props.style}
      >
        {this.props.children}
      </div>
    );
  }

}