import * as React from 'react';
import {PropsWithChildren} from 'react';
import DataService from '../../shared/services/DataService';
import {useSurveyModelStringsStore} from '../hooks/useSurveyModelStringsStore';
import {SurveyModelStringCache} from '../context/SurveyModelStringCache';
import {SurveyModelStringsData} from '../types/SurveyModelStringsData';

export const SurveyModelStringStore = (props: PropsWithChildren<unknown>) => {
  const {
    getSurveyModelStringByCode,
    getSurveyModelStringByCodeAsync,
    getTranslatedStringByCode,
    getTranslatedStringByCodeAsync,
    hasTranslation,
    hasTranslationAsync,
    setLocalSurveyModelString
  } = useSurveyModelStringsStore(
    async (key) => {
      const data = await DataService.tryLoad<SurveyModelStringsData>(
        '/engage-survey/survey-model/load-survey-model-strings',
        {
          surveyModelId: key.surveyModelId,
          languageCode: key.languageCode
        }
      );
      return data;
    },
    []
  );

  return (
    <SurveyModelStringCache.Provider
      value={{
        getSurveyModelStringByCode,
        getSurveyModelStringByCodeAsync,
        getTranslatedStringByCode,
        getTranslatedStringByCodeAsync,
        hasTranslation,
        hasTranslationAsync,
        setLocalSurveyModelString
      }}
    >
      {props.children}
    </SurveyModelStringCache.Provider>
  );
};
